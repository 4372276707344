import { useMemo, useState, useEffect, useContext, useCallback } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import MonthlyChart from "./MonthlyChart";
import AnnualChart from "./AnnualChart";
import ReviewsTable from "./ReviewsTable";
import StatsComponent from "./StatsComponent";
import DialogComponent from "../DialogComponent";
import { listReviews } from "../../graphql/queries";
import listAll from "../../utils/list-all";
import { graphqlOperation, API } from "aws-amplify";
import useSWR from "swr";

import {
  reviewsByOrganization,
  reviewsByBusiness,
  reviewsByExternalBusiness,
} from "./reviewQueries";
import { AppContext } from "../../AppContext";

const ReviewReportPage = ({ open, onClose }) => {
  const [viewMode, setViewMode] = useState("monthly");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [organizationData, setOrganizationData] = useState([]);
  const [businessesData, setBusinessesData] = useState([]);
  const [externalBusinessesData, setExternalBusinessesData] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const { user } = useContext(AppContext);

  const fetchReviews = async (selectedEntity) => {
    let reviews = [];

    try {
      if (!selectedEntity) {
        reviews = await listAll(graphqlOperation(listReviews), "listReviews");
      } else {
        const { type, value } = selectedEntity;
        if (!value) return [];
        switch (type) {
          case "ORGANIZATION":
            reviews = await API.graphql(
              graphqlOperation(reviewsByOrganization, { organizationId: value })
            ).then(
              (response) =>
                response.data?.reviewsByOrganizationByStartTime?.items || []
            );
            break;
          case "BUSINESS":
            reviews = await API.graphql(
              graphqlOperation(reviewsByBusiness, { orgBusinessId: value })
            ).then(
              (response) =>
                response.data?.reviewsByBusinessByStartTime?.items || []
            );
            break;
          case "EXTERNAL_BUSINESS":
            reviews = await API.graphql(
              graphqlOperation(reviewsByExternalBusiness, {
                orgExternalBusinessId: value,
              })
            ).then(
              (response) =>
                response.data?.reviewsByExternalBusinessByStartTime?.items || []
            );
            break;
          default:
            reviews = [];
        }
      }
    } catch (e) {
      console.error("Failed to fetch reviews:", e);
    }

    const reviewsWithDate = reviews?.map((review) => {
      return {
        ...review,
        Date: new Date(review.startTime),
      };
    });

    // Check if reviews is null or undefined, and return an empty array if so
    return reviewsWithDate || [];
  };

  useEffect(() => {
    const role = user?.role;

    if (user?.organizationId) {
      switch (role) {
        case "ORGANIZATION_ADMIN":
          // Set all organizations, businesses, and external businesses with the same organizationId
          setOrganizationData({
            name: "ORGANISAATIO",
            id: user?.organizationId,
          });
          setBusinessesData(user.businessByOrg.items);
          setExternalBusinessesData(user.externalBusinessByOrg.items);
          break;
        case "BUSINESS_ADMIN":
          // Set the one business corresponding to this user
          setBusinessesData(
            user.businessByOrg.items.filter((b) => b.id === user.orgBusinessId)
          );
          break;
        case "EXTERNAL_BUSINESS_ADMIN":
          // Set the one external business corresponding to this user
          setExternalBusinessesData(
            user.externalBusinessByOrg.items.filter(
              (b) => b.id === user.externalBusinessId
            )
          );
          break;
        default:
          break;
      }
    }
  }, [user]);

  const { data } = useSWR(
    [selectedEntity],
    () => fetchReviews(selectedEntity),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );

  const getSelectOptions = useCallback(() => {
    if (!user) return []; // Return an empty array if the user is not defined
    const role = user?.role;
    let options = [];
    switch (role) {
      case "ORGANIZATION_ADMIN":
        if (organizationData)
          options.push({
            label: organizationData.name,
            value: organizationData.id,
            type: "ORGANIZATION",
          });
        if (businessesData.length)
          options.push(
            ...businessesData.map((b) => ({
              label: b.name,
              value: b.id,
              type: "BUSINESS",
            }))
          );
        if (externalBusinessesData.length)
          options.push(
            ...externalBusinessesData.map((eb) => ({
              label: eb.name,
              value: eb.id,
              type: "EXTERNAL_BUSINESS",
            }))
          );
        break;

      case "BUSINESS_ADMIN":
        if (businessesData.length)
          options.push(
            ...businessesData.map((b) => ({
              label: b.name,
              value: b.id,
              type: "BUSINESS",
            }))
          );
        if (externalBusinessesData.length)
          options.push(
            ...externalBusinessesData.map((eb) => ({
              label: eb.name,
              value: eb.id,
              type: "EXTERNAL_BUSINESS",
            }))
          );
        break;

      case "EXTERNAL_BUSINESS_ADMIN":
        if (externalBusinessesData.length)
          options.push(
            ...externalBusinessesData.map((eb) => ({
              label: eb.name,
              value: eb.id,
              type: "EXTERNAL_BUSINESS",
            }))
          );
        break;

      default:
        break;
    }

    return options;
  }, [businessesData, externalBusinessesData, organizationData, user]);

  useEffect(() => {
    if (!user || !getSelectOptions().length) return;
    let bizz = {};
    if (user?.role === "EXTERNAL_BUSINESS_ADMIN" && externalBusinessesData) {
      bizz = { ...externalBusinessesData[0], type: "EXTERNAL_BUSINESS" };
    } else if (user?.role === "BUSINESS_ADMIN" && businessesData) {
      bizz = { ...businessesData[0], type: "BUSINESS" };
    } else if (user?.role === "ORGANIZATION_ADMIN" && organizationData) {
      bizz = { ...organizationData, type: "ORGANIZATION" };
    }

    setSelectedEntity({
      label: bizz?.name,
      value: bizz?.id,
      type: bizz?.type,
    });
  }, [
    externalBusinessesData,
    businessesData,
    organizationData,
    user,
    getSelectOptions,
  ]);

  const filteredData = useMemo(() => {
    // Filter data based on selected month and year
    let filtered = data?.filter((review) => {
      const reviewMonth = review.Date.getMonth();
      const reviewYear = review.Date.getFullYear();
      if (viewMode === "annual") return reviewYear === selectedYear;
      return reviewMonth === selectedMonth && reviewYear === selectedYear;
    });

    return filtered;
  }, [data, viewMode, selectedMonth, selectedYear]);

  const percentageChange = useMemo(() => {
    // Calculate percentage change in average review from previous month/year
    let filteredBefore;
    if (viewMode === "annual") {
      filteredBefore = data?.filter(
        (review) => new Date(review.Date).getFullYear() === selectedYear - 1
      );
    } else {
      const previousMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
      const previousYear =
        selectedMonth === 0 ? selectedYear - 1 : selectedYear;
      filteredBefore = data?.filter(
        (review) =>
          new Date(review.Date).getFullYear() === previousYear &&
          new Date(review.Date).getMonth() === previousMonth
      );
    }

    if (filteredBefore?.length === 0) return 0;

    const averageBefore =
      filteredBefore?.reduce((acc, review) => acc + review.rating, 0) /
      filteredBefore?.length;
    const averageCurrent =
      filteredData?.reduce((acc, review) => acc + review.rating, 0) /
      filteredData?.length;

    return (((averageCurrent - averageBefore) / averageBefore) * 100).toFixed(
      2
    );
  }, [data, filteredData, viewMode, selectedMonth, selectedYear]);

  // Highest SUM of the same score wihin one month, rounded up by increments of 5
  const yAxisMax = useMemo(() => {
    const reviewCountsByMonth = {};

    // Loop over each data item and count the reviews for each month
    data?.forEach((item) => {
      const itemDate = new Date(item.Date);
      const itemYear = itemDate.getFullYear();
      const itemMonth = itemDate.getMonth();

      if (itemYear === selectedYear) {
        const rating = item.rating;
        const reviewCounts = reviewCountsByMonth[itemMonth] || {};
        reviewCounts[rating] = (reviewCounts[rating] || 0) + 1;
        reviewCountsByMonth[itemMonth] = reviewCounts;
      }
    });

    // Calculate the maximum reviews for each month
    const maxReviewsByMonth = {};
    Object.entries(reviewCountsByMonth).forEach(([month, reviewCounts]) => {
      const maxCount = Object.values(reviewCounts).reduce(
        (max, count) => Math.max(max, count),
        0
      );
      const roundedCount = Math.ceil(maxCount / 5) * 5;
      maxReviewsByMonth[month] = roundedCount;
    });

    // Find the highest value among the maximum reviews for each month
    const maxReviews = Object.values(maxReviewsByMonth).reduce(
      (max, value) => Math.max(max, value),
      0
    );

    // Round up to the nearest multiple of 5
    const roundedCount = Math.ceil(maxReviews / 5) * 5;

    return roundedCount;
  }, [data, selectedYear]);

  // handle view mode change
  const handleViewModeChange = (event) => {
    setViewMode(event.target.value);
  };

  // handle year change
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  // handle month change
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <DialogComponent
      height="85vh"
      maxWidth="lg"
      dialogTitle={"NPS raportti"}
      open={open}
      dialogClose={() => onClose()}
    >
      <Box p={2}>
        {!!selectedEntity && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            mb={2}
          >
            <FormControl>
              <InputLabel variant="standard" id="yritysmuoto-label">
                Yritysmuoto
              </InputLabel>
              <Select
                sx={{ minWidth: 300 }}
                label="Yritysmuoto"
                key={selectedEntity?.value || "initial"}
                value={selectedEntity?.value || ""}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  const selectedOption = getSelectOptions().find(
                    (option) => option.value === selectedValue
                  );
                  setSelectedEntity(selectedOption);
                }}
              >
                {getSelectOptions().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <Box display="flex" alignItems="center" mb={2}>
          <Box mr={2}>
            <FormControl>
              <InputLabel variant="standard" id="view-mode-label">
                Näkymä
              </InputLabel>
              <Select
                labelId="view-mode-label"
                id="view-mode"
                value={viewMode}
                onChange={handleViewModeChange}
              >
                <MenuItem value="monthly">Kuukausi</MenuItem>
                <MenuItem value="annual">Vuosi</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mr={2}>
            <FormControl>
              <InputLabel variant="standard" id="year-label">
                Vuosi
              </InputLabel>
              <Select
                labelId="year-label"
                id="year"
                value={selectedYear}
                onChange={handleYearChange}
              >
                {Array.from(new Set(data?.map((d) => d.Date.getFullYear())))
                  .sort((a, b) => a - b) // Sort the years from lowest to highest
                  .map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          {viewMode === "monthly" && (
            <Box>
              <FormControl>
                <InputLabel variant="standard" id="month-label">
                  Kuukausi
                </InputLabel>
                <Select
                  labelId="month-label"
                  id="month"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  {Array.from({ length: 12 }, (_, i) => i).map((month) => (
                    <MenuItem key={month} value={month}>
                      {new Date(selectedYear, month, 1).toLocaleString(
                        "fi-FI",
                        {
                          month: "long",
                        }
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
        {viewMode === "monthly" && (
          <>
            <MonthlyChart data={filteredData || []} yAxisMax={yAxisMax} />
            <StatsComponent
              data={filteredData || []}
              change={percentageChange}
              viewMode={viewMode}
            />
          </>
        )}
        {viewMode === "annual" && (
          <>
            <AnnualChart data={filteredData || []} />
            <StatsComponent
              data={filteredData || []}
              change={percentageChange}
              viewMode={viewMode}
            />
          </>
        )}
        <Box mt={4}>
          <ReviewsTable
            data={filteredData || []}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            selectedMode={viewMode}
          />
        </Box>
      </Box>
    </DialogComponent>
  );
};

export default ReviewReportPage;
