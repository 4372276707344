import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router";
import { useNavigate } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import "./navbar.css";

import { AppProvider } from "~/AppContext";

import NavBar from "./components/NavBar";
import SideMenu from "./components/SideMenu";
import { AppDialogs } from "~/components/AppDialogs";
import ModalContainer from "./components/ModalContainer";

const allowedRoles = ["ORGANIZATION_ADMIN", "BUSINESS_ADMIN"];

export default function MainLayout({ signOut, user, USER_ADMIN_TYPE }) {
  //  const user = useLoaderData();
  const navigate = useNavigate();

  const [customerSearch, setCustomerSearch] = useState(false);
  const [showFindVehicle, setShowFindVehicle] = useState(false);

  const [openMessages, setOpenMessages] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [iconSize, setIconSize] = useState("2vw");
  const [selectBusinessExpanded, setSelectedBusinessExpanded] = useState(false);
  const [selectedPage, setSelectedPage] = useState("eventsCalendar");
  const [showAdditionalServices, setShowAdditionalServices] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showHansel, setShowHansel] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [showProductAvailabilityWidget, setShowProductAvailabilityWidget] =
    useState(false);
  const [showReviewsReport, setShowReviewsReport] = useState(false);
  const [showSpecialHours, setShowSpecialHours] = useState(false);
  const [showUserManagementDialog, setShowUserManagementDialog] =
    useState(false);
  const [showVouchers, setShowVouchers] = useState(false);
  const [showNews, setShowNews] = useState(false);
  const showExternalReservationsLink = useMemo(
    () => user?.organizationId && allowedRoles.includes(user?.role),
    [user]
  );

  const handleNavigate = (value) => {
    if (value === "eventsCalendar") {
      setSelectedPage("eventsCalendar");
      navigate("/");
    }
    if (value === "externalReservations") {
      setSelectedPage("externalReservations");
      navigate("/toimeksiannot");
    }
    if (value === "economy") {
      setSelectedPage("economy");
      navigate("/raportointi");
    }
    if (value === "overview") {
      setSelectedPage("overview");
      navigate("/overview");
    }
    if (value === "product") {
      setSelectedPage("product");
      navigate("/products");
    }

    if (value === "assignment") {
      navigate("/varaus/9971e8d6-c652-4588-a706-2d7f58edd4f5");
    }
  };

  useEffect(() => {
    function handleResize() {
      // Example: Update the icon size based on viewport width
      let newSize = Math.min(window.innerWidth * 0.02, 26); // 2% of viewport width or maximum 26px
      newSize = Math.max(newSize, 20); // Ensure the icon size is at least 20px
      setIconSize(newSize);
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return (
    <AppProvider userData={user} USER_ADMIN_TYPE={USER_ADMIN_TYPE}>
      <Box
        sx={{
          bgcolor: "#f7f7f7",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          height: "100vh",
          overflow: "hidden",
          padding: "10px",
          width: "100%",
        }}
      >
        <SnackbarProvider>
          <NavBar
            customerSearch={customerSearch}
            iconSize={iconSize}
            setCustomerSearch={setCustomerSearch}
            setDrawerOpen={setDrawerOpen}
            openMessages={openMessages}
            setOpenMessages={setOpenMessages}
            showExternalReservationsLink={showExternalReservationsLink}
            showFindVehicle={showFindVehicle}
            setShowFindVehicle={setShowFindVehicle}
          />
          <SideMenu
            drawerOpen={drawerOpen}
            handleNavigate={handleNavigate}
            iconSize={iconSize}
            selectBusinessExpanded={selectBusinessExpanded}
            selectedPage={selectedPage}
            setCustomerSearch={setCustomerSearch}
            setDrawerOpen={setDrawerOpen}
            setSelectedBusinessExpanded={setSelectedBusinessExpanded}
            setShowAdditionalServices={setShowAdditionalServices}
            setShowCategories={setShowCategories}
            setShowHansel={setShowHansel}
            setShowPricing={setShowPricing}
            setShowProductAvailabilityWidget={setShowProductAvailabilityWidget}
            setShowReviewsReport={setShowReviewsReport}
            setShowUserManagementDialog={setShowUserManagementDialog}
            setShowVouchers={setShowVouchers}
            signOut={signOut}
            showExternalReservationsLink={showExternalReservationsLink}
            setShowNews={setShowNews}
          />
          <AppDialogs
            showNews={showNews}
            setShowNews={setShowNews}
            setShowAdditionalServices={setShowAdditionalServices}
            setShowCategories={setShowCategories}
            setShowHansel={setShowHansel}
            setShowPricing={setShowPricing}
            setShowProductAvailabilityWidget={setShowProductAvailabilityWidget}
            setShowReviewsReport={setShowReviewsReport}
            setShowSpecialHours={setShowSpecialHours}
            setShowUserManagementDialog={setShowUserManagementDialog}
            setShowVouchers={setShowVouchers}
            showAdditionalServices={showAdditionalServices}
            showCategories={showCategories}
            showHansel={showHansel}
            showPricing={showPricing}
            showProductAvailabilityWidget={showProductAvailabilityWidget}
            showReviewsReport={showReviewsReport}
            showSpecialHours={showSpecialHours}
            showUserManagementDialog={showUserManagementDialog}
            showVouchers={showVouchers}
          />
          <Outlet />
        </SnackbarProvider>
      </Box>

      <ModalContainer />
    </AppProvider>
  );
}
