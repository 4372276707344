import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
} from "@mui/material";

type FormikInputSelectArrayProps = any;

function FormikInputSelectArray({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  md,
  xs,
  index,
  arrayName,
  attributeName,
  ...props
}: FormikInputSelectArrayProps) {
  return (
    <Grid item xs={xs} md={md}>
      <FormControl
        fullWidth
        error={
          form?.touched?.[arrayName]?.[index]?.[attributeName] &&
          !!form?.errors?.[arrayName]?.[index]?.[attributeName]
        }
      >
        <InputLabel id={`${field?.name}-select-label`}>{props.label}</InputLabel>
        <InputLabel id={`${field?.name}-select-label`}>{props.label}</InputLabel>
        <Select
          {...field}
          labelId={`${field?.name}-select-label`}
          size="small"
          fullWidth
          {...props}
        />
        <FormHelperText>
          {form?.touched?.[arrayName]?.[index]?.[attributeName] &&
            form?.errors?.[arrayName]?.[index]?.[attributeName]}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
}

export default FormikInputSelectArray;
