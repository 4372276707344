import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { searchReservations as searchReservationsQuery } from "../../../graphql/queries";
import { migrateReservations } from "../../../graphql/mutations";

export function useSearchReservations(selectedCustomer) {
  const [reservations, setReservations] = useState([]);

  // This handles the search for reservations when selectedCustomer is selected from previous query
  useEffect(() => {
    const searchReservations = async () => {
      if (!selectedCustomer) {
        return setReservations([]);
      }

      // If selectedCustomer has a company name, reservation search is filtered by it, else with name and email
      const searchFilters = () => {
        if (selectedCustomer.companyName) {
          return {
            companyName: {
              matchPhrase: selectedCustomer.companyName,
            },
          };
        }

        // if for some reason the customer email isnt "real", dont include it to the search
        if (selectedCustomer.email.length < 3) {
          return {
            name: {
              matchPhrase: selectedCustomer.name,
            },
          };
        } else {
          return {
            name: {
              matchPhrase: selectedCustomer.name,
            },
            email: {
              matchPhrase: selectedCustomer.email,
            },
          };
        }
      };

      try {
        const response = await API.graphql(
          graphqlOperation(searchReservationsQuery, {
            filter: {
              and: searchFilters(),
            },
            sort: {
              field: "startTime",
              direction: "desc",
            },
          })
        );
        const sorted = response.data.searchReservations.items.sort(
          (s1, s2) => s1.startTime < s2.startTime
        );
        setReservations(sorted);
      } catch (e) {
        console.info("Error on searchReservations", e);
        let errorIds = [];
        for (const error of e.errors) {
          const path = error.path;
          const id = e.data.searchReservations.items[path[2]].id;
          errorIds.push(id);
        }
        // Try running migrations before searching again
        await API.graphql(
          graphqlOperation(migrateReservations, { ids: errorIds })
        );
        // Wait for the migrations to propagate into OpenSearch
        setTimeout(async function () {
          const response = await API.graphql(
            graphqlOperation(searchReservationsQuery, {
              filter: {
                and: searchFilters(),
              },
              sort: {
                field: "createdAt",
                direction: "desc",
              },
            })
          );
          setReservations(response.data.searchReservations.items);
        }, 1000);
      }
    };

    searchReservations(selectedCustomer);
  }, [selectedCustomer]);
  return { reservations: reservations };
}
