import "../contract.css";
import React from "react";
import { format } from "date-fns";
import { fi } from "date-fns/locale";

import ContractAgreement from "./components/ContractAgreement";
import ContractAdditionalDriverInformation from "./components/ContractAdditionalDriverInformation";
import ContractCompanyInformation from "./components/ContractCompanyInformation";
import ContractCustomerInformation from "./components/ContractCustomerInformation";
import ContractDriverInformation from "./components/ContractDriverInformation";
import ContractPrices from "./components/ContractPrices";
import ContractSchedule from "./components/ContractSchedule";
import ContractVehicle from "./components/ContractVehicle";
import { clientLogoData } from "../../customerLogos/ClientLogoData";

//import additionalServices from "../shared/additional-services";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  ECustomerInfo,
  EOrderDetails,
} from "./components/LanguagePack/translatedFields";
import {
  ScandiaRentAssistanceField,
  ScandiaRentRefuelCharge,
} from "./components/ContractCustomFields";
import { VehicleDamageMap } from "./components/ContractVehicleDamageMap";
import { titleStyle } from "./components/styles";
import { enableVehicleDamageMap } from "../../features";

export default function ContractTemplate({
  reservation,
  company,
  additionalServices,
  callback,
  vehicles,
  userEntity,
  selectedLanguage,
}) {
  const getVehicle = (id) => {
    return vehicles.find((v) => v.id === id);
  };

  return (
    <>
      <style>{css}</style>
      <div className="contract" ref={callback}>
        <Typography align="center" sx={{ fontWeight: 550 }}>
          {EOrderDetails[selectedLanguage].CONTRACT_TITLE}
        </Typography>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Logo userEntity={userEntity} />
          <Stack>
            {reservation.shortId && (
              <p>{`${EOrderDetails[selectedLanguage].RESERVATION_NUMBER}: ${reservation.shortId}`}</p>
            )}
            <p className="date">{formatDate(new Date())}</p>
          </Stack>
        </Stack>
        <Stack
          sx={{
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
          direction={"row"}
        >
          <CompanyTiny company={company} />

          <ContractSchedule
            reservation={reservation}
            selectedLanguage={selectedLanguage}
          />
        </Stack>
        <Typography sx={titleStyle}>
          {ECustomerInfo[selectedLanguage].TITLE}
        </Typography>
        <Box>
          <Grid container direction={"row"}>
            <ContractCustomerInformation
              reservation={reservation}
              selectedLanguage={selectedLanguage}
            />

            <ContractDriverInformation
              reservation={reservation}
              visibility={true}
              selectedLanguage={selectedLanguage}
            />

            <ContractCompanyInformation
              reservation={reservation}
              visibility={true} //reservation?.isCompany
              selectedLanguage={selectedLanguage}
            />
            <ContractAdditionalDriverInformation
              reservation={reservation}
              visibility={reservation?.additionalDriver}
              selectedLanguage={selectedLanguage}
            />
          </Grid>
        </Box>
        <Grid
          container
          direction={"row"}
          sx={{ marginY: "10px" }}
          justifyContent={"flex-start"}
        >
          {vehicles
            ? vehicles.map((v) => (
                <React.Fragment key={v.id}>
                  <ContractVehicle
                    reservation={reservation}
                    additionalServices={additionalServices}
                    vehicle={getVehicle(v.id)}
                    selectedLanguage={selectedLanguage}
                    company={company}
                  />
                  {enableVehicleDamageMap(userEntity, v?.category?.typeId) && (
                    <>
                      <VehicleDamageMap
                        vehicle={v}
                        returnTime={reservation?.returnTime}
                        selectedLanguage={selectedLanguage}
                      />
                    </>
                  )}
                </React.Fragment>
              ))
            : null}
        </Grid>
        <ContractPrices
          reservation={reservation}
          additionalServices={additionalServices}
          getVehicle={getVehicle}
          selectedLanguage={selectedLanguage}
        />
        <Stack spacing={1}>
          <ContractAgreement
            reservation={reservation}
            selectedLanguage={selectedLanguage}
          />
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <ScandiaRentRefuelCharge
            visibility={EFeatureFields.SCANDIARENT_REFUEL_CHARGE.includes(
              userEntity
            )}
          />
          <ScandiaRentAssistanceField
            visibility={EFeatureFields.SCANDIARENT_ASSISTANCE.includes(
              userEntity
            )}
          />
        </Stack>
        <PageBreak />
      </div>
    </>
  );
}

const formatDate = (date) => {
  return format(date, "dd.MM.yyyy", { locale: fi });
};

const CompanyTiny = ({ company }) => {
  return (
    <div className="company-info">
      <p style={{ fontWeight: 550 }}>{company.name}</p>
      <p>
        {company.address} {company.postalCode} {company.city}
      </p>
      <p>{company.phone}</p>
      <p>{company.businessId}</p>
    </div>
  );
};

const PageBreak = () => {
  return <div className="pageBreak"></div>;
};

const css = `
@page { size: auto;  margin: 5mm; }

.contract {
font-size: 8pt;
padding:5px
}

.contract h1 {
margin: 1;
font-size: 140%;
text-align: center;
}



  background-color: #e8e8e8;

.contract .label {
margin-left: 5px;
  font-size: 70%;
}

.contract .sign-box {
  height: 3em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}


.contract .company-info p {
  margin: 0;
  margin: 0;
          margin-left: 3%;
}
 `;

const Logo = (props) => {
  const { userEntity } = props;
  // userEntity is organizationId or group

  const logo = clientLogoData.find((item) => item.id === userEntity)?.logo;
  //const logo = clientLogoData[0]?.logo;
  if (!logo) {
    return <Box />;
  }
  return (
    <img alt={"logo"} src={logo} style={{ width: "250px", margin: "10px" }} />
  );
};

const EFeatureFields = {
  SCANDIARENT_REFUEL_CHARGE: "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1",
  SCANDIARENT_ASSISTANCE: "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1",
};
