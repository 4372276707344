import { useContext, useMemo, useState } from "react";
import { AppContext } from "../AppContext";

import {
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box, useTheme } from "@mui/system";

import {
  DataGridPro,
  gridFilteredDescendantCountLookupSelector,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";

import { format } from "date-fns";

import { centsToLocalString } from "../shared/money";

import { OpenInNew } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MailIcon from "@mui/icons-material/Mail";
import PendingIcon from "@mui/icons-material/Pending";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

import React from "react";
import { useFetchCompanies } from "../services/fetchCompanies";
import { useFetchReservations } from "../services/fetchReservations";
import { pageContainerStyle } from "~/styles/page-container";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../atoms/reservationDataAtom";

export default function ReservationTableExternalUsers({ visibility }) {
  const {
    appBarHeight,
    business,
    USER_ADMIN_CORPORATE,
    USER_ADMIN_GENERIC,
    user,
  } = useContext(AppContext);
  /*   const { vehicleData: allVehicles } = useFetchVehicles(
    user?.group,
    business?.id
  ); */

  const { companies } = useFetchCompanies(user?.group, business?.id);
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const { reservations, reservationsLoading } =
    useFetchReservations("existsBetween");
  const theme = useTheme();

  const [showProducts, setShowProducts] = useState(0);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    type: false,
    authStatus: false,
    company: user?.organizationId ? false : true,
    channel: false,
    vehicleCategoryName: false,
  });

  const handleOpenReservation = (id) => {
    const reservation = reservations.find((res) => res.id === id);

    if (reservation) {
      setEditReservationData(reservation);
    }
  };

  const columns = useMemo(() => {
    const showLivionData = companies
      ? companies.find((c) => c?.livionDeviceId !== null)
      : null;
    const showAuthentication = companies
      ? companies.find((c) => c?.mustSign !== null)
      : null;
    const livionData = {
      field: "livionData",
      headerName: "Livion data",
      sortable: false,
      width: 150,
    };

    const authData = {
      field: "authStatus",
      headerName: "Tunnistautuminen",
      sortable: "false",
      hidden: true,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (params.value === "COMPLETED") {
          return (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <CheckCircleIcon sx={{ color: theme.palette.success.main }} />
            </Box>
          );
        }

        if (params.value === "PENDING_MUST") {
          return (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <PendingIcon sx={{ color: "#F18F01" }} />
            </Box>
          );
        }

        if (params.value === "PENDING") {
          return (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <PendingIcon sx={{ color: "#F18F01" }} />
            </Box>
          );
        }

        return "";
      },
    };

    const companyField = {
      field: "company",
      headerName: "Toimipiste",
      sortable: true,
      flex: 1,
      minWidth: 200,
    };

    const channel = {
      field: "channel",
      headerName: "Varauskanava",
      sortable: true,
      flex: 1,
      minWidth: 200,
    };

    const type = {
      field: "type",
      headerName: "Tyyppi",
      sortable: true,
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        if (params.value === "INSURANCE") {
          return "Vakuutus";
        }
        if (params.value === "HANSEL") {
          return "Hansel";
        }

        return params.value;
      },
    };

    const startTimeField = {
      field: "startTime",
      headerName: "Alkamisajankohta",
      sortable: true,
      flex: 0.3,
      minWidth: 150,
    };

    const endTimeField = {
      field: "endTime",
      headerName: "Päättymisajankohta",
      sortable: true,
      flex: 0.3,
      minWidth: 150,
    };

    const vehiclesField = {
      field: "vehicle",
      headerName: "Rekisterinumero",
      sortable: true,
      flex: 0.5,
      minWidth: 100,
    };
    const vehicleCategoryNameField = {
      field: "vehicleCategoryName",
      headerName: "Kategoria",
      sortable: true,
      flex: 1,
      minWidth: 200,
    };
    const vehicleNameField = {
      field: "vehicleName",
      headerName: "Tuote",
      sortable: true,
      flex: 1,
      minWidth: 200,
    };
    const priceField = {
      field: "price",
      headerName: "Hinta",
      type: "number",
      sortable: true,
      flex: 0.5,
      minWidth: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${centsToLocalString(params.value)} €`;
      },
      renderCell: (params) => {
        if (params.value && params.row.openReservation)
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 550 }}>
                {centsToLocalString(params.value)} €
              </Typography>
            </Box>
          );
      },
    };
    const totalPriceField = {
      field: "totalPrice",
      headerName: "Kokonaissumma",
      type: "number",
      sortable: true,
      flex: 0.5,
      minWidth: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${centsToLocalString(params.value)} €`;
      },
      renderCell: (params) => {
        if (params.value && params.row.openReservation)
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 550 }}>
                {centsToLocalString(params.value)} €
              </Typography>
            </Box>
          );
      },
    };

    /*     const openReservationField = {
      field: "openReservation",
      headerName: "",
      sortable: false,

      width: 100,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          ></Box>
        );
      },
    }; */

    const paymentStatusField = {
      field: "paymentStatus",
      headerName: "Maksun tila",
      sortable: true,
      flex: 0.5,
      minWidth: 100,
      editable: true,
      renderCell: (params) => {
        if (params.row.billPayment) {
          return (
            <Tooltip title="Laskutus">
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {params.value === "COMPLETED" ? (
                  <MailIcon sx={{ color: theme.palette.success.main }} />
                ) : (
                  <MailIcon sx={{ color: theme.palette.primary.main }} />
                )}
              </Box>
            </Tooltip>
          );
        }
        if (
          params.value === "COMPLETED" ||
          params.value === "COMPLETED_MANUAL"
        ) {
          return (
            <Tooltip title="Maksu on suoritettu">
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <CheckCircleIcon sx={{ color: theme.palette.success.light }} />
              </Box>
            </Tooltip>
          );
        }

        if (params.value === "COMPLETED_INVOICE") {
          return (
            <Tooltip title="Laskutettu">
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <ReceiptLongIcon sx={{ color: theme.palette.success.light }} />
              </Box>
            </Tooltip>
          );
        }

        if (params.value === "PENDING" || params.value === "PENDING_INVOICE") {
          return (
            <Tooltip title="Maksu on vireillä">
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <PendingIcon sx={{ color: "#F18F01" }} />
              </Box>
            </Tooltip>
          );
        }
        if (params.value === "CANCELLED") {
          return "";
        }
        if (
          params.value === "COMPLETED_DEPOSIT" ||
          params.value === "PENDING_FULL"
        ) {
          return (
            <Tooltip title="Varausmaksu suoritettu">
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <ReceiptLongIcon sx={{ color: "#8D8D92" }} />
              </Box>
            </Tooltip>
          );
        }
      },
    };

    const customerNameField = {
      field: "name",
      headerName: "Asiakkaan nimi",
      sortable: true,
      flex: 1,
      minWidth: 200,
    };

    /*     const invoicingPermitId = {
      field: "invoicingPermitId",
      headerName: "Vahinkonumero",
      sortable: true,
      flex: 1,
      minWidth: 100,
    }; */

    /*     const customerNameFieldEBA = {
      field: "customerNameFieldEBA",
      headerName: "Asiakkaan nimi",
      sortable: true,
      flex: 1,
      minWidth: 200,
    }; */

    /*     const customerCarRegistrationPlate = {
      field: "customerCarRegistrationPlate",
      headerName: "Asiakkaan rekisterinumero",
      sortable: true,
      flex: 1,
      minWidth: 200,
    }; */

    const columnsAdmin = [
      companyField,
      startTimeField,
      endTimeField,
      vehiclesField,
      vehicleNameField,
      vehicleCategoryNameField,
      priceField,
      totalPriceField,
      paymentStatusField,
      customerNameField,
      channel,
      type,
    ];

    const columnsExternalBusinessAdmin = [
      startTimeField,
      endTimeField,
      customerNameField,
    ];

    if (USER_ADMIN_GENERIC) {
      if (showAuthentication) {
        columnsAdmin.push(authData);
      }
      if (showLivionData) {
        columnsAdmin.push(livionData);
      }
      return columnsAdmin;
    }

    if (USER_ADMIN_CORPORATE) {
      return columnsExternalBusinessAdmin;
    }
    return columnsAdmin;
  }, [companies, USER_ADMIN_CORPORATE, USER_ADMIN_GENERIC, theme]);

  /*
  reservationData is filtered by the paymentStatus.
  CANCELLED
  PENDING
  PENDING_FULL
  PENDING_INVOICE # Invoicing to be done
  COMPLETED_DEPOSIT
  COMPLETED_MANUAL
  COMPLETED_INVOICE # Invoice succesfully sent
  COMPLETED # Online payment completed
  */
  const reservationsData = useMemo(() => {
    if (USER_ADMIN_CORPORATE) {
      return reservations;
    }
  }, [reservations, USER_ADMIN_CORPORATE]);
  const rows = useMemo(() => {
    if (!reservationsData) {
      return [];
    }

    let productRows = [];

    const data = reservationsData;
    data.forEach((reservation, idx) => {
      const translatedChannel = (channelValue) => {
        if (channelValue === "DEALER_EXTERNAL") {
          return "YRITYSASIAKAS";
        }
        if (channelValue === "DEALER") {
          return "YRITTÄJÄ";
        }
        if (channelValue === "DEALER_ORGANIZATION") {
          return "SCREDO";
        }
        if (channelValue === "CUSTOMER") {
          return "ONLINE";
        }
        return channelValue;
      };

      productRows.push({
        id: reservation.id,
        openReservation: reservation.id,
        hierarchy: [`Varaus-${idx + 1}`],
        //  company: companies.find((c) => c.id === reservation?.companyId)?.name,
        startTime: format(new Date(reservation.startTime), " dd.MM.yyyy HH:MM"),
        endTime: format(new Date(reservation.endTime), " dd.MM.yyyy HH:MM"),
        paymentStatus: reservation.paymentStatus,
        totalPrice: calculateReservationTotalPrice(reservation),
        billPayment: reservation.billPayment,
        signStatus: reservation.signStatus,
        authStatus: reservation.authStatus,
        livionData: reservation.livionData,
        name: reservation.name,
        additionalServices: reservation?.additionalServices?.map((s) => s.key),
        company: companies
          ? companies.find((c) => c.id === reservation?.companyId)?.name
          : null,
        channel: translatedChannel(reservation?.channel),
        type: reservation?.type,
      });

      reservation?.reservationVehicles?.map((vehicle, index) => {
        const hierarchy = [
          `Varaus-${idx + 1}`,
          `${vehicle?.name}-${index}` ?? `Tuote - ${vehicle.id}`,
        ];

        return productRows.push({
          id: `${reservation.id}+${vehicle.id}+${index}`,

          resId: reservation.id,
          vehicle: vehicle?.registrationPlate,
          vehicleName: vehicle?.name ?? "Tuotteen tietoja ei löydy.",
          vehicleData: vehicle,
          price: vehicle.price,
          vehicleCategoryName: vehicle?.categoryName,
          // -----------------
          hierarchy: hierarchy,
          // -----------------
        });
      });
    });

    return productRows;
  }, [reservationsData, companies]);
  const getTreeDataPath = (row) => row.hierarchy;

  function CustomGridTreeDataGroupingCell(props) {
    const { id, field, rowNode, row } = props;

    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector
    );
    const filteredDescendantCount =
      filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick = (event) => {
      if (rowNode.type !== "group") {
        return;
      }

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    };
    return (
      <Box sx={{ ml: rowNode.depth * 4, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",

            justifyContent: "space-between",
          }}
        >
          <Tooltip title="Avaa varaus">
            <IconButton
              variant="contained"
              onClick={() => {
                handleOpenReservation(row.id);
              }}
            >
              <OpenInNew sx={{ color: theme.palette.success.light }} />
            </IconButton>
          </Tooltip>
          {filteredDescendantCount > 0 ? (
            <>
              {!USER_ADMIN_CORPORATE && (
                <IconButton onClick={handleClick} tabIndex={-1}>
                  <ExpandMoreIcon />
                </IconButton>
              )}
            </>
          ) : null}
        </Box>
      </Box>
    );
  }

  const groupingColDef = {
    headerName: "Hierarchy",
    width: 120,
    renderHeader: () => (
      <FormControlLabel
        control={
          <Switch
            checked={!!showProducts}
            onChange={() =>
              setShowProducts((prevState) => (!prevState ? 1 : 0))
            }
          />
        }
        // label={<Typography variant="caption">Näytä kaikki tuotteet</Typography>}
        sx={{ marginLeft: "1px" }}
      />
    ),
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  if (visibility)
    return (
      <Box sx={{ ...pageContainerStyle, marginX: { sm: "2%" } }}>
        <DataGridPro
          treeData={true}
          getTreeDataPath={getTreeDataPath}
          //  getRowId={(row) => row.key}
          sx={{
            //   borderColor: "transparent",
            border: "none",
            overflow: "hidden",
            /*         fontFamily: "Public Sans, sans-serif",
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            }, */
            "& .MuiDataGrid-columnHeadersInner": {
              backgroundColor: "#f7f7f8",
              color: "#4e536c",
              // boxShadow: theme.shadows[4],
            },
            height: `calc(100vh - ${appBarHeight}px)`,
          }}
          loading={reservationsLoading}
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[2]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          defaultGroupingExpansionDepth={showProducts}
          groupingColDef={groupingColDef}
          disableRowSelectionOnClick
          slots={{ toolbar: USER_ADMIN_GENERIC && GridToolbar }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
        />
      </Box>
    );
}

function InfoContainer({ children, sort, sortBy, setSortBy }) {
  const { mobileViewPort } = useContext(AppContext);
  const theme = useTheme();
  return (
    <Grid item xs={6} sm={6} md={4} lg={2}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "80px",
          justifyContent: mobileViewPort ? "flex-start" : "center",
          alignItems: "center",

          cursor: "pointer",
          "&:hover": {
            bgcolor: "rgba(0,0,0,0.05)",
            boxShadow: theme.shadows[2],
          },

          transition: "250ms ease-in-out",
          borderRadius: "14px",
          border: "2px solid",
          borderColor:
            sort === sortBy ? theme.palette.primary.main : "transparent",

          bgcolor: sort === sortBy ? "rgba(0,0,0,0.03)" : "transparent",
        }}
        onClick={() => setSortBy(sort)}
      >
        {children}
      </Box>
    </Grid>
  );
}

const calculateReservationTotalPrice = (res) => {
  // handle totalPrice
  const calcVehiclePrices = res?.reservationVehicles?.reduce(
    (accumulator, currentProduct) => {
      const quantity = currentProduct?.quantity ?? 1;

      return accumulator + currentProduct.price * quantity;
    },
    0
  );

  const calcServicePrices = res?.additionalServices?.reduce(
    (accumulator, currentProduct) => {
      const quantity = currentProduct?.quantity ?? 1;

      return accumulator + currentProduct.price * quantity;
    },
    0
  );

  return calcVehiclePrices + calcServicePrices;
};

const calculateReservationsTotal = (reservations, filterBy) => {
  if (!reservations) return "-";

  const totalPrice = reservations.reduce((total, reservation) => {
    if (filterBy === "all-results") {
      if (reservation?.status !== "EXTERNAL_PENDING_DEALER")
        return total + calculateReservationTotalPrice(reservation);
    }
    if (filterBy === "results-by-cash") {
      if (
        (reservation.paymentStatus === "CANCELLED" &&
          reservation?.status !== "EXTERNAL_PENDING_DEALER") ||
        (!reservation?.paymentStatus &&
          reservation?.status !== "EXTERNAL_PENDING_DEALER")
      ) {
        return total + calculateReservationTotalPrice(reservation);
      } else return total;
    }
    if (filterBy === "results-by-online") {
      if (reservation.paymentStatus === "COMPLETED") {
        return total + calculateReservationTotalPrice(reservation);
      } else return total;
    }
    if (filterBy === "results-by-invoice") {
      if (
        reservation.paymentStatus === "PENDING_INVOICE" ||
        reservation.paymentStatus === "COMPLETED_INVOICE"
      ) {
        return total + calculateReservationTotalPrice(reservation);
      } else return total;
    }

    return 0;
  }, 0);
  return centsToLocalString(totalPrice);
};

const totalAmountOfReservations = (reservations, filterBy) => {
  const totalAmount = reservations.reduce((total, reservation) => {
    if (filterBy === "all-results") {
      return total + 1;
    }
    if (filterBy === "results-by-cash") {
      if (
        reservation.paymentStatus === "CANCELLED" ||
        !reservation?.paymentStatus
      ) {
        return total + 1;
      } else return total;
    }
    if (filterBy === "results-by-online") {
      if (reservation.paymentStatus === "COMPLETED") {
        return total + 1;
      } else return total;
    }
    if (filterBy === "results-by-invoice") {
      if (
        reservation.paymentStatus === "PENDING_INVOICE" ||
        reservation.paymentStatus === "COMPLETED_INVOICE"
      ) {
        return total + 1;
      } else return total;
    }
    return null;
  }, 0);

  return totalAmount;
};
