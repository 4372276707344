import { format } from "date-fns";
import { Field } from "./common";
import { EOrderDetails } from "./LanguagePack/translatedFields";
import { Stack } from "@mui/material";

export default function ContractSchedule(props) {
  const { reservation, selectedLanguage } = props;
  const { startTime, returnTime, startLocation, returnLocation } = reservation;
  const fields = EOrderDetails[selectedLanguage];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        width: "33%",
      }}
    >
      <Stack direction={"row"}>
        <Field
          value={startLocation}
          label={fields.START_LOCATION}
          removeBorders
        />
        <Field
          value={startTime ? format(startTime, "dd.MM.yyyy HH:mm") : null}
          label={fields.START_DATE}
          removeBorders
        />
      </Stack>
      <Stack direction={"row"}>
        <Field
          value={returnLocation}
          label={fields.RETURN_LOCATION}
          removeBorders
        />
        <Field
          value={returnTime ? format(returnTime, "dd.MM.yyyy HH:mm") : null}
          label={fields.RETURN_DATE}
          removeBorders
        />
      </Stack>
    </div>
  );
}
