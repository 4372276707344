import {
  Button,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../../../../AppContext";
import { externalUserHanselClasses } from "../../../utils/externalUserHanselClasses";
import OfferMenuItem from "./OfferMenuItem";
import { useFormikContext } from "formik";
import { useGetExternalUserOffers } from "../../../../../hooks/useGetExternalUserOffers";
import { useCategories } from "../../../../../queries/useCategories";
import { isValid } from "date-fns";
import { ReservationContext } from "../../../contextProviders/ReservationContext";
import { translatedFields } from "../../../formik/fields";

export default function ExternalUserSelectVehicleClass(props) {
  const { user } = useContext(AppContext);
  const { selectedLanguage } = useContext(ReservationContext);
  const { values } = useFormikContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const clientType = user?.externalBusiness?.hanselId ? "HANSEL" : "STANDARD"; // HANSEL or STANDARD

  const label =
    clientType?.type === "HANSEL"
      ? translatedFields[selectedLanguage]?.selectHanselClass
      : translatedFields[selectedLanguage]?.selectCategory;

  const categories = useCategories();
  const { offers, loading } = useGetExternalUserOffers(values);

  const offersList = offers
    .map((offer) => {
      const category = categories.find((category) => category.id === offer.id);
      return { ...offer, ...category };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const createHanselOffersList = (
    externalUserHanselClasses,
    categories,
    offers
  ) => {
    const availableClasses = externalUserHanselClasses.reduce(
      (acc, hanselClass) => {
        const category = categories.find((category) =>
          hanselClass.vehicleClasses.includes(category.name)
        );
        const price = offers.find((offer) => offer.id === category?.id)?.price;
        if (price) {
          acc.push({
            category: category,
            name: category.name,
            categoryId: category.id,
            price: price,
            ...hanselClass,
          });
        }
        return acc;
      },
      []
    );
    return availableClasses;
  };

  const hanselOffersList = createHanselOffersList(
    externalUserHanselClasses,
    categories,
    offers
  );

  const EOffersList = {
    HANSEL: hanselOffersList,
    STANDARD: offersList,
  };

  const isDisabled = !isValid(values.startTime) && !isValid(values.returnTime);

  const handleOpenMenu = (e) => {
    if (isDisabled) {
      return;
    }
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      <Tooltip
        title={
          isDisabled
            ? translatedFields[selectedLanguage].verifyReservationTimes
            : null
        }
      >
        <Button
          variant="contained"
          onClick={handleOpenMenu}
          sx={{ textTransform: "none", borderRadius: "20px" }}
          fullWidth
        >
          {label}
        </Button>
      </Tooltip>
      <Menu
        id="select-category"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        label={label}
        slotProps={{
          paper: {
            sx: {
              padding: "5px",
              width: { sm: "500px", xs: "100%" },
            },
          },
        }}
      >
        {<LoadingItems loading={loading} />}
        {!loading &&
          EOffersList[clientType].map((offer) => (
            <OfferMenuItem
              key={offer?.id ?? offer?.name}
              offerId={offer?.id}
              offer={offer}
              setAnchorEl={setAnchorEl}
              clientType={clientType}
            />
          ))}
      </Menu>
    </>
  );
}
function LoadingItems({ loading }) {
  if (!loading) return null;
  return (
    <>
      <LoadingItem />
      <LoadingItem />
      <LoadingItem />
      <LoadingItem />
    </>
  );
}

function LoadingItem() {
  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant="rounded" width={40} height={40} />
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton variant="text" width={"60%"} />}
        secondary={<Skeleton variant="text" width={"60%"} />}
      />
      <ListItemSecondaryAction>
        <Skeleton variant="text" width={"70px"} />
      </ListItemSecondaryAction>
    </ListItem>
  );
}
