import { format } from "date-fns";
import { fi } from "date-fns/locale";
export const Field = ({ label, value, removeBorders, style }) => {
  const getFieldValue = () => {
    if (value === 0) {
      return 0;
    }
    if (value === "" || value === null || value === undefined) {
      return <Empty />;
    }
    return value;
  };
  const containerStyle = removeBorders ? stylesNoBorder : stylesWithBorder;
  return (
    <div style={{ ...style, ...containerStyle }}>
      <Label>{label}</Label>
      <div>{getFieldValue()}</div>
    </div>
  );
};

export const Divider = () => {
  return <div style={{ borderRight: "1px solid lightgrey" }} />;
};

export const DividedField = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {children}
    </div>
  );
};

const Label = (props) => {
  return <div className="label" {...props}></div>;
};

const Empty = () => {
  return <div style={{ height: "16px" }} />;
};

export const formatDateTime = (date) => {
  return format(date, "dd.MM.yyyy HH:mm", { locale: fi });
};

const stylesWithBorder = {
  borderBottom: "1px solid lightgrey",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  paddingLeft: "5px",
  paddingTop: "2px",
};

const stylesNoBorder = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  paddingLeft: "5px",
  paddingTop: "2px",
};
