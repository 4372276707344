import useSWR from "swr";
import { graphqlOperation } from "aws-amplify";
import listAll from "../utils/list-all";
import { invoiceByReservation } from "../graphql/queries";
import captureError from "../utils/capture-error";

const fetchInvoices = async (reservationId) => {
  try {
    const invoices = await listAll(
      graphqlOperation(invoiceByReservation, {
        reservationId: reservationId,
      }),
      "invoiceByReservation"
    );
    return invoices;
  } catch (e) {
    console.error("Get invoices failed", "GET_INVOICES_FAILED", e);
    captureError("Get invoices failed", "GET_INVOICES_FAILED", e);
    return [];
  }
};

export function useFetchInvoices(reservationId, featureInvoicing) {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    (featureInvoicing && reservationId) ? ["fetchInvoices", reservationId] : null,
    () => fetchInvoices(reservationId),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );

  return {
    invoices: data,
    invoicesLoading: isLoading,
    invoicesValidating: isValidating,
    invoicesError: error,
    invoicesMutate: mutate,
  };
}
