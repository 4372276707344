import { Stack, useMediaQuery, useTheme, Box } from "@mui/material";

import DeleteReservation from "./components/DeleteReservation";
import SubmitReservation from "./components/SubmitReservation";
import TotalPrice from "./components/TotalPrice";
import CloseDialog from "./components/CloseDialog";
import { dialogDimensions } from "../../utils/dialogDimensions";

export default function DialogFooter() {
  const theme = useTheme();

  const mobileViewPort = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <MobileViewTotalPrice />
      <Stack
        direction={"row"}
        sx={{
          alignItems: "center",
          bgcolor: "#ffffff",
          bottom: 0,
          justifyContent: "space-between",
          maxHeight: dialogDimensions.footerHeight,
          paddingTop: "10px",
          position: "sticky",
          width: "100%",
          zIndex: 5,
        }}
      >
        <DeleteReservation />
        <Box sx={{ flex: 1 }} />
        {!mobileViewPort && <TotalPrice />}

        <Stack direction={"row"}>
          <CloseDialog />
          <SubmitReservation />
        </Stack>
      </Stack>
    </>
  );
}

const MobileViewTotalPrice = () => {
  const theme = useTheme();
  const mobileViewPort = useMediaQuery(theme.breakpoints.down("sm"));
  if (mobileViewPort)
    return (
      <Stack
        direction={"row"}
        sx={{
          alignItems: "center",
          display: { sm: "none", md: "flex" },
          justifyContent: "center",
          width: "100%",
        }}
      >
        <TotalPrice />
      </Stack>
    );
};
