import { useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';

export function useNotification() {
  const show = useCallback((message, severity, duration) => {
    const variant = severity;
    return enqueueSnackbar(message, { variant });
  }, []); // Empty dependencies array means the function will not change on re-renders

  return { show };
}
