import { useTheme } from "@emotion/react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useCategories } from "~/queries/useCategories";
import CategoryRow from "./components/CategoryRow";

export default function CategoryPricingsView(props) {
  const { setEditablePricing, selectedCompany } = props;
  const categories = useCategories();

  const theme = useTheme();

  return (
    <Stack direction={"column"} flex={1} overflow={"auto"}>
      <Table
        size="small"
        stickyHeader
        sx={{
          "& .MuiTableCell-head": {
            color: "white",
            backgroundColor: theme.palette.primary.main,
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableHeaderCell title="Autoluokka" align="left" />
            <TableHeaderCell title="Hinnasto" align="left" />
            <TableHeaderCell title="Dynaaminen hinta" align="left" />
            <TableHeaderCell title="Tunti" align="right" />
            <TableHeaderCell title="Vuorokausi" align="right" />
            <TableHeaderCell title="Verokanta" align="right" />
            <TableHeaderCell title="" align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {categories?.map((category, idx) => {
            return (
              <CategoryRow
                key={category?.id}
                category={category}
                setEditablePricing={setEditablePricing}
                selectedCompany={selectedCompany}
              />
            );
          })}
        </TableBody>
      </Table>
    </Stack>
  );
}

function TableHeaderCell(props) {
  const { title, align } = props;

  return (
    <TableCell align={align}>
      <Typography>{title}</Typography>
    </TableCell>
  );
}
