import { List, ListItemButton, ListItemText, Box, Stack } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LicenseInfo } from "@mui/x-license-pro";
import { useFormikContext } from "formik";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import { getHours, isBefore, isSameDay, set } from "date-fns";
import { useReservationContext } from "../ReservationContext";
LicenseInfo.setLicenseKey(
  "57d9cfea154b5252705da4c9ab0f8583Tz03NTg0OSxFPTE3Mjc5NDI2OTEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export default function ReservationSchedule() {
  const { setFieldTouched } = useFormikContext();
  const { USER_ADMIN_GENERIC, mobileViewPort, USER_ADMIN_CORPORATE } =
    useContext(AppContext);
  const {
    setStartTime,
    startTime,
    setReturnTime,
    returnTime,
    setEndTime,
    endTime,
    reservationData,
  } = useReservationContext();
  //if (userRole === "admin")

  function ActionList(props) {
    const { onAccept, onCancel, className } = props;
    const actions = [
      { text: "Peruuta", method: onCancel },
      { text: "Valmis", method: onAccept },
    ];
    return (
      // Propagate the className such that CSS selectors can be applied
      <Box
        className={className}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {actions.map(({ text, method }) => (
          <List key={text} sx={{ margin: "5px", marginBottom: "10px" }}>
            <ListItemButton
              onClick={method}
              sx={{
                paddingY: "5px",
                borderRadius: "7px",
              }}
            >
              <ListItemText primary={text} />
            </ListItemButton>
          </List>
        ))}
      </Box>
    );
  }

  function PickerToolbar(props) {
    const { className } = props;

    return (
      <Box className={className}>
        <Box sx={{ display: "flex", justifyContent: "center" }}></Box>
      </Box>
    );
  }

  return (
    <Stack
      spacing={2}
      justifyContent={"flex-start"}
      direction={mobileViewPort ? "column" : "row"}
      width={"100%"}
    >
      <Box>
        <DateTimePicker
          ampm={false}
          label="Aloitusaika"
          value={startTime}
          slots={{
            toolbar: PickerToolbar,
            actionBar: ActionList,
          }}
          disabled={reservationData?.id && USER_ADMIN_CORPORATE}
          disablePast={!reservationData && USER_ADMIN_CORPORATE}
          displayWeekNumber
          // thresholdToRenderTimeInASingleColumn={100}
          timeSteps={{ hours: 1, minutes: 15, seconds: 5 }}
          onChange={(date) => {
            if (!startTime) {
              setStartTime(set(date, { hours: getHours(new Date()) + 1 }));
            } else {
              setStartTime(date);
            }
            if (isBefore(returnTime, date) || !returnTime) {
              setReturnTime(set(date, { hours: getHours(new Date()) + 2 }));
              setEndTime(set(date, { hours: getHours(new Date()) + 2 }));
            }
          }}
          onClose={() => setFieldTouched("startTime", true)}
        />
      </Box>

      <Box>
        <DateTimePicker
          ampm={false}
          label="Palautusaika"
          displayWeekNumber
          value={returnTime}
          disabled={reservationData?.id && USER_ADMIN_CORPORATE}
          slots={{
            toolbar: PickerToolbar,
            actionBar: ActionList,
          }}
          onChange={(date) => {
            setReturnTime(date);
            setEndTime(date);
          }}
          //  thresholdToRenderTimeInASingleColumn={100}
          timeSteps={{ hours: 1, minutes: 15, seconds: 5 }}
          shouldDisableDate={(day) =>
            isBefore(day, startTime) && !isSameDay(day, startTime)
          }
          shouldDisableTime={(time) => {
            if (startTime) {
              return time.getTime() <= startTime?.getTime();
            }
            return false;
          }}
          onClose={() => setFieldTouched("returnTime", true)}
        />
      </Box>
      {USER_ADMIN_GENERIC && (
        <Box>
          <DateTimePicker
            ampm={false}
            displayWeekNumber
            label="Vapaa uudelleen varattavaksi"
            value={endTime}
            slots={{
              toolbar: PickerToolbar,
              actionBar: ActionList,
            }}
            //  thresholdToRenderTimeInASingleColumn={100}
            timeSteps={{ hours: 1, minutes: 15, seconds: 5 }}
            shouldDisableDate={(day) =>
              isBefore(day, startTime) && !isSameDay(day, startTime)
            }
            shouldDisableTime={(time) => {
              if (startTime) {
                return time.getTime() <= startTime?.getTime();
              } else {
                return false;
              }
            }}
            onChange={async (date) => setEndTime(date)}
            onClose={() => setFieldTouched("endTime", true)}
          />
        </Box>
      )}
    </Stack>
  );
}
