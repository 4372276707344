import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFetchInvoices } from "../../../../../../../../services/fetchInvoices";
import { useFormikContext } from "formik";
import { useFetchVehicles } from "../../../../../../../../services/fetchVehicles";
import React, { useContext, useMemo } from "react";
import { ReservationContext } from "../../../../../../contextProviders/ReservationContext";
import { differenceInDays, parse } from "date-fns";
import { FormInputField } from "../../../../../../components/ReservationForm/utils/FormInputField";
import { DatePicker } from "@mui/x-date-pickers-pro";

export function SelectHanselInvoice(props) {
  const { isDisabled } = props;
  const { FEATURE_INVOICING } = useContext(ReservationContext);
  const { vehicleData: allVehicles } = useFetchVehicles();
  const { setValues, values, setFieldValue } = useFormikContext();
  const { returnTime, startTime } = values;
  const { invoices } = useFetchInvoices(values.id, FEATURE_INVOICING, "Hansel");

  const selectedInvoice = useMemo(() => {
    if (
      invoices &&
      invoices.length > 0 &&
      values?.hanselData?.customerInvoiceId
    ) {
      const invoice = invoices?.find(
        (invoice) =>
          invoice.innovoiceId === values?.hanselData?.customerInvoiceId
      );
      return invoice;
    }
  }, [values?.hanselData?.customerInvoiceId, invoices]);
  if (invoices && invoices.length > 0)
    return (
      <React.Fragment>
        <Grid item xs={12} md={12}>
          <Typography>
            Hakeaksesi raportin tiedot laskulta, valitse lasku.
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <InputLabel id="select-invoice-label" shrink>
              Valitse lasku
            </InputLabel>
            <Select
              labelId="select-invoice-label"
              id="select-invoice"
              disabled={isDisabled}
              label="Valitse lasku"
              displayEmpty
              value={selectedInvoice ?? ""}
              renderValue={(selected) =>
                selected?.customer?.company_name ??
                selected?.customer?.lastname ??
                selected?.customer?.email
              }
              onChange={(e) => {
                if (!e.target.value) {
                  setValues({
                    ...values,
                    hanselData: {
                      ...values.hanselData,
                      customerInvoiceId: null,
                      additionalInfo1:
                        differenceInDays(returnTime, startTime) + 1,
                      customerInvoiceDate: null,
                      desc: "",
                      additionalInfo2: allVehicles
                        .find((v) => v.id === values.reservationVehicles[0].id)
                        ?.additionalFields.find((v) => v.key === "emission")
                        ?.value,
                    },
                  });
                } else {
                  const invoiceData = e.target.value;

                  setValues({
                    ...values,
                    hanselData: {
                      ...values.hanselData,
                      customerInvoiceId: invoiceData?.innovoiceId,
                      additionalInfo1:
                        differenceInDays(returnTime, startTime) + 1,
                      customerInvoiceDate: parse(
                        invoiceData?.delivery_date,
                        "yyyy-MM-dd",
                        new Date()
                      ),
                      desc: "",
                      additionalInfo2: allVehicles
                        .find((v) => v.id === values.reservationVehicles[0].id)
                        ?.additionalFields.find((v) => v.key === "emission")
                        ?.value,
                    },
                  });
                }
              }}
            >
              <MenuItem key={"clear selected"} value={undefined}>
                Ei valittua laskua
              </MenuItem>
              {invoices &&
                invoices.map((invoice) => (
                  <MenuItem value={invoice} key={invoice.id}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography> {invoice?.innovoiceId}</Typography>
                      <Typography>
                        {invoice?.customer?.firstname}
                        {invoice?.customer?.lastname}
                      </Typography>
                      <Typography>{invoice?.customer?.email}</Typography>
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {selectedInvoice && (
          <React.Fragment>
            <Grid item xs={6} md={4}>
              <FormInputField
                name="hanselData.customerInvoiceId"
                label="Asiakkaalle lähetetyn laskun nro"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <DatePicker
                disabled={isDisabled}
                label="Asiakkaalle lähetetyn laskun pvm"
                inputVariant="outlined"
                name="hanselData.customerInvoiceDate"
                value={
                  values?.hanselData?.customerInvoiceDate
                    ? new Date(values?.hanselData?.customerInvoiceDate)
                    : undefined
                }
                onChange={(date) => {
                  setFieldValue("hanselData.customerInvoiceDate", date);
                }}
                inputFormat="dd.MM.yyyy"
                cancelLabel="Peruuta"
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
}
