import React from "react";

import { useFormikContext } from "formik";
import { Checkbox, FormControlLabel, Grid, Stack } from "@mui/material";
import { FormContainer } from "../../../../../components/FormContainer";
import CheckBoxComponent from "../../../../../components/ReservationForm/CheckBoxComponent";
import DisableConfirmationEmail from "./components/DisableConfirmationEmail";
import DisablePricesInPrintouts from "./components/DisablePricesInPrintouts";
import EnableRegistrationPlate from "./components/EnableRegistrationPlate";
import IsBrokerCheckBox from "./IsBrokerCheckBox";
import SelectContractLanguage from "./components/SelectContractLanguage";
import SelectCustomerType from "./components/SelectCustomerType";
import SendStatusToCustomer from "./components/SendStatusToCustomer";
import ShowBrokerFields from "./components/ShowBrokerFields";
import ShowCompanyCustomerFields from "./components/CompanyCustomerFields/ShowCompanyCustomerFields";
import ShowDifferentDriverFields from "./components/ShowDifferentDriverFields";
import ShowHanselFields from "./components/ShowHanselFields";
import ShowPrivateCustomerFields from "./components/ShowPrivateCustomerFields";
import ShowAdditionalDriverFields from "./components/ShowAdditionalDriverFields";

export default function CustomerInformationTab() {
  const formik = useFormikContext();
  const { values, setFieldValue, handleBlur } = formik;
  const selectedLanguage = "FIN";

  return (
    <FormContainer visibility={true} title={"Yhteystiedot"}>
      <Grid
        alignItems={"flex-start"}
        container
        direction="row"
        item
        justifyContent={"flex-start"}
        spacing={1}
      >
        <Grid item xs={12}>
          <Stack
            alignItems={"center"}
            direction="row"
            justifyContent={"space-between"}
            width={"100%"}
          >
            <SelectCustomerType />
            <SelectContractLanguage />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <SendStatusToCustomer />
        </Grid>
        <Grid item xs={12}>
          <DisableConfirmationEmail />
        </Grid>
        <EnableRegistrationPlate />
        <DisablePricesInPrintouts />
        <Grid item sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(values?.type === "HANSEL")}
                color="primary"
                onChange={(e) =>
                  setFieldValue("type", e.target.checked ? "HANSEL" : null)
                }
                onBlur={handleBlur}
              />
            }
            label={<>{"Hansel-varaus"}</>}
          />
        </Grid>
        <Grid item sm={3}>
          <IsBrokerCheckBox />
        </Grid>
        <ShowBrokerFields />
        <Grid item xs={12}>
          <ShowHanselFields visibility={Boolean(values.type === "HANSEL")} />
        </Grid>
        <ShowCompanyCustomerFields />
        <ShowPrivateCustomerFields />
        <Grid item xs={12}>
          <CheckBoxComponent
            name={"differentDriver"}
            label={fieldLabel[selectedLanguage].differentDriver}
          />
          <CheckBoxComponent
            name={"additionalDriver"}
            label={fieldLabel[selectedLanguage].additionalDriver}
          />
        </Grid>
        <ShowDifferentDriverFields />
        <ShowAdditionalDriverFields />
      </Grid>
    </FormContainer>
  );
}

const EFormFields = {
  IS_AGENT_CUSTOMER: "isAgentCustomer",
  DIFFERENT_DRIVER: "differentDriver",
  ADDITIONAL_DRIVER: "additionalDriver",
};

const formFieldLabelsFIN = {
  [EFormFields.DIFFERENT_DRIVER]: "Kuljettaja eri kuin vuokraaja",
  [EFormFields.IS_AGENT_CUSTOMER]: "Agenttiasiakas",
  [EFormFields.ADDITIONAL_DRIVER]: "Lisäkuljettaja",
};

const fieldLabel = {
  FIN: formFieldLabelsFIN,
};
