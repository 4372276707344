import { Form, Formik } from "formik";
import { useContext, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { AppContext } from "../../../../../AppContext";
import { getReservationChannel } from "../../../formik/utils";
import { reservationFields } from "../../../formik/fields";
import { upsertReservationAdmin } from "../../../../../graphql/mutations";
import { captureError } from "../../../../../utilities";
import { useAtomValue, useSetAtom } from "jotai";
import { reservationDataAtom } from "../../../../../atoms/reservationDataAtom";
import { useValidateFormikFields } from "../../../formik/validate";
import { useGetInitialFormDataExternalUsers } from "../../../formik/hooks/useGetInitialFormDataExternalUsers";
import { useFetchReservations } from "../../../../../services/fetchReservations";
import { useNotification } from "../../../../Notification";

export default function ReservationForm(props) {
  const { children } = props;
  const { user } = useContext(AppContext);
  const reservationData = useAtomValue(reservationDataAtom);
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const initialValues = useGetInitialFormDataExternalUsers();
  const formRef = useRef(null);
  const { validate } = useValidateFormikFields();
  const { reservationsMutate } = useFetchReservations("existsBetween");
  const notification = useNotification();
  return (
    <Formik
      initialValues={initialValues}
      innerRef={formRef}
      onSubmit={async (values) => {
        try {
          const response = await submit(values, reservationData, user);
          if (response?.success) {
            notification.show("Varaus luotu onnistuneesti", "success");
            reservationsMutate();
            setEditReservationData(null);
          }

          if (!response?.success) {
            notification.show("Varauksen luonti epäonnistui", "error");
          }

          return response;
        } catch (err) {
          notification.show("Varauksen luonti epäonnistui", "error");
          captureError(
            "Upsert external reservation failed",
            "UPSERT_EXTERNAL_RESERVATION_FAILED",
            err
          );
          console.log("UPSERT", err);
        }
      }}
      validate={(values) => validate(values)}
    >
      <Form
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Form>
    </Formik>
  );
}

const submit = async (values, reservationData, user) => {
  const reservationChannel = getReservationChannel(
    reservationData?.id,
    user?.role
  );

  const reservation = {
    [reservationFields.channel]: reservationChannel,
    [reservationFields.status]: "EXTERNAL_PENDING_DEALER",
    [reservationFields.paymentStatus]: "PENDING_INVOICE",

    /* DEALER FIELDS */
    [reservationFields.group]: values.group,
    [reservationFields.organizationId]: values.organizationId,
    [reservationFields.orgBusinessId]: values.orgBusinessId,
    [reservationFields.orgExternalBusinessId]: user.externalBusinessId,
    [reservationFields.companyId]: values.companyId,
    [reservationFields.isCompany]: true,
    [reservationFields.customerCompanyId]: values.customerCompanyId,
    [reservationFields.type]: user?.externalBusiness?.hanselId
      ? "HANSEL"
      : null,
    [reservationFields.hanselData]: handleHanselData(values, user),
    [reservationFields.additionalServices]: handleAdditionalServices(values),
    [reservationFields.initialClassInfo]: handleInitialClassInfo(values),
    [reservationFields.companyName]: values.companyName,
    [reservationFields.companyBusinessId]: values.companyBusinessId,
    [reservationFields.companyCity]: values.companyCity,
    [reservationFields.companyPostalCode]: values.companyPostalCode,
    [reservationFields.companyAddress]: values.companyAddress,
    [reservationFields.companyBillingRef]: values.companyBillingRef,
    [reservationFields.companyBillingAddress]: values.companyBillingAddress,
    /* RESERVATION PICKUP- & RETURN LOCATION */
    [reservationFields.startLocation]: values.startLocation,
    [reservationFields.startLocationID]: values.startLocationID,
    [reservationFields.returnLocation]: values.returnLocation,
    [reservationFields.returnLocationID]: values.returnLocationID,

    /* RESERVATION SCHEDULE */
    [reservationFields.startTime]: values.startTime,
    [reservationFields.returnTime]: values.returnTime,
    [reservationFields.endTime]: values.returnTime, // external users cannot select end time
    /* EXTERNAL USER SELECTED OFFER  */
    [reservationFields.wildCardVehicles]: handleWildCardVehicles(values),
    [reservationFields.reservationVehicles]: [],

    /* CUSTOMER FIELDS */
    [reservationFields.email]: values?.email?.trim(),
    [reservationFields.name]: values.name,
    [reservationFields.address]: values.address,
    [reservationFields.phone]: values?.phone,

    /* RESERVATION NOTES */
    [reservationFields.internalNotes]: values.internalNotes,
    [reservationFields.externalNotes]: values.externalNotes,
    [reservationFields.rentalNotes]: values.rentalNotes,
  };

  delete reservation.insuranceData?.__typename; // Make sure we don't try to save __typename
  try {
    const submitReservation = reservationData
      ? { ...reservationData, ...reservation }
      : reservation;
    const response = await API.graphql(
      graphqlOperation(upsertReservationAdmin, {
        reservation: submitReservation,
        sendStatusToCustomer: values.sendStatusToCustomer,
        disableConfirmationEmail: values.disableConfirmationEmail,
      })
    );

    return { success: true, data: response.data.upsertReservationAdmin };
  } catch (err) {
    captureError("Upsert reservation failed", "UPSERT_RESERVATION_FAILED", err);
    //   notification.show("Varauksen luonti epäonnistui", "error");
    console.log("UPSERT", err);
    return { success: false, error: err };
  }
};

const handleHanselData = (values, user) => {
  if (!user?.externalBusiness?.hanselId) return null;
  const hanselDataInput = {
    contractNro: null,
    customerId: user?.externalBusiness?.hanselId,
    hanselClass: values.wildCardVehicles[0]?.hanselClass,
    quantity: 1,
  };
  return hanselDataInput;
};

const handleInitialClassInfo = (values) => {
  const vehicle = values?.wildCardVehicles[0];
  if (!vehicle) return null;
  return {
    categoryId: vehicle?.categoryId,
    categoryName: vehicle?.category?.name,
    hanselClass: vehicle?.hanselClass,
  };
};

const handleWildCardVehicles = (values) => {
  return values?.wildCardVehicles?.map((vehicle) => {
    return {
      categoryId: vehicle?.categoryId,
      name: vehicle?.name,
      price: vehicle?.price,
      tax: vehicle?.tax,
      deductible: vehicle?.deductible,
    };
  });
};

const handleAdditionalServices = (values) => {
  if (!values?.additionalServices) return null;
  return values.additionalServices.map((service) => {
    const quantity = service?.quantity ?? 1;
    return {
      key: service?.key,
      description: service?.description,
      price: service?.offerPrice * quantity,
      tax: service?.tax,
      details: service?.details,
      deductibleValue: service?.deductibleValue,
      category: service?.category,
      quantity: service?.quantity,
      enableNotification: service?.enableNotification,
    };
  });
};
