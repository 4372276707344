import { Box, Tooltip, Typography } from "@mui/material";
import { getReservationName } from "./utils/getReservationName";
import { BsPatchExclamationFill } from "react-icons/bs";
import { Reservation, Vehicle } from "~/graphql/API";
import { format } from "date-fns";
import { FaFileImport } from "react-icons/fa";
import styles from "./styles.module.scss";

type ReservationCardLabelProps = {
  reservation: Reservation;
  vehicle?: Vehicle;
  showTime?: boolean;
};

function ReservationCardLabel({
  reservation,
  vehicle,
  showTime = true,
}: ReservationCardLabelProps) {
  const startTime = format(reservation.startTime as unknown as Date, "HH:mm");

  let returnTime = format(reservation.returnTime as unknown as Date, "HH:mm");
  if (returnTime === "23:59") {
    returnTime = "24";
  }

  return (
    <div className={styles.root}>
      {!!reservation.customImportDate && (
        <Tooltip
          title={
            "Tujaus: tuotu " +
            format(new Date(reservation.customImportDate), "dd.MM.yyyy")
          }
        >
          <Box>
            <FaFileImport
              style={{
                color: "white",
              }}
            />
          </Box>
        </Tooltip>
      )}

      {vehicle && vehicle?.companyId !== reservation.companyId && (
        <BsPatchExclamationFill />
      )}

      <div className={"ReservationCardLabel-label " + styles.label}>
        {reservation.outOfCommission ? (
          <Typography variant="caption">{reservation.internalNotes}</Typography>
        ) : (
          <>
            {showTime && (
              <Typography variant="body1">
                {startTime} - {returnTime}
              </Typography>
            )}
            <Typography
              variant="caption"
              overflow="clip"
              textOverflow="ellipsis"
            >
              {getReservationName(reservation)}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
}

export default ReservationCardLabel;
