import React, { useContext, useMemo } from "react";
import { Tooltip, Typography, Box } from "@mui/material";
import { format } from "date-fns";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { BsPatchExclamationFill } from "react-icons/bs";
import { useTheme } from "@emotion/react";
import { AppContext } from "../AppContext";
import { useFetchCompanies } from "../services/fetchCompanies";
import { AuthStatusCompletedIcon, OnlinePaymentCompletedIcon } from "./common/StatusIcons";
export default function ReservationTooltip(props) {
  const {
    reservation,
    differentReturnLocation,
    children,
    vehicle,
    visibility,
  } = props;
  const { user, business } = useContext(AppContext);

  const { companies } = useFetchCompanies(user?.group, business?.id);

  const theme = useTheme();

  const startLocation = useMemo(() => {
    if (!companies || !vehicle) return null;
    if (reservation?.startLocation) {
      return reservation.startLocation;
    } else {
      return companies.find((c) => c.id === vehicle.companyId)?.name;
    }
  }, [companies, reservation, vehicle]);

  const returnLocation = useMemo(() => {
    if (!companies || !vehicle) return null;
    if (reservation?.returnLocation) {
      return reservation.returnLocation;
    } else {
      return companies.find((c) => c.id === vehicle.companyId)?.name;
    }
  }, [companies, reservation, vehicle]);

  return (
    <Tooltip
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            border: "solid 1px rgba(0,0,0,1)",
            borderWidth: "1px",
            borderColor: theme.palette.primary.main,
            color: "rgba(0,0,0,0.65)",
            backgroundColor: "#29293e",
            borderRadius: "10px",
          },
        },
      }}
      title={
        visibility ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                color: "#ffffff",
              }}
            >
              <ReservationCustomer reservation={reservation} />
            </Box>
            {reservation?.startTime && reservation?.returnTime && (
              <Box>
                <Typography variant="body1" sx={{ color: "#ceced2" }}>
                  {format(reservation.startTime, "dd.MM HH:mm")} -{" "}
                  {format(reservation.returnTime, "dd.MM HH:mm")}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginY: "5px",
              }}
            >
              {reservation?.companyId && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: theme.palette.info.light }}
                  >
                    Noutopaikka:
                  </Typography>
                  <Typography
                    sx={{ marginLeft: "5px", color: theme.palette.info.light }}
                    noWrap
                  >
                    {startLocation}
                  </Typography>
                </Box>
              )}
              {reservation?.companyId && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    color: theme.palette.info.light,
                  }}
                >
                  <Typography variant="body1">Palautuspaikka:</Typography>
                  <Typography
                    sx={{ marginLeft: "5px", color: theme.palette.info.light }}
                    noWrap
                  >
                    {returnLocation}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                marginY: "5px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {differentReturnLocation && (
                <Tooltip title="HUOM! varauksella on eri palautuspiste">
                  <Box
                    sx={{
                      border: "2px solid #ffffff",
                      borderRadius: "50%",
                      margin: "5px",
                      padding: "7px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BsPatchExclamationFill
                      style={{
                        fontSize: "24px",
                        color: "yellow",
                      }}
                    />
                  </Box>
                </Tooltip>
              )}

              {reservation.authStatus === "COMPLETED" && (
                <AuthStatusCompletedIcon />
              )}
              {reservation.signStatus === "COMPLETED" && (
                <Tooltip title="Visma Sign allekirjoitettu">
                  <BorderColorRoundedIcon
                    style={{ color: theme.palette.success.main }}
                  />
                </Tooltip>
              )}
              {reservation.paymentStatus === "COMPLETED" && (
                <OnlinePaymentCompletedIcon />
              )}
              {(reservation.paymentStatus === "COMPLETED_DEPOSIT" ||
                reservation.paymentStatus === "PENDING_FULL") && (
                <Tooltip title="Varausmaksu maksettu">
                  <Box
                    sx={{
                      border: "2px solid #ffffff",

                      borderRadius: "50%",
                      margin: "5px",
                      padding: "7px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MonetizationOnIcon
                      style={{
                        color: "gold",
                        fontSize: "24px",
                      }}
                    />
                  </Box>
                </Tooltip>
              )}
              {reservation.paymentStatus === "PENDING_INVOICE" && (
                <Box
                  sx={{
                    border: "2px solid #ffffff",

                    borderRadius: "50%",
                    margin: "5px",
                    padding: "7px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MonetizationOnIcon
                    style={{
                      color: "tomato",
                      fontSize: "24px",
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        ) : null
      }
    >
      {children}
    </Tooltip>
  );
}

function ReservationCustomer(props) {
  const { reservation } = props;
  let name;
  if (reservation.hasOwnProperty("name")) {
    name = reservation.name;
  }
  if (
    reservation.hasOwnProperty("companyName") &&
    reservation?.companyName !== ""
  ) {
    name = reservation.companyName;
  }

  return (
    <>
      <Typography variant="h6">Varaaja:</Typography>
      <Typography variant="h6" sx={{ marginLeft: "5px" }}>
        {name}
      </Typography>
    </>
  );
}
