import { useContext } from "react";

import { List, ListItemButton, ListItemText, Box, Stack } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LicenseInfo } from "@mui/x-license-pro";
import { useFormikContext } from "formik";
import { getHours, isBefore, isSameDay, isValid, set } from "date-fns";
import { AppContext } from "../../../../AppContext";
LicenseInfo.setLicenseKey(
  "57d9cfea154b5252705da4c9ab0f8583Tz03NTg0OSxFPTE3Mjc5NDI2OTEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export default function ReservationSchedule() {
  const { USER_ADMIN_GENERIC, mobileViewPort } = useContext(AppContext);
  //if (userRole === "admin")
  return (
    <Stack
      spacing={2}
      justifyContent={"flex-start"}
      direction={mobileViewPort ? "column" : "row"}
      width={"100%"}
    >
      <Box>
        <StartTimePicker />
      </Box>

      <Box>
        <ReturnTimePicker />
      </Box>
      {USER_ADMIN_GENERIC && (
        <Box>
          <EndTimePicker />
        </Box>
      )}
    </Stack>
  );
}

function StartTimePicker(props) {
  const { disablePast } = props;
  const { values, setFieldValue, errors } = useFormikContext();

  async function handleChangeStartTime(date) {
    setFieldValue("startTime", date);
    if (isBefore(values.returnTime, date) || !values.returnTime) {
      const returnDate = set(date, { hours: getHours(new Date()) + 2 });
      const endDate = set(date, { hours: getHours(new Date()) + 2 });
      await setFieldValue("returnTime", returnDate);
      await setFieldValue("endTime", endDate);
    }
  }
  const errorText = errors?.startTime ? "Valitse aloitusaika" : "";
  const hasError = errors?.startTime;
  return (
    <DateTimePicker
      ampm={false}
      label="Aloitusaika"
      value={values.startTime}
      slots={{
        toolbar: PickerToolbar,
        actionBar: ActionList,
      }}
      slotProps={{
        textField: {
          helperText: errorText,
          error: !!hasError,
        },
      }}
      disablePast={disablePast} //!reservationData && USER_ADMIN_CORPORATE
      displayWeekNumber
      // thresholdToRenderTimeInASingleColumn={100}
      timeSteps={{ hours: 1, minutes: 15, seconds: 5 }}
      onChange={(date) => handleChangeStartTime(date)}
      //  onClose={(date) => handleClose("startTime", date, values.startTime)}
    />
  );
}

function ReturnTimePicker(props) {
  const { values, setFieldValue, errors } = useFormikContext();

  async function handleReturnDate(date) {
    await setFieldValue("returnTime", date);
    await setFieldValue("endTime", date);
  }
  const errorText = errors?.returnTime ?? "";
  const hasError = errors?.returnTime;
  return (
    <Box>
      <DateTimePicker
        ampm={false}
        label="Palautusaika"
        displayWeekNumber
        value={values.returnTime}
        slots={{
          toolbar: PickerToolbar,
          actionBar: ActionList,
        }}
        slotProps={{
          textField: {
            helperText: errorText,
            error: !!hasError,
          },
        }}
        onChange={(date) => handleReturnDate(date)}
        //  thresholdToRenderTimeInASingleColumn={100}
        timeSteps={{ hours: 1, minutes: 15, seconds: 5 }}
        shouldDisableDate={(day) =>
          isBefore(day, values.startTime) && !isSameDay(day, values.startTime)
        }
        shouldDisableTime={(time) => {
          if (isValid(values?.startTime)) {
            return time.getTime() <= values?.startTime?.getTime();
          }
          return false;
        }}
        // onClose={(date) => handleClose("returnTime", date, values.returnTime)}
      />
    </Box>
  );
}

function EndTimePicker(props) {
  const { values, setFieldValue, errors } = useFormikContext();
  const errorText = errors?.endTime ? "Valitse aika" : "";
  const hasError = errors?.endTime;
  return (
    <Box>
      <DateTimePicker
        ampm={false}
        displayWeekNumber
        label="Vapaa uudelleen varattavaksi"
        value={values.endTime}
        slots={{
          toolbar: PickerToolbar,
          actionBar: ActionList,
        }}
        slotProps={{
          textField: {
            helperText: errorText,
            error: !!hasError,
          },
        }}
        //  thresholdToRenderTimeInASingleColumn={100}
        timeSteps={{ hours: 1, minutes: 15, seconds: 5 }}
        shouldDisableDate={(day) =>
          isBefore(day, values.startTime) && !isSameDay(day, values.startTime)
        }
        shouldDisableTime={(time) => {
          if (isValid(values.startTime)) {
            return time.getTime() <= values.startTime?.getTime();
          } else {
            return false;
          }
        }}
        onChange={async (date) => setFieldValue("endTime", date)}
        //   onClose={(date) => handleClose("endTime", date, values.endTime)}
      />
    </Box>
  );
}

function ActionList(props) {
  const { onAccept, onCancel, className } = props;
  const actions = [
    { text: "Peruuta", method: onCancel },
    { text: "Valmis", method: onAccept },
  ];
  return (
    // Propagate the className such that CSS selectors can be applied
    <Box
      className={className}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      {actions.map(({ text, method }) => (
        <List key={text} sx={{ margin: "5px", marginBottom: "10px" }}>
          <ListItemButton
            onClick={method}
            sx={{
              paddingY: "5px",
              borderRadius: "7px",
            }}
          >
            <ListItemText primary={text} />
          </ListItemButton>
        </List>
      ))}
    </Box>
  );
}

function PickerToolbar(props) {
  const { className } = props;

  return (
    <Box className={className}>
      <Box sx={{ display: "flex", justifyContent: "center" }}></Box>
    </Box>
  );
}
