import { Box, Typography } from "@mui/material";

export const ScandiaRentAssistanceField = (props) => {
  const { visibility } = props;

  if (visibility) {
    return (
      <Box>
        <Typography sx={{ fontWeight: 550, fontSize: "12px" }}>
          Scandia Rent 24h Assistance 020 690 110{" "}
        </Typography>
      </Box>
    );
  }
};

export const ScandiaRentRefuelCharge = (props) => {
  const { visibility } = props;

  if (visibility) {
    return (
      <Box>
        <Typography sx={{ fontWeight: 550, fontSize: "12px" }}>
          Tankkauslisä: 12,00 €. Polttoaineen hinta tankkauksessa: 3,90 €/l.
        </Typography>
      </Box>
    );
  }
};
