import {
  EContractAgreement,
  EOrderVehicle,
} from "./LanguagePack/translatedFields";
import { Field, formatDateTime } from "./common";
import { Typography, Stack, Box } from "@mui/material";
import { titleStyle } from "./styles";
export default function ContractAgreement({ reservation, selectedLanguage }) {
  const fields = EContractAgreement[selectedLanguage];
  const { authTime, signerName, ssn } = reservation;

  if (
    reservation?.signStatus === "PENDING" ||
    reservation?.signStatus === "COMPLETED"
  ) {
    return "";
  }
  if (reservation?.authStatus === "COMPLETED") {
    const digitalSignature = authTime ? (
      <div>
        {authTime
          ? `(${formatDateTime(new Date(authTime))}) ${signerName} ${ssn}`
          : ""}
        :
      </div>
    ) : null;
    return (
      <Stack direction={"column"}>
        <Field label={fields.DIGITAL_SIGNATURE} value={digitalSignature} />
      </Stack>
    );
  }
  return (
    <Stack direction={"column"}>
      <Typography sx={titleStyle}>{fields.AGREEMENT_TITLE}</Typography>
      <Box sx={{ border: "1px solid lightgrey", padding: "2px" }}>
        <Typography sx={{ fontWeight: "bolder", fontSize: "10px" }}>
          {EOrderVehicle[selectedLanguage].FILL_UP_FUEL_LABEL}
        </Typography>

        <Typography sx={{ fontSize: "8px" }}>
          {fields.AGREEMENT_TERMS}
        </Typography>
        <Stack direction={"row"} sx={{ marginTop: "5px" }}>
          <Field label={fields.AGREEMENT_CUSTOMER} value={""} />
          <Field label={fields.AGREEMENT_RENTAL_COMPANY} value={""} />
        </Stack>
      </Box>
    </Stack>
  );
}
