import { ECompanyInfo } from "./LanguagePack/translatedFields";
import { Field } from "./common";
import { Grid } from "@mui/material";
export default function ContractCompanyInformation({
  reservation,
  visibility,
  selectedLanguage,
}) {
  const { companyName, companyBillingAddress, companyBusinessId } = reservation;
  const fields = ECompanyInfo[selectedLanguage];
  if (visibility)
    return (
      <Grid item xs={4} sx={{ border: "1px solid lightgrey" }}>
        <Field value={companyName} label={fields?.NAME} />
        <Field value={companyBusinessId} label={fields?.BUSINESS_ID} />
        <Field value={companyBillingAddress} label={fields?.BILLING_ADDRESS} />
      </Grid>
    );
}
