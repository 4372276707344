import { useAtomValue, useSetAtom } from "jotai";
import { vehiclePricesAtom } from "../../../stores/vehiclePrices";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { fetchPriceByCategoryId } from "../../../services/fetchPriceByCategoryId";
import { fetchPriceByVehicleId } from "../../../services/fetchPriceByVehicleId";
import { centsToLocalString } from "../../../shared/money";
import { isTimesValid } from "../utils/isTimesValid";
import { isValid } from "date-fns";

export default function useGetEffectivePrice(vehicleId) {
  const vehiclePrices = useAtomValue(vehiclePricesAtom);
  const setPrice = useSetAtom(vehiclePricesAtom);
  const { values } = useFormikContext();
  const [loading, setLoading] = useState(false);
  const vehicle = vehiclePrices[vehicleId];
  const [fieldInput, setFieldInput] = useState(
    centsToLocalString(vehicle?.price) ?? ""
  );
  const [deductibleInput, setDeductibleInput] = useState(
    centsToLocalString(vehicle?.deductible) ?? ""
  );
  const { pricingCategoryId, orgExternalBusinessId, companyId } = values;
  const vehicleIds =
    values?.reservationVehicles?.map((vehicle) => vehicle?.id) ?? [];

  const priceByCategory =
    pricingCategoryId ?? values?.wildCardVehicles[0]?.categoryId;
  const queryResourceCategory = {
    categoryIds: [priceByCategory],
    startTime: values?.startTime,
    returnTime: values?.returnTime,
    externalBusinessId: orgExternalBusinessId,
  };

  const hasPriceByCategory = values?.pricingCategoryId ?? "none";

  const queryResourceVehicle = {
    vehicleIds: vehicleIds,
    startTime: values?.startTime,
    returnTime: values?.returnTime,
    companyId: companyId,
    externalBusinessId: orgExternalBusinessId,
  };

  const fetcher = async () => {
    const deductible = vehiclePrices[vehicleId]?.deductible ?? undefined;
    if (priceByCategory) {
      const offerPrice = await fetchPriceByCategoryId(queryResourceCategory);
      if (offerPrice?.categoryOfferPrices?.length > 0) {
        return {
          [vehicleId]: {
            price: offerPrice?.categoryOfferPrices[0]?.price,
            startTime: values?.startTime,
            returnTime: values?.returnTime,
            pricingCategoryId: pricingCategoryId,
            deductible:
              deductible ?? offerPrice?.categoryOfferPrices[0]?.deductible,
            tax: offerPrice?.categoryOfferPrices[0]?.tax,
          },
        };
      }
    }
    const offerPrice = await fetchPriceByVehicleId(queryResourceVehicle);
    const offer = offerPrice?.vehicleOfferPrices?.find(
      (offer) => offer.id === vehicleId
    );
    return {
      [vehicleId]: {
        price: offer?.price,
        startTime: values?.startTime,
        returnTime: values?.returnTime,
        pricingCategoryId: pricingCategoryId,
        deductible: deductible ?? offer?.deductible,
        tax: offer?.tax,
      },
    };
  };

  const key = isTimesValid(values?.startTime, values?.returnTime)
    ? [vehicleId, values?.startTime, values?.returnTime, hasPriceByCategory]
    : null;

  const { data } = useSWR(key, fetcher, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (vehicle) {
      const start = isValid(values?.startTime)
        ? values?.startTime?.getTime()
        : null;
      const end = isValid(values?.returnTime)
        ? values?.returnTime?.getTime()
        : null;
      const vehicleStart = vehicle?.startTime
        ? new Date(vehicle?.startTime).getTime()
        : null;
      const vehicleEnd = vehicle?.returnTime
        ? new Date(vehicle?.returnTime).getTime()
        : null;
      if (
        values?.pricingCategoryId != vehicle?.pricingCategoryId ||
        start !== vehicleStart ||
        end !== vehicleEnd ||
        vehicle?.price === undefined
      ) {
        setLoading(true);
        if (data) {
          setPrice((prev) => ({ ...prev, ...data }));
          setFieldInput(centsToLocalString(data[vehicleId]?.price));
          setLoading(false);
        }
      }
    }
  }, [
    data,
    vehicle,
    vehicleId,
    vehicle?.startTime,
    vehicle?.returnTime,
    vehicle?.pricingCategoryId,
    values?.startTime,
    values?.returnTime,
    values?.pricingCategoryId,
    setPrice,
  ]);

  return [
    vehicle?.price,
    loading,
    fieldInput,
    setFieldInput,
    deductibleInput,
    setDeductibleInput,
  ];
}
