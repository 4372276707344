export const VAT_VALUES = {
  0: "0",
  10: "10",
  14: "14",
  24: "24",
  25.5: "25.5",
};

// Exclude 0 for now, needs small changes to emails and stuff
export const VAT_VALUES_PRICINGS = {
  10: "10",
  14: "14",
  24: "24",
  25.5: "25.5",
};

export const DEFAULT_VAT_VALUE = 25.5;
