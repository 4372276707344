import { useMemo } from "react";

import {
  Box,
  IconButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../../atoms/reservationDataAtom";
import { format } from "date-fns";

import { dialogDimensions } from "../../utils/dialogDimensions";
import { storeValuesToCache } from "../../utils/utilities";

import { PrintingComponent } from "../PrintContract";
import SendEmailConfirmation from "../SendEmailConfirmation";

import { CiGlobe } from "react-icons/ci";
import { Menu } from "@mui/icons-material";
import { TbLayoutBoardSplit } from "react-icons/tb";
import { useFormikContext } from "formik";

export default function DialogHeader(props) {
  const { values } = useFormikContext();
  const { setOpenDrawer, setShowAllContent, showAllContent } = props;

  return (
    <Stack
      direction={"row"}
      sx={{
        alignItems: "center",
        bgcolor: "#ffffff",
        borderBottom: "1px solid lightgrey",
        height: dialogDimensions.headerHeight,
        justifyContent: "space-between",
        position: "sticky",
        top: 0,
        zIndex: 5,
      }}
    >
      <Stack
        direction={"row"}
        sx={{ width: { sm: !showAllContent && dialogDimensions.sideMenuWidth } }}
      >
        <SideMenuButton setOpenDrawer={setOpenDrawer} />
        {values?.shortId && (
          <Box sx={{ alignSelf: "center" }}>
            <Typography fontWeight={"bold"}>{values.shortId}</Typography>
          </Box>
        )}
      </Stack>
      <ReservationScheduleTitle showAllContent={showAllContent} />
      <span style={{ flex: 1 }} />
      <PrintingComponent />
      <SendEmailConfirmation />
      <ChangeContentLayout
        setShowAllContent={setShowAllContent}
        showAllContent={showAllContent}
      />
    </Stack>
  );
}

function ChangeContentLayout(props) {
  const { setShowAllContent, showAllContent } = props;
  const handleClick = () => {
    storeValuesToCache("adaptiveView", !showAllContent);
    setShowAllContent((prevState) => !prevState);
  };
  return (
    <IconButton onClick={handleClick}>
      <TbLayoutBoardSplit style={{ fontSize: "1.5rem" }} />
    </IconButton>
  );
}

function SideMenuButton(props) {
  const { setOpenDrawer } = props;

  return (
    <Box>
      <IconButton
        sx={{
          display: { lg: "none" },
        }}
        onClick={() => setOpenDrawer((prevState) => !prevState)}
      >
        <Menu />
      </IconButton>
    </Box>
  );
}

function ReservationScheduleTitle(props) {
  const reservationData = useAtomValue(reservationDataAtom);
  const { values } = useFormikContext();
  const handleNameTitle = useMemo(() => {
    let title = "";

    if (values?.companyName) {
      title = values.companyName;
    } else if (values?.name) {
      title = values.name;
    } else if (values?.email) {
      title = values.email;
    }

    return title;
  }, [values?.name, values?.companyName, values?.email]);

  const theme = useTheme();
  if (!values) return null;
  const schedule = values?.startTime && values?.returnTime && (
    <Typography sx={{ fontWeight: 550 }}>{`${
      values?.startTime && format(new Date(values?.startTime), "dd.MM.yyyy")
    } - ${
      values?.returnTime && format(new Date(values?.returnTime), "dd.MM.yyyy")
    }`}</Typography>
  );
  return (
    <Stack
      sx={{
        display: { xs: "none", sm: "flex" },
      }}
      direction={"row"}
      flex={1}
      alignItems={"center"}
      spacing={1}
    >
      {reservationData?.channel === "CUSTOMER" && (
        <Box>
          <CiGlobe
            style={{
              fontSize: "40px",
              color: theme.palette.success.main,
            }}
          />
        </Box>
      )}

      <Box>
        <ListItemText
          disableTypography
          primary={schedule}
          secondary={handleNameTitle}
        />
      </Box>
    </Stack>
  );
}
