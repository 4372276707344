// @ts-ignore
import DialogComponent from "~/components/DialogComponent";
import { useModal } from "../hooks";
import { Box, CircularProgress } from "@mui/material";
import { Formik } from "formik";
import { Reservation } from "~/graphql/API";
import { EInvoiceType } from "./enums/InvoiceType";
import { MODAL_LABEL } from "./config/modal";
import validate from "./form/validate";
import InsuranceInput from "./components/InsuranceInput";
import { ModalContextProvider } from "./context";
import InvoiceItemsInput from "./components/InvoiceItemsInput";
import { INSURANCE_TYPES } from "./constants";
import CustomerDetails from "./components/CustomerDetails";
import InvoiceDetails from "./components/InvoiceDetails";
import getInitialValues from "./form/getInitialvalues";
import { InvoiceCustomer } from "./interfaces/invoice-customer";
import useSubmit from "./form/submit";
import { EModal } from "../enums";
import InvoiceTotal from "./components/InvoiceTotal";

type CreateInvoiceProps = {
  title?: string;
  invoiceType: EInvoiceType;
  customer?: InvoiceCustomer;
  reservations?: Reservation[];
};

function CreateInvoice({
  title,
  invoiceType,
  customer,
  reservations = [],
}: CreateInvoiceProps) {
  const { openModal, closeModal } = useModal();
  const submit = useSubmit({
    onSuccess() {
      closeModal();
    },
  });

  let dialogTitle = MODAL_LABEL[invoiceType];
  if (title) {
    dialogTitle = title + " - " + dialogTitle;
  }

  return (
    <ModalContextProvider value={{ invoiceType }}>
      <Formik
        initialValues={getInitialValues({
          invoiceType,
          customer,
          reservations,
        })}
        validate={validate({ invoiceType })}
        onSubmit={submit}
      >
        {(formik) => (
          <DialogComponent
            open
            dialogClose={() => openModal(EModal.CONSOLIDATED_INVOICES)}
            dialogTitle={dialogTitle}
            maxWidth="lg"
            dialogAction={() => {
              formik.submitForm();
            }}
            dialogActionText={
              formik.isSubmitting ? <CircularProgress size={24} /> : "Lähetä"
            }
            disableAction={formik.isSubmitting}
            dialogActionDisabled={formik.isSubmitting}
          >
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
                padding: "10px",
              }}
            >
              {INSURANCE_TYPES.includes(invoiceType) && <InsuranceInput />}

              <CustomerDetails />

              <InvoiceItemsInput />

              <InvoiceTotal />

              <InvoiceDetails />
            </Box>
          </DialogComponent>
        )}
      </Formik>
    </ModalContextProvider>
  );
}

export default CreateInvoice;
