import {
  BsFillCloudHaze2Fill,
  BsPersonCheckFill,
  BsBox,
  BsPeopleFill,
} from "react-icons/bs";
import { TbManualGearbox, TbDimensions } from "react-icons/tb";
import {
  FaIdCard,
  FaCarBattery,
  FaInbox,
  FaBriefcase,
  FaArrowsAltV,
  FaSnowflake,
  FaStickyNote,
  FaListAlt,
  FaParking,
  FaHotTub,
  FaWifi,
  FaGasPump,
} from "react-icons/fa";
import { GiCarDoor, GiMeal } from "react-icons/gi";
import { MdFastfood, MdPets, MdRvHookup } from "react-icons/md";
import { TbEngine } from "react-icons/tb";
import { GiCarWheel } from "react-icons/gi";
import { MdCoffeeMaker } from "react-icons/md";
import { BiSolidCoffee } from "react-icons/bi";
import { FaToilet } from "react-icons/fa";
import { FaChair } from "react-icons/fa";
import { GiCampfire } from "react-icons/gi";
import { BsProjector } from "react-icons/bs";
import { IoIosColorPalette } from "react-icons/io";
import { FaCar } from "react-icons/fa";
import { IoSpeedometer } from "react-icons/io5";
import { GiWoodPile } from "react-icons/gi";

const powerType = {
  label: "Käyttövoima",
  label_fi: "Käyttövoima",
  label_en: "Fuel",
  label_se: "Kraftkälla",
  key: "powerType",
  primaryFilter: false,
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Bensiini", value: "B" },
    { label: "Diesel", value: "D" },
    { label: "Sähkö", value: "E" },
    { label: "Bensiini 95 E10", value: "B95E10" },
    { label: "Bensiini 95E", value: "B95E" },
    { label: "Bensiini 98 E5", value: "B98" },
    { label: "Bensiini-hybridi", value: "BH" },
    { label: "Diesel-hybridi", value: "DH" },
    { label: "muu", value: "other" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Bensiini", value: "B" },
    { label: "Diesel", value: "D" },
    { label: "Sähkö", value: "E" },
    { label: "Bensiini 95 E10", value: "B95E10" },
    { label: "Bensiini 95E", value: "B95E" },
    { label: "Bensiini 98 E5", value: "B98" },
    { label: "Bensiini-hybridi", value: "BH" },
    { label: "Diesel-hybridi", value: "DH" },
    { label: "muu", value: "other" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Gasoline", value: "B" },
    { label: "Diesel", value: "D" },
    { label: "Electric", value: "E" },
    { label: "Gasoline 95 E10", value: "B95E10" },
    { label: "Gasoline 95E", value: "B95E" },
    { label: "Gasoline 98 E5", value: "B98" },
    { label: "Gasoline-Hybrid", value: "BH" },
    { label: "Diesel-Hybrid", value: "DH" },
    { label: "muu", value: "other" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Bensin", value: "B" },
    { label: "Diesel", value: "D" },
    { label: "Elektrisk", value: "E" },
    { label: "Bensin 95 E10", value: "B95E10" },
    { label: "Bensin 95E", value: "B95E" },
    { label: "Bensin 98 E5", value: "B98" },
    { label: "Bensin-Hybrid", value: "BH" },
    { label: "Diesel-Hybrid", value: "DH" },
    { label: "muu", value: "other" },
  ],
  icon: <TbEngine />,
};

const emission = {
  label: "Päästöt",
  label_fi: "Päästöt",
  label_en: "Emission",
  label_se: "Utsläpp",
  key: "emission",
  primaryFilter: false,
  unit: "CO2 g/km",
  unit_fi: "CO2 g/km",
  unit_en: "CO2 g/km",
  unit_se: "CO2 g/km",
  icon: <BsFillCloudHaze2Fill />,
};
const transmission = {
  label: "Vaihteisto",
  label_fi: "Vaihteisto",
  label_en: "Transmission",
  label_se: "Överföring",
  key: "transmission",
  primaryFilter: true,
  options: [
    { label: "Inte vald", value: undefined },
    { label: "Manuaali", value: "M" },
    { label: "Automaatti", value: "A" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Manuaali", value: "M" },
    { label: "Automaatti", value: "A" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Manual", value: "M" },
    { label: "Automatic", value: "A" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Växellåda", value: "M" },
    { label: "Automatisk", value: "A" },
  ],
  icon: <TbManualGearbox />,
};

const towingHook = {
  label: "Vetokoukku",
  label_fi: "Vetokoukku",
  label_en: "Towinghook",
  label_se: "Dragkrok",
  key: "towingHook",
  primaryFilter: true,
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Yes" },
    { label: "Nej", value: "No" },
  ],
  icon: <MdRvHookup />,
};

const towingCapacity = {
  label: "Vetomassa",
  label_fi: "Vetomassa",
  label_en: "Towing capacity",
  label_se: "Dragkapacitet",
  key: "towingCapacity",
  primaryFilter: false,
  unit: "kg",
  icon: <MdRvHookup />,
};

const fuelConsumption = {
  label: "Kulutus",
  label_fi: "Kulutus",
  label_en: "Fuel consumption",
  label_se: "Bränsleförbrukning",
  key: "fuelConsumption",
  primaryFilter: true,
  unit: "L / 100km",
  unit_fi: "L / 100km",
  unit_se: "L / 100km",
  unit_en: "L / 100km",
  icon: <FaGasPump />,
};
const ageLimit = {
  label: "Vähimmäis ajoikäraja",
  label_fi: "Vähimmäis ajoikäraja",
  label_en: "Age limit",
  label_se: "Åldersgräns",
  key: "ageLimit",
  primaryFilter: false,
  unit: "vuotta",
  unit_fi: "vuotta",
  unit_en: "years",
  unit_se: "år",
  icon: <BsPersonCheckFill />,
};
const batteryLife = {
  label: "Akun kesto",
  label_fi: "Akun kesto",
  label_en: "Battery life",
  label_se: "Batteritid",
  key: "batteryLife",
  primaryFilter: true,
  unit: "tuntia",
  unit_fi: "tuntia",
  unit_en: "hours",
  unit_se: "timmar",

  icon: <FaCarBattery />,
};
const requiredLicense = {
  label: "Vaadittu ajo-oikeus",
  label_fi: "Vaadittu ajo-oikeus",
  label_en: "Required license",
  label_se: "Nödvändigt körkort",
  key: "requiredLicense",
  primaryFilter: true,
  unit: "-kortti",
  unit_fi: "-kortti",
  unit_en: "-card",
  unit_se: "-kort",
  icon: <FaIdCard />,
};
const outerSize = {
  label: "Ulkomitat",
  label_fi: "Ulkomitat",
  label_en: "Outer dimensions",
  label_se: "Yttre dimensioner",
  key: "outerSize",
  primaryFilter: false,
  unit: "p. x l. x k.",
  icon: <TbDimensions />,
};
const innerSize = {
  label: "Tavaratilan sisämitat",
  label_fi: "Tavaratilan sisämitat",
  label_en: "Trunk dimensions",
  label_se: "Bagageutrymmets dimensioner",
  key: "innerSize",
  primaryFilter: false,
  unit: "p. x l. x k.",
  icon: <FaInbox />,
};
const luggage = {
  label: "Matkatavarat",
  label_fi: "Matkatavarat",
  label_en: "Luggages",
  label_se: "Bagage",
  key: "luggage",
  primaryFilter: false,
  icon: <FaBriefcase />,
};
const volume = {
  label: "Tilavuus",
  label_fi: "Tilavuus",
  label_en: "Volume",
  label_se: "kapacitet",
  key: "volume",
  primaryFilter: false,
  unit: "m³",
  icon: <BsBox />,
};
const size = {
  label: "Koko",
  label_fi: "Koko",
  label_en: "Size",
  label_se: "Storlek",
  key: "size",
  primaryFilter: true,
  icon: <FaArrowsAltV />,
};
const doors = {
  label: "Ovet",
  label_fi: "Ovet",
  label_en: "Doors",
  label_se: "Dörrar",
  key: "doors",
  primaryFilter: false,
  unit: "ovinen",
  unit_fi: "ovinen",
  unit_en: "doors",
  unit_se: "dörrar",

  icon: <GiCarDoor />,
};
const airCondition = {
  label: "Ilmastointi",
  label_fi: "Ilmastointi",
  label_en: "AC",
  label_se: "Luftkonditionering",
  key: "ac",
  primaryFilter: true,
  type: Boolean,
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: true },
    { label: "Ei", value: false },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Yes" },
    { label: "Nej", value: "No" },
  ],
  icon: <FaSnowflake />,
};
const passangers = {
  label: "Matkustajien lukumäärä",
  label_fi: "Matkustajien lukumäärä",
  label_en: "Passangers",
  label_se: "Passagerare",
  key: "passengers",
  primaryFilter: true,
  unit: "henkeä",
  unit_fi: " henkeä",
  unit_en: " persons",
  unit_se: "personer",
  icon: <BsPeopleFill />,
};
const equipments = {
  label: "Varusteet",
  label_fi: "Varusteet",
  label_en: "Equipments",
  label_se: "Equipment",
  key: "equipments",
  primaryFilter: false,
  icon: <FaListAlt />,
};
const other = {
  label: "Muut tiedot",
  label_fi: "Muut tiedot",
  label_en: "Other info",
  label_se: "annan information",
  key: "other",
  primaryFilter: false,
  icon: <FaStickyNote />,
};

const breakfast = {
  label: "Sisältää aamiaisen",
  label_fi: "Sisältää aamiaisen",
  label_en: "Includes breakfast",
  label_se: "Inkluderar frukost",
  key: "includeBreakfast",
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Yes", value: "Kyllä" },
    { label: "No", value: "Ei" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Kyllä" },
    { label: "Nej", value: "Ei" },
  ],

  icon: <GiMeal />,
};
const parking = {
  label: "Pysäköinti",
  label_fi: "Pysäköinti",
  label_se: "Parkering",
  label_en: "Available parking",
  key: "availableParking",
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Yes", value: "Kyllä" },
    { label: "No", value: "Ei" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Kyllä" },
    { label: "Nej", value: "Ei" },
  ],
  icon: <FaParking />,
};
const saunaOption = {
  label: "Sauna",
  label_fi: "Sauna",
  label_en: "Sauna",
  label_se: "Bastu",
  key: "sauna",
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Yes", value: "Kyllä" },
    { label: "No", value: "Ei" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Kyllä" },
    { label: "Nej", value: "Ei" },
  ],
  icon: <FaHotTub />,
};

const cookingOption = {
  label: "Ruoanlaitto",
  label_fi: "Ruoanlaitto",
  label_en: "Cooking option",
  label_se: "Matlagningsalternativ",
  key: "cookingOption",
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Yes", value: "Kyllä" },
    { label: "No", value: "Ei" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Kyllä" },
    { label: "Nej", value: "Ei" },
  ],
  icon: <MdFastfood />,
};

const seating = {
  label: "Istumapaikat",
  label_fi: "Istumapaikat",
  label_en: "Seating",
  label_se: "Sittplaster",
  key: "seating",
  primaryFilter: false,
  unit: "paikkaa",
  unit_fi: "paikkaa",
  unit_en: "seats",
  unit_se: "platser",
  icon: <FaChair />,
};

const firewood = {
  label: "Polttopuut",
  label_fi: "Polttopuut",
  label_en: "Firewood included",
  label_se: "Ved",
  key: "firewood",
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Yes", value: "Kyllä" },
    { label: "No", value: "Ei" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Kyllä" },
    { label: "Nej", value: "Ei" },
  ],
  icon: <GiWoodPile />,
};

const firepit = {
  label: "Grillipaikka",
  label_fi: "Grillipaikka",
  label_en: "Firepit",
  label_se: "Grillplats",
  key: "firepit",
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Yes", value: "Kyllä" },
    { label: "No", value: "Ei" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Kyllä" },
    { label: "Nej", value: "Ei" },
  ],
  icon: <GiCampfire />,
};

const coffeeMaker = {
  label: "Kahvinkeitin",
  label_fi: "Kahvinkeitin",
  label_en: "Coffee maker",
  label_se: "Kaffekokare",
  key: "coffeeMaker",
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Yes", value: "Kyllä" },
    { label: "No", value: "Ei" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Kyllä" },
    { label: "Nej", value: "Ei" },
  ],
  icon: <MdCoffeeMaker />,
};

const electricKettle = {
  label: "Vedenkeitin ",
  label_fi: "Vedenkeitin ",
  label_en: "Electric kettle",
  label_se: "Vattenkokare",
  key: "electricKettle",
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Yes", value: "Kyllä" },
    { label: "No", value: "Ei" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Kyllä" },
    { label: "Nej", value: "Ei" },
  ],
  icon: <BiSolidCoffee />,
};

const toilet = {
  label: "WC ",
  label_fi: "WC",
  label_en: "Toilet",
  label_se: "WC",
  key: "toilet",
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Yes", value: "Kyllä" },
    { label: "No", value: "Ei" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Kyllä" },
    { label: "Nej", value: "Ei" },
  ],
  icon: <FaToilet />,
};

const mediaPlayers = {
  label: "Mediatoistimet",
  label_fi: "Mediatoistimet",
  label_en: "Mediaplayers",
  label_se: "Mediaspelare",
  key: "mediaPlayers",
  primaryFilter: true,
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Apple TV", value: "appleTV" },
    { label: "Chromecast", value: "chromecast" },
    { label: "Videotykki", value: "projector" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Apple TV", value: "appleTV" },
    { label: "Chromecast", value: "chromecast" },
    { label: "Videotykki", value: "projector" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Apple TV", value: "appleTV" },
    { label: "Chromecast", value: "chromecast" },
    { label: "Projector", value: "projector" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Apple TV", value: "appleTV" },
    { label: "Chromecast", value: "chromecast" },
    { label: "Videokanon", value: "projector" },
  ],
  icon: <BsProjector />,
};

const maxGuests = {
  label: "Max. majoittuja määrä",
  label_fi: "Max. majoittuja määrä",
  label_en: "Max. number of guests",
  key: "maxGuests",
  unit: "henkeä",
  unit_fi: "henkeä",
  unit_en: "persons",
  unit_se: "personer",
  icon: <BsPeopleFill />,
};
const petsAllowed = {
  label: "Lemmikkieläimet sallittu",
  label_fi: "Lemmikkieläimet sallittu",
  label_en: "Pets allowed",
  label_se: "Husdjur tillåtna",
  key: "petsAllowed",
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Yes", value: "Kyllä" },
    { label: "No", value: "Ei" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Kyllä" },
    { label: "Nej", value: "Ei" },
  ],
  icon: <MdPets />,
};
const wifiAvailable = {
  label: "Wifi",
  label_fi: "Wifi",
  label_en: "Wifi",
  label_se: "Wifi",
  key: "wifi",
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kyllä", value: "Kyllä" },
    { label: "Ei", value: "Ei" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Yes", value: "Kyllä" },
    { label: "No", value: "Ei" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Kyllä" },
    { label: "Nej", value: "Ei" },
  ],
  icon: <FaWifi />,
};

const wheelType = {
  label: "Rengastyyppi",
  label_fi: "Rengastyyppi",
  label_en: "Wheeltype",
  label_se: "Hjultyp",
  key: "wheels",
  primaryFilter: false,
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Kesärenkaat", value: "summer" },
    { label: "Talvirenkaat", value: "winter" },
    { label: "Kitkarenkaat", value: "traction" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Kesärenkaat", value: "summer" },
    { label: "Talvirenkaat", value: "winter" },
    { label: "Kitkarenkaat", value: "traction" },
  ],
  options_en: [
    { label: "Not selected", value: undefined },
    { label: "Summer tires", value: "summer" },
    { label: "Snow tires", value: "winter" },
    { label: "Winter friction tires", value: "traction" },
  ],
  options_se: [
    { label: "Inte vald", value: undefined },
    { label: "Ja", value: "Yes" },
    { label: "Nej", value: "No" },
  ],
  icon: <GiCarWheel />,
};

const cubicCapacity = {
  label: "Kuutiotilavuus",
  label_fi: "Kuutiotilavuus",
  label_en: "Cubic capacity",
  label_se: "Cylindervolym",
  key: "cubicCapacity",

  primaryFilter: true,
  unit: "cc",
  icon: <TbEngine />,
};

const driveType = {
  label: "Vetotapa",
  label_fi: "Vetotapa",
  label_en: "Drivetrain",
  label_se: "Drivlina",
  key: "driveType",
  primaryFilter: true,
  options: [
    { label: "Ei valittu", value: undefined },
    { label: "Etuveto", value: "frontWheel" },
    { label: "Takaveto", value: "rearWheel" },
    { label: "Neliveto", value: "fourWheel" },
  ],
  options_fi: [
    { label: "Ei valittu", value: undefined },
    { label: "Etuveto", value: "frontWheel" },
    { label: "Takaveto", value: "rearWheel" },
    { label: "Neliveto", value: "fourWheel" },
  ],
  options_en: [
    { label: "Ei valittu", value: undefined },
    { label: "Front wheel drive", value: "frontWheel" },
    { label: "Rear wheel drive", value: "rearWheel" },
    { label: "4 wheel drive", value: "fourWheel" },
  ],
  options_se: [
    { label: "Ei valittu", value: undefined },
    { label: "Framhjulsdrift", value: "frontWheel" },
    { label: "Bakhjulsdrift", value: "rearWheel" },
    { label: "Fyrhjulsdrift", value: "fourWheel" },
  ],
  icon: <TbEngine />,
};

const yearModel = {
  label: "Vähimmäis ajoikäraja",
  label_fi: "Vähimmäis ajoikäraja",
  label_en: "Age limit",
  label_se: "Åldersgräns",
  key: "yearModel ",
  primaryFilter: false,
  unit: "vuotta",
  unit_fi: "vuotta",
  unit_en: "years",
  unit_se: "år",
  icon: <FaCar />,
};
const color = {
  label: "Väri",
  label_fi: "Väri",
  label_en: "color",
  label_se: "Färg",
  key: "color ",
  primaryFilter: false,
  unit: "",
  unit_fi: "",
  unit_en: "",
  unit_se: "",
  icon: <IoIosColorPalette />,
};

const fuelCapacity = {
  label: "Tankin tilavuus",
  label_fi: "Tankin tilavuus",
  label_en: "FuelCapacity",
  label_se: "tankens volym",
  key: "fuelCapacity",
  primaryFilter: false,
  unit: "",
  unit_fi: "",
  unit_en: "",
  unit_se: "",
  icon: <IoSpeedometer />,
};

export const ADDITIONAL_FIELDS_ALL = [
  powerType,
  emission,
  transmission,
  fuelConsumption,
  ageLimit,
  requiredLicense,
  luggage,
  doors,
  batteryLife,
  innerSize,
  outerSize,
  airCondition,
  towingCapacity,
  towingHook,
  size,
  passangers,
  equipments,
  other,
  volume,
  wifiAvailable,
  petsAllowed,
  maxGuests,
  cookingOption,
  saunaOption,
  parking,
  breakfast,
  wheelType,
  cubicCapacity,
  fuelCapacity,
  color,
  yearModel,
  driveType,
  seating,
  firewood,
  firepit,
  coffeeMaker,
  electricKettle,
  toilet,
  mediaPlayers,
];

export const ADDITIONAL_FIELDS_CAR = [
  powerType,
  transmission,
  emission,
  fuelConsumption,
  ageLimit,
  requiredLicense,
  luggage,
  doors,
  outerSize,
  airCondition,
  passangers,
  equipments,
  other,
  towingCapacity,
  towingHook,
  wheelType,
  fuelCapacity,
  color,
  yearModel,
  driveType,
];

export const ADDITIONAL_FIELDS_RV = [
  powerType,
  emission,
  transmission,
  fuelConsumption,
  ageLimit,
  requiredLicense,
  luggage,
  doors,
  outerSize,
  innerSize,
  airCondition,
  passangers,
  equipments,
  other,
  towingCapacity,
  towingHook,
  wheelType,
  fuelCapacity,
  color,
  yearModel,
];
export const ADDITIONAL_FIELDS_CAMPER = [
  emission,
  ageLimit,
  requiredLicense,
  luggage,
  doors,
  volume,
  passangers,
  equipments,
  other,
  wheelType,
  fuelCapacity,
  color,
  yearModel,
];

export const ADDITIONAL_FIELDS_SNOWMOBILE = [
  emission,
  fuelConsumption,
  ageLimit,
  requiredLicense,
  luggage,
  passangers,
  equipments,
  other,
];
export const ADDITIONAL_FIELDS_VAN = [
  powerType,
  emission,
  transmission,
  fuelConsumption,
  ageLimit,
  requiredLicense,
  doors,
  outerSize,
  innerSize,
  volume,
  airCondition,
  passangers,
  equipments,
  other,
  towingCapacity,
  towingHook,
  wheelType,
  fuelCapacity,
  color,
  yearModel,
  driveType,
];
export const ADDITIONAL_FIELDS_TRAILER = [
  ageLimit,
  requiredLicense,
  outerSize,
  volume,
  equipments,
  other,
];
export const ADDITIONAL_FIELDS_BICYCLE = [batteryLife, size, equipments, other];
export const ADDITIONAL_FIELDS_SAUNABOAT = [passangers, equipments, other];
export const ADDITIONAL_FIELDS_JETSKI = [
  emission,
  ageLimit,
  requiredLicense,
  passangers,
  equipments,
  other,
];

export const ADDITIONAL_FIELDS_ACCOMMODATION = [
  breakfast,
  parking,
  saunaOption,
  cookingOption,
  maxGuests,
  petsAllowed,
  wifiAvailable,
  other,
  equipments,
  seating,
  firewood,
  firepit,
  coffeeMaker,
  electricKettle,
  toilet,
  mediaPlayers,
];

export const ADDITIONAL_FIELDS_SCOOTER = [
  cubicCapacity,
  passangers,
  requiredLicense,
  ageLimit,
  other,
  equipments,
];

export const ADDITIONAL_FIELDS_OTHER = [other];
