import { ReservationsGroup } from "../../utils/groupReservations";
import { Box, Button } from "@mui/material";
import { DataGridPro, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { columns } from "./table";
import BackButton from "./components/BackButton";
import { useState } from "react";
import { ECustomerType, MCustomerType } from "../../views/OpenOrdersView/enums";
import { useModal } from "~/modals/hooks";
import { EModal } from "~/modals/enums";
import { EInvoiceType } from "~/modals/CreateInvoice/enums/InvoiceType";
import { Customer } from "~/graphql/API";

type InvoiceListProps = {
  reservationsGroup: ReservationsGroup;
  onBack: () => void;
};

function InvoiceList({
  reservationsGroup: { customerType, customer, items },
  onBack,
}: InvoiceListProps) {
  const { openModal } = useModal();

  const [selection, setSelection] = useState<GridRowSelectionModel>([]);

  return (
    <>
      <Box display="flex" alignItems="center" gap={4}>
        <BackButton onClick={onBack} />
        <Box>
          {
            MCustomerType[
              (customer?.clientType || customerType) as ECustomerType
            ]
          }
          :{" "}
          {customer?.companyName ||
            items.find(({ name }) => name)?.name ||
            items.find(({ email }) => email)?.email ||
            customer?.id}
        </Box>

        <Box marginLeft="auto">
          <Button
            variant="contained"
            disabled={!selection.length}
            onClick={() => {
              openModal(EModal.CREATE_INVOICE, {
                invoiceType: EInvoiceType.FULL,
                customer: {
                  ...customer,
                  name: items.find(({ name }) => name)?.name || undefined,
                } as Customer,
                reservations: items.filter(({ id }) => selection.includes(id)),
              });
            }}
          >
            {selection.length > 1 ? "Luo koontilasku" : "Luo lasku"}
          </Button>
        </Box>
      </Box>

      <DataGridPro
        sx={{ marginTop: 1 }}
        columns={columns}
        rows={items}
        checkboxSelection
        disableRowSelectionOnClick
        rowSelectionModel={selection}
        onRowSelectionModelChange={setSelection}
      />
    </>
  );
}

export default InvoiceList;
