import { isValid } from "date-fns";
import { LABEL_REQUIRED } from "../constants";
// @ts-ignore
import { parseToCents } from "~/shared/money";
import { EInvoiceType } from "../enums/InvoiceType";

type ValidateArgs = { invoiceType: EInvoiceType };

function validate({ invoiceType }: ValidateArgs) {
  const isAutoliitto = invoiceType === EInvoiceType.AUTOLIITTO;

  return (values: any) => {
    const errors: Record<string, any> = {};

    // Invoice
    if (!values.payment_terms) {
      errors.payment_terms = LABEL_REQUIRED;
    }
    if (!values.vat_included) {
      errors.vat_included = LABEL_REQUIRED;
    }
    //if (!values.invoice_type) {
    //errors.invoice_type = LABEL_REQUIRED;
    //}
    if (!values.delivery_by) {
      errors.delivery_by = LABEL_REQUIRED;
    }
    if (values.delivery_by === "e_invoice") {
      if (!values.e_invoice_operator) {
        errors.e_invoice_operator = LABEL_REQUIRED;
      }
      if (!values.e_invoice_address) {
        errors.e_invoice_address = LABEL_REQUIRED;
      }
    }
    if (!values.delivery_date) {
      errors.delivery_date = LABEL_REQUIRED;
    }
    if (!isValid(values.delivery_date)) {
      errors.delivery_date = "Virheellinen aikamuoto";
    }
    //if (!values.seller_reference) {
    //errors.seller_reference = LABEL_REQUIRED;
    //}
    //if (!values.buyer_reference) {
    //errors.buyer_reference = LABEL_REQUIRED;
    //}
    // Customer
    if (values.customer_type === "person") {
      if (!values.firstname) {
        errors.firstname = LABEL_REQUIRED;
      }
      if (!values.lastname) {
        errors.lastname = LABEL_REQUIRED;
      }
      //if (!values.ssn) {
      //errors.ssn = LABEL_REQUIRED;
      //}
    }
    if (values.customer_type === "company") {
      if (!values.companyName && !isAutoliitto) {
        errors.companyName = LABEL_REQUIRED;
      }
      if (!values.companyBusinessId) {
        errors.companyBusinessId = LABEL_REQUIRED;
      }
    }
    if (!values.address && !isAutoliitto) {
      errors.address = LABEL_REQUIRED;
    }
    if (!values.postalCode && !isAutoliitto) {
      errors.postalCode = LABEL_REQUIRED;
    }
    if (values.delivery_by === "email" && !values.email) {
      errors.email = LABEL_REQUIRED;
    }
    //if (!values.phone) {
    //errors.phone = LABEL_REQUIRED;
    //}
    if (!values.city && !isAutoliitto) {
      errors.city = LABEL_REQUIRED;
    }
    // Items
    errors.items = [];
    for (let i = 0; i < values.items.length; i++) {
      if (!errors.items[i]) {
        errors.items[i] = {};
      }
      if (!values.items[i].name) {
        errors.items[i].name = LABEL_REQUIRED;
      }
      if (!values.items[i].unitprice) {
        errors.items[i].unitprice = LABEL_REQUIRED;
      } else if (parseToCents(values.items[i].unitprice) === null) {
        errors.items[i].unitprice = `Anna hinta muodossa "123,45"`;
      }
      if (!values.items[i].quantity) {
        errors.items[i].quantity = LABEL_REQUIRED;
      }
      if (!values.items[i].vat) {
        errors.items[i].vat = LABEL_REQUIRED;
      }
      if (Object.keys(errors.items[i]).length === 0) delete errors.items[i];
    }

    // Insurance
    if (
      invoiceType === EInvoiceType.INSURANCE_COMPANY &&
      !values.insuranceCompany
    ) {
      errors.insuranceCompany = "Valitse vakuutusyhtiö";
    }

    if (
      (invoiceType === EInvoiceType.INSURANCE_COMPANY || isAutoliitto) &&
      !values.vehicle_class
    ) {
      errors.vehicle_class = LABEL_REQUIRED;
    }

    if (Object.keys(errors.items).length === 0) {
      delete errors.items; // Convert to empty object if no errors are found
    }
    //console.log(errors);

    return errors;
  };
}

export default validate;
