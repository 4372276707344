import {
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { useMemo } from "react";
import { useGetBrokers } from "~/hooks/useGetBrokers";

export default function ShowBrokerFields() {
  const { values } = useFormikContext();
  if (values?.isBroker)
    return (
      <Grid container item xs={12}>
        <BrokerNameField />
      </Grid>
    );
}

function BrokerNameField() {
  const { brokers, loading } = useGetBrokers();
  const { values, setFieldValue, touched, errors } = useFormikContext();
  const selectedBroker = useMemo(() => {
    if (!values?.customerBrokerId) return null;
    if (values?.customerBrokerId) {
      return brokers.find((item) => item.id === values?.customerBrokerId);
    }
  }, [values?.customerBrokerId, brokers]);
  return (
    <>
      <Autocomplete
        fullWidth
        disableClearable
        disabled={!!values?.customerBrokerId}
        options={brokers}
        loading={loading}
        loadingText="Ladataan..."
        getOptionLabel={(option) => option?.companyName ?? option}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedBroker}
        onChange={(event, newValue, reason) => {
          if (reason === "selectOption" && newValue) {
            // Remove selectedBroker and use brokerCustomer from formik instead?
            setFieldValue("customerBrokerId", newValue.id);
            setFieldValue("brokerCustomer", newValue);
          } else if (reason === "clear") {
            setFieldValue("customerBrokerId", "");
          }
        }}
        selectOnFocus
        clearOnBlur
        renderInput={(params) => (
          <TextField
            {...params}
            label={"Valitse agentti"}
            variant="outlined"
            error={touched.customerBrokerId && !!errors.customerBrokerId}
            helperText={touched.customerBrokerId && errors.customerBrokerId}
            InputProps={{
              ...params.InputProps,
              id: "broker-autocomplete",
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                  {!!values.customerBrokerId && (
                    <IconButton
                      onClick={() => {
                        setFieldValue("brokerCustomer", null);
                        setFieldValue("customerBrokerId", "");
                      }}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.companyName}
          </li>
        )}
      />
      {selectedBroker && (
        <Typography
          variant="body1"
          display="block"
          sx={{
            mt: 1,
            fontWeight: "bold",
          }}
        >
          Agentille asetettu laskutettava yritys: {selectedBroker?.companyName}
        </Typography>
      )}
    </>
  );
}
