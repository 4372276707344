import useSWR from "swr";
import { useCategories } from "../queries/useCategories";
import { fetchPriceByCategoryId } from "../services/fetchPriceByCategoryId";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import { isTimesValid } from "../components/ReservationDialogV2/utils/isTimesValid";

export const useGetExternalUserOffers = (values) => {
  const { user } = useContext(AppContext);
  const categories = useCategories();
  const { orgExternalBusinessId } = values;

  const availableCategories = categories.filter((category) =>
    user?.externalBusiness?.pricings?.items?.some(
      (pricing) => pricing?.name === category?.name
    )
  );
  const categoryIds = availableCategories.map((category) => category.id);
  // const FEATURE_DEV_CATEGORY_IDS = ["9e3c4ce1-31f8-4249-833d-ffe86542c412"];
  const queryResourceCategory = {
    categoryIds: categoryIds,
    startTime: values.startTime,
    returnTime: values.returnTime,
    externalBusinessId: orgExternalBusinessId,
  };

  const key = isTimesValid(values?.startTime, values?.returnTime)
    ? [queryResourceCategory]
    : null;
  const fetcher = () => fetchPriceByCategoryId(queryResourceCategory);

  const { data, error, isLoading } = useSWR(key, fetcher, {
    revalidateOnFocus: false,
  });
  const additionalServiceOffers =
    data?.additionalServiceOffers.map((service) => {
      return { ...service, quantity: 1 };
    }) ?? [];

  return {
    offers: data?.categoryOfferPrices ? data?.categoryOfferPrices : [],
    additionalServiceOffers: additionalServiceOffers ?? [],
    loading: isLoading,
    error: error,
  };
};
