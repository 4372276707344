import {
  Avatar,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCategoryImage } from "~/hooks/useCategoryImage";

import React, { useState } from "react";
import { useCategories } from "../../../../../../../../queries/useCategories";
import {
  centsToLocalString,
  parseToCents,
} from "../../../../../../../../shared/money";
import { useField, useFormikContext } from "formik";
import { Euro } from "@mui/icons-material";
import { ProductCardTemplate } from "../../../../../../components/ProductCardTemplate";
import { useGetOfferPrices } from "~/hooks/useGetOfferPrices";
import PriceLoading from "../../../../../../components/PriceLoading";
import useGetEffectivePrice from "../../../../../../hooks/useGetEffectivePrice";
import { useSetAtom } from "jotai";
import { vehiclePricesAtom } from "../../../../../../../../stores/vehiclePrices";

export default function SelectedVehicleClass() {
  const categories = useCategories();
  const { values } = useFormikContext();
  const [expanded, setExpanded] = useState(false);
  const selectedVehicleClass =
    values?.wildCardVehicles?.length > 0 &&
    values?.reservationVehicles.length === 0
      ? values.wildCardVehicles[0]
      : null;

  const category = categories.find(
    (c) => c.id === selectedVehicleClass?.categoryId
  );

  if (selectedVehicleClass)
    return (
      <ProductCardTemplate
        expanded={expanded}
        setExpanded={setExpanded}
        summary={<ProductCardTitle category={category} />}
        details={
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1}
            width={"100%"}
          >
            <FieldInput
              selectedVehicleClass={selectedVehicleClass}
              expanded={expanded}
            />
          </Stack>
        }
      />
    );
}

const ProductCardTitle = (props) => {
  const { category } = props;
  const { image } = useCategoryImage(category);
  const mobileViewPort = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const imageSize = mobileViewPort
    ? { height: "50px", width: "50px" }
    : { height: "100px", width: "100px" };
  return (
    <Stack
      direction={"row"}
      flex={1}
      alignItems={"center"}
      spacing={2}
      width={"100%"}
    >
      <Box>
        <Avatar variant="rounded" sx={imageSize} src={image} />
      </Box>
      <Stack width={"100%"}>
        <Typography
          sx={{
            fontSize: { sm: "1.2rem", xs: "1.1rem" },
            fontFamily: "Sofia Pro",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {category?.name}
        </Typography>
      </Stack>
    </Stack>
  );
};

function FieldInput(props) {
  const { selectedVehicleClass, expanded } = props;
  const { values } = useFormikContext();
  const { offerPrices, offerPricesLoading } = useGetOfferPrices(values);
  const [price, loading, fieldInput, setFieldInput] = useGetEffectivePrice(
    selectedVehicleClass?.categoryId
  );
  const mobileViewPort = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const offerPriceByCategory =
    offerPrices?.categoryOfferPrices?.length > 0
      ? offerPrices?.categoryOfferPrices[0]
      : null;

  return (
    <Stack
      direction={"column"}
      alignItems={"flex-end"}
      spacing={1}
      width={"100%"}
      justifyContent={"flex-end"}
    >
      <PriceField
        selectedVehicleClass={selectedVehicleClass}
        price={price}
        offerPriceByCategory={offerPriceByCategory}
        loading={loading}
        expanded={expanded}
        fieldInput={fieldInput}
        setFieldInput={setFieldInput}
      />
      <DeductibleField
        selectedVehicleClass={selectedVehicleClass}
        offerPriceByCategory={offerPriceByCategory}
        loading={loading}
        expanded={expanded}
      />
    </Stack>
  );
}

function Price(props) {
  const { price } = props;
  return (
    <Typography sx={{ fontFamily: "Sofia Pro", fontSize: "1.3rem" }}>
      {centsToLocalString(price)}€
    </Typography>
  );
}

function PriceField(props) {
  const {
    selectedVehicleClass,
    price,
    fieldInput,
    setFieldInput,
    offerPriceByCategory,
    loading,
    expanded,
  } = props;
  const setPrice = useSetAtom(vehiclePricesAtom);
  const { values } = useFormikContext();
  const handleChangePrice = () => {
    if (fieldInput === "" || fieldInput === null) {
      return setPrice({
        [selectedVehicleClass?.categoryId]: {
          price: offerPriceByCategory?.price,
          startTime: values.startTime,
          returnTime: values.returnTime,
          pricingCategoryId: values.pricingCategoryId,
          tax: offerPriceByCategory?.tax,
        },
      });
    }
    setFieldInput(centsToLocalString(parseToCents(fieldInput)));
    setPrice({
      [selectedVehicleClass?.categoryId]: {
        price: parseToCents(fieldInput),
        startTime: values.startTime,
        returnTime: values.returnTime,
        pricingCategoryId: values.pricingCategoryId,
        tax: offerPriceByCategory?.tax,
      },
    });
  };

  if (loading) {
    return <PriceLoading />;
  }

  if (!expanded) {
    return <Price price={price} />;
  }

  return (
    <Stack>
      <ListingPrice
        listingPrice={offerPriceByCategory?.price}
        loading={loading}
      />
      <TextField
        label="Hinta"
        placeholder={`${centsToLocalString(offerPriceByCategory?.price)}`}
        InputLabelProps={{ shrink: true }}
        value={fieldInput}
        onChange={(e) => setFieldInput(e.target.value)}
        onBlur={handleChangePrice}
        size="small"
        sx={{ width: "130px" }}
        InputProps={{
          endAdornment: (
            <Euro
              sx={{ color: "grey", marginBottom: "5px", fontSize: "1.2rem" }}
            />
          ),
        }}
      />
    </Stack>
  );
}

function ListingPrice(props) {
  const { listingPrice, loading } = props;

  return (
    <Typography variant="caption" sx={{ marginBottom: "5px" }}>
      Listahinta:{" "}
      {loading ? (
        <CircularProgress size={14} />
      ) : (
        `${centsToLocalString(listingPrice)} €`
      )}
    </Typography>
  );
}

function DeductibleField(props) {
  const {
    selectedVehicleClass,
    offerPriceByCategory,
    offerPricesLoading,
    expanded,
  } = props;
  const [, meta, helpers] = useField("wildCardVehicles");
  const { setValue } = helpers;
  const { value } = meta;
  const storedValue = value[0]?.price ?? "";
  const [fieldInput, setFieldInput] = useState(
    () => meta?.value[0]?.deductible ?? ""
  );

  const handleChangePrice = () => {
    if (fieldInput === "" || fieldInput === null) {
      return setValue(
        value.map((vehicle) => {
          if (vehicle.categoryId === selectedVehicleClass?.categoryId) {
            return {
              ...vehicle,
              deductible: null,
            };
          }

          return vehicle;
        })
      );
    }
    if (fieldInput === storedValue) {
      return;
    }
    return setValue(
      value.map((vehicle) => {
        if (vehicle.categoryId === selectedVehicleClass?.categoryId) {
          return {
            ...vehicle,
            deductible: fieldInput,
          };
        }
        return vehicle;
      })
    );
  };

  if (!expanded) {
    return (
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <Typography variant="caption">Omavastuu:</Typography>
        <Typography sx={{ fontFamily: "Sofia Pro", fontSize: "1rem" }}>
          {value[0]?.deductible ??
            centsToLocalString(offerPriceByCategory?.deductible)}
          €
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack>
      <ListingPrice
        listingPrice={offerPriceByCategory?.deductible}
        loading={offerPricesLoading}
      />
      <FormControl variant="outlined">
        <InputLabel
          shrink
          id="wild-card-deductible-price-field"
          htmlFor="deductible"
        >
          Omavastuu
        </InputLabel>
        <TextField
          label="Omavastuu"
          placeholder={`${centsToLocalString(
            offerPriceByCategory?.deductible
          )}`}
          InputLabelProps={{ shrink: true }}
          value={fieldInput}
          onChange={(e) => setFieldInput(e.target.value)}
          onBlur={handleChangePrice}
          readOnly
          size="small"
          sx={{ width: "130px" }}
          InputProps={{
            endAdornment: (
              <Euro
                sx={{ color: "grey", marginBottom: "5px", fontSize: "1.2rem" }}
              />
            ),
          }}
        />
      </FormControl>
    </Stack>
  );
}
