import { useMemo } from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

// App routes
import IndexPage from "~/routes/ReservationsOverview";
import EconomyDialog from "~/components/EconomyDialog/EconomyDialog";
import ErrorBoundary from "~/components/ErrorBoundary";
import MainLayout from "~/layouts/MainLayout";
import CompanyDialog from "~/components/CompanyDialog";
import ReservationTableExternalUsers from "~/components/ReservationTableExternalUsers";
import { EUserAdminType } from "~/enums/user-admin-type";
import InsuranceAssignments from "./InsuranceAssignments";
import PendingReservations from "./PendingReservations";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../atoms/reservationDataAtom";
import { fetchReservation } from "../services/fetchReservation";
const landerPageRoute = {
  [EUserAdminType.GENERIC]: <IndexPage />,
  [EUserAdminType.INSURANCE]: <InsuranceAssignments />,
  [EUserAdminType.CORPORATE]: (
    <ReservationTableExternalUsers
      visibility={true}
      queryMode={"existBetween"}
    />
  ),
};

export default function AppRoutes({ user, signOut }) {
  const USER_ADMIN_TYPE = useMemo(() => {
    // Admin roles that share many functionalities. Some differences exist too,
    // but this can be used for generic functions.
    if (
      ["admin", "BUSINESS_ADMIN", "ORGANIZATION_ADMIN"].includes(user?.role)
    ) {
      return EUserAdminType.GENERIC;
    }

    // Insurance company users
    if (
      user?.role === "EXTERNAL_BUSINESS_ADMIN" &&
      user?.externalBusiness?.type === "INSURANCE"
    ) {
      return EUserAdminType.INSURANCE;
    }

    // External business user type: NORMAL
    if (
      (user?.role === "EXTERNAL_BUSINESS_ADMIN" &&
        user?.externalBusiness.type === "STANDARD") ||
      (user?.role === "EXTERNAL_BUSINESS_ADMIN" &&
        user?.externalBusiness.type === "NORMAL")
    ) {
      return EUserAdminType.CORPORATE;
    }
  }, [user]);
  const [fetchReservationData] = useGetReservationData();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={
          <MainLayout
            signOut={signOut}
            user={user}
            USER_ADMIN_TYPE={USER_ADMIN_TYPE}
          />
        }
        errorElement={<ErrorBoundary />}
      >
        <Route path="*" element={landerPageRoute[USER_ADMIN_TYPE]} />
        <Route path="/" element={landerPageRoute[USER_ADMIN_TYPE]} />
        {USER_ADMIN_TYPE === EUserAdminType.GENERIC && (
          <>
            <Route path="/raportointi" element={<EconomyDialog />} />
          </>
        )}
        <Route
          path="/asetukset"
          element={
            <CompanyDialog
            /*    setEditReservationData={setEditReservationData} */
            />
          }
        />
        <Route path="/toimeksiannot" element={<PendingReservations />} />
        <Route
          path="/varaus/:externalReservationId?"
          element={landerPageRoute[USER_ADMIN_TYPE]}
          loader={fetchReservationData}
        />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

const useGetReservationData = () => {
  const setReservationData = useSetAtom(reservationDataAtom);

  const fetchReservationData = async ({ params }) => {
    try {
      const response = await fetchReservation(params?.externalReservationId);
      if (response) {
        setReservationData(response);
        return response;
      } else {
        return null;
      }
    } catch (error) {
      console.log("Error in useGetReservationData", error);
      return null;
    }
  };
  return [fetchReservationData];
};
