import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Typography,
  Stack,
  Box,
} from "@mui/material";

import {
  cognitoDynamoCreateUser,
  cognitoDynamoDeleteUser,
} from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

import useSWR from "swr";

import DialogComponent from "../DialogComponent";

import { AppContext } from "../../AppContext";
import UserTable from "./userTable";

import {
  userByOrganization,
  userByExternalBusiness,
  userByOrgBusinessId,
} from "./userQueries";

import { GetLogo } from "../getLogo";
import listAll from "../../utils/list-all";

const fetchUsers = async (selectedEntity) => {
  let users = [];

  try {
    if (selectedEntity) {
      const { type, value } = selectedEntity;
      switch (type) {
        case "ORGANIZATION":
          users = await listAll(
            graphqlOperation(userByOrganization, {
              organizationId: value,
              limit: 1000,
            }),
            "userByOrganization"
          );
          const filteredUsers = users.filter((user) => {
            return !(user.externalBusinessId || user.orgBusinessId);
          });
          return filteredUsers;
        case "BUSINESS":
          users = await listAll(
            graphqlOperation(userByOrgBusinessId, {
              orgBusinessId: value,
              limit: 1000,
            }),
            "userByOrgBusinessId"
          );
          return users;
        case "EXTERNAL_BUSINESS":
          users = await listAll(
            graphqlOperation(userByExternalBusiness, {
              externalBusinessId: value,
              limit: 1000,
            }),
            "userByExternalBusiness"
          );
          return users;
        default:
          return null;
      }
    }
  } catch (e) {
    console.error("Failed to fetch users:", e);
  }
};

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const UserManagementTable = ({ open, onClose }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUser, setNewUser] = useState({ username: "", email: "" });
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [organizationData, setOrganizationData] = useState(null);
  const [businessesData, setBusinessesData] = useState([]);
  const [externalBusinessesData, setExternalBusinessesData] = useState([]);
  const [loadingAddDialog, setLoadingAddDialog] = useState(false);
  const [loadingDeleteDialog, setLoadingDeleteDialog] = useState(false);
  const [inputError, setInputError] = useState(false);
  const { user, mobileViewPort } = useContext(AppContext);
  const { data, error, isLoading, mutate } = useSWR(
    selectedEntity ? [selectedEntity] : null,
    () => fetchUsers(selectedEntity),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );

  if (error) {
    console.error("SWR error:", error.message);
  }

  useEffect(() => {
    const role = user?.role;

    if (user?.organizationId) {
      switch (role) {
        case "ORGANIZATION_ADMIN":
          // Set all organizations, businesses, and external businesses with the same organizationId
          setOrganizationData({
            name: "Organisaation käyttäjät",
            id: user?.organizationId,
          });
          setBusinessesData(
            user.businessByOrg.items.sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
          );
          setExternalBusinessesData(
            user.externalBusinessByOrg.items.sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
          );
          break;
        case "BUSINESS_ADMIN":
          // Set the one business corresponding to this user
          setBusinessesData(
            user.businessByOrg.items
              .filter((b) => b.id === user.orgBusinessId)
              .sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
          );
          break;
        case "EXTERNAL_BUSINESS_ADMIN":
          // Set the one external business corresponding to this user
          setExternalBusinessesData(
            user.externalBusinessByOrg.items
              .filter((b) => b.id === user.externalBusinessId)
              .sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
          );
          break;
        default:
          break;
      }
    }
  }, [user]);

  const getSelectOptions = useCallback(() => {
    if (!user) return [];

    const role = user?.role;
    let options = [];

    switch (role) {
      case "ORGANIZATION_ADMIN":
        if (organizationData)
          options.push({
            label: (
              <Typography variant="body1">
                <b>{organizationData.name}</b>
              </Typography>
            ),
            value: organizationData.id,
            type: "ORGANIZATION",
          });

        if (businessesData.length)
          options.push({
            label: (
              <Typography variant="body1" sx={{ color: "black" }}>
                {"Organisaation yrittäjät"}
              </Typography>
            ),
          });
        options.push(
          ...businessesData.map((b) => ({
            label: b.name,
            value: b.id,
            type: "BUSINESS",
          }))
        );
        if (externalBusinessesData.length)
          options.push({
            label: (
              <Typography variant="body1" sx={{ color: "black" }}>
                {"Sopimusasiakkaat"}
              </Typography>
            ),
          });
        options.push(
          ...externalBusinessesData.map((eb) => ({
            label: eb.name,
            value: eb.id,
            type: "EXTERNAL_BUSINESS",
          }))
        );
        break;

      case "BUSINESS_ADMIN":
        if (businessesData.length)
          options.push(
            ...businessesData.map((b) => ({
              label: b.name,
              value: b.id,
              type: "BUSINESS",
            }))
          );
        if (externalBusinessesData.length)
          options.push(
            ...externalBusinessesData.map((eb) => ({
              label: eb.name,
              value: eb.id,
              type: "EXTERNAL_BUSINESS",
            }))
          );
        break;

      case "EXTERNAL_BUSINESS_ADMIN":
        if (externalBusinessesData.length)
          options.push(
            ...externalBusinessesData.map((eb) => ({
              label: eb.name,
              value: eb.id,
              type: "EXTERNAL_BUSINESS",
            }))
          );
        break;

      default:
        break;
    }

    return options;
  }, [businessesData, externalBusinessesData, organizationData, user]);

  useEffect(() => {
    if (!user || !getSelectOptions().length) return;
    let bizz = {};
    if (user?.role === "EXTERNAL_BUSINESS_ADMIN" && externalBusinessesData) {
      bizz = { ...externalBusinessesData[0], type: "EXTERNAL_BUSINESS" };
    } else if (user?.role === "BUSINESS_ADMIN" && businessesData) {
      bizz = { ...businessesData[0], type: "BUSINESS" };
    } else if (user?.role === "ORGANIZATION_ADMIN" && organizationData) {
      bizz = { ...organizationData, type: "ORGANIZATION" };
    }
    setSelectedEntity({
      label: bizz?.name,
      value: bizz?.id,
      type: bizz?.type,
    });
  }, [
    externalBusinessesData,
    businessesData,
    organizationData,
    user,
    getSelectOptions,
  ]);

  useEffect(() => {
    setPage(0);
    mutate();
  }, [selectedEntity, mutate]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDeleteDialog = (user) => {
    setSelectedUser(user);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleOpenAddDialog = () => {
    setNewUser({ username: "", email: "" });
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setInputError("");
    setOpenAddDialog(false);
  };

  const handleDeleteUser = async () => {
    if (selectedUser.id === user.id) return;
    try {
      setLoadingDeleteDialog(true);
      const response = await API.graphql(
        graphqlOperation(cognitoDynamoDeleteUser, {
          userId: selectedUser.id,
          adminId: user.id,
        })
      );
      console.log("User deleted:", response.data.cognitoDynamoDeleteUser);
      mutate();
      handleCloseDeleteDialog();
    } catch (error) {
      console.error("Error deleting user:", error);
      // Handle error in UI (e.g., show an error message)
    } finally {
      setLoadingDeleteDialog(false);
    }
  };

  const handleAddUser = async () => {
    let missingField = "";
    if (!newUser.username && !newUser.email) {
      missingField = "Käyttäjätunnus ja sähköposti";
    } else if (!newUser.username) {
      missingField = "Käyttäjätunnus";
    } else if (!newUser.email) {
      missingField = "Sähköposti";
    }

    if (missingField) {
      setInputError(`${missingField} puuttuu.`); // Set the inputError state to the missing field
      return;
    }

    if (!isValidEmail(newUser.email)) {
      setInputError("Tarkista käyttäjän sähköposti.");
      return;
    }

    try {
      setInputError(""); // Reset the inputError state
      setLoadingAddDialog(true);

      let key;
      if (selectedEntity.type === "ORGANIZATION") {
        key = "organizationId";
      } else if (selectedEntity.type === "BUSINESS") {
        key = "businessId";
      } else if (selectedEntity.type === "EXTERNAL_BUSINESS") {
        key = "externalBusinessId";
      }

      const input = {
        [key]: selectedEntity.value,
        role: selectedEntity.type + "_ADMIN",
        name: newUser.username,
        email: newUser.email,
      };
      const response = await API.graphql(
        graphqlOperation(cognitoDynamoCreateUser, input)
      );
      // If not succesful
      if (!response.data.cognitoDynamoCreateUser) {
        setInputError("Jokin meni vikaan.");
        throw new Error(`with input: ${input}`);
      }
      console.log("User created:", response.data.cognitoDynamoCreateUser);
      mutate();
      handleCloseAddDialog();
    } catch (error) {
      console.error("Error creating user:", error);
      // Handle error in UI (e.g., show an error message)
    } finally {
      setLoadingAddDialog(false);
    }
  };

  const customBgColor = () => {
    // Tokmanni color
    if (user?.organizationId === "703cdba4-7158-11ee-b962-0242ac120002") {
      return "#fe0100";
    }

    // Scandia Rent color
    if (user?.organizationId === "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1") {
      return "#e50523";
    }
    return "transparent";
  };

  return (
    <DialogComponent
      height="50dvh"
      maxWidth="md"
      dialogAltTitle={
        <Box
          sx={{
            display: "flex",
            minHeight: "75px",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: customBgColor(),
          }}
        >
          <GetLogo />
        </Box>
      }
      open={open}
      dialogClose={onClose}
      testName="userManagementDialog"
    >
      <Stack
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ marginY: mobileViewPort ? "25px" : undefined }}
        direction={mobileViewPort ? "column" : "row"}
      >
        {!mobileViewPort && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontSize: "20px" }}>
              <b>Käyttäjähallinta</b>
            </Typography>
            <Typography variant="body2">{selectedEntity?.label}</Typography>
          </Box>
        )}

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={2}
        >
          {user?.role === "ORGANIZATION_ADMIN" ? (
            <FormControl variant="outlined">
              <InputLabel>Valitse yritysmuoto</InputLabel>
              <Select
                sx={{ minWidth: 200 }}
                MenuProps={{ PaperProps: { sx: { maxHeight: "300px" } } }}
                label="Valitse yritysmuoto"
                key={selectedEntity?.value || "initial"}
                value={selectedEntity?.value || ""}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  const selectedOption = getSelectOptions().find(
                    (option) => option.value === selectedValue
                  );
                  setSelectedEntity(selectedOption);
                }}
                data-cy="userManagementUserTypeSelect"
              >
                {getSelectOptions().map((option, index) => (
                  <MenuItem
                    key={`option-key-${index}`}
                    value={option.value}
                    disabled={!option.value}
                    data-cy="userManagementUserTypeOption"
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}

          <Button
            onClick={handleOpenAddDialog}
            data-cy="addUserButton"
            sx={{ height: "57px", textTransform: "none" }}
            variant="contained"
          >
            Lisää käyttäjä
          </Button>
        </Stack>
      </Stack>
      <UserTable
        data={data}
        rowsPerPage={rowsPerPage}
        page={page}
        currentUser={user}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleOpenDeleteDialog={handleOpenDeleteDialog}
        user={user}
        loading={isLoading}
      />

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Poista käyttäjä"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Haluatko varmasti poistaa käyttäjän {selectedUser?.username}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loadingDeleteDialog ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: "50px" }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="right"
              alignItems="right"
              style={{ height: "50px" }}
            >
              <Button onClick={handleCloseDeleteDialog} color="primary">
                Peruuta
              </Button>
              <Button
                onClick={handleDeleteUser}
                color="primary"
                autoFocus
                data-cy="confirmDeleteUserButton"
              >
                Kyllä
              </Button>
            </Grid>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Lisää uusi käyttäjä</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                id="username"
                label="Käyttäjän nimi"
                type="text"
                fullWidth
                value={newUser.username}
                onChange={(event) =>
                  setNewUser({ ...newUser, username: event.target.value })
                }
                data-cy="userManagementUsernameField"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                id="email"
                label="Sähköposti"
                type="email"
                fullWidth
                value={newUser.email}
                onChange={(event) =>
                  setNewUser({ ...newUser, email: event.target.value })
                }
                data-cy="userManagementEmailField"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {loadingAddDialog ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: "50px" }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ height: "50px", marginLeft: "1em" }}
            >
              <Grid item xs={6}>
                {inputError && (
                  <DialogContentText style={{ color: "red" }}>
                    {`${inputError}`}
                  </DialogContentText>
                )}
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                <Button onClick={handleCloseAddDialog} color="primary">
                  Peruuta
                </Button>
                <Button
                  onClick={handleAddUser}
                  color="primary"
                  data-cy="confirmAddUserButton"
                >
                  Lisää
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </Dialog>
    </DialogComponent>
  );
};

export default UserManagementTable;
