import OldContractTemplate from "../../../print-contracts/OldContractTemplate";
import ContractTemplate from "../../../print-contracts/ContractTemplate";

import ContractTemplateEbikeRental from "../../../print-contracts/ContractTemplateEbikeRental";
import ContractTemplateJetRental from "../../../print-contracts/ContractTemplateJetRental";
import print from "../../../print-contracts/print-contract";
import { getZonedTime } from "../../../utils/common";
const EFeatureContracts = {
  FEATURE_EBIKERENTAL_CONTRACT: ["ebikerental", "elämysristeilyt"],
  FEATURE_JET_CONTRACT: [
    "jyrkila",
    "greattime",
    "jettirentti",
    "majakkawatersportslohja",
    "majakkawatersports",
  ],
  FEATURE_NEW_CONTRACT: [
    "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1", // Scandia Rent,
    "d90a0808-7f16-41af-a374-0244e5fe9df1", // Dev organization
  ],
};

export const getContract = (
  reservation,
  allVehicles,
  group,
  user,
  company,
  timezone,
  selectedLanguage
) => {
  //const userEntity = "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1";
  const userEntity = user?.organizationId ?? group;
  const vehicles = reservation?.reservationVehicles
    ? reservation.reservationVehicles.map((rv) => {
        const vehicle = allVehicles.find((v) => v.id === rv.id);
        // Idea is that primarily use data from reservationVehicle, but use "real" Vehicle as a backup (older reservations)
        return {
          ...vehicle,
          ...rv, // Priorize information saved onto reservationVehicles
        };
      })
    : null;

  const updatedReservation = {
    ...reservation,
    startTime: getZonedTime(reservation.startTime, timezone),
    returnTime: getZonedTime(reservation.returnTime, timezone),
    endTime: getZonedTime(reservation.endTime, timezone),
  };

  const typesOfContract = {
    FEATURE_EBIKERENTAL_CONTRACT: () => (
      <ContractTemplateEbikeRental
        reservation={updatedReservation}
        company={company}
        additionalServices={reservation?.additionalServices}
        vehicles={vehicles}
      />
    ),
    FEATURE_JET_CONTRACT: () => (
      <ContractTemplateJetRental
        reservation={updatedReservation}
        company={company}
        additionalServices={reservation?.additionalServices}
        vehicles={vehicles}
      />
    ),
    FEATURE_NEW_CONTRACT: () => (
      <ContractTemplate
        reservation={updatedReservation}
        company={company}
        additionalServices={reservation?.additionalServices}
        vehicles={vehicles}
        userEntity={userEntity}
        selectedLanguage={selectedLanguage}
      />
    ),
  };
  const isFeatureContract = Object.keys(EFeatureContracts).find((key) =>
    EFeatureContracts[key].includes(userEntity)
  );

  const featureContract = typesOfContract[isFeatureContract];

  if (featureContract) {
    return featureContract();
  }
  // return default contract template
  return (
    <OldContractTemplate
      reservation={updatedReservation}
      company={company}
      vehicles={vehicles}
      additionalServices={reservation?.additionalServices}
    />
  );
};

export function showPrintContract(
  reservationData,
  company,
  user,
  allVehicles,
  group
) {
  const selectedServiceOffers = reservationData?.additionalServices ?? [];

  try {
    print(
      getContract(
        reservationData,
        allVehicles,
        group,
        user,
        company,
        selectedServiceOffers
      )
    );
  } catch (e) {
    console.error(e);
  }
}
