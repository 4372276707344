import { ReservationDataProvider } from "./contextProviders/ReservationContext";
import { Dialog, DialogContent, useMediaQuery, useTheme } from "@mui/material";
import ContentByUserRole from "./views";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { reservationDataAtom } from "../../atoms/reservationDataAtom";
import { vehiclePricesAtom } from "../../stores/vehiclePrices";
import { useContext, useEffect } from "react";
import { AppContext } from "../../AppContext";
import { insuranceDataAtom } from "../../stores/insuranceData";
import CustomInsuranceForm from "./views/CustomInsuranceForm";
export default function ReservationDialog() {
  const theme = useTheme();
  const mobileViewPort = useMediaQuery(theme.breakpoints.down("sm"));
  const { USER_ADMIN_TYPE } = useContext(AppContext);
  const [reservationData] = useAtom(reservationDataAtom);
  const setVehiclePrices = useSetAtom(vehiclePricesAtom);
  const insuranceData = useAtomValue(insuranceDataAtom);
  useEffect(() => {
    if (reservationData) {
      const vehicles = initializeVehiclePrices(reservationData);
      setVehiclePrices(vehicles);
    }
  }, [reservationData]);

  const isDataValid = () => {
    if (!!insuranceData) {
      return EDataTypes.INSURANCE;
    }
    if (!!reservationData) {
      return EDataTypes.GENERIC;
    }
    return null;
  };

  const data = isDataValid();
  console.log("data", data);
  return (
    <ReservationDataProvider>
      <Dialog
        open={Boolean(data)}
        fullScreen={mobileViewPort}
        maxWidth={EMaxWidth[USER_ADMIN_TYPE]}
        fullWidth
      >
        <DialogContent
          sx={{
            height: { xs: undefined, sm: EHeight[USER_ADMIN_TYPE] },
            padding: { sm: "20px", xs: "10px" },
          }}
        >
          {EContentByData[data]}
        </DialogContent>
      </Dialog>
    </ReservationDataProvider>
  );
}

const initializeVehiclePrices = (reservationData) => {
  if (reservationData?.reservationVehicles?.length > 0) {
    const vehicles = reservationData?.reservationVehicles.reduce(
      (acc, vehicle) => {
        acc[vehicle.id] = {
          price: vehicle?.price,
          deductible: vehicle?.deductible,
          pricingCategoryId: reservationData?.pricingCategoryId
            ? reservationData?.pricingCategoryId
            : null,
          startTime: reservationData?.startTime,
          returnTime: reservationData?.returnTime,
          tax: vehicle?.tax,
        };

        return acc;
      },
      {}
    );
    return vehicles;
  }
  if (reservationData?.wildCardVehicles?.length > 0) {
    const vehicles = reservationData?.wildCardVehicles.reduce(
      (acc, vehicle) => {
        acc[vehicle.categoryId] = {
          price: vehicle?.price,
          pricingCategoryId: reservationData?.pricingCategoryId
            ? reservationData?.pricingCategoryId
            : null,
          startTime: reservationData?.startTime,
          returnTime: reservationData?.returnTime,
          tax: vehicle?.tax,
        };

        return acc;
      },
      {}
    );
    return vehicles;
  }
};

const EMaxWidth = {
  CORPORATE: "md",
  INSURANCE: "lg",
  GENERIC: "lg",
};

const EHeight = {
  INSURANCE: "90vh",
  GENERIC: "90vh",
};

const EDataTypes = {
  INSURANCE: "INSURANCE",
  GENERIC: "GENERIC",
};

const EContentByData = {
  INSURANCE: <CustomInsuranceForm />,
  GENERIC: <ContentByUserRole />,
};
