import { ADDITIONAL_FIELDS_ALL } from "../../../shared/additional-fields";
import { centsToLocalString } from "../../../shared/money";
import {
  EContractAgreement,
  EOrderVehicle,
} from "./LanguagePack/translatedFields";
import { Divider, Field, formatDateTime } from "./common";
import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { titleStyle } from "./styles";
import PostBilling from "./ContractPostBilling";

export default function ContractVehicle({
  reservation,
  additionalServices,
  vehicle,
  selectedLanguage,
  company,
}) {
  const fields = EOrderVehicle[selectedLanguage];

  const as = reservation.additionalServices;
  const deductible = getDeductible(as, vehicle);

  const enabledServices = reservation.additionalServices.map(
    (reservationService) => {
      const service = additionalServices.find(
        (as) => as.id === reservationService.key
      );
      return {
        ...reservationService,
        ...service,
      };
    }
  );

  const includedDistance =
    enabledServices.findIndex((as) => as.category === "distance") > -1
      ? enabledServices.find((as) => as.category === "distance").description
      : vehicle?.includedDistance ?? "";

  const exportLicense = enabledServices.find(
    (as) => as.key === "exportLicense" || as.type === "EXPORT_LICENSE"
  );
  const exportCountry =
    exportLicense?.details || fields.EXPORT_LICENSE_NO_DETAILS;

  const exportLabel = exportLicense
    ? fields.EXPORT_LICENSE_CLEARANCE + exportCountry
    : fields.EXPORT_LICENSE_NO_CLEARANCE;

  const powerType = ADDITIONAL_FIELDS_ALL.find(
    (field) => field.key === "powerType"
  )?.options.find((option) => option.value === vehicle?.powerType)?.label;

  const enableRegistrationPlate = !(
    reservation.enableRegistrationPlate === false
  );
  // if registration plate is hidden, add "or equivalent" to the vehicle name
  const nameField = enableRegistrationPlate
    ? vehicle?.name
    : `${vehicle?.name} ${fields.VEHICLE_OR_EQUIVALENT_LABEL}`;

  const relevantDamageRecords = vehicle?.damageRecords?.filter(
    (damageRecord) =>
      new Date(damageRecord.createdAt) < new Date(reservation?.returnTime)
  );

  const insuranceData = reservation?.insuranceData?.providerCompany
    ? `Vakuutusyhtiö: ${reservation?.insuranceData?.providerCompany}, Laskutuslupa: ${reservation?.insuranceData?.billingPermitCode}, asiakkaan rekisterinumero: ${reservation?.customerCarRegistrationPlate} `
    : "";
  return (
    <Grid item xs={8}>
      <Typography sx={titleStyle}>{fields.TITLE}</Typography>
      <Grid item xs={12}>
        <Stack
          direction={"row"}
          sx={{
            borderTop: "1px solid lightgrey",
            borderLeft: "1px solid lightgrey",
            borderRight: "1px solid lightgrey",
          }}
        >
          {enableRegistrationPlate && (
            <>
              <Field
                style={{ maxWidth: "100px" }}
                label={fields.REGISTRATION_NUMBER}
                value={vehicle?.registrationPlate}
              />
              <Divider />
            </>
          )}
          <Field label={fields.NAME} value={nameField} />
        </Stack>
        <Stack
          direction={"row"}
          sx={{
            borderLeft: "1px solid lightgrey",
            borderRight: "1px solid lightgrey",
          }}
        >
          <Field
            label={fields.MILEAGE_ON_DELIVERY}
            value={vehicle?.startMileage}
          />
          <Divider />
          <Field label={fields.MILEAGE_ON_RETURN} value={vehicle?.endMileage} />
          <Divider />
          <Field
            label={fields.MILEAGE_DRIVEN}
            value={calcMileage(vehicle?.startMileage, vehicle?.endMileage)}
          />

          <Divider />
          <Field label={fields.MILEAGE_INCLUDED} value={includedDistance} />
          <Divider />
          <Field
            label={fields.POWERTYPE}
            value={powerType}
            style={{ maxWidth: "1250px" }}
          />
          <Divider />
          <Field
            label={fields.FUEL_ON_DELIVERY}
            value={""}
            style={{ maxWidth: "100px" }}
          />
          <Divider />
          <Field
            label={fields.FUEL_ON_RETURN}
            value={""}
            style={{ maxWidth: "100px" }}
          />
        </Stack>

        <Stack
          direction={"row"}
          sx={{
            borderLeft: "1px solid lightgrey",
            borderRight: "1px solid lightgrey",
          }}
        >
          <Field
            label={`${fields.COLLISION_DAMAGE_WAIVER} ${fields.DEDUCTIBLE}`}
            value={deductible}
          />
          <Divider />
          <Field
            label={`${fields.THEFT_PROTECTION} ${fields.DEDUCTIBLE}`}
            value={deductible}
          />
          <Divider />

          {!vehicle?.hideExport && (
            <Field label={fields.EXPORT_LICENSE_LABEL} value={exportLabel} />
          )}
        </Stack>
        <Box
          sx={{
            borderLeft: "1px solid lightgrey",
            borderRight: "1px solid lightgrey",
          }}
        >
          <Field
            label={fields.EXTRAINFO}
            value={`${vehicle?.extraInfo ?? ""} ${insuranceData}`}
          />
        </Box>
        <Typography sx={titleStyle}>{fields.VEHICLE_DAMAGE_RECORDS}</Typography>
        <Stack
          sx={{ border: "1px solid lightgrey", minHeight: "25px" }}
          direction={"row"}
        >
          {relevantDamageRecords?.map((record, index) => (
            <Stack key={index} direction={"row"}>
              <Typography sx={{ fontWeight: 550, fontSize: "8px" }}>
                {record.label}:
              </Typography>
              <Typography sx={{ fontSize: "8px" }}>
                {record.description || ""},
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Grid>
      <PostBilling selectedLanguage={selectedLanguage} />
    </Grid>
  );
}

const getDeductible = (as, vehicle) => {
  // New way of setting deducible service, value found on the service itself
  const deductibleFromService = as.find((s) => s.deductibleValue);
  if (deductibleFromService)
    return centsToLocalString(deductibleFromService.deductibleValue);

  // Old way of setting deductible services
  const noDeductible = as.find((s) => s.key === "noDeductible") ? true : false;
  const halvedDeductible = as.find((s) => s.key === "halvedDeductible")
    ? true
    : false;
  let deductible = vehicle?.deductible;
  if (noDeductible || deductible === null || deductible === undefined) {
    deductible = "";
  } else if (halvedDeductible) {
    deductible = centsToLocalString(vehicle?.deductible / 2, "fi-FI");
  } else {
    deductible = centsToLocalString(vehicle?.deductible, "fi-FI");
  }
  return deductible;
};

function calcMileage(start, end) {
  if (start && end) {
    return end - start;
  }
  return null;
}
