import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../atoms/reservationDataAtom";
import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { LocalOffer } from "@mui/icons-material";

export default function AppliedVoucher() {
  const reservationData = useAtomValue(reservationDataAtom);
  if (!reservationData?.voucherData?.amount) return null;

  return (
    <Stack spacing={1}>
      <Box>
        <Typography>Varauksella käytetty alekoodi</Typography>
        <ListItem>
          <ListItemAvatar>
            <LocalOffer color="primary" sx={{ fontSize: "30px" }} />
          </ListItemAvatar>
          <ListItemText
            primary={`Alekoodi: ${reservationData?.voucherData?.name}`}
            secondary={`Alennus: ${reservationData?.voucherData?.amount} %`}
          />
        </ListItem>
      </Box>
    </Stack>
  );
}
