import {
  Avatar,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import FINflag from "~/assets/flags/fin.png";
import SWEflag from "~//assets/flags/swe.png";
import ENGflag from "~/assets/flags/eng.png";
import { useContext, useState } from "react";
import { ReservationContext } from "../../../../../../contextProviders/ReservationContext";

export default function SelectContractLanguage() {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const { selectedLanguage, setSelectedLanguage } =
    useContext(ReservationContext);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Valitse sopimuksen kieli">
        <IconButton onClick={handleClick}>
          <Avatar
            src={Eflags[selectedLanguage]}
            sx={{ boxShadow: theme.shadows[3], border: "1px solid lightgrey" }}
          />
        </IconButton>
      </Tooltip>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        {Object.keys(Elanguages).map((language) => (
          <MenuItem
            key={language}
            onClick={() => handleSelectLanguage(language)}
          >
            <ListItemAvatar>
              <Avatar
                src={Eflags[language]}
                sx={{
                  boxShadow: theme.shadows[3],
                  border: "1px solid lightgrey",
                }}
              />
            </ListItemAvatar>
            <ListItemText>
              <Typography>{Elanguages[language]}</Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

const Eflags = {
  FIN: FINflag,
  SWE: SWEflag,
  ENG: ENGflag,
};

const Elanguages = {
  FIN: "Suomi",
  SWE: "Ruotsi",
  ENG: "Englanti",
  GER: "Saksa",
};

/* 
  {languages.map((language) => (
            <MenuItem key={language.name} onClick={handleClose}>
              <Typography>{language.name}</Typography>
            </MenuItem>
          ))}

*/
