import { Box } from "@mui/material";

import { ReservationInfo } from "./ReservationInfo";
import { ReservationVehicleCard } from "./VehicleCard";

import { useFetchVehicles } from "../../../services/fetchVehicles";
import { useSearchReservations } from "../hooks/useSearchReservations";

export function ReservationsByCustomer(props) {
  const { user, companies, selectedCustomer, loading, handleOpenReservation } =
    props;
  const { vehicleData } = useFetchVehicles();
  const { reservations } = useSearchReservations(selectedCustomer);
  // Get company object for reservation
  const getCompanyObj = (reservation) => {
    const legacyCompany = !!reservation?.group;
    const organizationCompany = !!reservation?.organizationId;
    let companyId = reservation?.companyId;

    // companyId does not exist for older reservations, use vehicle information

    if (companyId && organizationCompany) {
      const id = companyId;
      for (let business of user?.businessByOrg?.items) {
        const company = business?.companies.items.find((c) => c.id === id);
        if (company) {
          return company;
        }
      }
    }

    if (!companyId && legacyCompany) {
      // companyId does not exist for older reservations, use vehicle information
      let vehicleId = null;
      vehicleId = reservation?.reservationVehicles?.[0].id;
      if (!vehicleId) {
        vehicleId = reservation?.reservationVehicleId;
      }
      companyId = vehicleData.find((v) => v.id === vehicleId)?.companyId;
      return companies.find((c) => c.id === companyId);
    }

    if (companyId && legacyCompany) {
      // companyId does not exist for older reservations, use vehicle information
      return companies.find((c) => c.id === companyId);
    }

    return null;
  };

  if (selectedCustomer && reservations?.length > 0 && !loading)
    return reservations.map((reservation, index) => {
      return (
        <Box
          key={reservation?.id}
          sx={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid rgba(0,0,0,0.10)",
            borderRadius: "14px",
            padding: "10px",
            margin: "5px",
            cursor: "pointer",
          }}
          onClick={() => (loading ? null : handleOpenReservation(reservation))}
        >
          <ReservationInfo
            reservation={reservation}
            getCompanyObj={getCompanyObj}
            vehicleData={vehicleData}
            companies={companies}
          />

          {reservation?.reservationVehicles && (
            <ReservationVehicleCard
              r={reservation.reservationVehicles}
              idx={index}
            />
          )}
        </Box>
      );
    });
}
