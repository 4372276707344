import { Box } from "@mui/material";
import urentLogo from "../customerLogos/uRent_logo.jpg";
import { useContext, useMemo } from "react";
import { AppContext } from "../AppContext";
import { clientLogoData } from "../customerLogos/ClientLogoData";

export function GetLogo(props) {
  const { customHeight } = props;
  const {
    company,
    user,
    USER_ADMIN_CORPORATE,
    USER_ADMIN_GENERIC,
    USER_ADMIN_INSURANCE,
  } = useContext(AppContext);

  const clientLogo = useMemo(() => {
    if (USER_ADMIN_GENERIC) {
      const logo = clientLogoData.find(
        (item) => item.id === user?.organizationId
      )?.logo;

      return logo ?? urentLogo;
    }

    if (USER_ADMIN_CORPORATE || USER_ADMIN_INSURANCE) {
      const logo = clientLogoData.find(
        (item) => item.id === user?.externalBusinessId
      )?.logo;

      return logo ?? urentLogo;
    }

    // handle legacy users
    if (company?.group) {
      const logo = clientLogoData.find(
        (item) => item.id === company.group
      )?.logo;
      return logo ?? urentLogo;
    }
  }, [
    user,
    company,
    USER_ADMIN_CORPORATE,
    USER_ADMIN_GENERIC,
    USER_ADMIN_INSURANCE,
  ]);

  return (
    <Box sx={{ width: "250px", marginTop: "5px" }}>
      <img
        alt={"logo"}
        src={clientLogo}
        style={{
          maxWidth: "100%",
          objectFit: "scale-down",
          height: customHeight ?? undefined,
        }}
      />
    </Box>
  );
}
