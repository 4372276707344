import {
  Avatar,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useField } from "formik";
import { useCategoryImage } from "~/hooks/useCategoryImage";
import { centsToLocalString } from "../../../../../shared/money";

export default function OfferMenuItem(props) {
  const { setAnchorEl, offer, clientType } = props;
  const { image } = useCategoryImage(offer?.category);
  const [, , helpers] = useField("wildCardVehicles");
  const { setValue } = helpers;
  const mobileView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <MenuItem
      onClick={() => {
        setValue([
          {
            categoryId: offer?.id ?? offer?.categoryId,
            name: offer?.name,
            price: offer?.price,
            category: offer?.category,
            exampleModel: offer?.exampleModel,
            hanselClass: offer?.class,
          },
        ]);
        setAnchorEl(null);
      }}
    >
      {!mobileView && (
        <ListItemAvatar>
          <Avatar variant="rounded" src={image}></Avatar>
        </ListItemAvatar>
      )}
      <ShowHanselClass clientType={clientType} hanselClass={offer.class} />
      <ListItemText primary={offer?.name} secondary={offer?.exampleModel} />
      <ListItemSecondaryAction>
        <Typography>{centsToLocalString(offer?.price)} €</Typography>
      </ListItemSecondaryAction>
    </MenuItem>
  );
}

const ShowHanselClass = ({ clientType, hanselClass }) => {
  if (clientType === "HANSEL")
    return (
      <Typography sx={{ marginRight: "10px" }}>
        <b>{hanselClass}</b>
      </Typography>
    );
};
