import { createRoot } from "react-dom/client";
import "./index.css";
// @ts-ignore
import Auth from "./Auth";
// @ts-ignore
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV || "unknown",
  autoSessionTracking: true,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.5,
  beforeSend: (event, hint) => {
    console.error(hint.originalException || hint.syntheticException);
    if (process.env.REACT_APP_ENV === "local") {
      return null;
    }
    return event;
  },
});
Sentry.setTag("app", "dealer");
/*
These are moved to Auth.js
Amplify.configure(awsExports);
I18n.putVocabularies(dict);
I18n.setLanguage("fi");
 */

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(<Auth />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
