import {
  ECompanyFields,
  EContractAgreementFields,
  EContractCommon,
  EContractSummary,
  ECustomerFields,
  EPaymentFields,
  EVehicleFields,
} from "../../../enums/contract-fields";
export const contractFieldCustomerGER = {
  TITLE: "Mieterinformationen",
  [ECustomerFields.ADDRESS]: "Adresse des Mieters",
  [ECustomerFields.DRIVERS_LICENSE_CLASS]: "Führerscheinklasse",
  [ECustomerFields.DRIVERS_LICENSE_ID]: "Führerscheinnummer",
  [ECustomerFields.EMAIL]: "E-Mail des Mieters",
  [ECustomerFields.NAME]: "Name des Mieters",
  [ECustomerFields.PHONE]: "Telefon des Mieters",
  [ECustomerFields.SSN]: "Sozialversicherungsnummer des Mieters",
};

export const contractFieldDriverGER = {
  TITLE: "Fahrerinformationen",
  [ECustomerFields.ADDRESS]: "Adresse des Fahrers",
  [ECustomerFields.DRIVERS_LICENSE_CLASS]: "Führerscheinklasse",
  [ECustomerFields.DRIVERS_LICENSE_ID]: "Führerscheinnummer",
  [ECustomerFields.EMAIL]: "E-Mail des Fahrers",
  [ECustomerFields.NAME]: "Name des Fahrers",
  [ECustomerFields.PHONE]: "Telefon des Fahrers",
  [ECustomerFields.SSN]: "Sozialversicherungsnummer des Fahrers",
};

export const contractFieldAdditionalDriverGER = {
  TITLE: "Zusätzliche Fahrerinformationen",
  [ECustomerFields.ADDRESS]: "Adresse des Fahrers",
  [ECustomerFields.DRIVERS_LICENSE_CLASS]: "Führerscheinklasse",
  [ECustomerFields.DRIVERS_LICENSE_ID]: "Führerscheinnummer",
  [ECustomerFields.EMAIL]: "E-Mail des Fahrers",
  [ECustomerFields.NAME]: "Name des zusätzlichen Fahrers",
  [ECustomerFields.PHONE]: "Telefon des Fahrers",
  [ECustomerFields.SSN]: "Sozialversicherungsnummer des Fahrers",
};

export const contractFieldCompanyGER = {
  TITLE: "Unternehmensinformationen",
  [ECompanyFields.BILLING_ADDRESS]: "Rechnungsadresse",
  [ECompanyFields.BUSINESS_ID]: "Unternehmens-ID",
  [ECompanyFields.CITY]: "Stadt",
  [ECompanyFields.NAME]: "Name des Unternehmens",
  [ECompanyFields.POSTAL_CODE]: "Postleitzahl",
};

export const contractFieldCommonGER = {
  [EContractCommon.CONTRACT_SUBTITLE]: "Mietvertrag",
  [EContractCommon.CONTRACT_TITLE]: "MIETVERTRAG",
  [EContractCommon.RESERVATION_NUMBER]: "Reservierungsnummer",
  [EContractCommon.RETURN_DATE]: "Vereinbartes Rückgabedatum",
  [EContractCommon.RETURN_LOCATION]: "Rückgabeort",
  [EContractCommon.START_DATE]: "Vereinbartes Startdatum",
  [EContractCommon.START_LOCATION]: "Abholort",
};

export const contractVehicleGER = {
  TITLE: "Fahrzeuginformationen",
  [EVehicleFields.ADDITIONAL_INFORMATION]: "Zusätzliche Informationen",
  [EVehicleFields.DEDUCTIBLE]: "Selbstbeteiligung",
  [EVehicleFields.DEDUCTIBLE_SIGNATORY]: "Unterschrift Selbstbeteiligung",
  [EVehicleFields.EXTRAINFO]: "Zusätzliche Informationen",
  [EVehicleFields.FUEL_ON_DELIVERY]: "Kraftstoff bei Abholung",
  [EVehicleFields.FUEL_ON_RETURN]: "Kraftstoff bei Rückgabe",
  [EVehicleFields.FILL_UP_FUEL_LABEL]:
    "Fahrzeug muss vollgetankt zurückgegeben werden.",
  [EVehicleFields.INCLUDED_MILEAGE]: "Inkludierte Kilometer",
  [EVehicleFields.MILEAGE_DRIVEN]: "Gefahrene Kilometer",
  [EVehicleFields.MILEAGE_ON_DELIVERY]: "Kilometerstand bei Abholung",
  [EVehicleFields.MILEAGE_ON_RETURN]: "Kilometerstand bei Rückgabe",
  [EVehicleFields.MILEAGE_INCLUDED]: "Inkludierte Kilometer",
  [EVehicleFields.NAME]: "Marke und Modell des Fahrzeugs",
  [EVehicleFields.POWERTYPE]: "Kraftstoffart",
  [EVehicleFields.REGISTRATION_NUMBER]: "Kennzeichen",
  [EVehicleFields.EXPORT_LICENSE_CLEARANCE]:
    "Fahrzeug hat eine gültige Ausfuhrgenehmigung für das Land: ",
  [EVehicleFields.EXPORT_LICENSE_NO_CLEARANCE]: "Keine Ausfuhrgenehmigung",
  [EVehicleFields.EXPORT_LICENSE_NO_DETAILS]: "Nicht angegeben",
  [EVehicleFields.COLLISION_DAMAGE_WAIVER]: "KOLLISIONSSCHUTZ",
  [EVehicleFields.THEFT_PROTECTION]: "DIEBSTAHLSCHUTZ",
  [EVehicleFields.VEHICLE_CONDITION]: "Fahrzeugzustand vor Vermietung",
  [EPaymentFields.DIGITAL_SIGNATURE]: "Digitale Unterschrift",
  [EVehicleFields.VEHICLE_DAMAGE_RECORDS]: "Schadensaufzeichnungen:",
  [EVehicleFields.EXPORT_LICENSE_LABEL]: "Ausfuhrgenehmigung",
  [EVehicleFields.VEHICLE_DAMAGE_CREATED]: "Erstellt am:",
  [EVehicleFields.VEHICLE_OR_EQUIVALENT_LABEL]: "- oder gleichwertig",
};

export const paymentStatusGER = {
  [EPaymentFields.PAYMENT_STATUS]: "Zahlungsstatus",
  [EPaymentFields.PAYMENT_METHOD]: "Zahlungsmethode",
  [EPaymentFields.PAYMENT_STATUS_COMPLETED]: "Zahlung abgeschlossen",
  [EPaymentFields.DIGITAL_SIGNATURE]: "Digitale Unterschrift",
};

export const contractAgreementGER = {
  [EContractAgreementFields.AGREEMENT]: "Mietbedingungen",
  [EContractAgreementFields.AGREEMENT_CUSTOMER]: "Mieter",
  [EContractAgreementFields.AGREEMENT_RENTAL_COMPANY]:
    "Im Namen des Vermieters.",
  [EContractAgreementFields.AGREEMENT_TERMS]:
    "Wir akzeptieren die Mietbedingungen und verpflichten uns, diese einzuhalten.",
  [EContractAgreementFields.AGREEMENT_STRONG_AUTHENTICATION]:
    "Der Mieter hat den Mietvertrag, die Selbstbeteiligung (Kollisionsschutz und Diebstahlschutz) und die Mietbedingungen elektronisch mit starker Authentifizierung unterschrieben und verpflichtet sich, die Mietbedingungen einzuhalten.",
  [EContractAgreementFields.DIGITAL_SIGNATURE]: "Digitale Unterschrift",
  [EContractAgreementFields.POST_BILLING]: "Nachträgliche Abrechnung",
  [EContractAgreementFields.POST_BILLING_SIGNATORY]:
    "Unterschrift Nachträgliche Abrechnung",
  [EContractAgreementFields.POST_BILLING_TERMS]:
    "(Kraftstoff, Zusatzkosten, Fahrzeugzustellung oder -abholung, Parkverstöße, Verkehrsstrafen, Selbstbeteiligung bei Schäden)",
  [EContractAgreementFields.POST_BILLING_AUTHORIZED]:
    "Ich ermächtige den Vermieter, eventuelle Nachträgliche Abrechnungen von der oben genannten Kreditkarte vorzunehmen.",
  [EContractAgreementFields.CREDIT_CARD_NUMBER]: "Kreditkartennummer",
  [EContractAgreementFields.CREDIT_CARD_EXPIRATION]: "Gültigkeitsdauer",
  [EContractAgreementFields.POST_BILLING_TITLE]: "Nachträgliche Abrechnung",
  [EContractAgreementFields.AGREEMENT_TITLE]: "Mietbedingungen",
};

export const contractSummaryGER = {
  [EContractSummary.TOTAL]: "Gesamt",
  [EContractSummary.VAT_EXCLUDED]: "Netto",
  [EContractSummary.PRODUCT]: "Produkt",
  [EContractSummary.PRICE]: "Preis",
  [EContractSummary.QUANTITY]: "Menge",
  [EContractSummary.VAT]: "MwSt",
  [EContractSummary.VAT_INCLUDED]: "(inkl. MwSt",
  [EContractSummary.TAX]: "Steuer",
};
