import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useField, useFormikContext } from "formik";

export default function IsBrokerCheckBox() {
  const [field, meta, helpers] = useField("isBroker");
  const { setFieldValue } = useFormikContext();
  const cypressTag = `data-cy-reservation-form-select-isBroker`;
  const handleChange = (e, checked) => {
    if (checked) {
      setFieldValue("isBroker", true);
    } else {
      setFieldValue("customerBrokerId", null);
      helpers.setValue(false);
    }
  };
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={field.name}
          checked={!!meta.value}
          color="primary"
          onChange={handleChange}
          onBlur={field.handleBlur}
          data-cy={cypressTag}
        />
      }
      label={<>{"Agenttivaraus"}</>}
    />
  );
}
