import { Box, Stack, Typography } from "@mui/material";
import { Divider, Field } from "./common";
import { titleStyle } from "./styles";
import { EContractAgreement } from "./LanguagePack/translatedFields";

export default function PostBilling(props) {
  const { selectedLanguage } = props;

  return (
    <>
      <Typography sx={titleStyle}>
        {EContractAgreement[selectedLanguage].POST_BILLING}
      </Typography>

      <Box sx={{ border: "1px solid lightgrey", padding: "2px" }}>
        <Stack direction={"row"} width={"100%"}>
          <Field
            label={EContractAgreement[selectedLanguage].CREDIT_CARD_NUMBER}
            value={""}
            style={{ maxWidth: "150px" }}
          />
          <Divider />
          <Field
            label={EContractAgreement[selectedLanguage].CREDIT_CARD_EXPIRATION}
            value={""}
            style={{ maxWidth: "100px" }}
          />
          <Divider />
          <Field label="" value="" />
        </Stack>

        <Stack direction={"row"}>
          <Field
            label={`${EContractAgreement[selectedLanguage].POST_BILLING} ${EContractAgreement[selectedLanguage].POST_BILLING_TERMS}`}
            value={EContractAgreement[selectedLanguage].POST_BILLING_AUTHORIZED}
            removeBorders
          />
        </Stack>
      </Box>
    </>
  );
}
