import { useContext } from "react";
import { AppContext } from "../../../../../AppContext";
import { useFetchPricingsOrganization } from "../../../../../services/fetchPricings";
import { EPricingBy } from "../../../utils/tabOptions";
import { TableCell, TableRow } from "@mui/material";
import SelectActivePricing from "./SelectActivePricing";
import AdjustCategoryDynamicPrice from "./AdjustCategoryDynamicPrice";
import HourlyPricingRate from "./HourlyPricingRate";
import DayPricingRate from "./DayPricingRate";
import VATRate from "./VATRate";
import EditPricing from "./EditPricing";

export default function CategoryRow(props) {
  const { category, setEditablePricing, selectedCompany } = props;
  const { business } = useContext(AppContext);
  const { organizationPricings, businessPricings } =
    useFetchPricingsOrganization("pricingListDialog");

  const pricingByBusiness = businessPricings?.find(
    (pricing) =>
      pricing?.businessCategoryAssociation === category?.id &&
      pricing?.orgBusinessId === business?.id
  );
  const pricingByOrganization = organizationPricings?.find(
    (pricing) => pricing?.id === category?.pricing?.id
  );

  const pricing = pricingByBusiness || pricingByOrganization;
  const activePricing = pricing
    ? pricing?.businessCategoryAssociation === category?.id
      ? EPricingBy.BUSINESS
      : EPricingBy.ORGANIZATION
    : "NONE";

  return (
    <TableRow>
      <TableCell sx={{ width: "250px" }}>{category?.name}</TableCell>

      <SelectActivePricing
        activePricing={activePricing}
        pricing={pricing}
        category={category}
        setEditablePricing={setEditablePricing}
      />

      <AdjustCategoryDynamicPrice
        visibility={pricing && !pricing?.businessCategoryAssociation}
        categoryId={category?.id}
        pricingId={pricing?.id}
        pricing={pricing}
        selectedCompany={selectedCompany}
        key={`${category?.id}-${selectedCompany?.id}`}
      />
      <HourlyPricingRate
        pricing={pricing}
        activePricing={activePricing}
        selectedCompany={selectedCompany}
      />
      <DayPricingRate
        pricing={pricing}
        activePricing={activePricing}
        selectedCompany={selectedCompany}
      />
      <VATRate pricing={pricing} />
      <EditPricing
        activePricing={activePricing}
        setEditablePricing={setEditablePricing}
        category={category}
        pricing={pricing}
      />
    </TableRow>
  );
}
