import {
  ECompanyFields,
  EContractAgreementFields,
  EContractCommon,
  EContractSummary,
  ECustomerFields,
  EPaymentFields,
  EVehicleFields,
} from "../../../enums/contract-fields";

export const contractFieldCustomerFIN = {
  TITLE: "Vuokraajan tiedot",
  [ECustomerFields.ADDRESS]: "Asiakkaan osoite",
  [ECustomerFields.DRIVERS_LICENSE_CLASS]: "Ajokorttiluokka",
  [ECustomerFields.DRIVERS_LICENSE_ID]: "Ajokortin numero",
  [ECustomerFields.EMAIL]: "Asiakkaan sähköposti",
  [ECustomerFields.NAME]: "Asiakkaan nimi",
  [ECustomerFields.PHONE]: "Asiakkaan puhelin",
  [ECustomerFields.SSN]: "Asiakkaan hlötunnus",
};

export const contractFieldDriverFIN = {
  TITLE: "Kuljettajan tiedot",
  [ECustomerFields.ADDRESS]: "Kuljettajan osoite",
  [ECustomerFields.DRIVERS_LICENSE_CLASS]: "Ajokorttiluokka",
  [ECustomerFields.DRIVERS_LICENSE_ID]: "Ajokortin numero",
  [ECustomerFields.EMAIL]: "Kuljettajan sähköposti",
  [ECustomerFields.NAME]: "Kuljettajan nimi",
  [ECustomerFields.PHONE]: "Kuljettajan puhelin",
  [ECustomerFields.SSN]: "Kuljettajan hlötunnus",
};

export const contractFieldAdditionalDriverFIN = {
  TITLE: "Lisäkuljettajan tiedot",
  [ECustomerFields.ADDRESS]: "Kuljettajan osoite",
  [ECustomerFields.DRIVERS_LICENSE_CLASS]: "Ajokorttiluokka",
  [ECustomerFields.DRIVERS_LICENSE_ID]: "Ajokortin numero",
  [ECustomerFields.EMAIL]: "Kuljettajan sähköposti",
  [ECustomerFields.NAME]: "Lisäkuljettajan nimi",
  [ECustomerFields.PHONE]: "Kuljettajan puhelin",
  [ECustomerFields.SSN]: "Kuljettajan hlötunnus",
};

export const contractFieldCompanyFIN = {
  TITLE: "Yrityksen tiedot",
  [ECompanyFields.BILLING_ADDRESS]: "Laskutusosoite",
  [ECompanyFields.BUSINESS_ID]: "Y-tunnus",
  [ECompanyFields.CITY]: "Kaupunki",
  [ECompanyFields.NAME]: "Yrityksen nimi",
  [ECompanyFields.POSTAL_CODE]: "Postinumero",
};

export const contractFieldCommonFIN = {
  [EContractCommon.CONTRACT_SUBTITLE]: "Vuokrasopimus",
  [EContractCommon.CONTRACT_TITLE]: "VUOKRASOPIMUS",
  [EContractCommon.RESERVATION_NUMBER]: "Varausnumero",
  [EContractCommon.RETURN_DATE]: "Sovittu palautusajankohta",
  [EContractCommon.RETURN_LOCATION]: "Palautuspaikka",
  [EContractCommon.START_DATE]: "Sovittu alkamisajankohta",
  [EContractCommon.START_LOCATION]: "Noutopaikka",
};

export const contractVehicleFIN = {
  TITLE: "Ajoneuvon tiedot",
  [EVehicleFields.ADDITIONAL_INFORMATION]: "Lisätiedot",
  [EVehicleFields.DEDUCTIBLE]: "Omavastuu",
  [EVehicleFields.DEDUCTIBLE_SIGNATORY]: "Omavastuun allekirjoittaja",
  [EVehicleFields.EXTRAINFO]: "Lisätiedot",
  [EVehicleFields.FUEL_ON_DELIVERY]: "Polttoaine noudettaessa",
  [EVehicleFields.FUEL_ON_RETURN]: "Polttoaine palautettaessa",
  [EVehicleFields.FILL_UP_FUEL_LABEL]: "Ajoneuvo palautettava tankattuna.",
  [EVehicleFields.INCLUDED_MILEAGE]: "Sisältyvä kilometrimäärä",
  [EVehicleFields.MILEAGE_DRIVEN]: "Ajetut kilometrit",
  [EVehicleFields.MILEAGE_ON_DELIVERY]: "Km noudettaessa",
  [EVehicleFields.MILEAGE_ON_RETURN]: "Km palautettaessa",
  [EVehicleFields.MILEAGE_INCLUDED]: "Sisältyvä kilometrimäärä",
  [EVehicleFields.NAME]: "Ajoneuvon merkki ja malli",
  [EVehicleFields.POWERTYPE]: "Käyttövoima",
  [EVehicleFields.REGISTRATION_NUMBER]: "Rekisterinumero",
  [EVehicleFields.EXPORT_LICENSE_CLEARANCE]:
    "Autolla voimassa maastavientilupa maahan: ",
  [EVehicleFields.EXPORT_LICENSE_NO_CLEARANCE]: "Ei maastavientilupaa",
  [EVehicleFields.EXPORT_LICENSE_NO_DETAILS]: "Ei ilmoitettu",
  [EVehicleFields.COLLISION_DAMAGE_WAIVER]: "KOLARITURVA",
  [EVehicleFields.THEFT_PROTECTION]: "VARKAUSTURVA",
  [EVehicleFields.VEHICLE_CONDITION]: "Ajoneuvon kunto ennen vuokralleantoa",
  [EPaymentFields.DIGITAL_SIGNATURE]: "Sähköinen allekirjoitus",
  [EVehicleFields.VEHICLE_DAMAGE_RECORDS]:
    "Ajoneuvon kuntoon liittyvät lisätiedot",
  [EVehicleFields.EXPORT_LICENSE_LABEL]: "Maastapoistumislupa",
  [EVehicleFields.VEHICLE_DAMAGE_CREATED]: "Luotu:",
  [EVehicleFields.VEHICLE_OR_EQUIVALENT_LABEL]: "- tai vastaava",
};

export const paymentStatusFIN = {
  [EPaymentFields.PAYMENT_STATUS]: "Maksun tila",
  [EPaymentFields.PAYMENT_METHOD]: "Maksutapa",
  [EPaymentFields.PAYMENT_STATUS_COMPLETED]: "Maksu suoritettu",
  [EPaymentFields.DIGITAL_SIGNATURE]: "Sähköinen allekirjoitus",
};

export const contractAgreementFIN = {
  [EContractAgreementFields.AGREEMENT]: "Vuokrausehdot",
  [EContractAgreementFields.AGREEMENT_CUSTOMER]: "Vuokralleottaja",
  [EContractAgreementFields.AGREEMENT_RENTAL_COMPANY]:
    "Vuokraamon virkailijan puolesta.",
  [EContractAgreementFields.AGREEMENT_TERMS]:
    "Hyväksymme vuokrausehdot ja sitoudumme niitä noudattamaan.",
  [EContractAgreementFields.AGREEMENT_STRONG_AUTHENTICATION]:
    "Vuokralleottaja on vahvan tunnistautumisen tekemällä sähköisesti allekirjoittanut vuokrasopimuksen, omavastuun kuittauksen (kolari- ja varkausturvan) ja sitoutunut noudattamaan vuokrausehtoja.",
  [EContractAgreementFields.DIGITAL_SIGNATURE]: "Sähköinen allekirjoitus",
  [EContractAgreementFields.POST_BILLING]: "Jälkiveloitus",
  [EContractAgreementFields.POST_BILLING_SIGNATORY]:
    "Jälkilaskutuksen allekirjoittaja",
  [EContractAgreementFields.POST_BILLING_TERMS]:
    "(polttoaine, lisävuokrakulut, auton toimitus tai nouto, pysäköintivirhemaksut, liikennesakot, vaurioiden omavastuuosuus)",
  [EContractAgreementFields.POST_BILLING_AUTHORIZED]:
    "Valtuutan vuokraamon tekemään mahdolliset jälkiveloitukset edellä mainitulta maksukortilta.",
  [EContractAgreementFields.CREDIT_CARD_NUMBER]: "Luottokortin numero",
  [EContractAgreementFields.CREDIT_CARD_EXPIRATION]: "Voimassaoloaika",
  [EContractAgreementFields.POST_BILLING_TITLE]: "Jälkiveloitus",
  [EContractAgreementFields.AGREEMENT_TITLE]: "Vuokrausehdot",
};

export const contractSummaryFIN = {
  [EContractSummary.TOTAL]: "Yhteensä",
  [EContractSummary.VAT_EXCLUDED]: "Veroton",
  [EContractSummary.PRODUCT]: "Tilauksen tuotteet",
  [EContractSummary.PRICE]: "Hinta (sis. vero)",
  [EContractSummary.QUANTITY]: "Määrä",
  [EContractSummary.VAT]: "ALV",
  [EContractSummary.VAT_INCLUDED]: "(sis. alv",
  [EContractSummary.TAX]: "Vero",
};
