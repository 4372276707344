import { EAdditionalDriverInfo } from "./LanguagePack/translatedFields";
import { Field } from "./common";
import { Box } from "@mui/material";

export default function AdditionalDriverInformation({
  reservation,
  visibility,
  selectedLanguage,
}) {
  const { additionalDriverName, additionalDriverSSN, additionalDriverPhone } =
    reservation;
  const fields = EAdditionalDriverInfo[selectedLanguage];

  if (visibility)
    return (
      <Box
        sx={{
          borderRight: "1px solid lightgrey",
          borderLeft: "1px solid lightgrey",
          display: "flex",
          flex: 1,
        }}
      >
        <Field
          value={`${additionalDriverName}, ${additionalDriverSSN}, ${additionalDriverPhone} `}
          label={fields.TITLE}
        />
      </Box>
    );
}
