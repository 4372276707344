import { EDriverInfo } from "./LanguagePack/translatedFields";
import { Divider, Field } from "./common";
import { Stack, Grid } from "@mui/material";
export default function ContractDriverInformation({
  reservation,
  visibility,
  selectedLanguage,
}) {
  const {
    driverName,
    driverSSN,
    driverAddress,
    driverPostalCode,
    driverCity,
    driverPhone,
    driverEmail,
  } = reservation;
  const fields = EDriverInfo[selectedLanguage];
  const maskedSsn = driverSSN
    ? driverSSN?.slice(0, -4) + driverSSN?.slice(-4).replace(/./g, "*")
    : null;

  if (visibility)
    return (
      <Grid item xs={4} sx={{ border: "1px solid lightgrey" }}>
        <Field value={driverName} label={fields.NAME} />

        <Stack direction={"row"}>
          <Field value={maskedSsn} label={fields.SSN} />
          <Divider />
          <Field value={""} label={fields.DRIVERS_LICENSE_CLASS} />
        </Stack>
        <Field
          value={
            driverAddress
              ? `${driverAddress}, ${driverPostalCode}, ${driverCity}`
              : ""
          }
          label={fields.ADDRESS}
        />
        <Stack direction={"row"}>
          <Field value={driverPhone} label={fields.PHONE} removeBorders />
          <Divider />
          <Field value={driverEmail} label={fields.EMAIL} removeBorders />
        </Stack>
      </Grid>
    );
}
