/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const updateInnovoiceStatus = /* GraphQL */ `mutation UpdateInnovoiceStatus(
  $reservationId: ID!
  $innovoiceStatus: String!
  $payload: UpdateStatusInput
) {
  updateInnovoiceStatus(
    reservationId: $reservationId
    innovoiceStatus: $innovoiceStatus
    payload: $payload
  ) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInnovoiceStatusMutationVariables,
  APITypes.UpdateInnovoiceStatusMutation
>;
export const setReservationVehicles = /* GraphQL */ `mutation SetReservationVehicles(
  $reservationId: ID!
  $reservationVehicles: [ReservationVehicleAdminInput]!
  $wildCardVehicles: [WildCardVehicleInput]!
) {
  setReservationVehicles(
    reservationId: $reservationId
    reservationVehicles: $reservationVehicles
    wildCardVehicles: $wildCardVehicles
  ) {
    success
    message
    reservation {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetReservationVehiclesMutationVariables,
  APITypes.SetReservationVehiclesMutation
>;
export const upsertReservationAdmin = /* GraphQL */ `mutation UpsertReservationAdmin(
  $reservation: UpsertReservationInputAdmin!
  $sendStatusToCustomer: Boolean
  $disableConfirmationEmail: Boolean
) {
  upsertReservationAdmin(
    reservation: $reservation
    sendStatusToCustomer: $sendStatusToCustomer
    disableConfirmationEmail: $disableConfirmationEmail
  ) {
    reservationId
    reservation {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    emailConfirmationSuccess
    code
    success
    destinationEmail
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpsertReservationAdminMutationVariables,
  APITypes.UpsertReservationAdminMutation
>;
export const cognitoDynamoDeleteUser = /* GraphQL */ `mutation CognitoDynamoDeleteUser($userId: ID!, $adminId: ID!) {
  cognitoDynamoDeleteUser(userId: $userId, adminId: $adminId)
}
` as GeneratedMutation<
  APITypes.CognitoDynamoDeleteUserMutationVariables,
  APITypes.CognitoDynamoDeleteUserMutation
>;
export const resetAndInitSign = /* GraphQL */ `mutation ResetAndInitSign($reservationId: ID!) {
  resetAndInitSign(reservationId: $reservationId) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ResetAndInitSignMutationVariables,
  APITypes.ResetAndInitSignMutation
>;
export const sendEmailVerification = /* GraphQL */ `mutation SendEmailVerification($reservationId: ID!) {
  sendEmailVerification(reservationId: $reservationId)
}
` as GeneratedMutation<
  APITypes.SendEmailVerificationMutationVariables,
  APITypes.SendEmailVerificationMutation
>;
export const createLivionContract = /* GraphQL */ `mutation CreateLivionContract($reservationId: ID!) {
  createLivionContract(reservationId: $reservationId) {
    success
    pincode
    registrationPlate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLivionContractMutationVariables,
  APITypes.CreateLivionContractMutation
>;
export const linkBusinessToChatID = /* GraphQL */ `mutation LinkBusinessToChatID($businessId: ID!) {
  linkBusinessToChatID(businessId: $businessId) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.LinkBusinessToChatIDMutationVariables,
  APITypes.LinkBusinessToChatIDMutation
>;
export const fetchTelegramConnections = /* GraphQL */ `mutation FetchTelegramConnections($businessId: ID!) {
  fetchTelegramConnections(businessId: $businessId) {
    message
    newConnectionsCount
    connections {
      chatId
      name
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.FetchTelegramConnectionsMutationVariables,
  APITypes.FetchTelegramConnectionsMutation
>;
export const removeTelegramConnection = /* GraphQL */ `mutation RemoveTelegramConnection($businessId: ID!, $chatId: String!) {
  removeTelegramConnection(businessId: $businessId, chatId: $chatId)
}
` as GeneratedMutation<
  APITypes.RemoveTelegramConnectionMutationVariables,
  APITypes.RemoveTelegramConnectionMutation
>;
export const unlinkBusinessWithChatID = /* GraphQL */ `mutation UnlinkBusinessWithChatID($businessId: ID!) {
  unlinkBusinessWithChatID(businessId: $businessId)
}
` as GeneratedMutation<
  APITypes.UnlinkBusinessWithChatIDMutationVariables,
  APITypes.UnlinkBusinessWithChatIDMutation
>;
export const sendTelegramMessage = /* GraphQL */ `mutation SendTelegramMessage($chatId: String!, $message: String!) {
  sendTelegramMessage(chatId: $chatId, message: $message) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendTelegramMessageMutationVariables,
  APITypes.SendTelegramMessageMutation
>;
export const sendTelegramMessages = /* GraphQL */ `mutation SendTelegramMessages($chatIds: [String!]!, $message: String!) {
  sendTelegramMessages(chatIds: $chatIds, message: $message) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendTelegramMessagesMutationVariables,
  APITypes.SendTelegramMessagesMutation
>;
export const cognitoDynamoCreateUser = /* GraphQL */ `mutation CognitoDynamoCreateUser(
  $name: String!
  $email: String!
  $role: String!
  $organizationId: ID
  $businessId: ID
  $externalBusinessId: ID
) {
  cognitoDynamoCreateUser(
    name: $name
    email: $email
    role: $role
    organizationId: $organizationId
    businessId: $businessId
    externalBusinessId: $externalBusinessId
  )
}
` as GeneratedMutation<
  APITypes.CognitoDynamoCreateUserMutationVariables,
  APITypes.CognitoDynamoCreateUserMutation
>;
export const migrateReservations = /* GraphQL */ `mutation MigrateReservations($ids: [ID!]!) {
  migrateReservations(ids: $ids) {
    id
    shortId
    language
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    companyId
    returnCompanyId
    startLocation
    returnLocation
    startLocationID
    returnLocationID
    reviewId
    customerBrokerId
    customerCompanyId
    customerPersonalId
    reservationVehicleId
    reservationVehicles {
      id
      price
      tax
      name
      registrationPlate
      deductible
      includedDistance
      powerType
      mileage
      startMileage
      endMileage
      extraInfo
      damages
      hideExport
      createdAt
      addedAt
      categoryName
      __typename
    }
    wildCardVehicles {
      name
      price
      tax
      categoryId
      quantity
      deductible
      __typename
    }
    createdAt
    updatedAt
    startTime
    returnTime
    endTime
    name
    address
    postalCode
    city
    phone
    email
    billingRef
    ssn
    rentalNotes
    internalNotes
    externalNotes
    outOfCommission
    vehiclePrice
    additionalServices {
      key
      price
      details
      quantity
      type
      deductibleValue
      groupFields
      tax
      enableNotification
      description
      category
      __typename
    }
    driverSSN
    differentDriver
    driverName
    driverAddress
    driverPostalCode
    driverCity
    driverPhone
    driverEmail
    additionalDriver
    additionalDriverName
    additionalDriverPhone
    additionalDriverSSN
    billPayment
    isCompany
    carStatus
    rentalStatus
    innovoiceStatus
    companyName
    companyBusinessId
    companyCity
    companyPostalCode
    companyBillingRef
    companyBillingAddress
    companyEBillingOperator
    companyEBillingAddress
    paymentToken
    npsStatus
    paymentStatus
    paymentData {
      paymentTotal
      paymentTodo
      depositPending
      deposit
      __typename
    }
    signStatus
    signDocumentId
    signerName
    reviewStatus
    authStatus
    authTime
    completeBefore
    mustSign
    livionData {
      id
      pincode
      keyId
      name
      registrationPlate
      __typename
    }
    tilakoneData {
      access_id
      begin_timestamp
      end_timestamp
      pincode
      __typename
    }
    voucherData {
      name
      code
      amount
      __typename
    }
    customerCarRegistrationPlate
    insuranceData {
      customerContact
      urgent
      customerCarModel
      customerCarMaker
      product
      coveragePercentage
      compensatableDays
      billingPermitCode
      coverageDuration
      providerCompany
      providerBusinessId
      claimAdjusterName
      claimAdjusterEmail
      receipt
      replacementVehicleClass
      claimId
      vatDeductible
      deliveryCosts
      returnCosts
      __typename
    }
    externalData {
      orderId
      orderReference
      serviceAgreementName
      operatorName
      orderInfo
      billingPayer
      billingDescription
      customerVehicle {
        brand
        model
        registrationPlate
        __typename
      }
      incidentLocation {
        description
        address
        city
        zipCode
        __typename
      }
      repairShop {
        description
        address
        city
        zipCode
        __typename
      }
      __typename
    }
    enableRegistrationPlate
    disablePricesInPrintouts
    hanselData {
      contractNro
      customerInvoiceId
      customerInvoiceDate
      hanselAgent
      hanselClass
      customerId
      subContractorNumber
      subContractorName
      suplierProductId
      suplierProductName
      quantity
      price
      totalPrice
      priceNoVAT
      additionalInfo1
      additionalInfo2
      additionalInfo3
      desc
      __typename
    }
    invoiceIds
    consolidatedInvoiceIds
    channel
    originURL
    type
    status
    reservationStatusInfo {
      deliveryTimestamp
      deliveryAddress
      deliveryZipcode
      deliveryCity
      deliveryCountry
      deliveryDescription
      returnTimestamp
      returnAddress
      returnZipcode
      returnCity
      returnCountry
      returnDescription
      cancelReason
      __typename
    }
    initialCategory {
      id
      name
      hanselClass
      __typename
    }
    initialClassInfo {
      categoryId
      categoryName
      hanselClass
      __typename
    }
    orgInvoiceCustomer {
      name
      businessId
      __typename
    }
    pricingCategoryId
    customImportDate
    vehicle {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    invoices {
      items {
        id
        createdAt
        innovoiceId
        reservationId
        reservationIds
        companyId
        innovoiceCompanyId
        innovoiceuRentCarRentalId
        innovoiceOrderId
        group
        organizationId
        orgBusinessId
        type
        creditNoteInvoiceId
        payment_terms
        vat_included
        delivery_by
        invoice_type
        delivery_date
        seller_reference
        buyer_reference
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    paymentEvents {
      items {
        id
        channel
        paymentMethod
        paymentTotal
        isDeleted
        deletedBy
        deletedAt
        createdByUserID
        createdByUserName
        reservationId
        organizationId
        orgBusinessId
        group
        msg
        state
        returnCode
        incidentId
        parameters
        apiPath
        paymentStatus
        orderNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.MigrateReservationsMutationVariables,
  APITypes.MigrateReservationsMutation
>;
export const createInnovoiceInvoice = /* GraphQL */ `mutation CreateInnovoiceInvoice(
  $invoice: CreateInnvoiceInput
  $reservationId: String
  $paymentType: InvoicePaymentType
) {
  createInnovoiceInvoice(
    invoice: $invoice
    reservationId: $reservationId
    paymentType: $paymentType
  ) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInnovoiceInvoiceMutationVariables,
  APITypes.CreateInnovoiceInvoiceMutation
>;
export const createInnovoiceConsolidatedInvoice = /* GraphQL */ `mutation CreateInnovoiceConsolidatedInvoice(
  $invoice: CreateConsolidatedInnvoiceInput!
) {
  createInnovoiceConsolidatedInvoice(invoice: $invoice) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInnovoiceConsolidatedInvoiceMutationVariables,
  APITypes.CreateInnovoiceConsolidatedInvoiceMutation
>;
export const createInnovoiceCreditNote = /* GraphQL */ `mutation CreateInnovoiceCreditNote($reservationId: String, $invoiceId: String) {
  createInnovoiceCreditNote(
    reservationId: $reservationId
    invoiceId: $invoiceId
  ) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInnovoiceCreditNoteMutationVariables,
  APITypes.CreateInnovoiceCreditNoteMutation
>;
export const createNewsItem = /* GraphQL */ `mutation CreateNewsItem(
  $input: CreateNewsItemInput!
  $condition: ModelNewsItemConditionInput
) {
  createNewsItem(input: $input, condition: $condition) {
    id
    header
    link
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNewsItemMutationVariables,
  APITypes.CreateNewsItemMutation
>;
export const updateNewsItem = /* GraphQL */ `mutation UpdateNewsItem(
  $input: UpdateNewsItemInput!
  $condition: ModelNewsItemConditionInput
) {
  updateNewsItem(input: $input, condition: $condition) {
    id
    header
    link
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNewsItemMutationVariables,
  APITypes.UpdateNewsItemMutation
>;
export const deleteNewsItem = /* GraphQL */ `mutation DeleteNewsItem(
  $input: DeleteNewsItemInput!
  $condition: ModelNewsItemConditionInput
) {
  deleteNewsItem(input: $input, condition: $condition) {
    id
    header
    link
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNewsItemMutationVariables,
  APITypes.DeleteNewsItemMutation
>;
export const upsertReview = /* GraphQL */ `mutation UpsertReview($review: UpsertReviewInput!) {
  upsertReview(review: $review) {
    reviewId
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpsertReviewMutationVariables,
  APITypes.UpsertReviewMutation
>;
export const createPricingsFromCsv = /* GraphQL */ `mutation CreatePricingsFromCsv($csvData: String!, $orgExternalBusinessId: ID!) {
  createPricingsFromCsv(
    csvData: $csvData
    orgExternalBusinessId: $orgExternalBusinessId
  ) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePricingsFromCsvMutationVariables,
  APITypes.CreatePricingsFromCsvMutation
>;
export const upsertReservationCustomer = /* GraphQL */ `mutation UpsertReservationCustomer(
  $reservation: UpsertReservationInputCustomer!
  $paymentMode: String
) {
  upsertReservationCustomer(
    reservation: $reservation
    paymentMode: $paymentMode
  ) {
    emailConfirmationSuccess
    code
    success
    destinationEmail
    reservationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpsertReservationCustomerMutationVariables,
  APITypes.UpsertReservationCustomerMutation
>;
export const cancelReservationCustomer = /* GraphQL */ `mutation CancelReservationCustomer($reservationId: ID!) {
  cancelReservationCustomer(reservationId: $reservationId) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CancelReservationCustomerMutationVariables,
  APITypes.CancelReservationCustomerMutation
>;
export const setReservationInfo = /* GraphQL */ `mutation SetReservationInfo(
  $reservation: ReservationInfoInput!
  $paymentMode: String
) {
  setReservationInfo(reservation: $reservation, paymentMode: $paymentMode) {
    id
    startTime
    returnTime
    paymentPending
    paymentStatus
    signStatus
    enableSign
    disablePricesInPrintouts
    mustSign
    paymentData {
      paymentTodo
      depositPending
      deposit
      paymentTotal
      __typename
    }
    askSsn
    ssn
    group
    companyTermsUrl
    companyId
    name
    address
    postalCode
    city
    phone
    billingRef
    email
    externalNotes
    differentDriver
    driverName
    driverAddress
    driverPostalCode
    driverCity
    driverPhone
    driverEmail
    driverSSN
    additionalDriver
    additionalDriverName
    additionalDriverPhone
    allowCancellation
    billPayment
    isCompany
    companyName
    companyBusinessId
    companyBillingRef
    companyBillingAddress
    companyEBillingOperator
    companyEBillingAddress
    authStatus
    vehicles {
      group
      organizationId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      orderNumber
      price
      tax
      category {
        name
        typeId
        __typename
      }
      id
      __typename
    }
    services {
      quantity
      description
      descriptionT {
        fi
        en
        sv
        __typename
      }
      price
      tax
      key
      __typename
    }
    expiryTime
    voucherData {
      amount
      code
      __typename
    }
    enableGTM
    errorMsg
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetReservationInfoMutationVariables,
  APITypes.SetReservationInfoMutation
>;
export const setReservationPaymentStatus = /* GraphQL */ `mutation SetReservationPaymentStatus($reservationId: ID!) {
  setReservationPaymentStatus(reservationId: $reservationId) {
    emailConfirmationSuccess
    code
    success
    destinationEmail
    reservationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetReservationPaymentStatusMutationVariables,
  APITypes.SetReservationPaymentStatusMutation
>;
export const cancelReservation = /* GraphQL */ `mutation CancelReservation($reservationId: ID!) {
  cancelReservation(reservationId: $reservationId)
}
` as GeneratedMutation<
  APITypes.CancelReservationMutationVariables,
  APITypes.CancelReservationMutation
>;
export const initVismaSign = /* GraphQL */ `mutation InitVismaSign($reservationId: ID!) {
  initVismaSign(reservationId: $reservationId) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.InitVismaSignMutationVariables,
  APITypes.InitVismaSignMutation
>;
export const createCompany = /* GraphQL */ `mutation CreateCompany(
  $input: CreateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  createCompany(input: $input, condition: $condition) {
    id
    organizationId
    orgBusinessId
    group
    name
    displayName
    email
    address
    postalCode
    city
    orderNumber
    orderNumberDealer
    phone
    businessId
    termsUrl
    timezone
    weeklyBusinessHours {
      openAt
      closeAt
      __typename
    }
    signConfirmationEmail
    nameInEmail
    senderNameSMS
    confirmationMsg
    confirmationMsgT {
      fi
      en
      sv
      __typename
    }
    feedbackMsg
    feedbackMsgT {
      fi
      en
      sv
      __typename
    }
    statusLinkMsg
    statusLinkMsgT {
      fi
      en
      sv
      __typename
    }
    livionDeviceId
    livionDeviceType
    enableReservationDeposit
    reservationDeposit {
      amount
      reminderBefore
      paymentBefore
      __typename
    }
    reservationLinkExpirationHours
    disableOffHourReturn
    disableMultiday
    disableSameDay
    disableStartDaysFromNow
    enableCart
    enableMonthlyView
    enableRegistrationPlate
    enableVehicleStartReturnTimes
    enableMustSign
    enablePaymentOnlineOnly
    enableGTM
    enableNetPromoterScore
    enableNPS
    reservationCancellation {
      cancellationEnabled
      daysBeforeStart
      __typename
    }
    creditCheck {
      enabled
      keyLookup
      userId
      passwordId
      __typename
    }
    limitReservationOncePerDay
    hidden
    receiptInfo {
      enabled
      name
      address
      city
      postalCode
      __typename
    }
    innovoice {
      enabled
      companyId
      uRentCarRentalId
      __typename
    }
    locationType
    offHourAvailability
    offHourAvailabilityFee
    deliveryFee
    deliveryBufferHours
    notAllowedCategoryIds
    importIdentifier
    features {
      enableNPS
      enableVismaPay
      enableReservationDialogV2
      enableTilakone
      enableGTM
      enableSMSConfirmation
      __typename
    }
    categoryDynamicPricings {
      categoryId
      pricingId
      percentage
      __typename
    }
    disableReservationCategoryIds
    locationColor
    createdAt
    updatedAt
    merchantDetails {
      api
      merchant_id
      cp
      __typename
    }
    businessHoursExceptions {
      items {
        organizationId
        orgBusinessId
        group
        date
        name
        companyId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    vehicles {
      items {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    categories {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    categoriesByOrg {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyMutationVariables,
  APITypes.CreateCompanyMutation
>;
export const updateCompany = /* GraphQL */ `mutation UpdateCompany(
  $input: UpdateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  updateCompany(input: $input, condition: $condition) {
    id
    organizationId
    orgBusinessId
    group
    name
    displayName
    email
    address
    postalCode
    city
    orderNumber
    orderNumberDealer
    phone
    businessId
    termsUrl
    timezone
    weeklyBusinessHours {
      openAt
      closeAt
      __typename
    }
    signConfirmationEmail
    nameInEmail
    senderNameSMS
    confirmationMsg
    confirmationMsgT {
      fi
      en
      sv
      __typename
    }
    feedbackMsg
    feedbackMsgT {
      fi
      en
      sv
      __typename
    }
    statusLinkMsg
    statusLinkMsgT {
      fi
      en
      sv
      __typename
    }
    livionDeviceId
    livionDeviceType
    enableReservationDeposit
    reservationDeposit {
      amount
      reminderBefore
      paymentBefore
      __typename
    }
    reservationLinkExpirationHours
    disableOffHourReturn
    disableMultiday
    disableSameDay
    disableStartDaysFromNow
    enableCart
    enableMonthlyView
    enableRegistrationPlate
    enableVehicleStartReturnTimes
    enableMustSign
    enablePaymentOnlineOnly
    enableGTM
    enableNetPromoterScore
    enableNPS
    reservationCancellation {
      cancellationEnabled
      daysBeforeStart
      __typename
    }
    creditCheck {
      enabled
      keyLookup
      userId
      passwordId
      __typename
    }
    limitReservationOncePerDay
    hidden
    receiptInfo {
      enabled
      name
      address
      city
      postalCode
      __typename
    }
    innovoice {
      enabled
      companyId
      uRentCarRentalId
      __typename
    }
    locationType
    offHourAvailability
    offHourAvailabilityFee
    deliveryFee
    deliveryBufferHours
    notAllowedCategoryIds
    importIdentifier
    features {
      enableNPS
      enableVismaPay
      enableReservationDialogV2
      enableTilakone
      enableGTM
      enableSMSConfirmation
      __typename
    }
    categoryDynamicPricings {
      categoryId
      pricingId
      percentage
      __typename
    }
    disableReservationCategoryIds
    locationColor
    createdAt
    updatedAt
    merchantDetails {
      api
      merchant_id
      cp
      __typename
    }
    businessHoursExceptions {
      items {
        organizationId
        orgBusinessId
        group
        date
        name
        companyId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    vehicles {
      items {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    categories {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    categoriesByOrg {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyMutationVariables,
  APITypes.UpdateCompanyMutation
>;
export const deleteCompany = /* GraphQL */ `mutation DeleteCompany(
  $input: DeleteCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  deleteCompany(input: $input, condition: $condition) {
    id
    organizationId
    orgBusinessId
    group
    name
    displayName
    email
    address
    postalCode
    city
    orderNumber
    orderNumberDealer
    phone
    businessId
    termsUrl
    timezone
    weeklyBusinessHours {
      openAt
      closeAt
      __typename
    }
    signConfirmationEmail
    nameInEmail
    senderNameSMS
    confirmationMsg
    confirmationMsgT {
      fi
      en
      sv
      __typename
    }
    feedbackMsg
    feedbackMsgT {
      fi
      en
      sv
      __typename
    }
    statusLinkMsg
    statusLinkMsgT {
      fi
      en
      sv
      __typename
    }
    livionDeviceId
    livionDeviceType
    enableReservationDeposit
    reservationDeposit {
      amount
      reminderBefore
      paymentBefore
      __typename
    }
    reservationLinkExpirationHours
    disableOffHourReturn
    disableMultiday
    disableSameDay
    disableStartDaysFromNow
    enableCart
    enableMonthlyView
    enableRegistrationPlate
    enableVehicleStartReturnTimes
    enableMustSign
    enablePaymentOnlineOnly
    enableGTM
    enableNetPromoterScore
    enableNPS
    reservationCancellation {
      cancellationEnabled
      daysBeforeStart
      __typename
    }
    creditCheck {
      enabled
      keyLookup
      userId
      passwordId
      __typename
    }
    limitReservationOncePerDay
    hidden
    receiptInfo {
      enabled
      name
      address
      city
      postalCode
      __typename
    }
    innovoice {
      enabled
      companyId
      uRentCarRentalId
      __typename
    }
    locationType
    offHourAvailability
    offHourAvailabilityFee
    deliveryFee
    deliveryBufferHours
    notAllowedCategoryIds
    importIdentifier
    features {
      enableNPS
      enableVismaPay
      enableReservationDialogV2
      enableTilakone
      enableGTM
      enableSMSConfirmation
      __typename
    }
    categoryDynamicPricings {
      categoryId
      pricingId
      percentage
      __typename
    }
    disableReservationCategoryIds
    locationColor
    createdAt
    updatedAt
    merchantDetails {
      api
      merchant_id
      cp
      __typename
    }
    businessHoursExceptions {
      items {
        organizationId
        orgBusinessId
        group
        date
        name
        companyId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    vehicles {
      items {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    categories {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    categoriesByOrg {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyMutationVariables,
  APITypes.DeleteCompanyMutation
>;
export const createBusinessHoursException = /* GraphQL */ `mutation CreateBusinessHoursException(
  $input: CreateBusinessHoursExceptionInput!
  $condition: ModelBusinessHoursExceptionConditionInput
) {
  createBusinessHoursException(input: $input, condition: $condition) {
    organizationId
    orgBusinessId
    group
    date
    name
    businessHours {
      openAt
      closeAt
      __typename
    }
    companyId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBusinessHoursExceptionMutationVariables,
  APITypes.CreateBusinessHoursExceptionMutation
>;
export const updateBusinessHoursException = /* GraphQL */ `mutation UpdateBusinessHoursException(
  $input: UpdateBusinessHoursExceptionInput!
  $condition: ModelBusinessHoursExceptionConditionInput
) {
  updateBusinessHoursException(input: $input, condition: $condition) {
    organizationId
    orgBusinessId
    group
    date
    name
    businessHours {
      openAt
      closeAt
      __typename
    }
    companyId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessHoursExceptionMutationVariables,
  APITypes.UpdateBusinessHoursExceptionMutation
>;
export const deleteBusinessHoursException = /* GraphQL */ `mutation DeleteBusinessHoursException(
  $input: DeleteBusinessHoursExceptionInput!
  $condition: ModelBusinessHoursExceptionConditionInput
) {
  deleteBusinessHoursException(input: $input, condition: $condition) {
    organizationId
    orgBusinessId
    group
    date
    name
    businessHours {
      openAt
      closeAt
      __typename
    }
    companyId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBusinessHoursExceptionMutationVariables,
  APITypes.DeleteBusinessHoursExceptionMutation
>;
export const createVehicle = /* GraphQL */ `mutation CreateVehicle(
  $input: CreateVehicleInput!
  $condition: ModelVehicleConditionInput
) {
  createVehicle(input: $input, condition: $condition) {
    id
    group
    organizationId
    orgBusinessId
    companyId
    name
    nameT {
      fi
      en
      sv
      __typename
    }
    registrationPlate
    damages
    damageRecords {
      normalizedX
      normalizedY
      label
      description
      createdAt
      __typename
    }
    additionalFields {
      key
      value
      valueT {
        fi
        en
        sv
        __typename
      }
      __typename
    }
    image
    powerType
    deductible
    includedDistance
    mileage
    orderNumber
    vehicleCategoryId
    useCategoryDefaults
    useStartReturnTimes
    customStartReturns {
      priority
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      __typename
    }
    startReturnInterval {
      start
      end
      __typename
    }
    startReturnTimes {
      start
      return
      __typename
    }
    hideExport
    extraInfo
    extraInfoT
    extraToConfirmation
    extraToConfirmationT
    livionKeyId
    phoneNumber
    importData
    disableOnline
    activeRange {
      start
      end
      __typename
    }
    createdAt
    updatedAt
    category {
      id
      group
      organizationId
      name
      image
      orderNumber
      backgroundColor
      minuteBuffer
      reservationBlockFromNow
      typeId
      _removed
      oldTujausValue
      exampleModelName
      exampleModelImage
      createdAt
      updatedAt
      additionalServices {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      externalBusinessPricings {
        nextToken
        __typename
      }
      __typename
    }
    additionalServices {
      items {
        id
        group
        organizationId
        orgBusinessId
        key
        vehicleId
        categoryId
        pricingId
        deductibleValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    reservations {
      items {
        id
        shortId
        language
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        companyId
        returnCompanyId
        startLocation
        returnLocation
        startLocationID
        returnLocationID
        reviewId
        customerBrokerId
        customerCompanyId
        customerPersonalId
        reservationVehicleId
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        billingRef
        ssn
        rentalNotes
        internalNotes
        externalNotes
        outOfCommission
        vehiclePrice
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        billPayment
        isCompany
        carStatus
        rentalStatus
        innovoiceStatus
        companyName
        companyBusinessId
        companyCity
        companyPostalCode
        companyBillingRef
        companyBillingAddress
        companyEBillingOperator
        companyEBillingAddress
        paymentToken
        npsStatus
        paymentStatus
        signStatus
        signDocumentId
        signerName
        reviewStatus
        authStatus
        authTime
        completeBefore
        mustSign
        customerCarRegistrationPlate
        enableRegistrationPlate
        disablePricesInPrintouts
        invoiceIds
        consolidatedInvoiceIds
        channel
        originURL
        type
        status
        pricingCategoryId
        customImportDate
        __typename
      }
      nextToken
      __typename
    }
    pricing {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVehicleMutationVariables,
  APITypes.CreateVehicleMutation
>;
export const updateVehicle = /* GraphQL */ `mutation UpdateVehicle(
  $input: UpdateVehicleInput!
  $condition: ModelVehicleConditionInput
) {
  updateVehicle(input: $input, condition: $condition) {
    id
    group
    organizationId
    orgBusinessId
    companyId
    name
    nameT {
      fi
      en
      sv
      __typename
    }
    registrationPlate
    damages
    damageRecords {
      normalizedX
      normalizedY
      label
      description
      createdAt
      __typename
    }
    additionalFields {
      key
      value
      valueT {
        fi
        en
        sv
        __typename
      }
      __typename
    }
    image
    powerType
    deductible
    includedDistance
    mileage
    orderNumber
    vehicleCategoryId
    useCategoryDefaults
    useStartReturnTimes
    customStartReturns {
      priority
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      __typename
    }
    startReturnInterval {
      start
      end
      __typename
    }
    startReturnTimes {
      start
      return
      __typename
    }
    hideExport
    extraInfo
    extraInfoT
    extraToConfirmation
    extraToConfirmationT
    livionKeyId
    phoneNumber
    importData
    disableOnline
    activeRange {
      start
      end
      __typename
    }
    createdAt
    updatedAt
    category {
      id
      group
      organizationId
      name
      image
      orderNumber
      backgroundColor
      minuteBuffer
      reservationBlockFromNow
      typeId
      _removed
      oldTujausValue
      exampleModelName
      exampleModelImage
      createdAt
      updatedAt
      additionalServices {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      externalBusinessPricings {
        nextToken
        __typename
      }
      __typename
    }
    additionalServices {
      items {
        id
        group
        organizationId
        orgBusinessId
        key
        vehicleId
        categoryId
        pricingId
        deductibleValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    reservations {
      items {
        id
        shortId
        language
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        companyId
        returnCompanyId
        startLocation
        returnLocation
        startLocationID
        returnLocationID
        reviewId
        customerBrokerId
        customerCompanyId
        customerPersonalId
        reservationVehicleId
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        billingRef
        ssn
        rentalNotes
        internalNotes
        externalNotes
        outOfCommission
        vehiclePrice
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        billPayment
        isCompany
        carStatus
        rentalStatus
        innovoiceStatus
        companyName
        companyBusinessId
        companyCity
        companyPostalCode
        companyBillingRef
        companyBillingAddress
        companyEBillingOperator
        companyEBillingAddress
        paymentToken
        npsStatus
        paymentStatus
        signStatus
        signDocumentId
        signerName
        reviewStatus
        authStatus
        authTime
        completeBefore
        mustSign
        customerCarRegistrationPlate
        enableRegistrationPlate
        disablePricesInPrintouts
        invoiceIds
        consolidatedInvoiceIds
        channel
        originURL
        type
        status
        pricingCategoryId
        customImportDate
        __typename
      }
      nextToken
      __typename
    }
    pricing {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVehicleMutationVariables,
  APITypes.UpdateVehicleMutation
>;
export const deleteVehicle = /* GraphQL */ `mutation DeleteVehicle(
  $input: DeleteVehicleInput!
  $condition: ModelVehicleConditionInput
) {
  deleteVehicle(input: $input, condition: $condition) {
    id
    group
    organizationId
    orgBusinessId
    companyId
    name
    nameT {
      fi
      en
      sv
      __typename
    }
    registrationPlate
    damages
    damageRecords {
      normalizedX
      normalizedY
      label
      description
      createdAt
      __typename
    }
    additionalFields {
      key
      value
      valueT {
        fi
        en
        sv
        __typename
      }
      __typename
    }
    image
    powerType
    deductible
    includedDistance
    mileage
    orderNumber
    vehicleCategoryId
    useCategoryDefaults
    useStartReturnTimes
    customStartReturns {
      priority
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      __typename
    }
    startReturnInterval {
      start
      end
      __typename
    }
    startReturnTimes {
      start
      return
      __typename
    }
    hideExport
    extraInfo
    extraInfoT
    extraToConfirmation
    extraToConfirmationT
    livionKeyId
    phoneNumber
    importData
    disableOnline
    activeRange {
      start
      end
      __typename
    }
    createdAt
    updatedAt
    category {
      id
      group
      organizationId
      name
      image
      orderNumber
      backgroundColor
      minuteBuffer
      reservationBlockFromNow
      typeId
      _removed
      oldTujausValue
      exampleModelName
      exampleModelImage
      createdAt
      updatedAt
      additionalServices {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      externalBusinessPricings {
        nextToken
        __typename
      }
      __typename
    }
    additionalServices {
      items {
        id
        group
        organizationId
        orgBusinessId
        key
        vehicleId
        categoryId
        pricingId
        deductibleValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    reservations {
      items {
        id
        shortId
        language
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        companyId
        returnCompanyId
        startLocation
        returnLocation
        startLocationID
        returnLocationID
        reviewId
        customerBrokerId
        customerCompanyId
        customerPersonalId
        reservationVehicleId
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        billingRef
        ssn
        rentalNotes
        internalNotes
        externalNotes
        outOfCommission
        vehiclePrice
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        billPayment
        isCompany
        carStatus
        rentalStatus
        innovoiceStatus
        companyName
        companyBusinessId
        companyCity
        companyPostalCode
        companyBillingRef
        companyBillingAddress
        companyEBillingOperator
        companyEBillingAddress
        paymentToken
        npsStatus
        paymentStatus
        signStatus
        signDocumentId
        signerName
        reviewStatus
        authStatus
        authTime
        completeBefore
        mustSign
        customerCarRegistrationPlate
        enableRegistrationPlate
        disablePricesInPrintouts
        invoiceIds
        consolidatedInvoiceIds
        channel
        originURL
        type
        status
        pricingCategoryId
        customImportDate
        __typename
      }
      nextToken
      __typename
    }
    pricing {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVehicleMutationVariables,
  APITypes.DeleteVehicleMutation
>;
export const createCategory = /* GraphQL */ `mutation CreateCategory(
  $input: CreateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  createCategory(input: $input, condition: $condition) {
    id
    group
    organizationId
    name
    image
    orderNumber
    backgroundColor
    minuteBuffer
    reservationBlockFromNow
    typeId
    _removed
    oldTujausValue
    exampleModelName
    exampleModelImage
    createdAt
    updatedAt
    additionalServices {
      items {
        id
        group
        organizationId
        orgBusinessId
        key
        vehicleId
        categoryId
        pricingId
        deductibleValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pricing {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    externalBusinessPricings {
      items {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryMutationVariables,
  APITypes.CreateCategoryMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory(
  $input: UpdateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  updateCategory(input: $input, condition: $condition) {
    id
    group
    organizationId
    name
    image
    orderNumber
    backgroundColor
    minuteBuffer
    reservationBlockFromNow
    typeId
    _removed
    oldTujausValue
    exampleModelName
    exampleModelImage
    createdAt
    updatedAt
    additionalServices {
      items {
        id
        group
        organizationId
        orgBusinessId
        key
        vehicleId
        categoryId
        pricingId
        deductibleValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pricing {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    externalBusinessPricings {
      items {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory(
  $input: DeleteCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  deleteCategory(input: $input, condition: $condition) {
    id
    group
    organizationId
    name
    image
    orderNumber
    backgroundColor
    minuteBuffer
    reservationBlockFromNow
    typeId
    _removed
    oldTujausValue
    exampleModelName
    exampleModelImage
    createdAt
    updatedAt
    additionalServices {
      items {
        id
        group
        organizationId
        orgBusinessId
        key
        vehicleId
        categoryId
        pricingId
        deductibleValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pricing {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    externalBusinessPricings {
      items {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const createAdditionalServicePricing = /* GraphQL */ `mutation CreateAdditionalServicePricing(
  $input: CreateAdditionalServicePricingInput!
  $condition: ModelAdditionalServicePricingConditionInput
) {
  createAdditionalServicePricing(input: $input, condition: $condition) {
    id
    group
    organizationId
    orgBusinessId
    key
    vehicleId
    categoryId
    pricingId
    deductibleValue
    createdAt
    updatedAt
    pricing {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdditionalServicePricingMutationVariables,
  APITypes.CreateAdditionalServicePricingMutation
>;
export const updateAdditionalServicePricing = /* GraphQL */ `mutation UpdateAdditionalServicePricing(
  $input: UpdateAdditionalServicePricingInput!
  $condition: ModelAdditionalServicePricingConditionInput
) {
  updateAdditionalServicePricing(input: $input, condition: $condition) {
    id
    group
    organizationId
    orgBusinessId
    key
    vehicleId
    categoryId
    pricingId
    deductibleValue
    createdAt
    updatedAt
    pricing {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdditionalServicePricingMutationVariables,
  APITypes.UpdateAdditionalServicePricingMutation
>;
export const deleteAdditionalServicePricing = /* GraphQL */ `mutation DeleteAdditionalServicePricing(
  $input: DeleteAdditionalServicePricingInput!
  $condition: ModelAdditionalServicePricingConditionInput
) {
  deleteAdditionalServicePricing(input: $input, condition: $condition) {
    id
    group
    organizationId
    orgBusinessId
    key
    vehicleId
    categoryId
    pricingId
    deductibleValue
    createdAt
    updatedAt
    pricing {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdditionalServicePricingMutationVariables,
  APITypes.DeleteAdditionalServicePricingMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    organizationId
    orgBusinessId
    externalBusinessId
    role
    permissions {
      userManagement
      __typename
    }
    email
    name
    regionalCompanyName
    createdAt
    updatedAt
    categoriesByOrg {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    organization {
      id
      name
      billingCustomers {
        name
        __typename
      }
      features {
        enableNPS
        enableBusinessWideReservation
        __typename
      }
      createdAt
      updatedAt
      businesses {
        nextToken
        __typename
      }
      __typename
    }
    externalBusiness {
      id
      organizationId
      type
      name
      insuranceProducts {
        name
        coveragePercentage
        compensatableDays
        billingPermitCode
        __typename
      }
      insuranceRegionalCompanies {
        name
        companyName
        businessId
        address
        postalCode
        postalArea
        email
        innovoiceId
        __typename
      }
      insuranceCompensatableTimes
      services {
        description
        price
        category
        deductibleValue
        __typename
      }
      useHansel
      hanselId
      availableOrgBusinessIds
      availableCompanyIds
      availableVehicleClasses
      eBillingRef
      eBillingOperator
      eBillingAddress
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      pricings {
        nextToken
        __typename
      }
      __typename
    }
    externalBusinessByOrg {
      items {
        id
        organizationId
        type
        name
        insuranceCompensatableTimes
        useHansel
        hanselId
        availableOrgBusinessIds
        availableCompanyIds
        availableVehicleClasses
        eBillingRef
        eBillingOperator
        eBillingAddress
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    business {
      id
      organizationId
      telegramChatId
      telegramConnections {
        chatId
        name
        __typename
      }
      group
      name
      address
      city
      email
      phone
      insuranceCoverageZone {
        start
        end
        __typename
      }
      insuranceCoverageZones {
        start
        end
        __typename
      }
      importIdentifier
      categoryDynamicPricings {
        categoryId
        pricingId
        percentage
        __typename
      }
      startLocations {
        id
        name
        locationColor
        companyId
        __typename
      }
      pickupLocations {
        id
        name
        locationColor
        companyId
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      __typename
    }
    businessByOrg {
      items {
        id
        organizationId
        telegramChatId
        group
        name
        address
        city
        email
        phone
        importIdentifier
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    organizationId
    orgBusinessId
    externalBusinessId
    role
    permissions {
      userManagement
      __typename
    }
    email
    name
    regionalCompanyName
    createdAt
    updatedAt
    categoriesByOrg {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    organization {
      id
      name
      billingCustomers {
        name
        __typename
      }
      features {
        enableNPS
        enableBusinessWideReservation
        __typename
      }
      createdAt
      updatedAt
      businesses {
        nextToken
        __typename
      }
      __typename
    }
    externalBusiness {
      id
      organizationId
      type
      name
      insuranceProducts {
        name
        coveragePercentage
        compensatableDays
        billingPermitCode
        __typename
      }
      insuranceRegionalCompanies {
        name
        companyName
        businessId
        address
        postalCode
        postalArea
        email
        innovoiceId
        __typename
      }
      insuranceCompensatableTimes
      services {
        description
        price
        category
        deductibleValue
        __typename
      }
      useHansel
      hanselId
      availableOrgBusinessIds
      availableCompanyIds
      availableVehicleClasses
      eBillingRef
      eBillingOperator
      eBillingAddress
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      pricings {
        nextToken
        __typename
      }
      __typename
    }
    externalBusinessByOrg {
      items {
        id
        organizationId
        type
        name
        insuranceCompensatableTimes
        useHansel
        hanselId
        availableOrgBusinessIds
        availableCompanyIds
        availableVehicleClasses
        eBillingRef
        eBillingOperator
        eBillingAddress
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    business {
      id
      organizationId
      telegramChatId
      telegramConnections {
        chatId
        name
        __typename
      }
      group
      name
      address
      city
      email
      phone
      insuranceCoverageZone {
        start
        end
        __typename
      }
      insuranceCoverageZones {
        start
        end
        __typename
      }
      importIdentifier
      categoryDynamicPricings {
        categoryId
        pricingId
        percentage
        __typename
      }
      startLocations {
        id
        name
        locationColor
        companyId
        __typename
      }
      pickupLocations {
        id
        name
        locationColor
        companyId
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      __typename
    }
    businessByOrg {
      items {
        id
        organizationId
        telegramChatId
        group
        name
        address
        city
        email
        phone
        importIdentifier
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    organizationId
    orgBusinessId
    externalBusinessId
    role
    permissions {
      userManagement
      __typename
    }
    email
    name
    regionalCompanyName
    createdAt
    updatedAt
    categoriesByOrg {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    organization {
      id
      name
      billingCustomers {
        name
        __typename
      }
      features {
        enableNPS
        enableBusinessWideReservation
        __typename
      }
      createdAt
      updatedAt
      businesses {
        nextToken
        __typename
      }
      __typename
    }
    externalBusiness {
      id
      organizationId
      type
      name
      insuranceProducts {
        name
        coveragePercentage
        compensatableDays
        billingPermitCode
        __typename
      }
      insuranceRegionalCompanies {
        name
        companyName
        businessId
        address
        postalCode
        postalArea
        email
        innovoiceId
        __typename
      }
      insuranceCompensatableTimes
      services {
        description
        price
        category
        deductibleValue
        __typename
      }
      useHansel
      hanselId
      availableOrgBusinessIds
      availableCompanyIds
      availableVehicleClasses
      eBillingRef
      eBillingOperator
      eBillingAddress
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      pricings {
        nextToken
        __typename
      }
      __typename
    }
    externalBusinessByOrg {
      items {
        id
        organizationId
        type
        name
        insuranceCompensatableTimes
        useHansel
        hanselId
        availableOrgBusinessIds
        availableCompanyIds
        availableVehicleClasses
        eBillingRef
        eBillingOperator
        eBillingAddress
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    business {
      id
      organizationId
      telegramChatId
      telegramConnections {
        chatId
        name
        __typename
      }
      group
      name
      address
      city
      email
      phone
      insuranceCoverageZone {
        start
        end
        __typename
      }
      insuranceCoverageZones {
        start
        end
        __typename
      }
      importIdentifier
      categoryDynamicPricings {
        categoryId
        pricingId
        percentage
        __typename
      }
      startLocations {
        id
        name
        locationColor
        companyId
        __typename
      }
      pickupLocations {
        id
        name
        locationColor
        companyId
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      __typename
    }
    businessByOrg {
      items {
        id
        organizationId
        telegramChatId
        group
        name
        address
        city
        email
        phone
        importIdentifier
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization(
  $input: CreateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  createOrganization(input: $input, condition: $condition) {
    id
    name
    billingCustomers {
      name
      __typename
    }
    features {
      enableNPS
      enableBusinessWideReservation
      __typename
    }
    createdAt
    updatedAt
    businesses {
      items {
        id
        organizationId
        telegramChatId
        group
        name
        address
        city
        email
        phone
        importIdentifier
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization(
  $input: UpdateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  updateOrganization(input: $input, condition: $condition) {
    id
    name
    billingCustomers {
      name
      __typename
    }
    features {
      enableNPS
      enableBusinessWideReservation
      __typename
    }
    createdAt
    updatedAt
    businesses {
      items {
        id
        organizationId
        telegramChatId
        group
        name
        address
        city
        email
        phone
        importIdentifier
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization(
  $input: DeleteOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  deleteOrganization(input: $input, condition: $condition) {
    id
    name
    billingCustomers {
      name
      __typename
    }
    features {
      enableNPS
      enableBusinessWideReservation
      __typename
    }
    createdAt
    updatedAt
    businesses {
      items {
        id
        organizationId
        telegramChatId
        group
        name
        address
        city
        email
        phone
        importIdentifier
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const createExternalBusiness = /* GraphQL */ `mutation CreateExternalBusiness(
  $input: CreateExternalBusinessInput!
  $condition: ModelExternalBusinessConditionInput
) {
  createExternalBusiness(input: $input, condition: $condition) {
    id
    organizationId
    type
    name
    insuranceProducts {
      name
      coveragePercentage
      compensatableDays
      billingPermitCode
      __typename
    }
    insuranceRegionalCompanies {
      name
      companyName
      businessId
      address
      postalCode
      postalArea
      email
      innovoiceId
      __typename
    }
    insuranceCompensatableTimes
    services {
      description
      price
      category
      deductibleValue
      __typename
    }
    useHansel
    hanselId
    availableOrgBusinessIds
    availableCompanyIds
    availableVehicleClasses
    eBillingRef
    eBillingOperator
    eBillingAddress
    createdAt
    updatedAt
    companies {
      items {
        id
        organizationId
        orgBusinessId
        group
        name
        displayName
        email
        address
        postalCode
        city
        orderNumber
        orderNumberDealer
        phone
        businessId
        termsUrl
        timezone
        signConfirmationEmail
        nameInEmail
        senderNameSMS
        confirmationMsg
        feedbackMsg
        statusLinkMsg
        livionDeviceId
        livionDeviceType
        enableReservationDeposit
        reservationLinkExpirationHours
        disableOffHourReturn
        disableMultiday
        disableSameDay
        disableStartDaysFromNow
        enableCart
        enableMonthlyView
        enableRegistrationPlate
        enableVehicleStartReturnTimes
        enableMustSign
        enablePaymentOnlineOnly
        enableGTM
        enableNetPromoterScore
        enableNPS
        limitReservationOncePerDay
        hidden
        locationType
        offHourAvailability
        offHourAvailabilityFee
        deliveryFee
        deliveryBufferHours
        notAllowedCategoryIds
        importIdentifier
        disableReservationCategoryIds
        locationColor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pricings {
      items {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExternalBusinessMutationVariables,
  APITypes.CreateExternalBusinessMutation
>;
export const updateExternalBusiness = /* GraphQL */ `mutation UpdateExternalBusiness(
  $input: UpdateExternalBusinessInput!
  $condition: ModelExternalBusinessConditionInput
) {
  updateExternalBusiness(input: $input, condition: $condition) {
    id
    organizationId
    type
    name
    insuranceProducts {
      name
      coveragePercentage
      compensatableDays
      billingPermitCode
      __typename
    }
    insuranceRegionalCompanies {
      name
      companyName
      businessId
      address
      postalCode
      postalArea
      email
      innovoiceId
      __typename
    }
    insuranceCompensatableTimes
    services {
      description
      price
      category
      deductibleValue
      __typename
    }
    useHansel
    hanselId
    availableOrgBusinessIds
    availableCompanyIds
    availableVehicleClasses
    eBillingRef
    eBillingOperator
    eBillingAddress
    createdAt
    updatedAt
    companies {
      items {
        id
        organizationId
        orgBusinessId
        group
        name
        displayName
        email
        address
        postalCode
        city
        orderNumber
        orderNumberDealer
        phone
        businessId
        termsUrl
        timezone
        signConfirmationEmail
        nameInEmail
        senderNameSMS
        confirmationMsg
        feedbackMsg
        statusLinkMsg
        livionDeviceId
        livionDeviceType
        enableReservationDeposit
        reservationLinkExpirationHours
        disableOffHourReturn
        disableMultiday
        disableSameDay
        disableStartDaysFromNow
        enableCart
        enableMonthlyView
        enableRegistrationPlate
        enableVehicleStartReturnTimes
        enableMustSign
        enablePaymentOnlineOnly
        enableGTM
        enableNetPromoterScore
        enableNPS
        limitReservationOncePerDay
        hidden
        locationType
        offHourAvailability
        offHourAvailabilityFee
        deliveryFee
        deliveryBufferHours
        notAllowedCategoryIds
        importIdentifier
        disableReservationCategoryIds
        locationColor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pricings {
      items {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExternalBusinessMutationVariables,
  APITypes.UpdateExternalBusinessMutation
>;
export const deleteExternalBusiness = /* GraphQL */ `mutation DeleteExternalBusiness(
  $input: DeleteExternalBusinessInput!
  $condition: ModelExternalBusinessConditionInput
) {
  deleteExternalBusiness(input: $input, condition: $condition) {
    id
    organizationId
    type
    name
    insuranceProducts {
      name
      coveragePercentage
      compensatableDays
      billingPermitCode
      __typename
    }
    insuranceRegionalCompanies {
      name
      companyName
      businessId
      address
      postalCode
      postalArea
      email
      innovoiceId
      __typename
    }
    insuranceCompensatableTimes
    services {
      description
      price
      category
      deductibleValue
      __typename
    }
    useHansel
    hanselId
    availableOrgBusinessIds
    availableCompanyIds
    availableVehicleClasses
    eBillingRef
    eBillingOperator
    eBillingAddress
    createdAt
    updatedAt
    companies {
      items {
        id
        organizationId
        orgBusinessId
        group
        name
        displayName
        email
        address
        postalCode
        city
        orderNumber
        orderNumberDealer
        phone
        businessId
        termsUrl
        timezone
        signConfirmationEmail
        nameInEmail
        senderNameSMS
        confirmationMsg
        feedbackMsg
        statusLinkMsg
        livionDeviceId
        livionDeviceType
        enableReservationDeposit
        reservationLinkExpirationHours
        disableOffHourReturn
        disableMultiday
        disableSameDay
        disableStartDaysFromNow
        enableCart
        enableMonthlyView
        enableRegistrationPlate
        enableVehicleStartReturnTimes
        enableMustSign
        enablePaymentOnlineOnly
        enableGTM
        enableNetPromoterScore
        enableNPS
        limitReservationOncePerDay
        hidden
        locationType
        offHourAvailability
        offHourAvailabilityFee
        deliveryFee
        deliveryBufferHours
        notAllowedCategoryIds
        importIdentifier
        disableReservationCategoryIds
        locationColor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pricings {
      items {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExternalBusinessMutationVariables,
  APITypes.DeleteExternalBusinessMutation
>;
export const createBusiness = /* GraphQL */ `mutation CreateBusiness(
  $input: CreateBusinessInput!
  $condition: ModelBusinessConditionInput
) {
  createBusiness(input: $input, condition: $condition) {
    id
    organizationId
    telegramChatId
    telegramConnections {
      chatId
      name
      __typename
    }
    group
    name
    address
    city
    email
    phone
    insuranceCoverageZone {
      start
      end
      __typename
    }
    insuranceCoverageZones {
      start
      end
      __typename
    }
    importIdentifier
    categoryDynamicPricings {
      categoryId
      pricingId
      percentage
      __typename
    }
    startLocations {
      id
      name
      locationColor
      companyId
      __typename
    }
    pickupLocations {
      id
      name
      locationColor
      companyId
      __typename
    }
    createdAt
    updatedAt
    companies {
      items {
        id
        organizationId
        orgBusinessId
        group
        name
        displayName
        email
        address
        postalCode
        city
        orderNumber
        orderNumberDealer
        phone
        businessId
        termsUrl
        timezone
        signConfirmationEmail
        nameInEmail
        senderNameSMS
        confirmationMsg
        feedbackMsg
        statusLinkMsg
        livionDeviceId
        livionDeviceType
        enableReservationDeposit
        reservationLinkExpirationHours
        disableOffHourReturn
        disableMultiday
        disableSameDay
        disableStartDaysFromNow
        enableCart
        enableMonthlyView
        enableRegistrationPlate
        enableVehicleStartReturnTimes
        enableMustSign
        enablePaymentOnlineOnly
        enableGTM
        enableNetPromoterScore
        enableNPS
        limitReservationOncePerDay
        hidden
        locationType
        offHourAvailability
        offHourAvailabilityFee
        deliveryFee
        deliveryBufferHours
        notAllowedCategoryIds
        importIdentifier
        disableReservationCategoryIds
        locationColor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    categories {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBusinessMutationVariables,
  APITypes.CreateBusinessMutation
>;
export const updateBusiness = /* GraphQL */ `mutation UpdateBusiness(
  $input: UpdateBusinessInput!
  $condition: ModelBusinessConditionInput
) {
  updateBusiness(input: $input, condition: $condition) {
    id
    organizationId
    telegramChatId
    telegramConnections {
      chatId
      name
      __typename
    }
    group
    name
    address
    city
    email
    phone
    insuranceCoverageZone {
      start
      end
      __typename
    }
    insuranceCoverageZones {
      start
      end
      __typename
    }
    importIdentifier
    categoryDynamicPricings {
      categoryId
      pricingId
      percentage
      __typename
    }
    startLocations {
      id
      name
      locationColor
      companyId
      __typename
    }
    pickupLocations {
      id
      name
      locationColor
      companyId
      __typename
    }
    createdAt
    updatedAt
    companies {
      items {
        id
        organizationId
        orgBusinessId
        group
        name
        displayName
        email
        address
        postalCode
        city
        orderNumber
        orderNumberDealer
        phone
        businessId
        termsUrl
        timezone
        signConfirmationEmail
        nameInEmail
        senderNameSMS
        confirmationMsg
        feedbackMsg
        statusLinkMsg
        livionDeviceId
        livionDeviceType
        enableReservationDeposit
        reservationLinkExpirationHours
        disableOffHourReturn
        disableMultiday
        disableSameDay
        disableStartDaysFromNow
        enableCart
        enableMonthlyView
        enableRegistrationPlate
        enableVehicleStartReturnTimes
        enableMustSign
        enablePaymentOnlineOnly
        enableGTM
        enableNetPromoterScore
        enableNPS
        limitReservationOncePerDay
        hidden
        locationType
        offHourAvailability
        offHourAvailabilityFee
        deliveryFee
        deliveryBufferHours
        notAllowedCategoryIds
        importIdentifier
        disableReservationCategoryIds
        locationColor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    categories {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessMutationVariables,
  APITypes.UpdateBusinessMutation
>;
export const deleteBusiness = /* GraphQL */ `mutation DeleteBusiness(
  $input: DeleteBusinessInput!
  $condition: ModelBusinessConditionInput
) {
  deleteBusiness(input: $input, condition: $condition) {
    id
    organizationId
    telegramChatId
    telegramConnections {
      chatId
      name
      __typename
    }
    group
    name
    address
    city
    email
    phone
    insuranceCoverageZone {
      start
      end
      __typename
    }
    insuranceCoverageZones {
      start
      end
      __typename
    }
    importIdentifier
    categoryDynamicPricings {
      categoryId
      pricingId
      percentage
      __typename
    }
    startLocations {
      id
      name
      locationColor
      companyId
      __typename
    }
    pickupLocations {
      id
      name
      locationColor
      companyId
      __typename
    }
    createdAt
    updatedAt
    companies {
      items {
        id
        organizationId
        orgBusinessId
        group
        name
        displayName
        email
        address
        postalCode
        city
        orderNumber
        orderNumberDealer
        phone
        businessId
        termsUrl
        timezone
        signConfirmationEmail
        nameInEmail
        senderNameSMS
        confirmationMsg
        feedbackMsg
        statusLinkMsg
        livionDeviceId
        livionDeviceType
        enableReservationDeposit
        reservationLinkExpirationHours
        disableOffHourReturn
        disableMultiday
        disableSameDay
        disableStartDaysFromNow
        enableCart
        enableMonthlyView
        enableRegistrationPlate
        enableVehicleStartReturnTimes
        enableMustSign
        enablePaymentOnlineOnly
        enableGTM
        enableNetPromoterScore
        enableNPS
        limitReservationOncePerDay
        hidden
        locationType
        offHourAvailability
        offHourAvailabilityFee
        deliveryFee
        deliveryBufferHours
        notAllowedCategoryIds
        importIdentifier
        disableReservationCategoryIds
        locationColor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    categories {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBusinessMutationVariables,
  APITypes.DeleteBusinessMutation
>;
export const createReservation = /* GraphQL */ `mutation CreateReservation(
  $input: CreateReservationInput!
  $condition: ModelReservationConditionInput
) {
  createReservation(input: $input, condition: $condition) {
    id
    shortId
    language
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    companyId
    returnCompanyId
    startLocation
    returnLocation
    startLocationID
    returnLocationID
    reviewId
    customerBrokerId
    customerCompanyId
    customerPersonalId
    reservationVehicleId
    reservationVehicles {
      id
      price
      tax
      name
      registrationPlate
      deductible
      includedDistance
      powerType
      mileage
      startMileage
      endMileage
      extraInfo
      damages
      hideExport
      createdAt
      addedAt
      categoryName
      __typename
    }
    wildCardVehicles {
      name
      price
      tax
      categoryId
      quantity
      deductible
      __typename
    }
    createdAt
    updatedAt
    startTime
    returnTime
    endTime
    name
    address
    postalCode
    city
    phone
    email
    billingRef
    ssn
    rentalNotes
    internalNotes
    externalNotes
    outOfCommission
    vehiclePrice
    additionalServices {
      key
      price
      details
      quantity
      type
      deductibleValue
      groupFields
      tax
      enableNotification
      description
      category
      __typename
    }
    driverSSN
    differentDriver
    driverName
    driverAddress
    driverPostalCode
    driverCity
    driverPhone
    driverEmail
    additionalDriver
    additionalDriverName
    additionalDriverPhone
    additionalDriverSSN
    billPayment
    isCompany
    carStatus
    rentalStatus
    innovoiceStatus
    companyName
    companyBusinessId
    companyCity
    companyPostalCode
    companyBillingRef
    companyBillingAddress
    companyEBillingOperator
    companyEBillingAddress
    paymentToken
    npsStatus
    paymentStatus
    paymentData {
      paymentTotal
      paymentTodo
      depositPending
      deposit
      __typename
    }
    signStatus
    signDocumentId
    signerName
    reviewStatus
    authStatus
    authTime
    completeBefore
    mustSign
    livionData {
      id
      pincode
      keyId
      name
      registrationPlate
      __typename
    }
    tilakoneData {
      access_id
      begin_timestamp
      end_timestamp
      pincode
      __typename
    }
    voucherData {
      name
      code
      amount
      __typename
    }
    customerCarRegistrationPlate
    insuranceData {
      customerContact
      urgent
      customerCarModel
      customerCarMaker
      product
      coveragePercentage
      compensatableDays
      billingPermitCode
      coverageDuration
      providerCompany
      providerBusinessId
      claimAdjusterName
      claimAdjusterEmail
      receipt
      replacementVehicleClass
      claimId
      vatDeductible
      deliveryCosts
      returnCosts
      __typename
    }
    externalData {
      orderId
      orderReference
      serviceAgreementName
      operatorName
      orderInfo
      billingPayer
      billingDescription
      customerVehicle {
        brand
        model
        registrationPlate
        __typename
      }
      incidentLocation {
        description
        address
        city
        zipCode
        __typename
      }
      repairShop {
        description
        address
        city
        zipCode
        __typename
      }
      __typename
    }
    enableRegistrationPlate
    disablePricesInPrintouts
    hanselData {
      contractNro
      customerInvoiceId
      customerInvoiceDate
      hanselAgent
      hanselClass
      customerId
      subContractorNumber
      subContractorName
      suplierProductId
      suplierProductName
      quantity
      price
      totalPrice
      priceNoVAT
      additionalInfo1
      additionalInfo2
      additionalInfo3
      desc
      __typename
    }
    invoiceIds
    consolidatedInvoiceIds
    channel
    originURL
    type
    status
    reservationStatusInfo {
      deliveryTimestamp
      deliveryAddress
      deliveryZipcode
      deliveryCity
      deliveryCountry
      deliveryDescription
      returnTimestamp
      returnAddress
      returnZipcode
      returnCity
      returnCountry
      returnDescription
      cancelReason
      __typename
    }
    initialCategory {
      id
      name
      hanselClass
      __typename
    }
    initialClassInfo {
      categoryId
      categoryName
      hanselClass
      __typename
    }
    orgInvoiceCustomer {
      name
      businessId
      __typename
    }
    pricingCategoryId
    customImportDate
    vehicle {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    invoices {
      items {
        id
        createdAt
        innovoiceId
        reservationId
        reservationIds
        companyId
        innovoiceCompanyId
        innovoiceuRentCarRentalId
        innovoiceOrderId
        group
        organizationId
        orgBusinessId
        type
        creditNoteInvoiceId
        payment_terms
        vat_included
        delivery_by
        invoice_type
        delivery_date
        seller_reference
        buyer_reference
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    paymentEvents {
      items {
        id
        channel
        paymentMethod
        paymentTotal
        isDeleted
        deletedBy
        deletedAt
        createdByUserID
        createdByUserName
        reservationId
        organizationId
        orgBusinessId
        group
        msg
        state
        returnCode
        incidentId
        parameters
        apiPath
        paymentStatus
        orderNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReservationMutationVariables,
  APITypes.CreateReservationMutation
>;
export const updateReservation = /* GraphQL */ `mutation UpdateReservation(
  $input: UpdateReservationInput!
  $condition: ModelReservationConditionInput
) {
  updateReservation(input: $input, condition: $condition) {
    id
    shortId
    language
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    companyId
    returnCompanyId
    startLocation
    returnLocation
    startLocationID
    returnLocationID
    reviewId
    customerBrokerId
    customerCompanyId
    customerPersonalId
    reservationVehicleId
    reservationVehicles {
      id
      price
      tax
      name
      registrationPlate
      deductible
      includedDistance
      powerType
      mileage
      startMileage
      endMileage
      extraInfo
      damages
      hideExport
      createdAt
      addedAt
      categoryName
      __typename
    }
    wildCardVehicles {
      name
      price
      tax
      categoryId
      quantity
      deductible
      __typename
    }
    createdAt
    updatedAt
    startTime
    returnTime
    endTime
    name
    address
    postalCode
    city
    phone
    email
    billingRef
    ssn
    rentalNotes
    internalNotes
    externalNotes
    outOfCommission
    vehiclePrice
    additionalServices {
      key
      price
      details
      quantity
      type
      deductibleValue
      groupFields
      tax
      enableNotification
      description
      category
      __typename
    }
    driverSSN
    differentDriver
    driverName
    driverAddress
    driverPostalCode
    driverCity
    driverPhone
    driverEmail
    additionalDriver
    additionalDriverName
    additionalDriverPhone
    additionalDriverSSN
    billPayment
    isCompany
    carStatus
    rentalStatus
    innovoiceStatus
    companyName
    companyBusinessId
    companyCity
    companyPostalCode
    companyBillingRef
    companyBillingAddress
    companyEBillingOperator
    companyEBillingAddress
    paymentToken
    npsStatus
    paymentStatus
    paymentData {
      paymentTotal
      paymentTodo
      depositPending
      deposit
      __typename
    }
    signStatus
    signDocumentId
    signerName
    reviewStatus
    authStatus
    authTime
    completeBefore
    mustSign
    livionData {
      id
      pincode
      keyId
      name
      registrationPlate
      __typename
    }
    tilakoneData {
      access_id
      begin_timestamp
      end_timestamp
      pincode
      __typename
    }
    voucherData {
      name
      code
      amount
      __typename
    }
    customerCarRegistrationPlate
    insuranceData {
      customerContact
      urgent
      customerCarModel
      customerCarMaker
      product
      coveragePercentage
      compensatableDays
      billingPermitCode
      coverageDuration
      providerCompany
      providerBusinessId
      claimAdjusterName
      claimAdjusterEmail
      receipt
      replacementVehicleClass
      claimId
      vatDeductible
      deliveryCosts
      returnCosts
      __typename
    }
    externalData {
      orderId
      orderReference
      serviceAgreementName
      operatorName
      orderInfo
      billingPayer
      billingDescription
      customerVehicle {
        brand
        model
        registrationPlate
        __typename
      }
      incidentLocation {
        description
        address
        city
        zipCode
        __typename
      }
      repairShop {
        description
        address
        city
        zipCode
        __typename
      }
      __typename
    }
    enableRegistrationPlate
    disablePricesInPrintouts
    hanselData {
      contractNro
      customerInvoiceId
      customerInvoiceDate
      hanselAgent
      hanselClass
      customerId
      subContractorNumber
      subContractorName
      suplierProductId
      suplierProductName
      quantity
      price
      totalPrice
      priceNoVAT
      additionalInfo1
      additionalInfo2
      additionalInfo3
      desc
      __typename
    }
    invoiceIds
    consolidatedInvoiceIds
    channel
    originURL
    type
    status
    reservationStatusInfo {
      deliveryTimestamp
      deliveryAddress
      deliveryZipcode
      deliveryCity
      deliveryCountry
      deliveryDescription
      returnTimestamp
      returnAddress
      returnZipcode
      returnCity
      returnCountry
      returnDescription
      cancelReason
      __typename
    }
    initialCategory {
      id
      name
      hanselClass
      __typename
    }
    initialClassInfo {
      categoryId
      categoryName
      hanselClass
      __typename
    }
    orgInvoiceCustomer {
      name
      businessId
      __typename
    }
    pricingCategoryId
    customImportDate
    vehicle {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    invoices {
      items {
        id
        createdAt
        innovoiceId
        reservationId
        reservationIds
        companyId
        innovoiceCompanyId
        innovoiceuRentCarRentalId
        innovoiceOrderId
        group
        organizationId
        orgBusinessId
        type
        creditNoteInvoiceId
        payment_terms
        vat_included
        delivery_by
        invoice_type
        delivery_date
        seller_reference
        buyer_reference
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    paymentEvents {
      items {
        id
        channel
        paymentMethod
        paymentTotal
        isDeleted
        deletedBy
        deletedAt
        createdByUserID
        createdByUserName
        reservationId
        organizationId
        orgBusinessId
        group
        msg
        state
        returnCode
        incidentId
        parameters
        apiPath
        paymentStatus
        orderNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReservationMutationVariables,
  APITypes.UpdateReservationMutation
>;
export const deleteReservation = /* GraphQL */ `mutation DeleteReservation(
  $input: DeleteReservationInput!
  $condition: ModelReservationConditionInput
) {
  deleteReservation(input: $input, condition: $condition) {
    id
    shortId
    language
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    companyId
    returnCompanyId
    startLocation
    returnLocation
    startLocationID
    returnLocationID
    reviewId
    customerBrokerId
    customerCompanyId
    customerPersonalId
    reservationVehicleId
    reservationVehicles {
      id
      price
      tax
      name
      registrationPlate
      deductible
      includedDistance
      powerType
      mileage
      startMileage
      endMileage
      extraInfo
      damages
      hideExport
      createdAt
      addedAt
      categoryName
      __typename
    }
    wildCardVehicles {
      name
      price
      tax
      categoryId
      quantity
      deductible
      __typename
    }
    createdAt
    updatedAt
    startTime
    returnTime
    endTime
    name
    address
    postalCode
    city
    phone
    email
    billingRef
    ssn
    rentalNotes
    internalNotes
    externalNotes
    outOfCommission
    vehiclePrice
    additionalServices {
      key
      price
      details
      quantity
      type
      deductibleValue
      groupFields
      tax
      enableNotification
      description
      category
      __typename
    }
    driverSSN
    differentDriver
    driverName
    driverAddress
    driverPostalCode
    driverCity
    driverPhone
    driverEmail
    additionalDriver
    additionalDriverName
    additionalDriverPhone
    additionalDriverSSN
    billPayment
    isCompany
    carStatus
    rentalStatus
    innovoiceStatus
    companyName
    companyBusinessId
    companyCity
    companyPostalCode
    companyBillingRef
    companyBillingAddress
    companyEBillingOperator
    companyEBillingAddress
    paymentToken
    npsStatus
    paymentStatus
    paymentData {
      paymentTotal
      paymentTodo
      depositPending
      deposit
      __typename
    }
    signStatus
    signDocumentId
    signerName
    reviewStatus
    authStatus
    authTime
    completeBefore
    mustSign
    livionData {
      id
      pincode
      keyId
      name
      registrationPlate
      __typename
    }
    tilakoneData {
      access_id
      begin_timestamp
      end_timestamp
      pincode
      __typename
    }
    voucherData {
      name
      code
      amount
      __typename
    }
    customerCarRegistrationPlate
    insuranceData {
      customerContact
      urgent
      customerCarModel
      customerCarMaker
      product
      coveragePercentage
      compensatableDays
      billingPermitCode
      coverageDuration
      providerCompany
      providerBusinessId
      claimAdjusterName
      claimAdjusterEmail
      receipt
      replacementVehicleClass
      claimId
      vatDeductible
      deliveryCosts
      returnCosts
      __typename
    }
    externalData {
      orderId
      orderReference
      serviceAgreementName
      operatorName
      orderInfo
      billingPayer
      billingDescription
      customerVehicle {
        brand
        model
        registrationPlate
        __typename
      }
      incidentLocation {
        description
        address
        city
        zipCode
        __typename
      }
      repairShop {
        description
        address
        city
        zipCode
        __typename
      }
      __typename
    }
    enableRegistrationPlate
    disablePricesInPrintouts
    hanselData {
      contractNro
      customerInvoiceId
      customerInvoiceDate
      hanselAgent
      hanselClass
      customerId
      subContractorNumber
      subContractorName
      suplierProductId
      suplierProductName
      quantity
      price
      totalPrice
      priceNoVAT
      additionalInfo1
      additionalInfo2
      additionalInfo3
      desc
      __typename
    }
    invoiceIds
    consolidatedInvoiceIds
    channel
    originURL
    type
    status
    reservationStatusInfo {
      deliveryTimestamp
      deliveryAddress
      deliveryZipcode
      deliveryCity
      deliveryCountry
      deliveryDescription
      returnTimestamp
      returnAddress
      returnZipcode
      returnCity
      returnCountry
      returnDescription
      cancelReason
      __typename
    }
    initialCategory {
      id
      name
      hanselClass
      __typename
    }
    initialClassInfo {
      categoryId
      categoryName
      hanselClass
      __typename
    }
    orgInvoiceCustomer {
      name
      businessId
      __typename
    }
    pricingCategoryId
    customImportDate
    vehicle {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    invoices {
      items {
        id
        createdAt
        innovoiceId
        reservationId
        reservationIds
        companyId
        innovoiceCompanyId
        innovoiceuRentCarRentalId
        innovoiceOrderId
        group
        organizationId
        orgBusinessId
        type
        creditNoteInvoiceId
        payment_terms
        vat_included
        delivery_by
        invoice_type
        delivery_date
        seller_reference
        buyer_reference
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    paymentEvents {
      items {
        id
        channel
        paymentMethod
        paymentTotal
        isDeleted
        deletedBy
        deletedAt
        createdByUserID
        createdByUserName
        reservationId
        organizationId
        orgBusinessId
        group
        msg
        state
        returnCode
        incidentId
        parameters
        apiPath
        paymentStatus
        orderNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReservationMutationVariables,
  APITypes.DeleteReservationMutation
>;
export const createReservationCount = /* GraphQL */ `mutation CreateReservationCount(
  $input: CreateReservationCountInput!
  $condition: ModelReservationCountConditionInput
) {
  createReservationCount(input: $input, condition: $condition) {
    tenant
    date
    count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReservationCountMutationVariables,
  APITypes.CreateReservationCountMutation
>;
export const updateReservationCount = /* GraphQL */ `mutation UpdateReservationCount(
  $input: UpdateReservationCountInput!
  $condition: ModelReservationCountConditionInput
) {
  updateReservationCount(input: $input, condition: $condition) {
    tenant
    date
    count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReservationCountMutationVariables,
  APITypes.UpdateReservationCountMutation
>;
export const deleteReservationCount = /* GraphQL */ `mutation DeleteReservationCount(
  $input: DeleteReservationCountInput!
  $condition: ModelReservationCountConditionInput
) {
  deleteReservationCount(input: $input, condition: $condition) {
    tenant
    date
    count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReservationCountMutationVariables,
  APITypes.DeleteReservationCountMutation
>;
export const createInvoice = /* GraphQL */ `mutation CreateInvoice(
  $input: CreateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  createInvoice(input: $input, condition: $condition) {
    id
    createdAt
    innovoiceId
    reservationId
    reservationIds
    companyId
    innovoiceCompanyId
    innovoiceuRentCarRentalId
    innovoiceOrderId
    group
    organizationId
    orgBusinessId
    type
    creditNoteInvoiceId
    payment_terms
    vat_included
    delivery_by
    invoice_type
    delivery_date
    seller_reference
    buyer_reference
    items {
      name
      description
      quantity
      unit
      unitprice
      discount
      vat
      type
      insuranceItemType
      reservationId
      __typename
    }
    customer {
      customer_type
      firstname
      lastname
      ssn
      address
      zipcode
      city
      email
      phone
      company_name
      contact_person
      e_invoice_operator
      e_invoice_address
      __typename
    }
    insurance {
      insurance_company_id
      vehicle_class
      vehicle_regno
      claim_no
      rental_start_date
      rental_end_date
      rental_days
      amount_cost_per_day
      amount_to_pay_customer
      amount_to_pay_insurance
      coveragePercentage
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvoiceMutationVariables,
  APITypes.CreateInvoiceMutation
>;
export const updateInvoice = /* GraphQL */ `mutation UpdateInvoice(
  $input: UpdateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  updateInvoice(input: $input, condition: $condition) {
    id
    createdAt
    innovoiceId
    reservationId
    reservationIds
    companyId
    innovoiceCompanyId
    innovoiceuRentCarRentalId
    innovoiceOrderId
    group
    organizationId
    orgBusinessId
    type
    creditNoteInvoiceId
    payment_terms
    vat_included
    delivery_by
    invoice_type
    delivery_date
    seller_reference
    buyer_reference
    items {
      name
      description
      quantity
      unit
      unitprice
      discount
      vat
      type
      insuranceItemType
      reservationId
      __typename
    }
    customer {
      customer_type
      firstname
      lastname
      ssn
      address
      zipcode
      city
      email
      phone
      company_name
      contact_person
      e_invoice_operator
      e_invoice_address
      __typename
    }
    insurance {
      insurance_company_id
      vehicle_class
      vehicle_regno
      claim_no
      rental_start_date
      rental_end_date
      rental_days
      amount_cost_per_day
      amount_to_pay_customer
      amount_to_pay_insurance
      coveragePercentage
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvoiceMutationVariables,
  APITypes.UpdateInvoiceMutation
>;
export const deleteInvoice = /* GraphQL */ `mutation DeleteInvoice(
  $input: DeleteInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  deleteInvoice(input: $input, condition: $condition) {
    id
    createdAt
    innovoiceId
    reservationId
    reservationIds
    companyId
    innovoiceCompanyId
    innovoiceuRentCarRentalId
    innovoiceOrderId
    group
    organizationId
    orgBusinessId
    type
    creditNoteInvoiceId
    payment_terms
    vat_included
    delivery_by
    invoice_type
    delivery_date
    seller_reference
    buyer_reference
    items {
      name
      description
      quantity
      unit
      unitprice
      discount
      vat
      type
      insuranceItemType
      reservationId
      __typename
    }
    customer {
      customer_type
      firstname
      lastname
      ssn
      address
      zipcode
      city
      email
      phone
      company_name
      contact_person
      e_invoice_operator
      e_invoice_address
      __typename
    }
    insurance {
      insurance_company_id
      vehicle_class
      vehicle_regno
      claim_no
      rental_start_date
      rental_end_date
      rental_days
      amount_cost_per_day
      amount_to_pay_customer
      amount_to_pay_insurance
      coveragePercentage
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvoiceMutationVariables,
  APITypes.DeleteInvoiceMutation
>;
export const createSignEvent = /* GraphQL */ `mutation CreateSignEvent(
  $input: CreateSignEventInput!
  $condition: ModelSignEventConditionInput
) {
  createSignEvent(input: $input, condition: $condition) {
    id
    reservationId
    company
    organizationId
    orgBusinessId
    group
    status
    documentId
    source
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSignEventMutationVariables,
  APITypes.CreateSignEventMutation
>;
export const updateSignEvent = /* GraphQL */ `mutation UpdateSignEvent(
  $input: UpdateSignEventInput!
  $condition: ModelSignEventConditionInput
) {
  updateSignEvent(input: $input, condition: $condition) {
    id
    reservationId
    company
    organizationId
    orgBusinessId
    group
    status
    documentId
    source
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSignEventMutationVariables,
  APITypes.UpdateSignEventMutation
>;
export const deleteSignEvent = /* GraphQL */ `mutation DeleteSignEvent(
  $input: DeleteSignEventInput!
  $condition: ModelSignEventConditionInput
) {
  deleteSignEvent(input: $input, condition: $condition) {
    id
    reservationId
    company
    organizationId
    orgBusinessId
    group
    status
    documentId
    source
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSignEventMutationVariables,
  APITypes.DeleteSignEventMutation
>;
export const createPaymentEvent = /* GraphQL */ `mutation CreatePaymentEvent(
  $input: CreatePaymentEventInput!
  $condition: ModelPaymentEventConditionInput
) {
  createPaymentEvent(input: $input, condition: $condition) {
    id
    channel
    paymentMethod
    paymentTotal
    isDeleted
    deletedBy
    deletedAt
    createdByUserID
    createdByUserName
    reservationId
    organizationId
    orgBusinessId
    group
    msg
    state
    returnCode
    incidentId
    parameters
    apiPath
    paymentStatus
    orderNumber
    createdAt
    updatedAt
    reservation {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentEventMutationVariables,
  APITypes.CreatePaymentEventMutation
>;
export const updatePaymentEvent = /* GraphQL */ `mutation UpdatePaymentEvent(
  $input: UpdatePaymentEventInput!
  $condition: ModelPaymentEventConditionInput
) {
  updatePaymentEvent(input: $input, condition: $condition) {
    id
    channel
    paymentMethod
    paymentTotal
    isDeleted
    deletedBy
    deletedAt
    createdByUserID
    createdByUserName
    reservationId
    organizationId
    orgBusinessId
    group
    msg
    state
    returnCode
    incidentId
    parameters
    apiPath
    paymentStatus
    orderNumber
    createdAt
    updatedAt
    reservation {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentEventMutationVariables,
  APITypes.UpdatePaymentEventMutation
>;
export const deletePaymentEvent = /* GraphQL */ `mutation DeletePaymentEvent(
  $input: DeletePaymentEventInput!
  $condition: ModelPaymentEventConditionInput
) {
  deletePaymentEvent(input: $input, condition: $condition) {
    id
    channel
    paymentMethod
    paymentTotal
    isDeleted
    deletedBy
    deletedAt
    createdByUserID
    createdByUserName
    reservationId
    organizationId
    orgBusinessId
    group
    msg
    state
    returnCode
    incidentId
    parameters
    apiPath
    paymentStatus
    orderNumber
    createdAt
    updatedAt
    reservation {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentEventMutationVariables,
  APITypes.DeletePaymentEventMutation
>;
export const createAdditionalService = /* GraphQL */ `mutation CreateAdditionalService(
  $input: CreateAdditionalServiceInput!
  $condition: ModelAdditionalServiceConditionInput
) {
  createAdditionalService(input: $input, condition: $condition) {
    id
    organizationId
    orgBusinessId
    group
    description
    descriptionT {
      fi
      en
      sv
      __typename
    }
    description2
    category
    type
    enableTextInput
    enableQuantity
    enableNotification
    maxQuantity
    inputLabel
    _removed
    isGroupParent
    groupFields
    groupParentId
    groupOrderNumber
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdditionalServiceMutationVariables,
  APITypes.CreateAdditionalServiceMutation
>;
export const updateAdditionalService = /* GraphQL */ `mutation UpdateAdditionalService(
  $input: UpdateAdditionalServiceInput!
  $condition: ModelAdditionalServiceConditionInput
) {
  updateAdditionalService(input: $input, condition: $condition) {
    id
    organizationId
    orgBusinessId
    group
    description
    descriptionT {
      fi
      en
      sv
      __typename
    }
    description2
    category
    type
    enableTextInput
    enableQuantity
    enableNotification
    maxQuantity
    inputLabel
    _removed
    isGroupParent
    groupFields
    groupParentId
    groupOrderNumber
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdditionalServiceMutationVariables,
  APITypes.UpdateAdditionalServiceMutation
>;
export const deleteAdditionalService = /* GraphQL */ `mutation DeleteAdditionalService(
  $input: DeleteAdditionalServiceInput!
  $condition: ModelAdditionalServiceConditionInput
) {
  deleteAdditionalService(input: $input, condition: $condition) {
    id
    organizationId
    orgBusinessId
    group
    description
    descriptionT {
      fi
      en
      sv
      __typename
    }
    description2
    category
    type
    enableTextInput
    enableQuantity
    enableNotification
    maxQuantity
    inputLabel
    _removed
    isGroupParent
    groupFields
    groupParentId
    groupOrderNumber
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdditionalServiceMutationVariables,
  APITypes.DeleteAdditionalServiceMutation
>;
export const createPricing = /* GraphQL */ `mutation CreatePricing(
  $input: CreatePricingInput!
  $condition: ModelPricingConditionInput
) {
  createPricing(input: $input, condition: $condition) {
    id
    name
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    externalBusinessCategoryAssociation
    businessCategoryAssociation
    elements {
      duration
      price
      __typename
    }
    useNextDayPrice
    useLastDayPrice
    tax
    _removed
    deductible
    reducedDeductible
    reducedDeductiblePrice
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePricingMutationVariables,
  APITypes.CreatePricingMutation
>;
export const updatePricing = /* GraphQL */ `mutation UpdatePricing(
  $input: UpdatePricingInput!
  $condition: ModelPricingConditionInput
) {
  updatePricing(input: $input, condition: $condition) {
    id
    name
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    externalBusinessCategoryAssociation
    businessCategoryAssociation
    elements {
      duration
      price
      __typename
    }
    useNextDayPrice
    useLastDayPrice
    tax
    _removed
    deductible
    reducedDeductible
    reducedDeductiblePrice
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePricingMutationVariables,
  APITypes.UpdatePricingMutation
>;
export const deletePricing = /* GraphQL */ `mutation DeletePricing(
  $input: DeletePricingInput!
  $condition: ModelPricingConditionInput
) {
  deletePricing(input: $input, condition: $condition) {
    id
    name
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    externalBusinessCategoryAssociation
    businessCategoryAssociation
    elements {
      duration
      price
      __typename
    }
    useNextDayPrice
    useLastDayPrice
    tax
    _removed
    deductible
    reducedDeductible
    reducedDeductiblePrice
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePricingMutationVariables,
  APITypes.DeletePricingMutation
>;
export const createPricingException = /* GraphQL */ `mutation CreatePricingException(
  $input: CreatePricingExceptionInput!
  $condition: ModelPricingExceptionConditionInput
) {
  createPricingException(input: $input, condition: $condition) {
    id
    name
    group
    organizationId
    orgBusinessId
    type
    amountType
    weeklyAmounts
    monthlyAmountPerDay
    daysBeforeMonthChange
    daysAfterMonthChange
    startDate
    endDate
    pricingIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePricingExceptionMutationVariables,
  APITypes.CreatePricingExceptionMutation
>;
export const updatePricingException = /* GraphQL */ `mutation UpdatePricingException(
  $input: UpdatePricingExceptionInput!
  $condition: ModelPricingExceptionConditionInput
) {
  updatePricingException(input: $input, condition: $condition) {
    id
    name
    group
    organizationId
    orgBusinessId
    type
    amountType
    weeklyAmounts
    monthlyAmountPerDay
    daysBeforeMonthChange
    daysAfterMonthChange
    startDate
    endDate
    pricingIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePricingExceptionMutationVariables,
  APITypes.UpdatePricingExceptionMutation
>;
export const deletePricingException = /* GraphQL */ `mutation DeletePricingException(
  $input: DeletePricingExceptionInput!
  $condition: ModelPricingExceptionConditionInput
) {
  deletePricingException(input: $input, condition: $condition) {
    id
    name
    group
    organizationId
    orgBusinessId
    type
    amountType
    weeklyAmounts
    monthlyAmountPerDay
    daysBeforeMonthChange
    daysAfterMonthChange
    startDate
    endDate
    pricingIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePricingExceptionMutationVariables,
  APITypes.DeletePricingExceptionMutation
>;
export const createVoucher = /* GraphQL */ `mutation CreateVoucher(
  $input: CreateVoucherInput!
  $condition: ModelVoucherConditionInput
) {
  createVoucher(input: $input, condition: $condition) {
    id
    name
    group
    organizationId
    code
    amount
    startDate
    endDate
    _removed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVoucherMutationVariables,
  APITypes.CreateVoucherMutation
>;
export const updateVoucher = /* GraphQL */ `mutation UpdateVoucher(
  $input: UpdateVoucherInput!
  $condition: ModelVoucherConditionInput
) {
  updateVoucher(input: $input, condition: $condition) {
    id
    name
    group
    organizationId
    code
    amount
    startDate
    endDate
    _removed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVoucherMutationVariables,
  APITypes.UpdateVoucherMutation
>;
export const deleteVoucher = /* GraphQL */ `mutation DeleteVoucher(
  $input: DeleteVoucherInput!
  $condition: ModelVoucherConditionInput
) {
  deleteVoucher(input: $input, condition: $condition) {
    id
    name
    group
    organizationId
    code
    amount
    startDate
    endDate
    _removed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVoucherMutationVariables,
  APITypes.DeleteVoucherMutation
>;
export const createReview = /* GraphQL */ `mutation CreateReview(
  $input: CreateReviewInput!
  $condition: ModelReviewConditionInput
) {
  createReview(input: $input, condition: $condition) {
    id
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    rating
    comment
    explanation
    reservationId
    companyId
    startTime
    returnTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const updateReview = /* GraphQL */ `mutation UpdateReview(
  $input: UpdateReviewInput!
  $condition: ModelReviewConditionInput
) {
  updateReview(input: $input, condition: $condition) {
    id
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    rating
    comment
    explanation
    reservationId
    companyId
    startTime
    returnTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewMutationVariables,
  APITypes.UpdateReviewMutation
>;
export const deleteReview = /* GraphQL */ `mutation DeleteReview(
  $input: DeleteReviewInput!
  $condition: ModelReviewConditionInput
) {
  deleteReview(input: $input, condition: $condition) {
    id
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    rating
    comment
    explanation
    reservationId
    companyId
    startTime
    returnTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewMutationVariables,
  APITypes.DeleteReviewMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $input: CreateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  createNotification(input: $input, condition: $condition) {
    id
    type
    status
    name
    message
    organizationId
    orgBusinessId
    group
    reservationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification(
  $input: UpdateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  updateNotification(input: $input, condition: $condition) {
    id
    type
    status
    name
    message
    organizationId
    orgBusinessId
    group
    reservationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification(
  $input: DeleteNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  deleteNotification(input: $input, condition: $condition) {
    id
    type
    status
    name
    message
    organizationId
    orgBusinessId
    group
    reservationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
export const createCustomer = /* GraphQL */ `mutation CreateCustomer(
  $input: CreateCustomerInput!
  $condition: ModelCustomerConditionInput
) {
  createCustomer(input: $input, condition: $condition) {
    id
    organizationId
    orgBusinessId
    orgExternalBusinessId
    group
    companyName
    brokerName
    address
    postalCode
    city
    phone
    email
    companyBillingAddress
    companyBusinessId
    companyCity
    companyPostalCode
    companyBillingRef
    companyEBillingOperator
    companyEBillingAddress
    clientType
    isDeleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomerMutationVariables,
  APITypes.CreateCustomerMutation
>;
export const updateCustomer = /* GraphQL */ `mutation UpdateCustomer(
  $input: UpdateCustomerInput!
  $condition: ModelCustomerConditionInput
) {
  updateCustomer(input: $input, condition: $condition) {
    id
    organizationId
    orgBusinessId
    orgExternalBusinessId
    group
    companyName
    brokerName
    address
    postalCode
    city
    phone
    email
    companyBillingAddress
    companyBusinessId
    companyCity
    companyPostalCode
    companyBillingRef
    companyEBillingOperator
    companyEBillingAddress
    clientType
    isDeleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerMutationVariables,
  APITypes.UpdateCustomerMutation
>;
export const deleteCustomer = /* GraphQL */ `mutation DeleteCustomer(
  $input: DeleteCustomerInput!
  $condition: ModelCustomerConditionInput
) {
  deleteCustomer(input: $input, condition: $condition) {
    id
    organizationId
    orgBusinessId
    orgExternalBusinessId
    group
    companyName
    brokerName
    address
    postalCode
    city
    phone
    email
    companyBillingAddress
    companyBusinessId
    companyCity
    companyPostalCode
    companyBillingRef
    companyEBillingOperator
    companyEBillingAddress
    clientType
    isDeleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomerMutationVariables,
  APITypes.DeleteCustomerMutation
>;
