import { Grid } from "@mui/material";
import React from "react";
import { FormInputField } from "../../../../../../components/ReservationForm/utils/FormInputField";
import FieldAutoComplete from "./FieldAutoComplete";
import { useFormikContext } from "formik";
import PhoneNumberField from "../../../../../../components/PhoneNumberField";
import { useGetRequiredFields } from "../../../../../../formik/requiredFields";

export default function ShowPrivateCustomerFields(props) {
  const selectedLanguage = "FIN";
  const { values } = useFormikContext();
  const { requiredFields } = useGetRequiredFields();

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6}>
        <FieldAutoComplete />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInputField
          name={"ssn"}
          label={fieldLabel[selectedLanguage].ssn}
          password={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PhoneNumberField
          fieldName={"phone"}
          required={requiredFields(values).includes("phone")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInputField
          name="email"
          label={fieldLabel[selectedLanguage].email}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputField
          name={"address"}
          label={fieldLabel[selectedLanguage].address}
        />
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} sm={6}>
          <FormInputField
            name={"city"}
            label={fieldLabel[selectedLanguage].city}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputField
            name={"postalCode"}
            label={fieldLabel[selectedLanguage].postalCode}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const EFormFields = {
  NAME: "name",
  SSN: "ssn",
  CITY: "city",
  EMAIL: "email",
  PHONE: "phone",
  IS_COMPANY: "isCompany",
  ADDRESS: "address",
  POSTAL_CODE: "postalCode",
  CUSTOMER_CAR_REGISTRATION_PLATE: "customerCarRegistrationPlate",
  CUSTOMER_CAR_MAKER: "insuranceData.customerCarMaker",
  CUSTOMER_CAR_MODEL: "insuranceData.customerCarModel",
  IS_AGENT_CUSTOMER: "isAgentCustomer",
  DIFFERENT_DRIVER: "differentDriver",
};

const formFieldLabelsFIN = {
  [EFormFields.NAME]: "Vuokraajan nimi",
  [EFormFields.SSN]: "Vuokraajan henkilötunnus",
  [EFormFields.CITY]: "Kaupunki",
  [EFormFields.PHONE]: "Puhelin",
  [EFormFields.EMAIL]: "Sähköposti",
  [EFormFields.DIFFERENT_DRIVER]: "Kuljettaja eri kuin vuokraaja",
  [EFormFields.IS_COMPANY]: "Yritysasiakas",
  [EFormFields.ADDRESS]: "Osoite",
  [EFormFields.POSTAL_CODE]: "Postinumero",
  [EFormFields.CUSTOMER_CAR_REGISTRATION_PLATE]: "Rekisterinumero",
  [EFormFields.CUSTOMER_CAR_MAKER]: "Auton valmistaja",
  [EFormFields.CUSTOMER_CAR_MODEL]: "Auton malli",
  [EFormFields.IS_AGENT_CUSTOMER]: "Agenttiasiakas",
};

const fieldLabel = {
  FIN: formFieldLabelsFIN,
};
