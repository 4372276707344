import { useContext } from "react";
// @ts-ignore
import { AppContext } from "~/AppContext";
// @ts-ignore
import { categoryByGroup, categoryByOrganization } from "../graphql/custom-queries.js";
import {
  Category,
  CategoryByOrganizationQuery,
  CategoryByOrganizationQueryVariables,
  CategoryByGroupQuery,
  CategoryByGroupQueryVariables,
} from "~/graphql/API";
import useSWR from "swr";
import { API } from "aws-amplify";

// Sort by orderNumber, ensure nulls (order not set) are last and lastly sort by name
const sortCategories = (
  { orderNumber: on1 = null, name: name1 }: Category,
  { orderNumber: on2 = null, name: name2 }: Category
) => {
  if (on1 === null && on2 === null) {
    // If both orderNumbers are null, sort by name
    return name1.localeCompare(name2);
  } else if (on1 === null) {
    // If on1 is null, c1 should be last
    return 1;
  } else if (on2 === null) {
    // If on2 is null, c2 should be last
    return -1;
  } else {
    // Compare by orderNumber
    return on1 - on2;
  }
};

const fallbackData: Category[] = [];

async function fetchByGroup(variables: CategoryByGroupQueryVariables) {
  const result = (await API.graphql({
    query: categoryByGroup,
    variables,
  })) as { data: CategoryByGroupQuery };

  return result.data?.categoryByGroup?.items || [];
}

async function fetchByOrg(variables: CategoryByOrganizationQueryVariables) {
  const result = (await API.graphql({
    query: categoryByOrganization,
    variables,
  })) as { data: CategoryByOrganizationQuery };

  return result.data?.categoryByOrganization?.items || [];
}

export function useCategories() {
  // Fix typescript types
  const { user } = useContext<any>(AppContext);

  const condition = user?.group ?? user?.organizationId;

  const { data } = useSWR(
    condition ? [user?.group, user?.organizationId, "categories"] : null,
    async () => {
      const categories = (
        user?.group
          ? await fetchByGroup({
              group: user.group,
            })
          : await fetchByOrg({ organizationId: user.organizationId })
      ).filter(Boolean) as Category[];

      categories.sort(sortCategories);

      return categories;
    },
    {
      fallbackData,
      revalidateOnFocus: false,
    }
  );

  return data;
}
