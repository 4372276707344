import {
  Grid,
  TextField,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Checkbox,
} from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React, { useContext, useMemo, useState } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import {
  createPricingException,
  updatePricingException,
  deletePricingException,
} from "../graphql/mutations";
import { useNotification } from "./Notification";
import { useConfirmation } from "./Confirmation";
import { useFormik } from "formik";
import captureError from "../utils/capture-error";
import fi from "date-fns/esm/locale/fi";
import {
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  getDay,
  format,
} from "date-fns";
import { init } from "../utils/array-utilities";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LicenseInfo } from "@mui/x-license-pro";
import DialogComponent from "./DialogComponent";
import { useFetchPricings } from "../services/fetchPricings";
import { AppContext } from "../AppContext";

LicenseInfo.setLicenseKey(
  "57d9cfea154b5252705da4c9ab0f8583Tz03NTg0OSxFPTE3Mjc5NDI2OTEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const capitalize = (str) => {
  if (str) {
    return str[0].toUpperCase() + str.slice(1);
  } else {
    return str;
  }
};

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Täytä tämä kenttä";
  }
  const weeklyAmountErrors = [];
  if (values.type === "WEEK") {
    values.weeklyAmounts?.forEach((weekDay, i) => {
      if (values.amountType === "FIXED") {
        if (weekDay === "" || isNaN(weekDay) || weekDay % 1 !== 0) {
          weeklyAmountErrors[i] = "Syötä kokonaisluku";
        }
      } else {
        if (
          (weekDay !== "" &&
            weekDay !== "0" &&
            weekDay !== 0 &&
            !Number(weekDay)) ||
          weekDay < -100 ||
          weekDay > 100 ||
          weekDay === ""
        ) {
          weeklyAmountErrors[i] = "Syötä numero väliltä -100 - 100";
        }
      }
    });
  }
  if (values.type === "MONTH") {
    if (!values.daysBeforeMonthChange) {
      errors.daysBeforeMonthChange = "Täytä tämä kenttä";
    }
    if (!values.daysAfterMonthChange) {
      errors.daysAfterMonthChange = "Täytä tämä kenttä";
    }
    if (
      !values.monthlyAmountPerDay ||
      isNaN(values.monthlyAmountPerDay || values.monthlyAmountPerDay % 1 !== 0)
    ) {
      errors.monthlyAmountPerDay = "Syötä kokonaisluku";
    }
  }
  if (weeklyAmountErrors.length > 0) {
    errors.weeklyAmounts = weeklyAmountErrors;
  }
  return errors;
};

const FormikTextField = ({ formik, name, disabled, ...props }) => {
  return (
    <TextField
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      name={name}
      disabled={disabled}
      variant="outlined"
      error={formik.touched[name] && !!formik.errors[name]}
      helperText={formik.touched[name] && formik.errors[name]}
      fullWidth
      {...props}
    />
  );
};

const get = (obj, properties) => {
  return properties.reduce((value, property) => value?.[property], obj);
};

const FormikPercentTextField = ({
  formik,
  name,
  index,
  disabled,
  ...props
}) => {
  const path = [name, index];
  return (
    <TextField
      value={formik.values[name][index]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      name={name + "." + index}
      variant="outlined"
      error={get(formik.touched, path) && !!get(formik.errors, path)}
      helperText={get(formik.touched, path) && get(formik.errors, path)}
      disabled={disabled}
      fullWidth
      {...props}
    />
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function DynaminPricingEditDialog({
  fetchPricingExceptions,
  onClose,
  pricingException,

  monthExceptionExists,
  disabled,
}) {
  const { group, user } = useContext(AppContext);
  const { pricings } = useFetchPricings(group, user);
  const notification = useNotification();
  const [submitting, setSubmitting] = useState(false);

  const weekdays = (() => {
    const now = new Date();
    return eachDayOfInterval({
      start: startOfWeek(now, { locale: fi }),
      end: endOfWeek(now, { locale: fi }),
    }).map((d) => ({
      label: capitalize(format(d, "eeeeee", { locale: fi })),
      index: getDay(d),
    }));
  })();

  const onSubmit = async () => {
    setSubmitting(true);

    const {
      name,
      weeklyAmounts,
      monthlyAmountPerDay,
      daysBeforeMonthChange,
      daysAfterMonthChange,
      startDate,
      endDate,
      pricingIds,
      amountType,
      type,
    } = formik.values;
    const user = await Auth.currentAuthenticatedUser();
    const group = user.signInUserSession.idToken.payload["cognito:groups"][0];

    try {
      const input =
        type === "WEEK"
          ? {
              group,
              name,
              weeklyAmounts,
              pricingIds,
              startDate: startDate.toISOString().split("T")[0], // ISO 8601 string without time
              endDate: endDate.toISOString().split("T")[0], // ISO 8601 string without time
              type: "WEEK",
              amountType: amountType,
            }
          : {
              group,
              name,
              monthlyAmountPerDay,
              pricingIds,
              daysBeforeMonthChange,
              daysAfterMonthChange,
              amountType: "FIXED",
              type: "MONTH",
            };
      if (pricingException.id) {
        await API.graphql(
          graphqlOperation(updatePricingException, {
            input: { ...input, id: pricingException.id },
          })
        );
      } else {
        await API.graphql(
          graphqlOperation(createPricingException, {
            input: input,
          })
        );
      }
      fetchPricingExceptions();
      onClose(true);
      return;
    } catch (e) {
      captureError(
        "Upsert PricingException failed",
        "UPSERT_PRICING_EXCEPTION_FAILED",
        e
      );
      notification.show("Jokin meni vikaan");
    }
    setSubmitting(false);
  };

  const [showDeleteConfirmation, DeleteConfirmationDialog] = useConfirmation(
    async () => {
      await API.graphql(
        graphqlOperation(deletePricingException, {
          input: {
            id: pricingException.id,
          },
        })
      );
      fetchPricingExceptions();
      onClose(true);
    }
  );

  const INITIAL_FORM_DATA = pricingException.id
    ? {
        ...pricingException,
        startDate: new Date(pricingException.startDate),
        endDate: new Date(pricingException.endDate),
      }
    : {
        name: "",
        weeklyAmounts: init(7, () => ""),
        monthlyAmountPerDay: "",
        startDate: new Date(),
        endDate: new Date(),
        daysBeforeMonthChange: 1,
        daysAfterMonthChange: 1,
        pricingIds: [],
        amountType: "FIXED",
        type: "WEEK",
      };

  const formik = useFormik({
    initialValues: INITIAL_FORM_DATA,
    validate: (values) => validate(values),
    onSubmit,
  });

  const defaultPricings = useMemo(() => {
    return pricings?.filter((p) =>
      pricingException.pricingIds?.find((id) => p.id === id)
    );
  }, [pricings, pricingException]);

  return (
    <>
      <DialogComponent
        open={true}
        dialogClose={() => onClose()}
        maxWidth={"xs"}
        dialogTitle={"Dynaaminen hinta"}
        dialogDelete={
          pricingException.id && !disabled
            ? () => showDeleteConfirmation()
            : null
        }
        dialogAction={disabled ? null : formik.submitForm}
        dialogActionText={"Tallenna"}
        dialogActionSubmitting={submitting}
      >
        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid
            item
            container
            direction="column"
            spacing={4}
            xs={12}
            sx={{ marginTop: "5px" }}
          >
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                name="name"
                label="Nimi"
                required
                disabled={disabled}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                multiple
                id="pricingCheckboxes"
                options={pricings}
                disableCloseOnSelect
                getOptionLabel={(pricing) => pricing.name}
                limitTags={1}
                disabled={disabled}
                onChange={(e, values) => {
                  const pricingIds = values.map((pricing) => pricing.id);
                  formik.setFieldValue("pricingIds", pricingIds);
                }}
                defaultValue={defaultPricings}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        sx={{ padding: "5px" }}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                        key={option.id}
                      />
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Hinnastot"
                    placeholder="Hinnastot"
                  >
                    tekstiä
                  </TextField>
                )}
              />
            </Grid>
            {!pricingException.id && (
              <Grid item container>
                <FormControl component="fieldset">
                  <Grid container alignItems="center">
                    <FormLabel component="legend">Tyyppi</FormLabel>
                  </Grid>
                  <RadioGroup
                    row
                    aria-label="Tyyppi"
                    name="type"
                    value={formik.values.type}
                    onChange={(e, value) => {
                      formik.setFieldValue("type", value);
                    }}
                  >
                    <FormControlLabel
                      value="WEEK"
                      control={<Radio />}
                      label="Päiväkohtainen"
                      disabled={disabled}
                    />
                    <FormControlLabel
                      value="MONTH"
                      control={<Radio />}
                      label="Kuunvaihde"
                      disabled={
                        pricingException.type !== "MONTH" &&
                        monthExceptionExists &&
                        disabled
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
            {formik.values.type === "WEEK" ? (
              <>
                <Grid container item spacing={2}>
                  <Grid item xs={12} md={6}>
                    {/*  <DatePicker
                      format="dd.MM.yyyy"
                      label="Aloitus"
                      value={formik.values.startDate}
                      name="startDate"
                      onChange={async (date) => {
                        console.log(date);
                        await formik.setFieldValue("startDate", date);
                      }}
                    /> */}

                    <DatePicker
                      label="Aloitus"
                      name="startDate"
                      value={formik.values.startDate}
                      onChange={async (date) => {
                        await formik.setFieldValue("startDate", date);
                      }}
                      inputFormat="dd.MM.yyyy"
                      disabled={disabled}
                      cancelLabel="Peruuta"
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Lopetus"
                      value={formik.values.endDate}
                      name="endDate"
                      onChange={async (date) =>
                        await formik.setFieldValue("endDate", date)
                      }
                      inputFormat="dd.MM.yyyy"
                      disabled={disabled}
                      cancelLabel="Peruuta"
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                    {/*   <DatePicker
                      format="dd.MM.yyyy"
                      label="Lopetus"
                      value={formik.values.endDate}
                      name="endDate"
                      onChange={async (date) =>
                        await formik.setFieldValue("endDate", date)
                      }
                    /> */}
                  </Grid>
                </Grid>

                <Grid item container>
                  <FormControl component="fieldset">
                    <Grid container alignItems="center">
                      <FormLabel component="legend">
                        Hintalisän tyyppi
                      </FormLabel>
                    </Grid>
                    <RadioGroup
                      row
                      aria-label="Hintalisän tyyppi"
                      name="amountType"
                      value={formik.values.amountType}
                      onChange={(e, value) => {
                        formik.setFieldValue("amountType", value);
                      }}
                    >
                      <FormControlLabel
                        value="PERCENT"
                        control={<Radio />}
                        label="Prosentti"
                        disabled={disabled}
                      />
                      <FormControlLabel
                        value="FIXED"
                        control={<Radio />}
                        label="Kiinteä"
                        disabled={disabled}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item container spacing={2}>
                  {weekdays.map((w) => (
                    <Grid
                      item
                      container
                      alignItems="center"
                      spacing={2}
                      key={w.index}
                    >
                      <Grid item style={{ width: 40 }}>
                        {w.label}
                      </Grid>
                      <Grid item xs>
                        <FormikPercentTextField
                          formik={formik}
                          name={"weeklyAmounts"}
                          index={w.index}
                          label={
                            formik.values.amountType === "PERCENT"
                              ? "Alennus-%"
                              : "Euroa"
                          }
                          disabled={disabled}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {formik.values.amountType === "PERCENT"
                                  ? "%"
                                  : "€"}
                              </InputAdornment>
                            ),
                          }}
                          required
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <>
                <FormLabel component="legend">
                  Voimassa kuunvaihteessa
                </FormLabel>
                <Grid container item spacing={2}>
                  <Grid item xs={6}>
                    <FormikTextField
                      formik={formik}
                      name="daysBeforeMonthChange"
                      label="Päivää ennen"
                      required
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextField
                      formik={formik}
                      name="daysAfterMonthChange"
                      label="Päivää jälkeen"
                      required
                      disabled={disabled}
                      autoFocus
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <FormikTextField
                    formik={formik}
                    name="monthlyAmountPerDay"
                    label="Euroa per päivä"
                    required
                    disabled={disabled}
                    autoFocus
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </DialogComponent>
      <DeleteConfirmationDialog
        message="Haluatko varmasti poistaa erikoishinnoittelun?"
        confirmLabel="Poista"
      />
    </>
  );
}
