import { useEffect, useState } from "react";
import { useContextFetchCompanies } from "../../../../../services/fetchCompanies";
import { useFetchPricingsOrganization } from "../../../../../services/fetchPricings";
import { useNotification } from "../../../../Notification";
import {
  handleUpdateDynamicPricing,
  updateDynamicPricingOptions,
} from "../../../utils/updateCategoryDynamicPricing";
import { Skeleton, TableCell, TextField } from "@mui/material";
import useGetDynamicPricingPercentage from "../../../hooks/useGetDynamicPricingPercentage";
import { checkNumericInput } from "../../../../../utils/checkNumericInput";

export default function AdjustCategoryDynamicPrice(props) {
  const { visibility, pricingId, selectedCompany } = props;
  const { companies, companiesMutate } = useContextFetchCompanies();
  const { dynamicPricing } = useGetDynamicPricingPercentage(
    selectedCompany?.id,
    pricingId
  );

  const [fieldInputValue, setFieldInputValue] = useState(
    () => dynamicPricing?.percentage ?? ""
  );

  const cellWidth = "150px";
  const { pricingsLoading } = useFetchPricingsOrganization("pricingListDialog");

  useEffect(() => {
    setFieldInputValue(dynamicPricing?.percentage);
  }, [dynamicPricing?.percentage]);

  const notification = useNotification();
  const handleUpdate = async (newValue) => {
    if (newValue.trim() === "")
      return setFieldInputValue(dynamicPricing?.percentage);
    const handleNewValue = () => {
      const categoryDynamicPricings = selectedCompany?.categoryDynamicPricings;

      if (categoryDynamicPricings) {
        const index = categoryDynamicPricings?.findIndex(
          (item) => item.pricingId === pricingId
        );

        if (index !== -1) {
          // If the id is found in the array, update the value
          return categoryDynamicPricings.map((item) => {
            delete item.__typename;
            return item.pricingId === pricingId
              ? { ...item, percentage: parseInt(newValue) }
              : item;
          });
        } else {
          // If the id is not found in the array, add a new item
          return [
            ...categoryDynamicPricings,
            { pricingId, percentage: parseInt(newValue) },
          ];
        }
      } else {
        return [{ pricingId, percentage: parseInt(newValue) }];
      }
    };

    const updatedValues = {
      id: selectedCompany?.id,
      categoryDynamicPricings: handleNewValue(),
    };
    await companiesMutate(
      handleUpdateDynamicPricing(updatedValues),
      updateDynamicPricingOptions(
        selectedCompany?.id,
        handleNewValue(),
        companies
      )
    );
  };

  if (pricingsLoading) {
    <TableCell sx={{ width: cellWidth }}>
      <Skeleton sx={{ height: "50px" }} />
    </TableCell>;
  }

  if (visibility) {
    return (
      <TableCell sx={{ width: cellWidth }}>
        <TextField
          size="small"
          value={fieldInputValue ?? ""}
          onChange={(e) => {
            const newValue = checkNumericInput(e.target.value);
            if (newValue) {
              setFieldInputValue(newValue);
            }
          }}
          onBlur={() => handleUpdate(fieldInputValue)}
          InputProps={{ endAdornment: "%" }}
          inputProps={{ style: { textAlign: "right" } }}
        />
      </TableCell>
    );
  } else {
    return <TableCell />;
  }
}
