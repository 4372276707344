import React from "react";

import vanDamageMap from "../../../assets/damageMapImages/van_damagemap.webp";
import carDamageMap from "../../../assets/damageMapImages/car_damagemap.webp";
import jetskiDamageMap from "../../../assets/damageMapImages/jetski_damagemap.webp";
import bicycleDamageMap from "../../../assets/damageMapImages/bicycle_damagemap.webp";
import cabinettrolleyDamageMap from "../../../assets/damageMapImages/cabinettrolley_damagemap.webp";
import camperDamageMap from "../../../assets/damageMapImages/camper_damagemap.webp";
import snowmobileDamageMap from "../../../assets/damageMapImages/snowmobile_damagemap.webp";
import trailerDamageMap from "../../../assets/damageMapImages/trailer_damagemap.webp";
import { Box, Grid, Typography } from "@mui/material";
import { EOrderVehicle } from "./LanguagePack/translatedFields";

export const VehicleDamageMap = (props) => {
  const { vehicle, returnTime, selectedLanguage } = props;
  const relevantDamageRecords = vehicle?.damageRecords?.filter(
    (damageRecord) => new Date(damageRecord.createdAt) < new Date(returnTime)
  );

  const damageMap = {
    "product-type-vans": vanDamageMap,
    "product-type-minibus": vanDamageMap,
    "product-type-rvs": vanDamageMap,
    "product-type-jetskis": jetskiDamageMap,
    "product-type-cars": carDamageMap,
    "product-type-bicycles": bicycleDamageMap,
    "product-type-trailers": cabinettrolleyDamageMap,
    "product-type-trailer-enclosed": cabinettrolleyDamageMap,
    "product-type-campers": camperDamageMap,
    "product-type-snowmobiles": snowmobileDamageMap,
    "product-type-trailer-open": trailerDamageMap,
    // ... any other product types
  };

  const image = damageMap[vehicle?.category?.typeId] || null;

  return (
    <Grid item xs={4}>
      {EOrderVehicle[selectedLanguage].VEHICLE_CONDITION}
      {vehicle?.damages}

      <Box
        style={{
          position: "relative",
        }}
      >
        <img
          src={image}
          alt="Vehicle Damage"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
          }}
        />
        {relevantDamageRecords?.map((coord, index) => (
          <React.Fragment key={index}>
            <Box
              sx={{
                position: "absolute",
                left: `${coord.normalizedX * 100}%`,
                top: `${coord.normalizedY * 100}%`,
                transform: "translate(-50%, -50%)",
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: "black",
                outline: "2px solid black",
              }}
            />
            <Typography
              sx={{
                position: "absolute",
                left: `${coord.normalizedX * 100}%`,
                top: `${coord.normalizedY * 100 + 2}%`,
                transform: "translate(-50%, 0)",
                color: "white",
                fontSize: "0.55rem",
                backgroundColor: "rgba(0,0,0,0.7)",
                borderRadius: "4px",
                userSelect: "none",
              }}
            >
              {coord.label}
            </Typography>
          </React.Fragment>
        ))}
      </Box>
    </Grid>
  );
};
