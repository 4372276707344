import { useContext } from "react";
import { AppContext } from "../AppContext";

import AdditionalServicesDialog from "./AdditionalServicesDialog";
import CategoriesDialog from "./CategoriesDialog";
import HanselDialog from "./HanselDialog";
import NewsDialog from "./NewsDialog";
import PricingListDialog from "./PricingListDialog";
import ProductAvailabilityWidget from "./ProductAvailabilityWidget";
import ReservationDialog from "./ReservationDialog";
import ReviewsReportDialog from "./ReviewsReportDialog";
import UserManagementDialog from "./UserManagementDialog";
import VehicleDialog from "./VehicleDialog";
import VouchersDialog from "./VouchersDialog";
import { ReservationDataProvider } from "./Reservation/ReservationContext";
import { useFetchCompanies } from "../services/fetchCompanies";
import CustomerManagementDialog from "./CustomerManagementDialog";
import ReservationDialogV2 from "./ReservationDialogV2";
import PricingsDialog from "./PricingsDialog";
import { enableReservationDialogV2 } from "../features";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../atoms/reservationDataAtom";
import { insuranceDataAtom } from "../stores/insuranceData";

export function AppDialogs(props) {
  const {
    setShowAdditionalServices,
    setShowCategories,
    setShowHansel,
    setShowPricing,
    setShowProductAvailabilityWidget,
    setShowReviewsReport,
    setShowUserManagementDialog,
    setShowVouchers,
    showAdditionalServices,
    showCategories,
    showHansel,
    showPricing,
    showProductAvailabilityWidget,
    showReviewsReport,
    showUserManagementDialog,
    showVouchers,
    setShowNews,
    showNews,
  } = props;
  const {
    business,
    company,
    editReservationData,
    editVehicleData,
    reservationDialogVersion,
    selectedView,
    setEditReservationData,
    setEditVehicleData,

    showCustomerManagementDialog,
    user,
    USER_ADMIN_TYPE,
  } = useContext(AppContext);
  const { companies } = useFetchCompanies(user?.group, business?.id);

  const FEATURE_ENABLE_UPDATED_RESERVATION_DIALOG = enableReservationDialogV2(
    USER_ADMIN_TYPE,
    business,
    user
  );
  const FEATURE_ORGANIZATION_PRICINGS_DIALOG = user?.organizationId;
  const reservationData = useAtomValue(reservationDataAtom);
  const insuranceData = useAtomValue(insuranceDataAtom);

  if (showCustomerManagementDialog) {
    return <CustomerManagementDialog />;
  }

  if (showPricing) {
    if (FEATURE_ORGANIZATION_PRICINGS_DIALOG) {
      return (
        <PricingsDialog
          open
          onClose={() => {
            setShowPricing(false);
          }}
        />
      );
    } else {
      return (
        <PricingListDialog
          open
          onClose={() => {
            setShowPricing(false);
          }}
        />
      );
    }
  }

  if (showAdditionalServices) {
    return (
      <AdditionalServicesDialog
        open
        onClose={() => {
          setShowAdditionalServices(false);
        }}
      />
    );
  }

  if (showVouchers) {
    return (
      <VouchersDialog
        open
        onClose={() => {
          setShowVouchers(false);
        }}
      />
    );
  }

  if (showHansel) {
    return (
      <HanselDialog
        open
        onClose={() => {
          setShowHansel(false);
        }}
      />
    );
  }

  if (showNews) {
    return (
      <NewsDialog
        open
        onClose={() => {
          setShowNews(false);
        }}
      />
    );
  }

  if (showCategories) {
    return (
      <CategoriesDialog
        open
        onClose={() => {
          setShowCategories(false);
        }}
        setShowCategories={setShowCategories}
        setEditVehicleData={setEditVehicleData}
        companies={companies}
        company={company}
        showPricings={() => setShowPricing(true)}
      />
    );
  }

  if (showReviewsReport) {
    return (
      <ReviewsReportDialog
        open
        company={company}
        onClose={() => {
          setShowReviewsReport(false);
        }}
      />
    );
  }

  if (showUserManagementDialog) {
    return (
      <UserManagementDialog
        open
        onClose={() => {
          setShowUserManagementDialog(false);
        }}
      />
    );
  }

  if (!!reservationData || !!insuranceData) {
    if (FEATURE_ENABLE_UPDATED_RESERVATION_DIALOG) {
      return <ReservationDialogV2 />;
    }

    if (!reservationDialogVersion)
      return (
        <ReservationDialog
          setEditReservationData={setEditReservationData}
          selectedView={selectedView}
          open
          reservationData={
            typeof editReservationData === "object"
              ? editReservationData
              : undefined
          }
        />
      );
    if (reservationDialogVersion) {
      return (
        <ReservationDataProvider
          selectedView={selectedView}
          editReservationData={editReservationData}
          setEditReservationData={setEditReservationData}
        />
      );
    }
  }

  if (editVehicleData) {
    return (
      <VehicleDialog
        dialogType="vehicle"
        open
        data={typeof editVehicleData === "object" ? editVehicleData : undefined}
        showCategories={() => setShowCategories(true)}
        companies={companies}
        showPricings={() => setShowPricing(true)}
        setEditVehicleData={setEditVehicleData}
      />
    );
  }

  if (showProductAvailabilityWidget) {
    return (
      <ProductAvailabilityWidget
        open={showProductAvailabilityWidget}
        onClose={() => setShowProductAvailabilityWidget(false)}
        setEditReservationData={setEditReservationData}
      />
    );
  }
}
