import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuList,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

import { useContext } from "react";

import { AppContext } from "../../../../AppContext";
import { FormContainer } from "../FormContainer";
import {
  additionalServiceScandia,
  externalServices,
} from "./components/ScandiaRentServices";
import { useFetchAdditionalServices } from "../../../../services/fetchAdditionalServices";
import {
  enableCustomAdditionalService,
  enableExternalServices,
} from "../../../../features";
import { centsToLocalString } from "../../../../shared/money";
import { EServiceCategories } from "../../utils/enums";
import { Extension } from "@mui/icons-material";
import { reservationDataAtom } from "../../../../atoms/reservationDataAtom";
import { useAddNewService } from "../../hooks/useAddNewService";
import { useAtomValue } from "jotai";
import AddCustomService from "./components/AddCustomService";
import AdditionalServiceList from "./components/AdditionalServiceList";
import AdditionalServiceCardTemplate from "../AdditionalServiceCardTemplate";
import { useGetOfferPrices } from "~/hooks/useGetOfferPrices";
import { useGetOfferPriceByIds } from "~/hooks/useGetOfferPriceByIds";
import Loading from "./components/Loading";

export default function AdditionalServicePage() {
  const { user } = useContext(AppContext);
  const reservationData = useAtomValue(reservationDataAtom);

  const FEATURE_ENABLE_CUSTOM_SERVICES = enableCustomAdditionalService(
    user?.organizationId
  );

  const FEATURE_ENABLE_SCANDIA_RENT_SERVICES = enableCustomAdditionalService(
    user?.organizationId
  );

  const FEATURE_ENABLE_EXTERNAL_SERVICES =
    enableExternalServices(user?.organizationId) &&
    reservationData?.type === "INSURANCE";
  const { values } = useFormikContext();

  const vehicleIds = values.reservationVehicles.map((vehicle) => vehicle.id);
  const { offerPrices, offerPricesLoading } = useGetOfferPriceByIds(
    vehicleIds,
    values.startTime,
    values.returnTime,
    values.companyId,
    values?.externalBusinessId
  );
  const EFeatures = {
    ADD_CUSTOM_SERVICE: FEATURE_ENABLE_CUSTOM_SERVICES && <AddCustomService />,
    CUSTOM_SERVICES: FEATURE_ENABLE_CUSTOM_SERVICES && (
      <AdditionalServiceList
        category={EServiceCategories.EXPENDABLE}
        offerPrices={offerPrices["serviceOfferPrices"]}
        offerPricesLoading={offerPricesLoading}
      />
    ),
    EXTERNAL_SERVICE: FEATURE_ENABLE_EXTERNAL_SERVICES && (
      <ExternalBusinessServices />
    ),
    SCANDIA_RENT_SERVICES: FEATURE_ENABLE_SCANDIA_RENT_SERVICES && (
      <ScandiaRentCustomServices />
    ),
  };

  return (
    <Stack flex={1}>
      <FormContainer title={"Lisäpalvelut"} visibility={true}>
        <Stack flex={1} spacing={1}>
          <AdditionalServiceList
            category={EServiceCategories.NORMAL}
            offerPrices={offerPrices["serviceOfferPrices"]}
            offerPricesLoading={offerPricesLoading}
          />
          <Box>
            <AddNewService
              offerPrices={offerPrices["serviceOfferPrices"]}
              offerPricesLoading={offerPricesLoading}
            />
          </Box>
        </Stack>
      </FormContainer>
      <FormContainer title={"Kertakäyttöpalvelut"} visibility={true}>
        <Stack flex={1} spacing={1}>
          {EFeatures.ADD_CUSTOM_SERVICE}
          {EFeatures.CUSTOM_SERVICES}
        </Stack>
      </FormContainer>
      {EFeatures.SCANDIA_RENT_SERVICES}
      {EFeatures.EXTERNAL_SERVICE}

      <FormContainer title={"Omavastuu"} visibility={true}>
        <Stack flex={1} spacing={1}>
          <AdditionalServiceList
            category={EServiceCategories.DEDUCTIBLE}
            offerPrices={offerPrices["serviceOfferPrices"]}
            offerPricesLoading={offerPricesLoading}
          />
        </Stack>
      </FormContainer>
      <FormContainer title={"Kilometrit"} visibility={true}>
        <Stack flex={1} spacing={1}>
          <AdditionalServiceList
            category={EServiceCategories.DISTANCE}
            offerPrices={offerPrices["serviceOfferPrices"]}
            offerPricesLoading={offerPricesLoading}
          />
        </Stack>
      </FormContainer>
    </Stack>
  );
}

export function AddNewService(props) {
  const { offerPricesLoading, offerPrices } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { additionalServices } = useFetchAdditionalServices();
  const { values } = useFormikContext();
  const { offerPrices: wildCardOffers } = useGetOfferPrices(values);

  const selectedServices = values.additionalServices.map(
    (service) => service.key
  );
  const availableServicesForProducts =
    offerPrices
      ?.filter((service) => !selectedServices.includes(service.key))
      .map((s) => {
        const serviceData = additionalServices.find((a) => a.key === s.key);
        return { ...s, ...serviceData };
      }) ?? [];

  const availableServicesForWildCard =
    wildCardOffers?.additionalServiceOffers
      ?.filter((service) => !selectedServices.includes(service.key))
      .map((s) => {
        const serviceData = additionalServices.find((a) => a.key === s.key);
        return {
          ...s,
          ...serviceData,
        };
      }) ?? [];

  const availableServices =
    values?.wildCardVehicles.length > 0
      ? availableServicesForWildCard
      : availableServicesForProducts;

  return (
    <>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant="contained"
        sx={{ textTransform: "none" }}
      >
        <Typography>Lisää palvelu</Typography>
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <List sx={{ width: { xs: "90vw", sm: "500px" }, maxHeight: "500px" }}>
          {offerPricesLoading && <Loading />}
          {availableServices.map((service) => (
            <ServiceMenuItem
              key={service?.key}
              service={service}
              offerPrices={offerPrices}
              offerPricesLoading={offerPricesLoading}
              setAnchorEl={setAnchorEl}
            />
          ))}
        </List>
      </Menu>
    </>
  );
}

function ServiceMenuItem(props) {
  const { offerPricesLoading, setAnchorEl, service } = props;
  const theme = useTheme();
  const { addService } = useAddNewService();

  return (
    <ListItem
      sx={{
        "&:hover": {
          backgroundColor: "rgba(0,0,0,0.07)",
          cursor: "pointer",
        },
      }}
      onClick={() => {
        addService(service, setAnchorEl);
      }}
    >
      <ListItemAvatar>
        <Avatar variant="rounded" sx={{ bgcolor: theme.palette.primary.main }}>
          <Extension />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={service?.description} />
      <ListItemSecondaryAction>
        <ServicePrice
          price={`${centsToLocalString(service?.offerPrice)} €`}
          offerPricesLoading={offerPricesLoading}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function ServicePrice(props) {
  const { offerPricesLoading, price } = props;

  if (offerPricesLoading) {
    return <CircularProgress size={20} />;
  }
  if (price === "--- €") return null;
  return (
    <Typography sx={{ fontWeight: 550, fontFamily: "Sofia Pro" }}>
      {price}
    </Typography>
  );
}

function ExternalBusinessServices() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [, meta] = useField("additionalServices");
  const { value } = meta;
  const selectedServices = value
    ? value?.filter((service) => service.category === "insurance" || "external")
    : [];
  return (
    <FormContainer title={"Lähitapiola lisäpalvelut"} visibility={true}>
      <Stack flex={1} direction={"column"} spacing={2}>
        <List dense sx={{ width: { width: "100%", maxWidth: "450px" } }}>
          {selectedServices?.map((service, index) => {
            // Handle if service is "deleted"
            if (!service?.hide) {
              return (
                <AdditionalServiceCardTemplate
                  service={service}
                  key={service.key}
                />
              );
            }
            return null;
          }) ?? null}
        </List>
        <Box display={"flex"}>
          <Button
            onClick={(e) => setAnchorEl(e.currentTarget)}
            variant="contained"
          >
            Lisää palvelu
          </Button>
          <ExternalServiceMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        </Box>
      </Stack>
    </FormContainer>
  );
}

function ScandiaRentCustomServices(props) {
  const { availableServices, handleClick } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <FormContainer title={"Scandia Rent lisäpalvelut"} visibility={true}>
      <Stack flex={1} spacing={2}>
        <AdditionalServiceList
          category={EServiceCategories.SCANDIARENT}
          additionalServiceOffers={availableServices}
        />

        <Box>
          <Button
            onClick={(e) => setAnchorEl(e.currentTarget)}
            variant="contained"
          >
            Lisää palvelu
          </Button>
        </Box>
      </Stack>
      <ScandiaRentServiceMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClick={handleClick}
      />
    </FormContainer>
  );
}

function ExternalServiceMenu(props) {
  const { anchorEl, setAnchorEl } = props;
  const [, meta] = useField("additionalServices");
  const availableServices = externalServices.filter(
    (service) => !meta.value.some((s) => s.key === service.key)
  );
  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      PaperProps={{ style: { maxHeight: "40%" } }}
      onClose={() => setAnchorEl(null)}
    >
      <MenuList>
        {availableServices.map((service) => (
          <ServiceMenuItem
            key={service.key}
            service={service}
            setAnchorEl={setAnchorEl}
          />
        ))}
      </MenuList>
    </Menu>
  );
}

function ScandiaRentServiceMenu(props) {
  const { anchorEl, setAnchorEl } = props;
  const { values } = useFormikContext();
  const selectedService = values.additionalServices.map(
    (service) => service.key
  );
  const availableService = additionalServiceScandia.filter(
    (service) => !selectedService.includes(service.key)
  );
  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      PaperProps={{ style: { maxHeight: "40%" } }}
      onClose={() => setAnchorEl(null)}
    >
      <MenuList>
        {availableService.map((service) => (
          <ServiceMenuItem
            key={service?.key}
            service={service}
            setAnchorEl={setAnchorEl}
          />
        ))}
      </MenuList>
    </Menu>
  );
}
