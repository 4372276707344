import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";

import {
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  getDay,
  format,
} from "date-fns";

import fi from "date-fns/esm/locale/fi";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LicenseInfo } from "@mui/x-license-pro";
import { useFormikContext } from "formik";

LicenseInfo.setLicenseKey(
  "57d9cfea154b5252705da4c9ab0f8583Tz03NTg0OSxFPTE3Mjc5NDI2OTEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const FormikHourTextField = ({ name, index, ...props }) => {
  const path = ["startReturnTimes", index, name];
  const get = (obj, properties) => {
    return properties.reduce((value, property) => value?.[property], obj);
  };
  const { values, errors, touched, handleBlur, handleChange } =
    useFormikContext();
  return (
    <TextField
      value={values.startReturnTimes[index][name]}
      onChange={handleChange}
      onBlur={handleBlur}
      name={"startReturnTimes." + index + "." + name}
      variant="outlined"
      error={get(touched, path) && !!get(errors, path)}
      helperText={get(touched, path) && get(errors, path)}
      fullWidth
      {...props}
    />
  );
};

export default function SpecialReservationTimesDialog() {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } =
    useFormikContext();
  const capitalize = (str) => {
    if (str) {
      return str[0].toUpperCase() + str.slice(1);
    } else {
      return str;
    }
  };
  const weekdays = (() => {
    const now = new Date();
    return eachDayOfInterval({
      start: startOfWeek(now, { locale: fi }),
      end: endOfWeek(now, { locale: fi }),
    }).map((d) => ({
      label: capitalize(format(d, "eeeeee", { locale: fi })),
      index: getDay(d),
    }));
  })();

  return (
    <Grid container direction="column" justifyContent="center">
      <Grid item container spacing={2} justifyContent="center">
        {weekdays.map((w) => (
          <Grid item>
            <Grid container alignItems="center" spacing={2} key={w.index}>
              <Grid item style={{ width: 40 }}>
                {w.label}
              </Grid>
              <Grid item xs={5}>
                <FormikHourTextField
                  name="start"
                  index={w.index}
                  label="Nouto"
                />
              </Grid>
              <Grid item xs={5}>
                <FormikHourTextField
                  name="return"
                  index={w.index}
                  label="Palautus"
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        <FormControlLabel
          control={
            <Switch
              name={"useStartReturnInterval"}
              checked={values["useStartReturnInterval"] || false}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          label="Määritä voimassaoloaika"
        />
      </Grid>
      {values.useStartReturnInterval && (
        <Grid item container spacing={2} justifyContent="center" xs={12}>
          <Grid item xs={11} md={5}>
            {/*  <DatePicker
              format="dd.MM.yyyy"
              label="Aloitus"
              value={values.vehicleTimesStart}
              name="vehicleTimesStart"
              onChange={async (date) => {
                await setFieldValue("vehicleTimesStart", date);
              }}
              onBlur={handleBlur}
              error={!!errors.vehicleTimesStart}
              helperText={errors.vehicleTimesStart}
            /> */}

            <DatePicker
              label="Aloitus"
              name="vehicleTimesStart"
              value={values.vehicleTimesStart}
              onChange={async (date) => {
                await setFieldValue("vehicleTimesStart", date);
              }}
              onBlur={handleBlur}
              autoOk
              inputFormat="dd.MM.yyyy"
              error={touched.date && errors.date !== undefined}
              helperText={touched.date && errors.date}
              cancelLabel="Peruuta"
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={11} md={5}>
            {/*    <DatePicker
              format="dd.MM.yyyy"
              label="Lopetus"
              value={values.vehicleTimesEnd}
              name="vehicleTimesEnd"
              onChange={async (date) => {
                await setFieldValue("vehicleTimesEnd", date);
              }}
              onBlur={handleBlur}
              error={!!errors.vehicleTimesEnd}
              helperText={errors.vehicleTimesEnd}
            /> */}

            <DatePicker
              label="Aloitus"
              name="vehicleTimesStart"
              value={values.vehicleTimesEnd}
              onChange={async (date) => {
                await setFieldValue("vehicleTimesEnd", date);
              }}
              onBlur={handleBlur}
              autoOk
              inputFormat="dd.MM.yyyy"
              error={touched.date && errors.date !== undefined}
              helperText={touched.date && errors.date}
              cancelLabel="Peruuta"
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
