import { useContext, useState } from "react";
import { ReservationContext } from "../../../../../../../../contextProviders/ReservationContext";
import { Stack, useMediaQuery } from "@mui/material";
import { ProductCardTemplateMobile } from "../../../../../../../../components/ProductCardTemplateMobile";
import { ProductCardTemplate } from "../../../../../../../../components/ProductCardTemplate";
import ProductCardTitle from "./ProductCardTitle";
import FieldInput from "./FieldInput";
export default function ProductCard(props) {
  const { item, selectedBusiness } = props;
  const { summaryView } = useContext(ReservationContext);

  const [expanded, setExpanded] = useState(false);
  const mobileViewPort = useMediaQuery((theme) => theme.breakpoints.down("md"));

  if (summaryView) {
    return <ProductCardTemplateMobile item={item} />;
  }

  return (
    <ProductCardTemplate
      expanded={expanded}
      setExpanded={setExpanded}
      summary={<ProductCardTitle item={item} mobileViewPort={mobileViewPort} />}
      details={
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyContent={"flex-end"}
          spacing={1}
          width={mobileViewPort ? "100%" : undefined}
        >
          <FieldInput
            expanded={expanded}
            item={item}
            mobileViewPort={mobileViewPort}
            selectedBusiness={selectedBusiness}
            vehicleId={item?.id}
          />
        </Stack>
      }
      productId={item?.id}
    />
  );
}
