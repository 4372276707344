import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";

import { ADDITIONAL_FIELDS_CAR } from "../../shared/additional-fields";

import {
  TbFileText,
  TbCurrencyEuro,
  TbLayout2,
  TbAlarm,
  TbCopy,
  TbTrash,
  TbMenu2,
  TbCheckupList,
  TbHistory,
} from "react-icons/tb";

import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import { Formik, useFormikContext } from "formik";
import React, { useEffect, useState, useMemo, useContext } from "react";
import { useRef } from "react";
import {
  createVehicle,
  deleteVehicle,
  updateVehicle,
  createAdditionalServicePricing,
  updateAdditionalServicePricing,
  deleteAdditionalServicePricing,
} from "../../graphql/mutations";
import { mapMaybe, init } from "../../utils/array-utilities";
import { centsToLocalString, parseToCents } from "../../shared/money";
import { useNotification } from "../Notification";
import { v4 } from "uuid";
import captureError from "../../utils/capture-error";
import { typeOfProducts } from "../../shared/ProductClassification/type-of-products";

import ProductInformation from "./ProductInformation";
import ProductPricing from "./ProductPricing";
import ProductAttributes from "./ProductAttributes";
import ProductTimeSettings from "./ProductTimeSettings";
import DamageMap from "./DamageMap";
import DeleteProduct from "./DeleteProduct";
import CopyProduct from "./CopyProduct";
import DialogComponent from "../DialogComponent";
import { Box, Stack } from "@mui/system";
import { useCategories } from "~/queries/useCategories";
import { useFetchAdditionalServices } from "../../services/fetchAdditionalServices";
import { AppContext } from "../../AppContext";
import { DialogMenu } from "../common/MenuAndContent";
import { isValid } from "date-fns";
import { useFetchVehicles } from "../../services/fetchVehicles";
import { enableVehicleDamageMap } from "../../features";
import ReservationHistory from "./components/ReservationHistory/";

const parseVehicleStartReturnTimes = (times) => {
  const errors = {};
  const timeRegex = /^((([0-9][0-9]?):([0-6][0-9]),*)*)$/;
  if (times.start !== "") {
    const result = timeRegex.exec(times.start);
    if (result === null) {
      errors.start = "HH:MM kaksoispisteellä eroteltuna";
    }
  }
  if (times.return !== "") {
    const result = timeRegex.exec(times.return);
    if (result === null) {
      errors.return = "HH:MM kaksoispisteellä eroteltuna";
    }
  }
  return errors;
};

export default function VehicleDialog({
  data,
  open,
  showPricings,
  showCategories,
  companies,
}) {
  const { company, group, user, setEditVehicleData } = useContext(AppContext);
  const { additionalServices } = useFetchAdditionalServices();
  const { vehiclesMutate } = useFetchVehicles();
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [image, setImage] = useState(data?.image ? data.image : null);
  const [selectedProductType, setSelectedProductType] = useState(
    typeOfProducts[0]
  );

  const notification = useNotification();

  const onClose = (success) => {
    if (success) {
      vehiclesMutate();
    }
    setEditVehicleData(false);
  };

  const onSubmit = async (values) => {
    setSubmitting(true);

    const {
      name,
      registrationPlate,
      id,
      damages,
      damageRecords,
      extraInfo,
      extraToConfirmation,
      hideExport,
      powerType,
      includedDistance,
      mileage,
      orderNumber,
      vehiclePricingId,
      vehicleCategoryId,
      useCategoryDefaults,
      useStartReturnTimes,
      useStartReturnInterval,
      vehicleTimesStart,
      vehicleTimesEnd,
      disableOnline,
    } = values;

    const companyId = values.companyId !== "" ? values.companyId : company.id;
    let livionKeyId = values.livionKeyId;
    if (company.livionDeviceType === "KEYPAD") {
      // Key Ids are not really used on keypads, just having something in the field
      // marks that this Vehicle is connected to the KeyPad.
      livionKeyId = livionKeyId ? "USE_KEYPAD" : null;
    }

    let startReturnTimes = null;
    if (useStartReturnTimes) {
      startReturnTimes = values.startReturnTimes.map((times) => {
        return {
          start: times.start.split(","),
          return: times.return.split(","),
        };
      });
    }

    const additionalFieldsKeys = ADDITIONAL_FIELDS.map((v) => v.key);
    const additionalFields = [];

    additionalFieldsKeys.forEach((x) => {
      if (values[x]) {
        const fieldValue = values[x];

        additionalFields.push({
          key: x,
          value: fieldValue,
        });
      }
    });

    const deductible = parseToCents(values.deductible);

    let startReturnInterval = null;
    if (useStartReturnInterval && vehicleTimesStart && vehicleTimesEnd) {
      startReturnInterval = {
        start: vehicleTimesStart.toISOString().split("T")[0], // ISO 8601 string without time
        end: vehicleTimesEnd.toISOString().split("T")[0], // ISO 8601 string without time
      };
    }
    let activeRange = null;
    if (values.activeRange.start && values.activeRange.end) {
      activeRange = {
        start: values.activeRange.start.toISOString().split("T")[0],
        end: values.activeRange.end.toISOString().split("T")[0],
      };
    }
    try {
      const categoryId = vehicleCategoryId ? vehicleCategoryId : null;
      let newVehicleId;
      if (id) {
        const { mileage } = values;
        const updateVehicleInput = {
          id,
          name,
          registrationPlate,
          damages,
          damageRecords,
          deductible,
          powerType,
          includedDistance,
          mileage,
          additionalFields,
          image,
          vehicleCategoryId: categoryId,
          companyId: companyId,
          useCategoryDefaults,
          disableOnline,
          useStartReturnTimes,
          orderNumber: parseInt(orderNumber),
          customStartReturns: [
            {
              priority: null,
              startReturnInterval: startReturnInterval,
              startReturnTimes: startReturnTimes,
            },
          ],
          startReturnTimes,
          startReturnInterval,
          ...(!useCategoryDefaults && { vehiclePricingId }),
          extraInfo,
          extraToConfirmation,
          ...(company.livionDeviceId && { livionKeyId }),
          hideExport,
          activeRange,
        };
        // Group may not exist for organization users
        if (group) {
          updateVehicleInput.group = group;
        }
        // Set organization and business if available
        if (company.organizationId) {
          updateVehicleInput.organizationId = company.organizationId;
          if (company.orgBusinessId) {
            updateVehicleInput.orgBusinessId = company.orgBusinessId;
          }
        }

        await API.graphql(
          graphqlOperation(updateVehicle, {
            input: updateVehicleInput,
          })
        );

        newVehicleId = id;
      } else {
        const createVehicleInput = {
          name,
          registrationPlate,
          damages,
          damageRecords,
          deductible,
          powerType,
          includedDistance,
          mileage,
          additionalFields,
          image,
          vehiclePricingId,
          ...(categoryId && { vehicleCategoryId: categoryId }),
          companyId: companyId,
          useCategoryDefaults,
          disableOnline,
          useStartReturnTimes,
          startReturnTimes,
          startReturnInterval,
          ...(!useCategoryDefaults && { vehiclePricingId }),
          extraInfo,
          extraToConfirmation,
          ...(company.livionDeviceId && { livionKeyId }),
          hideExport,
          orderNumber: parseInt(orderNumber),
          activeRange,
        };
        // Group may not exist for organization users
        if (group) {
          createVehicleInput.group = group;
        }
        // Set organization and business if available
        if (company.organizationId) {
          createVehicleInput.organizationId = company.organizationId;
          if (company.orgBusinessId) {
            createVehicleInput.orgBusinessId = company.orgBusinessId;
          }
        }
        const response = await API.graphql(
          graphqlOperation(createVehicle, {
            input: createVehicleInput,
          })
        );

        newVehicleId = response.data.createVehicle.id;
      }

      const additionalServiceFields = mapMaybe(additionalServices, (k) => {
        const fieldValue = values[k.id];
        if (fieldValue) {
          return {
            group,
            key: k.id,
            vehicleId: newVehicleId,
            pricingId: fieldValue,
            deductibleValue: parseToCents(values[k.id + "Details"]) || null,
          };
        }
      });

      const oldAdditionalServices = data?.additionalServices.items ?? [];

      const findOldField = (newFieldValue) =>
        oldAdditionalServices.find(
          (oldFieldValue) => newFieldValue.key === oldFieldValue.key
        );

      const createPromises = additionalServiceFields
        .filter((newFieldValue) => findOldField(newFieldValue) == null)
        .map(async (newFieldValue) => {
          await API.graphql(
            graphqlOperation(createAdditionalServicePricing, {
              input: newFieldValue,
            })
          );
        });

      const updatePromises = additionalServiceFields.map(
        async (newFieldValue) => {
          const oldFieldValue = findOldField(newFieldValue);
          if (
            oldFieldValue != null &&
            (oldFieldValue.pricingId !== newFieldValue.pricingId ||
              oldFieldValue.deductibleValue !== newFieldValue.deductibleValue)
          ) {
            await API.graphql(
              graphqlOperation(updateAdditionalServicePricing, {
                input: {
                  id: oldFieldValue.id,
                  pricingId: newFieldValue.pricingId,
                  deductibleValue: newFieldValue.deductibleValue,
                },
              })
            );
          }
        }
      );

      const deletePromises = oldAdditionalServices
        .filter((oldFieldValue) => !values[oldFieldValue.key])
        .map(async (oldFieldValue) => {
          await API.graphql(
            graphqlOperation(deleteAdditionalServicePricing, {
              input: { id: oldFieldValue.id },
            })
          );
        });

      Promise.all([...createPromises, ...updatePromises, ...deletePromises]);

      onClose(true);
      return;
    } catch (err) {
      console.log("error", err);
      captureError("Upsert vehicle failed", "UPSERT_VEHICLE_FAILED", err);
      notification.show("Jokin meni vikaan");
    }
    setSubmitting(false);
  };

  const onRemove = async (id) => {
    await API.graphql(
      graphqlOperation(deleteVehicle, {
        input: {
          id: data.id,
        },
      })
    );
    setShowConfirmation(false);
    onClose(true);
  };

  const initializeFormikData = () => {
    //Additional fields are represented as flatten fields for formik
    const additionalFields = Object.fromEntries(
      (data?.additionalFields ?? []).map((f) => [f.key, f.value])
    );

    const serviceValues = {};
    (data?.additionalServices.items ?? []).forEach((service) => {
      serviceValues[service.key] = service.pricingId;
      serviceValues[service.key + "Details"] = centsToLocalString(
        service.deductibleValue
      );
    });

    const deductible = data?.deductible
      ? centsToLocalString(data.deductible, "fi-FI")
      : "";
    const stringifyTimes = (times) => {
      if (!times) return null;
      return { start: times.start.join(), return: times.return.join() };
    };

    const activeRange = data?.activeRange
      ? {
          start: new Date(data.activeRange.start),
          end: new Date(data.activeRange.end),
        }
      : INITIAL_FORM_DATA.activeRange;

    const formikData = {
      ...INITIAL_FORM_DATA,
      ...data,
      ...additionalFields,
      ...serviceValues,
      vehiclePricingId: data?.pricing?.id,
      vehicleCategoryId: data?.category?.id,
      deductible: deductible,
      startReturnTimes: INITIAL_FORM_DATA.startReturnTimes.map((times, idx) => {
        return stringifyTimes(data?.startReturnTimes?.[idx]) || times;
      }),
      activeRange: activeRange,
      disableOnline: data?.disableOnline ?? false,
      damageRecords: data?.damageRecords ?? [],
    };
    if (user?.organizationId) {
      // organization users always have useCategoryDefaults and they cannot control the state
      formikData.useCategoryDefaults = true;
    }

    if (data?.startReturnInterval) {
      formikData.useStartReturnInterval = true;
      formikData.vehicleTimesStart = new Date(data.startReturnInterval.start);
      formikData.vehicleTimesEnd = new Date(data.startReturnInterval.end);
    }

    return formikData;
  };
  const ADDITIONAL_FIELDS = useMemo(() => {
    return selectedProductType?.additionalFields ?? ADDITIONAL_FIELDS_CAR;
  }, [selectedProductType]);

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initializeFormikData()}
        validate={(values) => {
          const errors = {};

          if (!values.name) {
            errors.name = "Täytä tämä kenttä";
          }

          if (!values.vehiclePricingId && !values["useCategoryDefaults"]) {
            errors.vehiclePricingId = "Valitse hinnasto";
          }

          if (
            values.deductible !== "" &&
            parseToCents(values.deductible) == null
          ) {
            errors.deductible = 'Anna hinta muodossa "123,45"';
          }

          if (user?.organizationId && !values?.vehicleCategoryId) {
            errors.vehicleCategoryId = "Määritä kategoria";
          }

          if (
            company.enableVehicleStartReturnTimes &&
            values.useStartReturnTimes
          ) {
            // Two formats allowed: HH:MM, HH:MM-HH:MM
            const startReturnTimesResults = values.startReturnTimes.map(
              parseVehicleStartReturnTimes
            );
            const startReturnTimesErrors = [];
            startReturnTimesResults.forEach((r, i) => {
              if (Object.keys(r).length) {
                startReturnTimesErrors[i] = r;
              }
            });
            if (startReturnTimesErrors.length > 0) {
              errors.startReturnTimes = startReturnTimesErrors;
            }
            //
            if (values.useStartReturnInterval) {
              if (values.vehicleTimesStart >= values.vehicleTimesEnd) {
                errors.vehicleTimesEnd =
                  "Lopetuspäivän ei voi olla ennen aloituspäivää";
              }
              if (!values.vehicleTimesStart) {
                errors.vehicleTimesStart = "Valitse aloituspäivä";
              }
              if (!values.vehicleTimesEnd) {
                errors.vehicleTimesEnd = "Valitse lopetuspäivä";
              }
            }
          }

          if (
            company.enableVehicleStartReturnTimes &&
            values.useCategoryDefaults &&
            values.useStartReturnTimes
          ) {
            errors.categoryStartReturnTimeConflict =
              "Kategorian niputusta ja erikoisaikoja ei voi käyttää samaan aikaan";
          }

          if (
            (values.activeRange.start || values.activeRange.end) &&
            !(values.activeRange.start && values.activeRange.end)
          ) {
            errors.activeRange = "Täytä molemmat ajat";
          }
          if (values.activeRange.start && values.activeRange.end) {
            if (
              !isValid(values.activeRange.start) ||
              !isValid(values.activeRange.end)
            ) {
              errors.activeRange = "Tarkista ajat";
            } else if (values.activeRange.end < values.activeRange.start) {
              errors.activeRange = "Aloituksen on oltava ennen lopetusta";
            }
          }
          if (additionalServices) {
            for (const service of additionalServices) {
              if (
                service.category === "deductible" &&
                service.groupParentId &&
                values[service.id]
              ) {
                if (parseToCents(values[service.id + "Details"]) === null) {
                  errors[service.id + "Details"] =
                    "Anna hinta muodossa '123,45'";
                }
              }
            }
          }

          return errors;
        }}
      >
        <DialogForm
          data={data}
          image={image}
          setImage={setImage}
          showCategories={showCategories}
          companies={companies}
          company={company}
          setShowConfirmation={setShowConfirmation}
          showPricings={showPricings}
          onSubmit={onSubmit}
          onClose={onClose}
          submitting={submitting}
          open={open}
          ADDITIONAL_FIELDS={ADDITIONAL_FIELDS}
          selectedProductType={selectedProductType}
          setSelectedProductType={setSelectedProductType}
        />
      </Formik>

      <DialogComponent
        open={showConfirmation}
        dialogClose={() => setShowConfirmation(false)}
        maxWidth="xs"
        dialogAction={() => onRemove()}
        dialogActionText={"Poista"}
        dialogActionColor={"error"}
        testName="deleteProductConfirmation"
      >
        <Typography variant="body1" align="center">
          Haluatko varmasti poistaa tuotteen?
        </Typography>
      </DialogComponent>
    </>
  );
}

const INITIAL_FORM_DATA = {
  name: "",
  companyId: "",
  registrationPlate: "",
  damages: "",
  powerType: "",
  deductible: "",
  includedDistance: "",
  mileage: "",
  orderNumber: 0,
  productType: null,
  vehicleCategoryId: false,
  useCategoryDefaults: false,
  disableOnline: false,
  useStartReturnTimes: false,
  useStartReturnInterval: false,
  vehicleTimesStart: null,
  vehicleTimesEnd: null,
  startReturnTimes: init(7, () => ({
    start: "",
    return: "",
  })),

  extraInfo: "",
  extraToConfirmation: "",
  hideExport: false,
  activeRange: {
    start: null,
    end: null,
  },
};

function DialogForm(props) {
  const {
    data,
    image,
    setImage,
    showCategories,
    companies,
    company,
    setShowConfirmation,
    showPricings,
    onClose,
    submitting,
    open,
    ADDITIONAL_FIELDS,
    setSelectedProductType,
  } = props;
  const categories = useCategories();

  const { setValues, setFieldValue, values, errors, submitForm } =
    useFormikContext();
  const { mobileViewPort, legacyUser, user } = useContext(AppContext);

  const notification = useNotification();

  const [selectedProductTab, setSelectedProductTab] = useState("info");
  const [openDrawer, setOpenDrawer] = useState(() =>
    mobileViewPort ? false : true
  );
  const handleSelectTab = (tab) => {
    setSelectedProductTab(tab);
    if (mobileViewPort) {
      setOpenDrawer((prevState) => !prevState);
    }
  };

  const handleDrawerToggle = () => setOpenDrawer((prevState) => !prevState);

  const [signedUrl, setSignedUrl] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [showSpecialTimes, setShowSpecialTimes] = useState(false);
  const [showChangeCompany, setShowChangeCompany] = useState(false);
  const fileInputRef = useRef();

  function switchProductTab(tabName) {
    switch (tabName) {
      case "info":
        return (
          <ProductInformation
            mobileViewPort={mobileViewPort}
            data={data}
            image={image}
            setImage={setImage}
            setSignedUrl={setSignedUrl}
            onChange={onChange}
            fileInputRef={fileInputRef}
            signedUrl={signedUrl}
            imageLoading={imageLoading}
            setImageLoading={setImageLoading}
            categories={categories}
            showCategories={showCategories}
            showCategoryStacking={showCategoryStacking}
            selectedCategory={selectedCategory}
            FEATURE_CHANGE_COMPANY={true}
            showChangeCompany={showChangeCompany}
            companies={companies}
            setShowChangeCompany={setShowChangeCompany}
            company={company}
          />
        );

      case "price":
        return (
          <ProductPricing
            showPricings={showPricings}
            company={company}
            selectedCategory={selectedCategory}
            servicesOnVehicle={data?.additionalServices.items}
          />
        );
      case "attributes":
        return (
          <ProductAttributes
            ADDITIONAL_FIELDS={ADDITIONAL_FIELDS}
            categories={categories}
            mobileViewPort={mobileViewPort}
          />
        );
      case "time":
        return (
          <ProductTimeSettings
            setShowSpecialTimes={setShowSpecialTimes}
            company={company}
            data={data}
            showSpecialTimes={showSpecialTimes}
          />
        );
      case "damages":
        return <DamageMap data={data} />;
      case "delete":
        return <DeleteProduct setShowConfirmation={setShowConfirmation} />;

      case "copy":
        return (
          <CopyProduct companies={companies} mobileViewPort={mobileViewPort} />
        );

      case "history":
        return <ReservationHistory vehicle={values} />;

      default:
        return (
          <ProductInformation
            image={image}
            setImage={setImage}
            onChange={onChange}
            fileInputRef={fileInputRef}
            signedUrl={signedUrl}
            imageLoading={imageLoading}
            categories={categories}
            showCategories={showCategories}
            showCategoryStacking={showCategoryStacking}
            selectedCategory={selectedCategory}
          />
        );
    }
  }

  const getSignedUrl = async (value) => {
    if (!value) {
      return;
    }
    setImageLoading(true);
    const [identityId, key] = value.split("/");
    const url = key
      ? await Storage.get(key, { identityId, level: "protected" })
      : null;
    setSignedUrl(url);
    setImageLoading(false);
  };

  useEffect(() => {
    getSignedUrl(image);
  }, [image]);
  /*
  const FEATURE_CHANGE_COMPANY = useMemo(() => {
    if (user?.organizationId) {
      return true;
    }
    return showChangeCompanyFeat(import.meta.env.REACT_APP_ENV, data?.group);
  }, [data, user]);
 */
  const selectedCategory = useMemo(() => {
    const categoryId = values["vehicleCategoryId"] ?? null;
    if (categoryId) {
      const category = categories.find((c) => c.id === categoryId);
      return category;
    }
    return null;
  }, [values, categories]);

  const showCategoryStacking = useMemo(() => {
    return company?.group !== "vuokraapaku";
  }, [company]);

  useEffect(() => {
    // Disable categoryDefaults if Category has no Pricing
    if (selectedCategory && !selectedCategory?.pricing && legacyUser) {
      setFieldValue("useCategoryDefaults", false);
    }
  }, [
    selectedCategory,
    values["useCategoryDefaults"],
    values["vehicleCategoryId"],
    legacyUser,
  ]);

  useEffect(() => {
    // Category pricing is displayed when useCategoryDefaults is selected
    if (
      selectedCategory &&
      selectedCategory.pricing &&
      values["useCategoryDefaults"]
    ) {
      const selectedAdditionalServicePricings = Object.fromEntries(
        (selectedCategory?.additionalServices.items ?? []).map((as) => [
          "category-" + as.key,
          as.pricingId,
        ])
      );
      setValues({
        ...values,
        ...selectedAdditionalServicePricings,
        categoryPricingId: selectedCategory?.pricing?.id,
      });
    }
  }, [selectedCategory, values["useCategoryDefaults"]]);

  const onChange = async (e) => {
    setImageLoading(true);
    setImage(null);
    const file = e.target.files[0];
    const type = file.type;
    const ext = type === "image/jpeg" ? "jpg" : "png";
    try {
      const result = await Storage.put(v4() + "." + ext, file, {
        contentType: type,
        level: "protected",
      });

      const creds = await Auth.currentCredentials();
      const imageValue = creds.identityId + "/" + result.key;

      setImage(imageValue);
    } catch (e) {
      captureError("Upload image failed", "UPLOAD_IMAGE_FAILED", e);
      notification.show("Kuvan lataus epäonnistui");
    }
    e.target.value = null;
    setImageLoading(false);
  };

  useEffect(() => {
    if (!data?.category && !values?.vehicleCategoryId) return;
    if (!data?.category?.typeId && !values?.vehicleCategoryId) {
      setSelectedProductType(typeOfProducts[0]);
    }
    if (!!values?.vehicleCategoryId) {
      const findSelectedCategory = categories.find(
        (c) => c.id === values.vehicleCategoryId
      );
      const findProductTypeById = typeOfProducts.find(
        (t) => t.id === findSelectedCategory?.typeId
      );
      setSelectedProductType(findProductTypeById ?? typeOfProducts[0]);
    }
  }, [values?.vehicleCategoryId, categories, data?.category]);

  const menu = [
    {
      title: "Tiedot",
      subTitle: (
        <>
          <>
            {!data && errors.name ? (
              <span style={{ color: "red" }}>Tuotteella ei ole nimeä</span>
            ) : (
              "Täytä tuotekohtaiset tiedot"
            )}
          </>
        </>
      ),
      icon: <TbFileText style={{ fontSize: 28 }} />,
      tab: "info",
      visibility: true,
      active: true,
    },
    {
      title: "Ominaisuudet",
      subTitle: <>Määritä tuotteen tekniset tiedot</>,
      icon: <TbLayout2 style={{ fontSize: 28 }} />,
      tab: "attributes",
      visibility: true,
      active: true,
    },
    {
      title: "Vauriot",
      subTitle: <>Määritä tuotteen vauriot</>,
      icon: <TbCheckupList style={{ fontSize: 28 }} />,
      tab: "damages",
      visibility: enableVehicleDamageMap(
        company?.group ?? user?.organizationId,
        values?.category?.typeId
      ),
      active: true,
    },
    {
      title: "Varaushistoria",
      subTitle: <></>,
      icon: <TbHistory style={{ fontSize: 28 }} />,
      tab: "history",
      visibility: true,
      active: true,
    },
    {
      title: "Hinnasto ja lisäpalvelut",
      subTitle: (
        <>
          <>
            {errors.vehiclePricingId ? (
              <span style={{ color: "red" }}>Tuotteella ei ole hinnastoa</span>
            ) : (
              <>
                Tuoteen hinnasto on{" "}
                {data?.pricing?.name ? data?.pricing?.name : "valittu"}{" "}
              </>
            )}
          </>
        </>
      ),
      icon: <TbCurrencyEuro style={{ fontSize: 28 }} />,
      tab: "price",
      visibility: true,
      active: true,
    },
    {
      title: "Erikoisajat",
      subTitle: <>Valitse tuoteelle erikoisnouto ja palautusajat</>,
      icon: <TbAlarm style={{ fontSize: 28 }} />,
      tab: "time",
      visibility: true,
      active: company.enableVehicleStartReturnTimes,
    },
    {
      title: "Kopio tuote",
      subTitle: <>Luo uusi tuote samoilla tiedoilla</>,
      icon: <TbCopy style={{ fontSize: 28 }} />,
      tab: "copy",
      visibility: true,
      active: true,
    },
    {
      title: "Poista tuote",
      subTitle: <>Poista tuote tietokannasta</>,
      icon: <TbTrash style={{ fontSize: 28 }} />,
      tab: "delete",
      visibility: true,
      active: true,
    },
  ];

  /*   {data && !data?.category?.typeId && data?.category && (
    <Alert color="warning" severity="warning">
      Kategorialle ei ole määritetty tuoteryhmää vielä.
    </Alert>
  )} */

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogContent sx={{ height: "80vh", overflow: "hidden" }}>
        <Stack
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            position: "relative",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {mobileViewPort && (
            <Box>
              <IconButton
                onClick={() => handleDrawerToggle()}
                style={{ padding: 0, marginLeft: 5, marginBottom: 3 }}
                size="large"
                data-cy="productDialogOpenSidebarMobile"
              >
                <TbMenu2 style={{ fontSize: 32 }} />
              </IconButton>
            </Box>
          )}
          <Box sx={{ display: "flex", width: "90%" }}>
            <Typography
              align="center"
              sx={{
                fontFamily: "Sofia Pro",
                fontSize: "24px",
                marginLeft: "10px",
              }}
              noWrap
            >
              {data?.name ? data?.name : ""}
            </Typography>
          </Box>
        </Stack>

        <Divider />

        <DialogMenu
          openDrawer={openDrawer}
          handleDrawerToggle={handleDrawerToggle}
          selectedProductTab={selectedProductTab}
          setSelectedProductTab={setSelectedProductTab}
          handleSelectTab={handleSelectTab}
          menu={menu}
          minHeight={"750px"}
        >
          {switchProductTab(selectedProductTab)}
        </DialogMenu>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Sulje</Button>
        <Button variant="contained" onClick={submitForm} disabled={submitting}>
          Vahvista
        </Button>
      </DialogActions>
    </Dialog>
  );
}
