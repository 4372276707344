import { Stack, Typography } from "@mui/material";
import { EPaymentStatus } from "./LanguagePack/translatedFields";

export default function ContractPaymentStatus(props) {
  const { reservation, selectedLanguage } = props;
  const fields = EPaymentStatus[selectedLanguage];

  return (
    <Stack sx={{ marginTop: "15px" }} direction={"row"}>
      <Stack direction={"row"}>
        <Typography sx={headerTextStyles}>{fields.PAYMENT_METHOD}: </Typography>
        {/* 
TODO: ADD PAYMENT METHOD
*/}
      </Stack>
      <span style={{ flex: 0.2 }} />
      <Stack direction={"row"}>
        <Typography sx={headerTextStyles}>{fields.PAYMENT_STATUS}:</Typography>
        <Typography sx={fontSize}>
          {reservation.paymentStatus === "COMPLETED"
            ? fields.PAYMENT_STATUS_COMPLETED
            : null}
        </Typography>
      </Stack>
    </Stack>
  );
}

const headerTextStyles = {
  fontWeight: "bold",
  fontSize: "12px",
  margin: "0",
  marginRight: "5px",
};

const fontSize = { fontSize: "12px" };
