import { useContext } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useFormikContext } from "formik";
import print from "../../../print-contracts/print-contract";
import { getContract } from "../utils/getContract";
import { AppContext } from "../../../AppContext";
import { ReservationContext } from "../contextProviders/ReservationContext";
import { useFetchVehicles } from "../../../services/fetchVehicles";
import { useValidateForm } from "../formik/hooks/useValidateForm";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../atoms/reservationDataAtom";
import { TbPrinter } from "react-icons/tb";
import { clientLogoData } from "../../../customerLogos/ClientLogoData";
import vanDamageMap from "../../../assets/damageMapImages/van_damagemap.webp";
import carDamageMap from "../../../assets/damageMapImages/car_damagemap.webp";

export const PrintingComponent = () => {
  const { submitForm } = useFormikContext();
  const { vehicleData } = useFetchVehicles();
  const { isSubmitting } = useFormikContext();
  const { user, company, timezone } = useContext(AppContext);
  const reservationData = useAtomValue(reservationDataAtom);
  const { selectedLanguage } = useContext(ReservationContext);
  const { validate } = useValidateForm();
  if (reservationData?.id)
    return (
      <Box>
        <Tooltip title={"Tulosta sopimus"} placement="top">
          <IconButton
            disabled={isSubmitting}
            onClick={async (event) => {
              const isValid = await validate();
              if (!isValid) return;
              const response = await submitForm();
              if (response?.success) {
                const userEntity = company?.group ?? user?.organizationId;
                const logo = clientLogoData.find(
                  (item) => item.id === userEntity
                )?.logo;

                // This removes any undefineds etc. from the list that might prevent the printing to go through.
                const imageUrls = [vanDamageMap, carDamageMap, logo].filter(
                  (url) => Boolean(url)
                );
                await Promise.all(
                  imageUrls.map((url) => {
                    return new Promise((resolve, reject) => {
                      const img = new Image();
                      img.onload = resolve;
                      img.onerror = reject;
                      img.src = url;
                    });
                  })
                );
                // await new Promise((resolve) => setTimeout(resolve, 1000)); This delay might help the issues with images not loading in time.

                print(
                  getContract(
                    response?.data?.reservation,
                    vehicleData,
                    user?.group,
                    user,
                    company,
                    timezone,
                    selectedLanguage
                  )
                );
              }
            }}
          >
            <TbPrinter style={{ fontSize: "1.5rem" }} />
          </IconButton>
        </Tooltip>
      </Box>
    );
};
