import { centsToLocalString } from "../../../shared/money";
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { EContractSummary } from "./LanguagePack/translatedFields";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ContractPaymentStatus from "./ContractPaymentStatus";
export default function ContractPrices({
  reservation,
  getVehicle,
  selectedLanguage,
  additionalServices,
}) {
  const fields = EContractSummary[selectedLanguage];
  const hideContractPrices = reservation.disablePricesInPrintouts;

  if (!reservation?.reservationVehicles) return null;
  var sumByTax = reservation.reservationVehicles.reduce((acc, curr) => {
    curr.tax = curr.tax ?? 24;

    if (acc[curr.tax]) {
      acc[curr.tax].total += curr.price;
      acc[curr.tax].taxless += curr.price / (curr.tax / 100 + 1);
    } else {
      acc[curr.tax] = {
        total: curr.price,
        tax: curr.tax,
        taxless: curr.price / (curr.tax / 100 + 1),
      };
    }

    return acc;
  }, {});

  reservation?.additionalServices.forEach((obj) => {
    obj.tax = obj.tax ?? 24;
    if (sumByTax[obj.tax]) {
      sumByTax[obj.tax].total += obj.price;
      sumByTax[obj.tax].taxless += obj.price / (obj.tax / 100 + 1);
    } else {
      sumByTax[obj.tax] = {
        total: obj.price,
        tax: obj.tax,
        taxless: obj.price / (obj.tax / 100 + 1),
      };
    }
  });

  sumByTax = Object.values(sumByTax);
  const totalVehiclesPrice = reservation.reservationVehicles.reduce(
    (p, c) => p + c.price,
    0
  );

  const totalPrice = reservation.additionalServices.reduce(
    (p, c) => p + c.price,
    totalVehiclesPrice
  );

  const totalTaxlessPrice = sumByTax.reduce((p, c) => p + c.taxless, 0);

  const taxOfPrice = (price, tax) => {
    var taxValue = tax ?? 24;
    taxValue = taxValue / 100 + 1;

    const taxPercentage = 1 - 1 / taxValue;
    return price * taxPercentage;
  };

  const taxlessPrice = (price, tax) => {
    const taxValue = tax ?? 24;
    return price / (taxValue / 100 + 1);
  };
  return (
    <Grid
      item
      xs={12}
      sx={{
        marginY: "5px",
      }}
    >
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            border: "none",
          },
          margin: 0,
        }}
        size="small"
      >
        <TableHead
          sx={{
            borderTop: "2px solid lightgrey",
            borderBottom: " 2px solid lightgrey",
          }}
        >
          <TableRow>
            <TableCell sx={{ width: "40%", ...cellStyles }}>
              <Typography sx={headerTextStyles}>{fields.PRODUCT}</Typography>
            </TableCell>
            <TableCell align="right" sx={cellStyles}>
              <Typography sx={headerTextStyles}>{fields.QUANTITY}</Typography>
            </TableCell>
            <PriceColumns fields={fields} visibility={!hideContractPrices} />
          </TableRow>
        </TableHead>
        <TableBody>
          {reservation?.reservationVehicles
            ? reservation?.reservationVehicles.map((v) => (
                <TableRow key={v.id}>
                  <TableCell sx={cellStyles}>
                    <Typography sx={fontSize}>
                      {getVehicle(v?.id)?.name}
                    </Typography>
                  </TableCell>

                  <TableCell align="right" sx={cellStyles}>
                    <Typography sx={fontSize}>{v?.quantity ?? 1}</Typography>
                  </TableCell>
                  <PriceRows
                    visibility={!hideContractPrices}
                    item={v}
                    taxlessPrice={taxlessPrice}
                    taxOfPrice={taxOfPrice}
                  />
                </TableRow>
              ))
            : null}

          {additionalServices
            ? additionalServices.map((item) => (
                <TableRow key={item?.id || item?.key}>
                  <TableCell sx={cellStyles}>
                    <Typography sx={fontSize}>{item?.description}</Typography>
                  </TableCell>

                  <TableCell align="right" sx={cellStyles}>
                    <Typography sx={fontSize}>{item?.quantity ?? 1}</Typography>
                  </TableCell>
                  <PriceRows
                    visibility={!hideContractPrices}
                    item={item}
                    taxlessPrice={taxlessPrice}
                    taxOfPrice={taxOfPrice}
                  />
                </TableRow>
              ))
            : null}
          {!hideContractPrices && (
            <TableRow sx={{ borderTop: "2px solid lightgrey" }}>
              <TableCell sx={cellStyles}>
                <Typography sx={headerTextStyles} align="right">
                  {fields.TOTAL}
                </Typography>
              </TableCell>
              <TableCell sx={cellStyles} />
              <TableCell sx={cellStyles} />
              <TableCell align="right" sx={cellStyles}>
                <Typography sx={headerTextStyles}>
                  {centsToLocalString(totalTaxlessPrice)} €
                </Typography>
              </TableCell>
              <TableCell align="right" sx={cellStyles}>
                <Typography sx={headerTextStyles}>
                  {sumByTax.map((v) =>
                    centsToLocalString(taxOfPrice(v.total, v.tax))
                  )}{" "}
                  €
                </Typography>
              </TableCell>
              <TableCell align="right" sx={cellStyles}>
                <Typography sx={headerTextStyles}>
                  {centsToLocalString(totalPrice)} €
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <ContractPaymentStatus
        reservation={reservation}
        selectedLanguage={selectedLanguage}
      />
    </Grid>
  );
}
const headerTextStyles = {
  fontWeight: "bold",
  fontSize: "12px",
  paddingY: "5px",
  margin: "0",
};

const cellStyles = {
  padding: "2px",
};

const fontSize = { fontSize: "12px" };

const PriceColumns = (props) => {
  const { visibility, fields } = props;

  if (visibility)
    return (
      <>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={headerTextStyles}>{fields.PRICE}</Typography>
        </TableCell>

        <TableCell align="right" sx={cellStyles}>
          <Typography sx={headerTextStyles}>{fields.VAT_EXCLUDED}</Typography>
        </TableCell>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={headerTextStyles}>{fields.VAT}</Typography>
        </TableCell>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={headerTextStyles}>{fields.TOTAL}</Typography>
        </TableCell>
      </>
    );
};

const PriceRows = (props) => {
  const { visibility, item, taxlessPrice, taxOfPrice } = props;

  if (visibility)
    return (
      <>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={fontSize}>
            {centsToLocalString(item?.price)} €
          </Typography>
        </TableCell>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={fontSize}>
            {centsToLocalString(taxlessPrice(item?.price, item?.tax))} €
          </Typography>
        </TableCell>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={fontSize}>
            {`${centsToLocalString(taxOfPrice(item?.price, item?.tax))}€ (${
              item?.tax ?? 24
            }%)`}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={fontSize}>
            {centsToLocalString(item?.price)} €
          </Typography>
        </TableCell>
      </>
    );
};
