import React, { useMemo, useContext, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Select,
  MenuItem,
  Button,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { FormGrid } from "./common/FormComponents";
import { AppContext } from "../AppContext";
import { useFormikContext } from "formik";
import { API, graphqlOperation } from "aws-amplify";
import { updateInnovoiceStatus } from "../graphql/mutations";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useNotification } from "./Notification";
import { useReservationContext } from "./Reservation/ReservationContext";
import captureError from "../utils/capture-error";
import { useFetchVehicles } from "../services/fetchVehicles";

export default function ExternalDataList() {
  const { reservationData, closeDialog, startTime, returnTime } =
    useReservationContext();
  const externalData = reservationData?.externalData;
  const { USER_ADMIN_GENERIC, mobileViewPort } = useContext(AppContext);
  const { values, handleBlur, handleChange, errors, touched, setFieldValue } =
    useFormikContext();

  const notification = useNotification();
  const { vehicleData } = useFetchVehicles();

  const [apiCallTab, setApiCallTab] = useState(0);
  const [buttonMessage, setButtonMessage] = useState("");
  const [buttonMessageStyle, setButtonMessageStyle] = useState("primary");
  const [isLoading, setIsLoading] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(true);

  const [initialStatus, setInitialStatus] = useState(
    values?.innovoiceStatus || ""
  );
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /*   const FEATURE_SCANDIA_RENT = useMemo(() => {
    return devStaging(process.env.REACT_APP_ENV, company);
  }, [company]);
 */
  const getStatusTranslation = (status) => {
    switch (status) {
      case "ACCEPTED":
        return "Hyväksytty";
      case "RETRIEVED":
        return "Noudettu";
      case "RETURNED":
        return "Palautettu";
      case "CANCELLED":
        return "Peruutettu";
      case "NONE":
        return "Ei hyväksytty";
      default:
        return "";
    }
  };

  const generalInfo = useMemo(() => {
    if (!externalData) return null;
    return {
      "Tilauksen ID": externalData.orderId,
      "Tilauksen viite": externalData.orderReference,
      "Palvelusopimuksen nimi": externalData.serviceAgreementName,
      "Tilauskäsittelijän nimi": externalData.operatorName,
      "Tilauksen tiedot": externalData.orderInfo,
      "Laskutuksen maksaja": externalData.billingPayer,
      "Laskutuksen kuvaus": externalData.billingDescription,
    };
  }, [externalData]);

  const customerVehicle = useMemo(() => {
    if (!externalData) return null;
    const vehicle = externalData.customerVehicle;
    return (
      vehicle && {
        Merkki: vehicle.brand,
        Malli: vehicle.model,
        Rekisterinumero: vehicle.registrationPlate,
        "Omistajan nimi": vehicle.owner?.name,
        "Omistajan osoite": vehicle.owner?.address,
      }
    );
  }, [externalData]);

  const tabStatusMapping = useMemo(() => {
    return {
      0: "ACCEPTED",
      1: "RETRIEVED",
      2: "RETURNED",
      3: "CANCELLED",
    };
  }, []);

  useEffect(() => {
    const innovoiceStatusToTabIndex = {
      NONE: 0, // Show accepted
      ACCEPTED: 1, // Show retrieved
      RETRIEVED: 2, // Show returned
      RETURNED: 2, // Show returned
      CANCELLED: 3, // Show cancelled
    };

    // Set the initial tab based on the current innovoiceStatus
    const initialTab = innovoiceStatusToTabIndex[values.innovoiceStatus] || 0;
    setApiCallTab(initialTab);

    const deliveryTimestamp =
      values?.reservationStatusInfo?.deliveryTimestamp ?? startTime;
    const returnTimestamp =
      values?.reservationStatusInfo?.returnTimestamp ?? returnTime;
    setFieldValue(
      "reservationStatusInfo.deliveryTimestamp",
      deliveryTimestamp ? new Date(deliveryTimestamp) : null
    );
    setFieldValue(
      "reservationStatusInfo.returnTimestamp",
      returnTimestamp ? new Date(returnTimestamp) : null
    );

    if (!values?.reservationStatusInfo?.deliveryCountry) {
      setFieldValue("reservationStatusInfo.deliveryCountry", "Finland");
    }
    if (!values?.reservationStatusInfo?.returnCountry) {
      setFieldValue("reservationStatusInfo.returnCountry", "Finland");
    }
  }, []);

  const buttonInfo = useMemo(() => {
    setUpdateStatus(true);
    setButtonMessage("");
    setButtonMessageStyle("primary");
    const tabStatus = tabStatusMapping[apiCallTab];
    const isNone = !values.innovoiceStatus || values.innovoiceStatus === "NONE";
    const isAccepted = values.innovoiceStatus === "ACCEPTED";
    const isCancelled = values.innovoiceStatus === "CANCELLED";
    const isRetrieved = values.innovoiceStatus === "RETRIEVED";
    const isReturned = values.innovoiceStatus === "RETURNED";

    if (tabStatus === "ACCEPTED") {
      return {
        color: "success",
        text: "Hyväksy tilaus",
        isDisabled: !isNone,
      };
    }

    if (tabStatus === "CANCELLED") {
      if (!!values.reservationStatusInfo?.cancelReason) {
        setButtonMessageStyle("error");
        setButtonMessage("Tilauksen peruuttaminen poistaa varauksen.");
      }
      if (isCancelled) {
        setButtonMessageStyle("");
        setButtonMessage("");
      }
      return {
        color: "error",
        text: "Peruuta tilaus",
        isDisabled: isCancelled,
      };
    }

    if (isCancelled) {
      setButtonMessage("");
      setButtonMessageStyle("");
      return {
        color: "success",
        text: "Hyväksy",
        isDisabled: true,
      };
    } else if (isReturned) {
      if (tabStatus === "RETRIEVED") {
        setUpdateStatus(false);
        setButtonMessage(
          "Syötä arvot ja paina viereistä painiketta päivittääksesi noudon tiedot."
        );
        return {
          color: "primary",
          text: "Päivitä noudon tiedot",
          isDisabled: false,
        };
      } else if (tabStatus === "RETURNED") {
        setUpdateStatus(false);
        setButtonMessage(
          "Syötä arvot ja paina viereistä painiketta päivittääksesi palautuksen tiedot."
        );
        return {
          color: "primary",
          text: "Päivitä palautuksen tiedot",
          isDisabled: false,
        };
      }
    } else if (isRetrieved) {
      if (tabStatus === "RETRIEVED") {
        setUpdateStatus(false);
        setButtonMessage(
          "Syötä arvot ja paina viereistä painiketta päivittääksesi noudon tiedot."
        );
        return {
          color: "primary",
          text: "Päivitä noudon tiedot",
          isDisabled: false,
        };
      }
      if (tabStatus === "RETURNED") {
        setButtonMessage(
          `Paina viereistä nappia hyväksyäksesi ${
            tabStatus === "RETRIEVED" ? "nouto" : "palautus"
          }.`
        );
        setButtonMessageStyle("success.main");
        return {
          color: "success",
          text: "Hyväksy palautus",
          isDisabled: isReturned,
        };
      }
    } else if (isAccepted) {
      if (tabStatus === "RETURNED") {
        setUpdateStatus(false);
        setButtonMessage(
          "Syötä arvot ja paina viereistä painiketta päivittääksesi noudon tiedot."
        );
        return {
          color: "primary",
          text: "Päivitä noudon tiedot",
          isDisabled: false,
        };
      }
      if (tabStatus === "RETRIEVED") {
        setButtonMessage(
          `Paina viereistä nappia hyväksyäksesi ${
            tabStatus === "RETRIEVED" ? "nouto" : "palautus"
          }.`
        );
        setButtonMessageStyle("success.main");
        return {
          color: "success",
          text: "Hyväksy nouto",
          isDisabled: isReturned,
        };
      }
    } else {
      setButtonMessage(
        `Paina viereistä nappia hyväksyäksesi ${
          tabStatus === "RETRIEVED" ? "nouto" : "palautus"
        }.`
      );
      setButtonMessageStyle("success.main");
      return {
        color: "success",
        text: `Hyväksy ${tabStatus === "RETRIEVED" ? "nouto" : "palautus"}`,
        isDisabled: isReturned,
      };
    }
  }, [
    tabStatusMapping,
    apiCallTab,
    values.innovoiceStatus,
    values.reservationStatusInfo?.cancelReason,
  ]);

  const locationInfo = (location) => {
    return (
      location && {
        Kuvaus: location.description,
        Katuosoite: location.address,
        Kaupunki: location.city,
        Koordinaatit: location.coords
          ? `${location.coords.lat}, ${location.coords.long}`
          : undefined,
      }
    );
  };

  const handleApiCallTabChange = (event, newValue) => {
    setApiCallTab(newValue);
  };

  const handleUpdateInnovoiceStatus = async (newStatus) => {
    setMessage("");
    setMessageType("");
    try {
      setIsLoading(true);
      const GENERAL_ERROR_MSG = "Täytä tämä kenttä";

      const validateFields = async () => {
        if (newStatus !== "RETRIEVED" && newStatus !== "RETURNED") {
          return true;
        }

        let hasError = false;

        const requiredFields = {
          RETRIEVED: ["deliveryTimestamp", "deliveryCountry"],
          RETURNED: ["returnTimestamp", "returnCountry"],
        };

        for (const field of requiredFields[newStatus]) {
          if (!values.reservationStatusInfo[field]) {
            errors[field] = GENERAL_ERROR_MSG;
            touched[field] = true;
            hasError = true;
          }
        }

        return !hasError;
      };

      const isValid = await validateFields();

      if (!isValid) {
        return;
      }

      let payload = {};
      setIsLoading(true);

      switch (newStatus) {
        case "ACCEPTED":
          payload = {
            orderId: reservationData?.externalData?.orderId,
            uRentCarRentalId: reservationData?.orgBusinessId,
          };
          break;
        case "RETRIEVED":
          const matchingVehicle = vehicleData.find(
            (vehicle) => vehicle.id === values.vehicleIds[0]
          );
          if (!matchingVehicle) {
            notification.show("Valitse ensin ajoneuvo", "warning", 5000);
            return;
          }
          payload = {
            orderId: reservationData?.externalData?.orderId,
            uRentCarRentalId: reservationData?.orgBusinessId,
            deliveryTimestamp: values?.reservationStatusInfo?.deliveryTimestamp,
            vehicle: {
              mark: externalData?.customerVehicle?.brand,
              model: externalData?.customerVehicle?.model,
              class: matchingVehicle.category.name,
            },
            deliveryLocation: {
              address: values?.reservationStatusInfo?.deliveryAddress,
              zipcode: values?.reservationStatusInfo?.deliveryZipcode,
              city: values?.reservationStatusInfo?.deliveryCity,
              country: values?.reservationStatusInfo?.deliveryCountry,
              description: values?.reservationStatusInfo?.deliveryDescription,
              latitude: null,
              longitude: null,
            },
          };
          break;
        case "RETURNED":
          // The daysBetween is necessary for the API so throw error if it can't be counted.
          if (
            !values.reservationStatusInfo?.returnTimestamp ||
            !values.reservationStatusInfo?.deliveryTimestamp
          ) {
            throw new Error(
              "Both returnTimestamp and deliveryTimestamp are required."
            );
          }

          // Calculate rental days rounded up
          //const timeDiff =
          //values.reservationStatusInfo?.returnTimestamp -
          //values.reservationStatusInfo?.deliveryTimestamp;
          // For now use returnTime and startTime for this calculation
          let daysBetween = 0;
          try {
            const timeDiff = returnTime - startTime;
            daysBetween = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
          } catch (e) {
            captureError(
              "Calculating actualRentalDays failed",
              "ACTUALRENTALDAYS_CALC_FAIL",
              e
            );
          }
          payload = {
            orderId: reservationData?.externalData?.orderId,
            uRentCarRentalId: reservationData?.orgBusinessId,
            returnTimestamp: values?.reservationStatusInfo?.returnTimestamp,
            actualRentalDays: daysBetween,
            returnLocation: {
              address: values?.reservationStatusInfo?.returnAddress,
              zipcode: values?.reservationStatusInfo?.returnZipcode,
              city: values?.reservationStatusInfo?.returnCity,
              country: values?.reservationStatusInfo?.returnCountry,
              description: values?.reservationStatusInfo?.returnDescription,
              latitude: null,
              longitude: null,
            },
          };
          break;
        default:
          break;
      }

      // If the button pressed is what it is then

      // If this fails don't set field values.
      const response = (
        await API.graphql(
          graphqlOperation(updateInnovoiceStatus, {
            reservationId: reservationData.id,
            innovoiceStatus: updateStatus ? newStatus : initialStatus,
            payload: payload,
          })
        )
      ).data.updateInnovoiceStatus;

      if (response.success) {
        setMessage("Päivitys onnistui!");
        notification.show("Päivitys onnistui!", "success");
        setFieldValue(
          "innovoiceStatus",
          updateStatus ? newStatus : initialStatus
        );
        setInitialStatus(updateStatus ? newStatus : initialStatus);
        setMessageType("success.main");
        setIsLoading(false);
      } else {
        let message = "Tuntematon virhe";
        if (response.message) {
          message = `Virhe: ${response.message}`;
        }
        setMessage(message);
        setMessageType("error");
      }
    } catch (error) {
      if (
        error instanceof TypeError &&
        error.message.includes("matchingVehicle is undefined")
      ) {
        // Handle the specific TypeError when no vehicles are chosen.
        setMessage("Varaukselle ei ole valittu ajoneuvoa.");
        setMessageType("error");
        setIsLoading(false);
      } else {
        // Handle other errors as usual
        setMessage("Tuntematon virhe suorittaessa päivitystä.");
        setMessageType("error");
        setIsLoading(false);
        console.error("Error updating reservation status:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelReservation = async () => {
    setMessage("");
    setMessageType("");
    setIsLoading(true);
    const payload = {
      orderId: reservationData?.externalData?.orderId,
      uRentCarRentalId: reservationData?.orgBusinessId,
      cancelReason: values?.reservationStatusInfo?.cancelReason,
    };

    try {
      const response = await API.graphql(
        graphqlOperation(updateInnovoiceStatus, {
          reservationId: reservationData.id,
          innovoiceStatus: "CANCELLED",
          payload: payload,
        })
      );

      if (response.data.updateInnovoiceStatus) {
        setMessage("Peruutus onnistui!");
        setFieldValue("innovoiceStatus", "CANCELLED");
        setInitialStatus("CANCELLED");
        setMessageType("success.main");
        notification.show("Tilaus peruutettu onnistuneesti.");
        closeDialog(true, true);
        setIsLoading(false);
      } else {
        setMessage("Jokin meni vikaan!");
        setMessageType("error");
        setIsLoading(false);
      }
    } catch (error) {
      setMessage("Virhe suorittaessa peruutusta.");
      setMessageType("error");
      setIsLoading(false);
      console.error("Error updating reservation status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const DataBox = ({ title, data }) => {
    return (
      data && (
        <Card
          style={{ marginBottom: "20px", boxShadow: "none", margin: "10px" }}
        >
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            {Object.entries(data).map(
              ([key, value]) =>
                value && (
                  <Typography key={key}>
                    <strong>{key}:</strong> {value}
                  </Typography>
                )
            )}
          </CardContent>
        </Card>
      )
    );
  };
  const showUpdateStatus = USER_ADMIN_GENERIC;
  // If the channel is not "AUTOLIITTO"
  // or if the data doesnt exist, return nothing
  if (reservationData?.channel !== "AUTOLIITTO" && USER_ADMIN_GENERIC) {
    return (
      <Grid
        item
        container
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontWeight: "bolder" }}>Varauksen tila</Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Select
            id="rentalStatus"
            name="rentalStatus"
            value={values.rentalStatus || "NONE"}
            fullWidth
            onChange={handleChange}
            size="small"
          >
            <MenuItem value="NONE">
              <i>Ei valittu</i>
            </MenuItem>

            <MenuItem value="RETRIEVED">Noudettu</MenuItem>
            <MenuItem value="RETURNED">Palautettu</MenuItem>
          </Select>
        </Grid>
      </Grid>
    );
  }

  if (!externalData) return null;

  return (
    <Grid container>
      {showUpdateStatus && (
        <FormGrid xs={12} md={12}>
          <Typography>
            Autoliiton tilapäivitys:
            {getStatusTranslation(values.innovoiceStatus)}
          </Typography>
          {message && (
            <Grid item xs={12}>
              <Typography color={messageType} align="left" variant="body2">
                {message}
              </Typography>
            </Grid>
          )}
          <Box sx={{ display: "flex", width: "100%" }}>
            <Card sx={{ p: 2, mt: 2, width: "100%" }}>
              <CardContent>
                <ContentMenu
                  apiCallTab={apiCallTab}
                  handleApiCallTabChange={handleApiCallTabChange}
                  values={values}
                />
                {apiCallTab === 0 && (
                  <Grid
                    container
                    justifyContent={mobileViewPort ? "center" : "flex-start"}
                    sx={{ marginTop: "15px" }}
                  >
                    <Button
                      fullWidth
                      sx={{
                        textTransform: "none",
                        height: "40px",
                        width: "175px",
                      }}
                      variant="contained"
                      color={buttonInfo.color}
                      onClick={async () => {
                        await handleUpdateInnovoiceStatus("ACCEPTED");
                      }}
                      disabled={isLoading || buttonInfo.isDisabled}
                    >
                      {isLoading ? "Lataa..." : "Hyväksy tilaus"}
                    </Button>
                  </Grid>
                )}
                {apiCallTab === 1 && (
                  <div>
                    <Grid container my={1} spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <DateTimePicker
                          inputFormat="dd.MM.yyyy HH:mm"
                          ampm={false}
                          minutesStep={5}
                          disabled={values.innovoiceStatus === "CANCELLED"}
                          label="Toimitusaika"
                          value={values.reservationStatusInfo.deliveryTimestamp}
                          onChange={async (date) => {
                            await setFieldValue(
                              "reservationStatusInfo.deliveryTimestamp",
                              date
                            );
                          }}
                          slotProps={{
                            textField: {
                              onBlur: handleBlur,
                              name: "reservationStatusInfo.deliveryTimestamp",
                              error:
                                touched["deliveryTimestamp"] &&
                                !!errors["deliveryTimestamp"],
                              helperText:
                                touched["deliveryTimestamp"] &&
                                errors["deliveryTimestamp"],
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Toimitusosoite"
                          variant="outlined"
                          fullWidth
                          disabled={values.innovoiceStatus === "CANCELLED"}
                          value={values.reservationStatusInfo.deliveryAddress}
                          name={"reservationStatusInfo.deliveryAddress"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Postinumero"
                          variant="outlined"
                          fullWidth
                          disabled={values.innovoiceStatus === "CANCELLED"}
                          value={values.reservationStatusInfo.deliveryZipcode}
                          name={"reservationStatusInfo.deliveryZipcode"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Kaupunki"
                          variant="outlined"
                          fullWidth
                          disabled={values.innovoiceStatus === "CANCELLED"}
                          value={values.reservationStatusInfo.deliveryCity}
                          name={"reservationStatusInfo.deliveryCity"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Maa"
                          variant="outlined"
                          fullWidth
                          disabled={values.innovoiceStatus === "CANCELLED"}
                          value={values.reservationStatusInfo.deliveryCountry}
                          name={"reservationStatusInfo.deliveryCountry"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched["deliveryCountry"] &&
                            !!errors["deliveryCountry"]
                          }
                          helperText={
                            touched["deliveryCountry"] &&
                            errors["deliveryCountry"]
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Kuvaus"
                          variant="outlined"
                          fullWidth
                          disabled={values.innovoiceStatus === "CANCELLED"}
                          value={
                            values.reservationStatusInfo.deliveryDescription
                          }
                          name={"reservationStatusInfo.deliveryDescription"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        margin=".1dvh"
                      >
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Button
                            fullWidth
                            sx={{
                              textTransform: "none",
                              height: "40px",
                            }}
                            variant="contained"
                            color={buttonInfo.color}
                            onClick={async () => {
                              await handleUpdateInnovoiceStatus("RETRIEVED");
                            }}
                            disabled={isLoading || buttonInfo.isDisabled}
                          >
                            {isLoading ? "Lataa..." : buttonInfo.text}
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={5} md={7} lg={8}>
                          {!!buttonMessage && (
                            <Typography
                              variant="body2"
                              color={buttonMessageStyle}
                            >
                              {buttonMessage}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {apiCallTab === 2 && (
                  <div>
                    <Grid container my={1} spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <DateTimePicker
                          inputFormat="dd.MM.yyyy HH:mm"
                          ampm={false}
                          minutesStep={5}
                          disabled={values.innovoiceStatus === "CANCELLED"}
                          label="Palautusaika"
                          value={values.reservationStatusInfo.returnTimestamp}
                          onChange={async (date) => {
                            await setFieldValue(
                              "reservationStatusInfo.returnTimestamp",
                              date
                            );
                          }}
                          slotProps={{
                            textField: {
                              onBlur: handleBlur,
                              name: "reservationStatusInfo.returnTimestamp",
                              error:
                                touched["returnTimestamp"] &&
                                !!errors["returnTimestamp"],
                              helperText:
                                touched["returnTimestamp"] &&
                                errors["returnTimestamp"],
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Palautusosoite"
                          variant="outlined"
                          fullWidth
                          disabled={values.innovoiceStatus === "CANCELLED"}
                          value={values.reservationStatusInfo.returnAddress}
                          name={"reservationStatusInfo.returnAddress"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Postinumero"
                          variant="outlined"
                          fullWidth
                          disabled={values.innovoiceStatus === "CANCELLED"}
                          value={values.reservationStatusInfo.returnZipcode}
                          name={"reservationStatusInfo.returnZipcode"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Kaupunki"
                          variant="outlined"
                          fullWidth
                          disabled={values.innovoiceStatus === "CANCELLED"}
                          value={values.reservationStatusInfo.returnCity}
                          name={"reservationStatusInfo.returnCity"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Maa"
                          variant="outlined"
                          fullWidth
                          disabled={values.innovoiceStatus === "CANCELLED"}
                          value={values.reservationStatusInfo.returnCountry}
                          name={"reservationStatusInfo.returnCountry"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched["returnCountry"] &&
                            !!errors["returnCountry"]
                          }
                          helperText={
                            touched["returnCountry"] && errors["returnCountry"]
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Kuvaus"
                          variant="outlined"
                          fullWidth
                          disabled={values.innovoiceStatus === "CANCELLED"}
                          value={values.reservationStatusInfo.returnDescription}
                          name={"reservationStatusInfo.returnDescription"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        margin=".1dvh"
                      >
                        <Grid item xs={11} sm={6} md={4} lg={3}>
                          <Button
                            fullWidth
                            sx={{
                              textTransform: "none",
                              height: "40px",
                            }}
                            variant="contained"
                            color={buttonInfo.color}
                            onClick={async () => {
                              await handleUpdateInnovoiceStatus("RETURNED");
                            }}
                            disabled={isLoading || buttonInfo.isDisabled}
                          >
                            {isLoading ? "Lataa..." : buttonInfo.text}
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={5} md={7} lg={8}>
                          {!!buttonMessage && (
                            <Typography
                              variant="body2"
                              color={buttonMessageStyle}
                            >
                              {buttonMessage}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {apiCallTab === 3 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Peruutuksen syy"
                        variant="outlined"
                        multiline
                        disabled={values.innovoiceStatus === "CANCELLED"}
                        rows={4}
                        fullWidth
                        value={values.reservationStatusInfo?.cancelReason}
                        name={"reservationStatusInfo.cancelReason"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched["cancelReason"] && !!errors["cancelReason"]
                        }
                        helperText={
                          touched["cancelReason"] && errors["cancelReason"]
                        }
                      />
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      margin=".1dvh"
                    >
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Button
                          fullWidth
                          sx={{
                            textTransform: "none",
                            height: "40px",
                          }}
                          variant="contained"
                          color={buttonInfo.color}
                          onClick={async () => {
                            await handleCancelReservation();
                          }}
                          disabled={isLoading || buttonInfo.isDisabled}
                        >
                          {isLoading ? "Lataa..." : "Peruuta"}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={5} md={7} lg={8}>
                        {!!buttonMessage && (
                          <Typography
                            variant="body2"
                            color={buttonMessageStyle}
                          >
                            {buttonMessage}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Box>
        </FormGrid>
      )}

      <Box marginY={1}>
        <Typography variant="h6">
          Lisätiedot{" "}
          {showUpdateStatus ? reservationData?.channel : "LT Hätäpalvelu"}:
        </Typography>
      </Box>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <DataBox title="Tilauksen tiedot" data={generalInfo} />
        </Grid>
        <Grid item xs={12}>
          <DataBox title="Asiakkaan ajoneuvo" data={customerVehicle} />
        </Grid>
        <Grid item xs={12}>
          <DataBox
            title="Tapahtumapaikka"
            data={locationInfo(externalData?.incidentLocation)}
          />
        </Grid>
        <Grid item xs={12}>
          <DataBox
            title="Korjaamo"
            data={locationInfo(externalData?.repairShop)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function ContentMenu(props) {
  const { apiCallTab, handleApiCallTabChange, values } = props;
  const { mobileViewPort } = useContext(AppContext);

  if (!mobileViewPort) {
    return (
      <Tabs
        value={apiCallTab}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        onChange={handleApiCallTabChange}
      >
        <Tab label="Hyväksy tilaus" />
        <Tab
          label="Noudon tiedot"
          disabled={
            values?.innovoiceStatus === "NONE" || !values?.innovoiceStatus
          }
        />
        <Tab
          label="Palautuksen tiedot"
          disabled={
            values?.innovoiceStatus === "NONE" || !values?.innovoiceStatus
          }
        />
        <Tab
          label="Tilauksen peruutus"
          disabled={values?.innovoiceStatus === "RETURNED"}
        />
      </Tabs>
    );
  }

  if (mobileViewPort) {
    return (
      <Select
        value={apiCallTab}
        fullWidth
        onChange={(e) => handleApiCallTabChange(null, e.target.value)}
      >
        <MenuItem value={0}>
          <Typography>Hyväksy tilaus</Typography>
        </MenuItem>
        <MenuItem value={1}>
          <Typography>Noudon tiedot</Typography>
        </MenuItem>
        <MenuItem value={2}>
          <Typography>Palautuksen tiedot</Typography>
        </MenuItem>
        <MenuItem value={3}>
          <Typography>Tilauksen peruutus</Typography>
        </MenuItem>
      </Select>
    );
  }
}
