import { Box, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { getTotalPrice } from "../../../utils/utilities";
import { useContext, useEffect, useMemo, useState } from "react";
import { ReservationContext } from "../../../contextProviders/ReservationContext";
import { centsToLocalString, parseToCents } from "../../../../../shared/money";
import { useFetchPaymentEvents } from "../../../../../services/fetchPaymentEvents";
import { useFetchInvoices } from "../../../../../services/fetchInvoices";
import { devStaging } from "../../../../../features";
import { useAtomValue, useSetAtom } from "jotai";
import { reservationTotalPriceAtom } from "../../../../../stores/reservationTotalPrice";
import { vehiclePricesAtom } from "../../../../../stores/vehiclePrices";

function UnpaidStatus({ totalInvoiceSum, totalPaymentSum, paymentStatus, unpaidAmount }) {
  if (paymentStatus?.startsWith("COMPLETED") && totalInvoiceSum === 0 && totalPaymentSum === 0 ) {
    return (
      <Typography variant="body2" ml={2}>
        Varaus merkattu maksetuksi
      </Typography>
    );
  }

  return (
    <Typography variant="body2" ml={2}>
      Maksamatta: <b>{unpaidAmount}</b>
    </Typography>
  );
}

export default function TotalPrice({ mobileViewPort }) {
  const { values } = useFormikContext();
  const { FEATURE_INVOICING } = useContext(ReservationContext);
  const [loading, setLoading] = useState(false);
  const setTotalPrice = useSetAtom(reservationTotalPriceAtom);
  const totalPrice = useAtomValue(reservationTotalPriceAtom);
  const vehiclePrices = useAtomValue(vehiclePricesAtom);

  const isDevStaging = !!devStaging(process.env.REACT_APP_ENV);

  const { paymentEvents: fetchedPaymentEvents } = useFetchPaymentEvents(
    values.id,
    FEATURE_INVOICING
  );

  const { invoices: fetchedInvoices } = useFetchInvoices(
    values.id,
    FEATURE_INVOICING
  );

  const totalInvoiceSum = useMemo(
    () =>
      fetchedInvoices?.reduce(
        (acc, invoice) =>
          acc +
          invoice.items.reduce(
            (total, item) => total + item.quantity * item.unitprice,
            0
          ),
        0
      ),
    [fetchedInvoices]
  );

  const totalPaymentSum = useMemo(
    () =>
      fetchedPaymentEvents
        ?.filter(
          (event) =>
            (event.returnCode === null || event.returnCode === 0) &&
            !event.isDeleted
        )
        .reduce((acc, event) => acc + event.paymentTotal, 0),
    [fetchedPaymentEvents]
  );

  const unpaidAmount = useMemo(() => {
    if (loading) return "Lasketaan...";
    const totalPriceCents = parseToCents(totalPrice);
    const totalPaidCents = totalPaymentSum + totalInvoiceSum;
    const unpaidCents = totalPriceCents - totalPaidCents;

    return `${centsToLocalString(unpaidCents)}€`;
  }, [totalPrice, totalInvoiceSum, totalPaymentSum, loading]);

  useEffect(() => {
    const calculateTotalPriceHandler = () => {
      setLoading(true);
      try {
        const calcPrice = getTotalPrice({
          additionalServices: values?.additionalServices,
          wildCardVehicles: values?.wildCardVehicles,
          reservationVehicles: vehiclePrices,
        });
        console.log("calcPrice", calcPrice);
        setTotalPrice(calcPrice);
      } catch (error) {
        console.log("error calculating total price in TotalPrice", error);
      } finally {
        setLoading(false);
      }
    };
    calculateTotalPriceHandler();
  }, [
    values.additionalServices,
    vehiclePrices,
    values.wildCardVehicles,
    setTotalPrice,
  ]);

  const paymentMethodLabels = {
    PENDING: "Odottaa maksutavan valintaa tai verkkomaksua",
    PENDING_INVOICE: "Laskutettava",
    COMPLETED_INVOICE: "Laskutettu",
    COMPLETED_MANUAL: "Maksettu",
    COMPLETED: "Maksettu (verkkomaksu)",
  };

  const paymentLabel =
    paymentMethodLabels[values.paymentStatus] || "Noudettaessa";

  if (!mobileViewPort)
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Typography variant="subtitle1" textAlign="center" gutterBottom>
          Maksun tila: <b>{paymentLabel}</b>
        </Typography>
        <Box
          display="flex"
          flexDirection={mobileViewPort ? "column" : "row"}
          justifyContent="center"
          width="100%"
        >
          <Typography variant="body2">
            Varaus yhteensä:
            <b>{totalPrice ? `${totalPrice}€` : "Lasketaan..."}</b>
          </Typography>
          <UnpaidStatus
            totalInvoiceSum={totalInvoiceSum}
            totalPaymentSum={totalPaymentSum}
            unpaidAmount={unpaidAmount}
            paymentStatus={values.paymentStatus}
          />
        </Box>
      </Box>
    );
}
