import { API, graphqlOperation } from "aws-amplify";
import useSWR from "swr";
import { searchCustomers } from "../graphql/queries";

export const useGetBrokers = () => {
  const { data, error, isLoading } = useSWR("brokers", fetchBrokers, {
    revalidateOnFocus: false,
  });

  // Filter out deleted brokers for dropdown display purposes
  const brokers = data ? data.filter((broker) => !broker.isDeleted) : [];

  return { brokers, isLoading, error };
};

const fetchBrokers = async () => {
  try {
    const filterConditions = {
      clientType: { eq: "BROKER" },
    };

    const brokerData = await API.graphql(
      graphqlOperation(searchCustomers, {
        filter: filterConditions,
      })
    );
    const allBrokers = brokerData.data.searchCustomers.items;
    return allBrokers; // Save all brokers to state
  } catch (error) {
    console.error("Error fetching brokers", error);
  }
};
