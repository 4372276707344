import { Typography, Stack, Box } from "@mui/material";

export function FormContainer({ children, title, visibility }) {
  if (visibility)
    return (
      <>
        <Box sx={{ display: "flex", height: "71px", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: {
                xs: "1.2rem",
                sm: "1.4rem",
              },
              fontFamily: "Sofia Pro",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Stack spacing={1} direction={"column"}>
          {children}
        </Stack>
      </>
    );
}
