import { ECustomerInfo } from "./LanguagePack/translatedFields";
import { Divider, Field } from "./common";
import { Stack, Grid } from "@mui/material";
export default function ContractCustomerInformation({
  reservation,
  selectedLanguage,
}) {
  const { name, ssn, address, postalCode, city, phone, email } = reservation;
  const fields = ECustomerInfo[selectedLanguage];

  const maskedSsn = ssn
    ? ssn.slice(0, -4) + ssn.slice(-4).replace(/./g, "*")
    : "";

  return (
    <Grid item xs={4} sx={{ border: "1px solid lightgrey" }}>
      <Field value={name} label={fields.NAME} />
      <Stack direction={"row"}>
        <Field value={maskedSsn} label={fields.SSN} />
        <Divider />
        <Field value={""} label={fields.DRIVERS_LICENSE_CLASS} />
      </Stack>
      <Field
        value={`${address}, ${postalCode}, ${city}`}
        label={fields.ADDRESS}
      />
      <Stack direction={"row"}>
        <Field value={phone} label={fields.PHONE} removeBorders />
        <Divider />
        <Field value={email} label={fields.EMAIL} removeBorders />
      </Stack>
    </Grid>
  );
}
