import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { useFormikContext } from "formik";
import { FormGrid } from "../common/FormComponents";

export default function ProductAttributes({
  ADDITIONAL_FIELDS,

  categories,
  mobileViewPort,
}) {
  const { handleChange, handleBlur, values, errors, touched } =
    useFormikContext();
  const AttributeSelection = ({ options, name, label, required }) => {
    return (
      <FormControl
        variant="outlined"
        fullWidth
        required={required}
        error={touched[name] && !!errors[name]}
      >
        <InputLabel id="categoryLabel">{label}</InputLabel>
        <Select
          labelId="attributeSelection"
          value={values[name] || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          name={name}
          label={label}
        >
          {(options || []).map((option, index) => {
            return (
              <MenuItem key={option.label + index} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
        {touched[name] && errors[name] && (
          <FormHelperText>{errors[name]}</FormHelperText>
        )}
      </FormControl>
    );
  };

  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      sx={{ marginTop: "25px" }}
    >
      <FormGrid container direction={"row"} spacing={2}>
        <FormGrid md={6}>
          <TextField
            name="deductible"
            label="Omavastuu"
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            fullWidth
            value={values.deductible ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGrid>
        <FormGrid md={6}>
          <TextField
            value={values.includedDistance ?? ""}
            name="includedDistance"
            label="Sisältyvät kilometrit"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGrid>

        {ADDITIONAL_FIELDS.map((field) => {
          if (field?.options)
            return (
              <FormGrid key={field.label + field.label} md={6}>
                <AttributeSelection
                  options={field.options}
                  categories={categories}
                  name={field.key}
                  label={field.label}
                />
              </FormGrid>
            );

          return (
            <FormGrid
              md={
                field.key === "other" ? 12 : field.key === "equipments" ? 12 : 6
              }
              key={field.key}
            >
              <TextField
                fullWidth
                name={field.key}
                label={field.label}
                value={values[field.key] ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={
                  field.unit
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            {field.unit}
                          </InputAdornment>
                        ),
                      }
                    : null
                }
                multiline={field.key === "other" ? true : false}
              />
            </FormGrid>
          );
        })}
      </FormGrid>
    </Grid>
  );
}
