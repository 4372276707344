import { Reservation } from "~/graphql/API";
import { defaultInvoiceItem, defaultValues } from "./defaultValues";
import { EInvoiceType } from "../enums/InvoiceType";
import { InvoiceCustomer } from "../interfaces/invoice-customer";
import parsePhoneNumberFromString from "libphonenumber-js";
import { formatDateTime } from "~/utils/date";
import { getTotal } from "~/utils/getTotal";
import { differenceInCalendarDays } from "date-fns";

type Args = {
  invoiceType: EInvoiceType;
  customer?: InvoiceCustomer;
  reservations: Reservation[];
};

function getInitialValues({
  invoiceType,
  customer,
  reservations,
}: Args): typeof defaultValues {
  const isCompanyCustomer = !!customer?.companyBusinessId;

  const [firstname, ...lastname] = (customer?.name || "").split(" ");

  let buyer_reference = customer?.companyBillingRef;

  // apply companyBillingRef from the first reservation if it exists
  if (reservations?.length === 1) {
    if (reservations[0]?.companyBillingRef) {
      buyer_reference = reservations[0]?.companyBillingRef;
    }
  }
  if (invoiceType === EInvoiceType.INSURANCE_COMPANY) {
    buyer_reference = reservations
      .reduce((acc, reservation) => {
        acc.push(
          `${"billingPermitCode"} / ${reservation.customerCarRegistrationPlate}`
        );

        return acc;
      }, [] as string[])
      .join(", ");
  }

  return {
    firstname,
    lastname: lastname.join(" "),
    address:
      customer?.address ||
      customer?.companyBillingAddress ||
      defaultValues.address,
    postalCode:
      customer?.postalCode ||
      customer?.companyPostalCode ||
      defaultValues.postalCode,
    city: customer?.city || customer?.companyCity || defaultValues.city,
    email: customer?.email || defaultValues.email,
    phone:
      parsePhoneNumberFromString(reservations[0]?.phone || "")?.number ||
      defaultValues.phone,
    ssn: reservations.find(({ ssn }) => ssn)?.ssn || defaultValues.ssn,
    contact_person: customer?.name || defaultValues.contact_person,
    companyName: customer?.companyName || defaultValues.companyName,
    companyBusinessId:
      customer?.companyBusinessId || defaultValues.companyBusinessId,

    // Read services from the Reservation, rest comes from the Invoice sent to insurance company
    items: reservations?.flatMap(getReservationItems),

    customer_type: isCompanyCustomer ? "company" : "person",
    payment_terms: defaultValues.payment_terms,
    delivery_date: defaultValues.delivery_date,
    seller_reference: defaultValues.seller_reference,
    buyer_reference: buyer_reference || defaultValues.buyer_reference,
    e_invoice_operator:
      customer?.companyEBillingOperator || defaultValues.e_invoice_operator,
    e_invoice_address:
      customer?.companyEBillingAddress || defaultValues.e_invoice_address,
    vat_included: defaultValues.vat_included,
    delivery_by: defaultValues.delivery_by,
    insuranceCompany:
      reservations.find(
        ({ insuranceData }) => insuranceData?.providerBusinessId
      )?.insuranceData?.providerBusinessId || defaultValues.insuranceCompany,
    insuranceCoveragePercentage:
      reservations.find(
        ({ insuranceData }) => insuranceData?.coveragePercentage
      )?.insuranceData?.coveragePercentage ||
      defaultValues.insuranceCoveragePercentage,
    insuranceVatDeductible:
      reservations.find(({ insuranceData }) => insuranceData?.vatDeductible)
        ?.insuranceData?.vatDeductible || defaultValues.insuranceVatDeductible,
    vehicle_class:
      reservations.find(
        ({ reservationVehicles }) => reservationVehicles?.[0]?.categoryName
      )?.reservationVehicles?.[0]?.categoryName || defaultValues.vehicle_class,
  };
}

export default getInitialValues;

function getReservationItems(
  reservation: Reservation
): typeof defaultInvoiceItem[] {
  return [
    ...(reservation.reservationVehicles || []).map((vehicle) => {
      const tax = vehicle?.tax || defaultInvoiceItem.vat;

      return {
        reservation: reservation,
        rentalDays: differenceInCalendarDays(
          new Date(reservation.startTime!),
          new Date(reservation.returnTime!)
        ),
        name: vehicle
          ? vehicle.name + " " + vehicle.registrationPlate
          : "unknown vehicle",
        description:
          "Vuokraus välillä " +
          formatDateTime(reservation.startTime as string) +
          " - " +
          formatDateTime(reservation.endTime as string),
        unitprice: getTotal(vehicle?.price || 0).toFixed(2),
        quantity: defaultInvoiceItem.quantity,
        unit: defaultInvoiceItem.unit,
        vat: tax,
      };
    }),
    ...(reservation.additionalServices || []).map((service) => {
      const tax = service.tax || defaultInvoiceItem.vat;
      const quantity = service.quantity || 1;

      return {
        reservation: reservation,
        name: service.description || "",
        description:
          "Vuokraus välillä " +
          formatDateTime(reservation.startTime as string) +
          " - " +
          formatDateTime(reservation.endTime as string),
        unitprice: getTotal(service.price || 0).toFixed(2),
        quantity,
        unit: "kpl",
        vat: tax,
      };
    }),
  ];
}
